<template>
    <div class="aside" :class="isShow ? 'show' : 'hide'">
        <!-- <div class="item weline">
        </div>
        <div class="item combo">
        </div>
        <div class="item gift">
        </div>
        <div class="bottom" @click="isShow = !isShow">
            <img  :class="isShow ? 'active' : ''" src="../../static/image/btn-drop.svg" alt="">
        </div> -->
        <img src="../../static/image/icon_sevice.png" alt="" @click="jumpUrl()">
    </div>
</template>
<script>

export default {
    props: {
    },
    data() {
        return {
            isShow: true
        };
    },
    computed: {

    },

    methods: {
      jumpUrl(){
        this.$router.push('/my/service')
      },
    },
};
</script>

<style lang="less" scoped>
.aside {
    position: fixed;
    bottom: 10%;
    right: 0;
    z-index: 999;
    // // width: 50px;
    // width: 48px;
    // border: 1px solid hsla(0, 0%, 100%, .3);
    // box-shadow: 0 0.02rem 0 hsla(0, 0%, 100%, .4), 0 0.06rem 0.08rem rgba(0, 0, 0, .15);
    // background: linear-gradient(90deg, hsla(0, 0%, 100%, .35), hsla(0, 0%, 100%, .2));
    // padding: 10px 6px 0;
    // border-radius: 14px;

   img {
    width: 60px;
    height: 60px;
   }
}
</style>
