export default {
    common: {
        contactNow: "Contact Now",
        minPrice: "Min",
        maxPrice: "Max",
        address: {
            title: "지갑 주소",
            account_number: "계좌 번호",
            add_btn: "주소 추가",
            label_type: "유형 선택",
            place_type: "유형을 선택하세요",
            label_number: "계좌번호",
            place_number: "계좌번호를 입력하세요",
            wallet_address: '지갑 주소',
            bank_name: '은행 이름',
            bank_address: "은행 주소",
            name: "이름",
            payee_address: '수취인 주소',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "지점 이름",
            branch_code: "지점 코드",
            bank_code: "은행 코드",
            phone: "핸드폰 번호",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "내용을 입력하십시오.",
        },
        priceType: {
            fast_sum: "합계",
            fast_three_all: "세 개의 같은 번호 총선",
            fast_three_one: "세 개의 동일한 번호 단일 선택",
            fast_three_diff: "세 가지 다른 숫자",
            fast_three_continue: "삼지선다형(TNT)(컴퓨팅) (컴퓨팅)",
            fast_two_some: "이중 인덱스 객관식",
            fast_two_one: "더블 개인 선택",
            fast_two_diff: "이등분 번호(전자)",
            fast_one_one: "불 켜짐. 체 하나에 하나 지불",
            racing_winner: "챔피언",
            racing_second: "2위(스포츠 대회에서)",
            racing_third: "동메달리스트",
            lotto_one: "A",
            lotto_two: "B",
            lotto_three: "C",
            lotto_four: "D",
            lotto_five: "E",
            wingo_sum: "합계",
            wingo_color: "Color",
            Big: "가장 오래된",
            Small: "Few",
            Single: "모노",
            Double: "성 ",
            Red: "보너스",
            Green: "녹색",
            Purple: "Purple",
        },
        img_error: "이미지를 로드하지 못했습니다.",
        more: "더 보기",
        cancel: "취소",
        confirm: "정의",
        tips: "힌트：",
        no_set: "고정되지 않음",
        submit: "제출",
        copy_success: "복사 성공！",
        copy_error: "복제 실패！",
        currency: "$",
        filter: "심사",
        edit: "수정",
        gameStart: "게임 시작",
        prizenumber: "당첨 번호",
        mynumber: "내 번호.",
        addresss: "링크 주소는 다음과 같습니다.",
        gameAgain: "다시 한 번 말씀드립니다.",
        gamerule: "게임 규칙",
        winning_numbers: "당첨 번호",
        winning: "우승을 축하합니다!！",
        notwin: "안타깝게도 당첨되지 못했습니다!",
        logout: "정말 로그아웃하시겠습니까?？",
        success: "성공 사례",
        search: "STH를 찾습니다.",
        withdraw0: "신청서(양식 등)",
        withdraw1: "진행 중",
        withdraw2: "성공 사례",
        withdraw3: "실패",
        balanceNot: "계정 잔액 부족",
        canceled: "취소됨",
        bill: {
            bill101: "사용자 충전",
            bill102: "시스템 통합업체",
            bill103: "시스템 정지",
            bill104: "시스템 고정 해제",
            bill201: "사용자 출금",
            bill202: "시스템 공제",
            bill203: "출금 성공",
            bill204: "출금 감사 실패",
            bill205: "출금 수수료",
            bill301: "게임 참여하기",
            bill302: "게임 수익",
        }
    },
    add: {
        cqk: "입금/출금",
        activity: "프로모션",
        online: "온라인 고객 서비스",
        lottery: "복권 홀",
        game: "플레이",
        lotter: "복권",
        search_lotter: "색상 검색",
        search: "상담(문서 등)",
        di: "(서수를 나타내는 접두사, 예: 첫 번째, 두 번째 등)",
        qi: "기간",
        ju: "의 첫 달이 만료되기 전에",
        feng: "회로 차단기",
        result: "추첨 결과",
        logout: "중단",
        available_balance: "사용 가능한 잔액",
        notice_title: "플랫폼 발표",
        tip30: "30분 동안 더 이상 팝업이 표시되지 않습니다.",
        close: "Cloture",
        personal: "개인 센터",
        level: "계층 구조",
        common: "일반적으로 볼 수 있는",
        news: "최신 오퍼",
        help_center: "도움말 센터",
        app_download:"앱 다운로드",
    },
    addnew: {
        remainder: "남은 시간",
        history: "개상 역사",
        trend: "추세",
        my_bet: "내 메모",
        bei: "배수",
        agree: "동의합니다",
        rule: "예매 규칙",
        total_amount: "총 금액",
        wallet_balance: "지갑 잔액",
        total: "합계",
        random_bet: "랜덤 베팅",
    },
    playMethod: {
        p1: "보려는 게임의 규칙을 선택하세요.",
        p2: "규칙을 숙지하면 수입이 두 배로 늘어납니다.",
        des1: "개요",
        des2: "100에 거래하는 경우 서비스 수수료 2%를 공제한 계약 금액은 98입니다.",
        fived: {
            p1: "5D 복권 게임 규칙",
            p2: "그리기 지침",
            p3: "각 호마다 무작위로 선택된 5자리 숫자(00000-99999)",
            p4: "예를 들어:",
            p5: "현재 추첨 번호는 12345입니다.",
            p6: "A = 1",
            p7: "B = 2",
            p8: "C = 3",
            p9: "D = 4",
            p10: "E = 5",
            p11: "And = A + B + C + D + E = 15",
            p12: "플레이 방법",
            p13: "플레이어는 6가지 결과와 총합인 A, B, C, D, E에 베팅을 지정할 수 있습니다.",
            p14: "A, B, C, D, E사용 가능",
            p15: "숫자(0 1 2 3 4 5 6 7 8 9)",
            p16: "낮음 (0 1 2 3 4)",
            p17: "높음(5 6 7 8 9)",
            p18: "홀수(1 3 5 7 9)",
            p19: "짝수 (0 2 4 6 8)",
            p20: "합계 = A+B+C+D+E를 구매할 수 있습니다.",
            p21: "낮음(0-22)",
            p22: "높음(23-45)",
            p23: "홀수(1 3---43 45)",
            p24: "짝수 (0 2---42 44)",
        },
        racing: {
            p1: "게임 플레이",
            p2: "※상위 3개 및：",
            p3: "처음 세 개의 차량 번호의 합이 큰 경우 16보다 크고, 작은 경우 16보다 작습니다. 짝수는 더블, 홀수는 싱글이라고 합니다. ※ 처음 세 개의 합계:",
            p4: "※1~3위：",
            p5: "차량 번호 지정, 각 차량 번호는 베팅 조합이며, 베팅 이름에 해당하는 추첨 결과 '베팅 차량 번호'는 당첨자로 간주되며, 나머지 상황은 당첨자가 아닌 것으로 간주됩니다.",
            p6: "※양쪽 모두：",
            p7: "싱글과 더블, 대형과 소형을 의미합니다.",
            p8: "※싱글 및 더블：",
            p9: "짝수는 2, 4, 6, 8, 10과 같이 더블이라고 하고 홀수는 1, 3, 5, 7, 9와 같이 싱글이라고 합니다.",
            p10: "※크고 작은",
            p11: "6보다 큰 숫자는 6, 7, 8, 9, 10과 같이 큰 숫자로 간주하고, 5보다 작은 숫자는 1, 2, 3, 4, 5와 같이 작은 숫자로 간주합니다.",
        },
        wingo: {
            p1: "게임 플레이",
            p2: "※단일 수치：",
            p3: "베팅할 번호를 선택하면 추첨 번호와 베팅 번호가 당첨 번호와 동일한 것으로 간주되며, 나머지 경우는 당첨되지 않은 것으로 간주됩니다.",
            p4: "※분홍색：",
            p5: "복권 번호가 0, 2, 4, 6, 8이면 빨간색에 베팅하면 당첨으로 간주되며, 나머지 시나리오에서는 당첨이 되지 않습니다.",
            p6: "※친환경：",
            p7: "녹색에 베팅하는 경우 추첨 번호가 1, 3, 5, 7, 9이면 당첨자로 간주되며, 나머지 시나리오에서는 비당첨자로 간주됩니다.",
            p8: "※바이올렛(색상)：",
            p9: "보라색에 베팅한 경우 복권 번호가 0과 5인 경우 당첨자로 간주되며, 나머지 경우에는 비당첨자로 간주됩니다.",
            p10: "※크고 작은：",
            p11: "5보다 큰 숫자는 5, 6, 7, 8, 9와 같이 큰 숫자로 간주하고 4보다 작은 숫자는 0, 1, 2, 3, 4와 같이 작은 숫자로 간주합니다.",
        },
        lottery: {
            p1: "게임 플레이 ~ 무작위로 6개가 열립니다.",
            p2: "A：대형 및 소형, 싱글 및 더블",
            p3: "크기: 복권 번호 3개 합이 11~18이면 큰 번호로 간주하고, 3~10이면 작은 번호로 간주합니다.",
            p4: "싱글 및 더블: 복권 번호 3개를 합한 총합이 싱글이면 싱글이 당첨되고, 더블이면 더블이 당첨됩니다.",
            p5: "B：번호 확인",
            p6: "복권 번호의 합이 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18이면 당첨되며, 예를 들어 복권 번호가 1, 2, 3이고 합이 6이면 '6'에 베팅하면 당첨됩니다.",
            p7: "C：단일 주사위",
            p8: "세 개의 복권 번호 중 하나가 선택한 번호와 같으면 당첨으로 간주됩니다. 예: 복권 번호가 1, 1, 3인 경우 싱글 주사위 1 또는 싱글 주사위 3에 베팅하면 당첨으로 간주됩니다. (지정된 포인트가 몇 번 나오든 상관없이 지불금은 한 번만 두 배가 되지 않습니다).",
            p9: "D：더블 주사위",
            p10: "당첨 번호 중 두 개가 같고 선택한 더블 주사위 조합과 일치하는 경우 당첨됩니다. 예: 상품 번호가 1, 1, 3인 경우 더블 주사위 1, 1에 베팅하면 1, 1이 당첨됩니다.",
            p11: "E：라운드 로빈, 풀 라운드 로빈",
            p12: "주사위: 세 개의 복권 번호가 같고 선택한 주사위 조합과 일치하면 상품이 당첨됩니다.",
            p13: "풀 서클 주사위: 복권 번호의 세 글자가 모두 같으면 당첨됩니다.",
            p14: "F：두 개의 주사위 조합",
            p15: "2 주사위 조합을 선택하면 추첨 결과가 선택한 조합과 동일하면 당첨으로 간주됩니다. 예: 복권 번호가 1, 2, 3인 경우 2 주사위 조합 12, 2 주사위 조합 23, 2 주사위 조합 13에 베팅하면 당첨으로 간주됩니다.",
        }
    },
    webadd: {
        hot: "유행 중",
        all: "전체",
        home: "팔러",
        hot_lottery: "인기 복권",
        select_channel: "충전 채널 선택",
        select_bank: "은행 선택",
        top_tab1: "상품 받기",
        top_tab2: "TOP",
        top_tab3: "	My",
        web_time: "웹사이트 시간",
        trade: "거래",
    },
    footer: {
        foot1: "무화과 시작",
        foot2: "플레이",
        foot3: "TOP",
        foot4: "함수",
        foot5: "My",
    },
    navBar: {
        back_home: "홈 페이지로 돌아가기",
        help_center: "도움말 센터",
        contact_service: "고객 서비스에 문의",
        balance: "잔액",
        my_account: "내 계정",
        account: "온라인 계정",
        record: "기록",
        proxy: "Proxy",
        other: "나머지",
        deposit: "충전",
        withdraw: "자금 인출",
        deposit_type1: "온라인 뱅킹",
        deposit_type2: "은행 송금",
        deposit_type3: "디지털 통화",
        deposit_type4: "OVO",
        logout: "중단",
        login: "로그인",
        register: "등록",
        nav1: "복권",
        nav2: "플레이",
        nav3: "함수",
        nav4: "휴대폰",
        nav5: "내 계정",
    },
    activity: {
        tit1: "일일 보너스",
        p1: "데일리 잭팟은 사용자가 어제 베팅한 금액에 따라 상금이 지급되는 보너스로, 상한선이 없습니다!",
        activity_detail: "이벤트 세부 정보",
        yestoday_bet: "어제의 베팅",
        bonus_ratio: "상금 증가 비율",
        now_level: "현재 레벨",
        receive_bonus: "추가 학점을 받을 수 있는 자격",
        receive: "Get",
        not_receive: "사용 불가",
        activity_time: "활동 시간",
        p2: "1、데일리 보너스는 매일 오전 00시 20분 이후에 수령할 수 있습니다.",
        p3: "2、보너스 비율은 사용자의 레벨과 어제 누적 베팅 금액에 따라 일정 비율의 보너스가 지급됩니다!",
        p4: "3、출금 및 기타 유효하지 않은 베팅은 계산되지 않습니다.",
        p5: "4、유효한 베팅은 [청구서 추첨 결제일]을 충족해야 하며, 베팅 후 며칠이 지나 청구서가 추첨되면 청구서의 유효한 베팅 금액이 [추첨 결제일]에 누적됩니다.",
        p6: "5、예: 10/1에 베팅을 했는데 추첨이 10/15까지 이루어지지 않는 경우, 해당 베팅의 유효한 베팅 금액은 10/15에 누적되며 사용자는 10/16에 해당 베팅의 보너스를 수령할 수 있습니다.",
        p7: "6、이벤트 당일 24:00 이전에 보너스를 수령하지 못하면 이벤트 자격이 자동으로 포기되는 것으로 간주됩니다.",
    },
    game: {
        history_tit: "역사상 상품",
        play_tit: "플레이 방법",
        official_award: "오리지널 공식 상품",
        betting: "베팅 진행 중",
        waiting: "추첨을 기다리는 중",
        opening: "그리기 진행 중입니다.",
        settled: "정착",
        auto_change: "자동 전환",
        height_win: "최대 승리",
        total: "합계",
        pour: "금액에 대한 분류기",
        every_pour: "각각",
        total_pour: "총 베팅 횟수",
        total_amount: "총 베팅 금액",
        now_bet: "지금 베팅하기",
        my_bet: "내 베팅",
        winning: "상품 받기",
        top: "TOP",
        winning_tip: "당첨 정보는 실시간으로 업데이트됩니다.",
        top_tip: "상위 랭킹",
    },
    record: {
        lottery: "복권",
        game: "플레이",
        today_profit_loss: "오늘의 손익",
        label_formula: "손익분기점 공식",
        formula: "공식: 승리 - 베팅 + 활동 + 리베이트",
        profitLoss_label1: "상품 받기",
        profitLoss_label2: "함수",
        profitLoss_label3: "베터",
        profitLoss_label4: "제휴사 보상",
        profitLoss_label5: "송금",
        profitLoss_label6: "수취인",
        profitLoss_label7: "빨간색 패킷 보내기",
        profitLoss_label8: "빨간색 패킷 받기",
        trade_tab1: "전체",
        trade_tab2: "충전",
        trade_tab3: "자금 인출",
    },
    table: {
        label_no: " No.",
        label_time: "횟수",
        label_result: "결국",
        label_size: "크기",
        label_single_double: "홀수 및 짝수",
        label_sun_value: "합계",
        no_data: "사용 가능한 데이터 없음",
        big: "가장 오래된",
        small: "Few",
        single: "모노",
        double: "성 ",
        label_issue: "발행 번호",
        label_bet_amount: "베팅 금액",
        label_reward: "시상금",
        no_reward: "승자가 아닙니다.",
        is_reward: "상품 받기",
        label_level: "계층 구조",
        label_integral: "성장 포인트",
        label_award: "진급 보상",
        label_skip_awardl: "레벨 건너뛰기 보너스",
        label_register_link: "등록 링크",
        label_remark: "참고",
        label_rise_time: "생성 시간",
        label_register_number: "등록 횟수",
        label_operate: "Rig",
        label_gametit: "색상/게임",
        label_lower_rebate: "제휴사 보상",
        label_self_rebate: "자체 리베이트",
        label_set_rebate_range: "리베이트 범위",
        bonus_state: "보너스 상태",
        label_serial_number: "매복 신호",
        label_income_amount: "수입 금액",
        label_expend_amount: "지출 금액",
        label_available_balance: "사용 가능한 잔액",
        label_abstract: "초록",
        label_details: "세부 정보",
        label_account: "온라인 계정",
        label_amount: "금액 합계",
        label_bet_cont: "베팅 내용",
        label_ottery_number: "복권 번호",
        order_number: "홀수",
        play_methods: "플레이 방식",
        label_periods: "단계",
        label_number: "숫자",
        label_color: "색상",
    },
    my: {
        last_login_time: "마지막 로그인 시간",
        logout_account: "계정에서 출금하기",
        my_account: "내 계정",
        account: "온라인 계정",
        personal_tit: "개인 프로필",
        security_tit: "보안 센터",
        bank_tit: "은행 계좌 번호",
        record: "기록",
        profit_loss_tit: "오늘의 손익",
        trade_record_tit: "거래 기록",
        bet_record: "베팅 기록",
        proxy: "Proxy",
        manag_tit: "대행사 관리",
        report_tit: "위임장",
        lower_bet_tit: "낮은 레벨 베팅",
        lower_trade_tit: "하위 거래",
        other: "나머지",
        gift_tit: "이벤트 선물",
        message_tit: "뉴스 센터",
        personal_data: "개인 프로필",
        balance: "잔액",
        last_sync_time: "마지막 동기화 시간",
        deposit: "충전",
        withdraw: "자금 인출",
        yesterday_deposit_time: "어제 평균 충전 시간",
        yesterday_withdraw_time: "어제 현금 인출에 걸린 평균 시간",
        help_tit: "도움말 센터",
        all: "전체",
        lower_report: "종속문",
        about_us: "회사 소개",
        logout: "등장",
        invite: "친구 초대",
        lang_change: "언어 전환",
    },
    account: {
        add_bank_account: "은행 계좌 번호 추가",
        bank_tip: "은행 계좌는 최대 5개까지 추가할 수 있습니다. 출금에 성공한 은행 계좌는 자동으로 잠기며 삭제하거나 수정할 수 없습니다. 출금에 성공하지 못한 은행 계좌 번호는 수정할 수 있지만 삭제할 수는 없습니다.",
        account_msg: "계정 정보",
        growth_value: "성장률",
        noset_nickname: "닉네임이 설정되지 않았습니다.",
        nationality: "시민권",
        next_level: "다음 단계로 이동",
        integral: "학생이 획득한 총 학점",
        integral_tip: "1.00 $을 충전할 때마다 1포인트가 적립됩니다.",
        level_system: "계층 구조",
        change_avatar: "아바타 변경",
        loginpsd_tip: "로그인 비밀번호 6~16자리 문자와 숫자 조합",
        modify_psd: "비밀번호 변경",
        securitypsd_tip: "보안 비밀번호(6자리 조합)",
        forget_securitypsd: "보안 코드 분실",
        confidentiality_tip: "보안 문제, 계정 번호 또는 비밀번호 분실 시 사용자 계정 정보를 검색할 수 있습니다.",
        bank_account: "은행 계좌 번호",
        edit_nickname: "닉네임 수정",
        confidentiality: "보안 문제",
        accumulated_winning: "프로그레시브 잭팟",
    },
    agent: {
        illustrate_p1: "이 페이지가 표시되면 계정이 플레이어 계정인 동시에 에이전트 계정이라는 의미이며, 이를 통해 자신의 베팅을 할 수 있을 뿐만 아니라 낮은 레벨의 플레이어를 육성하고 리베이트 커미션을 받을 수 있습니다.",
        illustrate_p2: "리베이트 적립 방법？",
        illustrate_p3: "받을 수있는 리베이트는 자신의 리베이트와 하위 레벨의 리베이트의 차이와 동일합니다 (예 : 자신의 리베이트 5와 하위 레벨의 리베이트 3), 하위 레벨 베팅 금액 2의 리베이트를받을 수 있습니다 (예 : 하위 레벨이 100 $에 베팅하면 2 $을 받게됩니다). 계정의 하위 레벨을 클릭하면 자신의 리베이트를 볼 수 있으며 하위 레벨의 리베이트를 설정할 수도 있습니다.",
        illustrate_p4: "부하 직원을 위해 계좌를 개설하는 방법？",
        illustrate_p5: "하위 계정을 클릭하고 먼저 하위 계정에 대한 리베이트를 설정하고 성공적인 초대 코드가 생성되고 초대 코드가 하위 등록, 등록, 그는 귀하의 부하 직원이며 사용자 관리를 클릭하면 그의 등록 된 계정을 볼 수 있습니다.",
        illustrate_p6: "부하 직원에게 에이전트 유형 계정을 설정하면 부하 직원은 계속해서 부하 직원을 육성할 수 있습니다. 플레이어 유형을 설정하면 부하 직원은 베팅만 할 수 있고 부하 직원을 육성할 수 없으며 에이전트 센터를 볼 수 없습니다. 사용자 관리",
        illustrate_tip1: "1、리베이트 배당률이 다르므로 리베이트 배당률 표를 클릭하면 리베이트 배당률을 확인할 수 있습니다.。",
        illustrate_tip2: "2、리베이트가 낮을수록 확률이 낮아지므로 낮은 레벨의 리베이트는 너무 낮게 설정하지 않는 것이 좋습니다!。",
        illustrate_tip3: "3、에이전트 보고서, 다운사이드 베팅, 다운사이드 거래에서 에이전트 개발 현황을 확인할 수 있습니다.。",
        illustrate_tip4: "4、다음 레벨의 개방은 개발 된 레벨 수에 관계없이 에이전트의 유형이며, 리베이트를 받게됩니다.。",
        open_type: "계좌 개설 유형：",
        open_type1: "전체",
        open_type2: "책임 ",
        open_type3: "플레이어",
        invite_tip1: "1、리베이트에 따라 당첨 확률이 다르며, 리베이트가 높을수록 당첨 확률이 높아집니다!。",
        invite_tip2: "2、에이전트가받을 수있는 수수료는 에이전트 자신의 리베이트와 에이전트 자신의 리베이트 6, 낮은 리베이트 4, 에이전트는 낮은 수준의 베팅 금액 2 %, 즉 낮은 수준의 베팅 100 위안의 차이, 에이전트는 2 위안을받을 수 있습니다!。",
        invite_tip3: "3、하위 레벨 리베이트의 값을 낮게 설정할수록 하위 레벨의 확률이 낮아지므로 하위 레벨 리베이트가 너무 낮지 않도록 설정하는 것이 좋습니다!。",
        rebateDialog_tit: "리베이트 보기",
        delete_invite_tip: "이 초대 코드를 삭제하시겠습니까?？",
        rebate_table: "리베이트 확률 표",
        generate_invitation_code: "초대 코드 생성",
        subordinate_tip1: "1、리베이트에 따라 당첨 확률이 다르며, 리베이트가 높을수록 당첨 확률이 높아집니다!。",
        subordinate_tip2: "2、에이전트가받을 수있는 수수료는 에이전트 자신의 리베이트와 에이전트 자신의 리베이트 6, 낮은 리베이트 4, 에이전트는 낮은 수준의 베팅 금액 2 %, 즉 낮은 수준의 베팅 100 위안의 차이, 에이전트는 2 위안을받을 수 있습니다!。",
        subordinate_tip3: "3、하위 레벨 리베이트의 값을 낮게 설정할수록 하위 레벨의 확률이 낮아지므로 하위 레벨 리베이트가 너무 낮지 않도록 설정하는 것이 좋습니다!。",
        manage_tab1: "에이전트 설명",
        manage_tab2: "하위 계정 개설",
        manage_tab3: "초대 코드",
        manage_tab4: "사용자 관리",
        lower_bet_tip: "거래 기록은 최대 21일 동안 보관됩니다.",
        report_label1: "베터",
        report_label2: "상품 받기",
        report_label3: "함수",
        report_label4: "팀 리베이트",
        report_label5: "팀 수익",
        report_label6: "베팅자 수",
        report_label7: "첫 충전 횟수",
        report_label8: "등록자 수",
        report_label9: "부하 직원 수",
        report_label10: "팀 밸런스",
        report_label11: "충전",
        report_label12: "자금 인출",
        report_label13: "상담원 리베이트",
        report_label14: "송금",
        report_label15: "수취인",
        report_label16: "빨간색 패킷 보내기",
        report_label17: "빨간색 패킷 받기",
    },
    fund: {
        tip1: "다음 은행 계좌로 송금해 주세요.",
        tip2: "송금 정보를 신중하게 입력하세요.",
        tip3: " (주문을 제출하기 전에 자금을 이체하지 않으면 결제가 제때 추적되지 않습니다.！)",
        deposit_rate: "입금 환율",
        currency_number: "통화 금액",
        tip4: "송금하려면 해당 디지털 통화 플랫폼을 사용하세요.",
        tip5: "단일 스트로크 제한",
        tip6: "(인증 정보를 입력할 수 없는 경우 거래 영수증 스크린샷을 업로드해 주세요.)",
        click_upload: "업로드하려면 클릭",
        upload_tip: "jpg/png 파일만 업로드할 수 있습니다.",
        receiving_address: "수취인 주소",
        tip7: "OVO를 사용하여 다음 은행 계좌로 자금을 이체하세요.",
        tip8: "1、결제하려면 인터넷 뱅킹이 필요합니다.。",
        tip9: "2、충전에 성공한 후 '판매자로 돌아가기'를 클릭하면 자동으로 적립됩니다.。",
    },
    other: {
        gift_tip1: "1、선물은 청구 조건이 충족된 경우에만 청구할 수 있습니다.。",
        gift_tip2: "2、미수령 선물은 유효 기간이 지나면 자동으로 만료되며 다시 받을 수 없습니다.。",
        other_tip1: "편지는 최대 7일 동안 기록으로 보관됩니다;",
    },
    form: {
        input_inner: "콘텐츠를 입력하세요",
        label_username: "사용자 계정：",
        place_username: "사용자 계정을 입력하세요.",
        label_loginpsd: "로그인 비밀번호：",
        place_loginpsd: "로그인 비밀번호를 입력하세요.",
        label_invitecode: "초대 코드：",
        place_invitecode: "8자리 초대 코드를 입력하세요.",
        label_oldpsd: "원본 비밀번호",
        place_oldpsd: "원래 비밀번호를 입력하세요.",
        label_newpsd: "새 비밀번호",
        place_newpsd: "새 비밀번호를 입력하세요.",
        label_crmpsd: "비밀번호 확인",
        place_crmpsd: "새 비밀번호를 다시 입력하세요.",
        label_remark: "참고",
        place_remark: "메모를 입력하세요.",
        label_lower_account: "사용자 이름：",
        place_lower_account: "하위  계좌 번호를 입력하세요.",
        label_type: "유형학：",
        place_select: "선택해 주세요",
        label_lottery_title: "추첨 상품：",
        label_state: "업무 상태：",
        option_state1: "전체",
        option_state2: "보류 중인 복권",
        option_state3: "승자가 아닙니다.",
        option_state4: "상을 받은 적이 있는 경우",
        label_time: "횟수：",
        times1: "현재",
        times2: "어제",
        times3: "현재 월",
        times4: "Ultimo",
        label_realname: "실명 및 성",
        place_realname: "실명을 입력하세요.",
        label_bank_number: "ATM 카드 번호",
        place_bank_number: "은행 카드 번호를 입력하세요.",
        label_bank_name: "은행 이름",
        place_bank_name: "은행 이름을 선택해 주세요.",
        label_abstract: "초록:",
        label_channel: "채널 충전",
        place_channel: "충전 채널을 선택하세요.",
        label_deposit_amount: "충전 금액",
        place_deposit_amount: "충전 금액을 입력하세요.",
        label_holder: "계정 소유자",
        label_deposit_name: "입금자 이름",
        place_deposit_name: "입금자 이름을 입력하세요.",
        label_transfer_msg: "전송 정보",
        place_transfer_msg: "송금 정보를 입력해 주세요.",
        label_transfer_pic: "거래 영수증 스크린샷",
        label_transfer_pic_tip: "(거래 영수증 스크린샷을 업로드해 주세요.)",
        label_bank_account_name: "은행 계좌 이름",
        label_balance: "잔액",
        label_available_withdraw: "출금 가능 금액",
        label_available_number: "출금 횟수",
        label_withdraw_amount: "출금 금액",
        placce_withdraw_amount: "출금 금액을 입력하세요.)",
        available_withdraw: "바람직성",
        label_transfer_bank: "직불 은행",
        label_security_psd: "보안 코드",
        place_security_psd: "보안 코드를 입력하세요.",
        place_nickname: "애정 기간",
        message_nickname: "닉네임을 입력하세요.",
        place_phone: "휴대폰 번호를 입력하세요.",
        place_email: "이메일 주소를 입력하세요.",
        place_invite_id: "초대 코드를 입력하세요.",
        place_verify_code: "인증 코드를 입력하세요.",
        send: "보내기",
        test_username: "테스트 사용자 이름",
        place_amount: "금액을 입력하세요.",
    },
    login: {
        login_tit: "사용자 로그인",
        forget_psd: "비밀번호를 잊으셨나요?？",
        login: "로그인",
        register_tit: "사용자 등록",
        register: "등록",
        have_account: "이미 계정이 있는 경우 로그인",
        country_code: "국가 코드",
        phone: "휴대폰",
        email: "받은 편지함",
    },
    city: {
        albania: "알바니아",
        algeria: "알제리",
        argentina: "아르헨티나",
        armenia: "아르메니아",
        australia: "호주",
        pakistan: "파키스탄",
        austria: "오스트리아",
        bahrain: "바레인",
        belgium: "벨기에",
        bosnia_and_Herzegovina: "보스니아 헤르체고비나",
        brazil: "브라질",
        brunei: "브루나이",
        bulgaria: "불가리아",
        cambodia: "캄보디아",
        canada: "캐나다",
        cameroon: "카메룬",
        chile: "칠레",
        colombia: "콜롬비아",
        costa_Rica: "코스타리카",
        croatia: "크로아티아",
        cyprus: "키프로스",
        czech_Republic: "체코",
        denmark: "덴마크",
        dominican_Republic: "도미니카",
        egypt: "이집트",
        estonia: "에스토니아",
        ethiopia: "에티오피아",
        finland: "핀란드",
        france: "프랑스",
        georgia: "그루지야",
        germany: "독일",
        ghana: "가나",
        greece: "그리스",
        guyana: "가이아나",
        honduras: "온두라스",
        hong_Kong_China: "중국 홍콩",
        hungary: "헝가리",
        iceland: "아이슬란드",
        ireland: "아일랜드",
        italy: "이탈리아",
        india: "인도",
        indonesia: "인도네시아",
        israel: "이스라엘",
        iran: "이란",
        iraq: "이라크",
        japan: "일본",
        kazakstan: "카자흐스탄",
        kenya: "케냐",
        korea: "한국",
        kuwait: "쿠웨이트",
        kyrgyzstan: "키르기스스탄",
        laos: "라오스",
        Latvia: "라트비아",
        lithuania: "리투아니아",
        luxembourg: "룩셈부르크",
        macao_China: "중국 마카오",
        macedonia: "마케도니아",
        malaysia: "말레이시아",
        malta: "몰타",
        mexico: "멕시코",
        moldova: "몰도바",
        monaco: "모나코",
        mongolia: "몽골",
        montenegro: "몬테네그로",
        morocco: "모로코",
        myanmar: "미얀마",
        netherlands: "네덜란드",
        new_Zealand: "뉴질랜드",
        nepal: "네팔",
        nigeria: "나이지리아",
        norway: "노르웨이",
        oman: "오만",
        palestine: "팔레스타인",
        panama: "파나마",
        paraguay: "파라과이",
        peru: "페루",
        philippines: "필리핀",
        poland: "폴란드",
        portugal: "포르투갈",
        puerto_Rico: "푸에르토리코",
        qatar: "카타르",
        romania: "루마니아",
        russia: "러시아",
        republic_of_Trinidad_and_Tobago: "트리니다드 토바고",
        rwanda: "르완다",
        saudi_Arabia: "사우디아라비아",
        serbia: "세르비아",
        singapore: "싱가포르",
        slovakia: "슬로바키아",
        slovenia: "슬로베니아",
        south_Africa: "남아프리카",
        spain: "스페인",
        sri_Lanka: "스리랑카",
        sweden: "스웨덴",
        switzerland: "스위스",
        taiwan_China: "대만",
        tajikistan: "타지키스탄",
        tanzania: "탄자니아",
        thailand: "태국",
        turkey: "터키",
        turkmenistan: "투르크메니스탄",
        ukraine: "영국",
        united_Arab_Emirates: "미국",
        united_Kingdom: "우즈베키스탄",
        united_States: "베네수엘라",
        uzbekistan: "베트남",
        venezuela: "아프가니스탄",
        vietnam: "베트남",
        afghanistan: "아프가니스탄",
        angola: "앙골라",
        azerbaijan: "아제르바이잔",
        bangladesh: "방글라데시",
        belarus: "벨라루스",
        belize: "벨리즈",
        benin: "베냉",
        bhutan: "부탄",
        bolivia: "볼리비아",
        botswana: "보츠와나",
        british_Virgin_Islands: "영국령 버진 아일랜드",
        burkina_Faso: "부르키나파소",
        burundi: "부룬디",
        cape_Verde: "카보베르데",
        cayman_Islands: "케이맨 제도",
        central_African_Republic: "중앙아프리카",
        chad: "차드",
        comoros: "코모로",
        the_Republic_of_the_Congo: "콩고 (부)",
        democratic_Republic_of_the_Congo: "콩고 (김)",
        djibouti: "지부티",
        ecuador: "에콰도르",
        el_Salvador: "엘살바도르",
        equatorial_Guinea: "적도 기니",
        eritrea: "에리트레아",
        fiji: "피지",
        gabon: "가봉",
        gambia: "감비아",
        greenland: "그린란드",
        guatemala: "과테말라",
        guinea: "기니",
        haiti: "아이티",
        isle_of_Man: "맨 섬",
        cote_d_Ivoire: "코트디부아르",
        jamaica: "자메이카",
        jordan: "요르단",
        lebanon: "레바논",
        lesotho: "레소토",
        liberia: "라이베리아",
        libya: "리비아",
        madagascar: "마다가스카르",
        malawi: "말라위",
        maldives: "몰디브",
        mali: "말리",
        mauritania: "모리타니",
        mauritius: "모리셔스",
        mozambique: "모잠비크",
        namibia: "나미비아",
        nicaragua: "니카라과",
        republic_of_Niger: "니제르",
        north_Korea: "조선",
        reunion: "레위니옹",
        san_Marino: "산마리노",
        senegal: "세네갈",
        sierra_Leone: "시에라리온",
        somalia: "소말리아",
        sudan: "수단",
        suriname: "수리남",
        eswatini: "스와질란드",
        syria: "시리아",
        togo: "토고",
        tonga: "통가",
        tunisia: "튀니지",
        united_States_Virgin_Islands: "미국령 버진 아일랜드",
        uganda: "우간다",
        uruguay: "우루과이",
        vatican: "바티칸",
        yemen: "예멘",
        yugoslavia: "유고슬라비아",
        zambia: "잠비아",
        zimbabwe: "짐바브웨",
        china: "중국",
      }
};
