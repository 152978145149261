export default {
    common: {
        contactNow: "Contact Now",
        minPrice: "最低",
        maxPrice: "最高",
        address: {
            title: "取款地址",
            account_number: "帳戶號碼",
            add_btn: "添加地址",
            label_type: "選擇類型",
            place_type: "請選擇類型",
            label_number: "帳戶號碼",
            place_number: "請輸入帳戶號碼",
            wallet_address: '錢包地址',
            bank_name: '銀行名字',
            bank_address: "銀行地址",
            name: "姓名",
            payee_address: '收款人地址',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "分行名字",
            branch_code: "分行程式碼",
            bank_code: "銀行程式碼",
            phone: "手機號",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "請輸入內容",
        },
        priceType: {
            fast_sum: "和值",
            fast_three_all: "三同号通选",
            fast_three_one: "三同号单选",
            fast_three_diff: "三不同号",
            fast_three_continue: "三连号通选",
            fast_two_some: "二同号复选",
            fast_two_one: "二同号单选",
            fast_two_diff: "二不同号",
            fast_one_one: "单筛一赔一",
            racing_winner: "冠军",
            racing_second: "亚军",
            racing_third: "季军",
            lotto_one: "万位",
            lotto_two: "千位",
            lotto_three: "百位",
            lotto_four: "十位",
            lotto_five: "个位",
            wingo_sum: "和值",
            wingo_color: "颜色",
            Big: "大",
            Small: "小",
            Single: "单",
            Double: "双",
            Red: "红",
            Green: "绿",
            Purple: "紫",
        },
        img_error: "图片加载失败",
        more: "更多",
        cancel: "取消",
        confirm: "确定",
        tips: "温馨提示",
        no_set: "未设置",
        submit: "提交",
        copy_success: "复制成功！",
        copy_error: "复制失败！",
        currency: "$",
        filter: "筛选",
        edit: "修改",
        gameStart: "开始游戏",
        prizenumber: "中奖号码",
        mynumber: "我的号码",
        addresss: "这里是链接地址",
        gameAgain: "再来一次",
        gamerule: "游戏规则",
        winning_numbers: "中奖号码",
        winning: "恭喜您中奖了！",
        notwin: "很遗憾，您没有中奖！",
        logout: "确定要退出登录吗？",
        success: "成功",
        search: "搜索",
        withdraw0: "申请",
        withdraw1: "下发中",
        withdraw2: "成功",
        withdraw3: "失败",
        balanceNot: "账户余额不足",
        canceled: "已取消",
        bill: {
            bill101: "用户充值",
            bill102: "系统增加",
            bill103: "系统冻结",
            bill104: "系统解冻",
            bill201: "用户提现",
            bill202: "系统扣除",
            bill203: "提现成功",
            bill204: "提现审核失败",
            bill205: "提现手续费",
            bill301: "加入游戏",
            bill302: "游戏盈利",
        }
    },
    add: {
        cqk: "存/取款",
        activity: "优惠活动",
        online: "在线客服",
        lottery: "购彩大厅",
        game: "游戏",
        lotter: "彩票",
        search_lotter: "搜索彩种",
        search: "查询",
        di: "第",
        qi: "期",
        ju: "距第",
        feng: "期封盘",
        result: "开奖结果",
        logout: "退出",
        available_balance: "可用余额",
        notice_title: "平台公告",
        tip30: "30分内不再弹出",
        close: "关闭",
        personal: "个人中心",
        level: "等级",
        common: "常见",
        news: "最新优惠",
        help_center: "帮助中心",
        app_download:"APP下载",
    },
    addnew: {
        remainder: "剩余时间",
        history: "开奖历史",
        trend: "走势",
        my_bet: "我的注单",
        bei: "倍数",
        agree: "我同意",
        rule: "《预售规则》",
        total_amount: "总金额",
        wallet_balance: "钱包余额",
        total: "总和",
        random_bet: "随机投注",
    },
    playMethod: {
        p1: "请选择要查看的游戏规则",
        p2: "规则研究透了会让收益翻倍哦",
        des1: "简介",
        des2: "如果您花费100进行交易，扣除服务费2%后，合约金额：98",
        fived: {
            p1: "5D彩票游戏规则",
            p2: "画出指令",
            p3: "每一期随机抽取5位数字(00000-99999)",
            p4: "例如:",
            p5: "本期抽号为12345",
            p6: "A = 1",
            p7: "B = 2",
            p8: "C = 3",
            p9: "D = 4",
            p10: "E = 5",
            p11: "和= A + B + C + D + E = 15",
            p12: "怎么玩",
            p13: "玩家可以指定投注A、B、C、D、E的六种结果和总和",
            p14: "A, B, C, D, E可以买到",
            p15: "数字(0 1 2 3 4 5 6 7 8 9)",
            p16: "低(0 1 2 3 4)",
            p17: "高(5 6 7 8 9)",
            p18: "奇数(1 3 5 7 9)",
            p19: "偶数(0 2 4 6 8)",
            p20: "Sum = A+B+C+D+E可以购买",
            p21: "低(0-22)",
            p22: "高(23-45)",
            p23: "奇数(1 3···43 45)",
            p24: "偶数(0 2···42 44)",
        },
        racing: {
            p1: "游戏玩法",
            p2: "※前三和：",
            p3: "前三名车号相加，和值大于16为大，小于或等于16为小。和值为偶数叫双，为奇数叫单。※前三和：",
            p4: "※第一名至第三名：",
            p5: "车号指定，每一个车号为一投注组合，开奖结果'投注车号'对应所投名次视为中奖，其余情形视为不中奖。",
            p6: "※两面：",
            p7: "指单、双；大、小。",
            p8: "※单、双：",
            p9: "号码为双数叫双，如2、4、6、8、10；号码为单数叫单，如1、3、5、7、9。",
            p10: "※大、小",
            p11: "开出之号码大于或等于6为大，如6、7、8、9、10；小于或等于5为小，如1、2、3、4、5。",
        },
        wingo: {
            p1: "游戏玩法",
            p2: "※单个数字：",
            p3: "选择一个数字投注，开奖数字与投注数字一样视为中奖，其余情形视为不中奖。",
            p4: "※红色：",
            p5: "投注红色，如果开奖数字为 0、2、4、6、8 视为中奖，其余情形视为不中奖。",
            p6: "※绿色：",
            p7: "投注绿色，如果开奖数字为 1、3、5、7、9 视为中奖，其余情形视为不中奖。",
            p8: "※紫色：",
            p9: "投注紫色，如果开奖数字为 0、5 视为中奖，其余情形视为不中奖。",
            p10: "※大、小：",
            p11: "开奖数字大于或等于5为大，如5、6、7、8、9；小于等于4为小，如0、1、2、3、4。",
        },
        lottery: {
            p1: "游戏玩法~6随机开出。",
            p2: "A：大小单双",
            p3: "大小：三个开奖号码总和值11~18 为大；总和值3~10 为小。",
            p4: "单双：三个开奖号码总和值为单数，则单中奖，为双数，则双中奖。",
            p5: "B：点数",
            p6: "开奖号码总和值为3、4、5、6、7、8、9、10、11、12、13、14、15、16、17、18时，即为中奖；举例：如开奖号码为1、2、3、总和值为6、则投注「6」即为中奖。",
            p7: "C：单骰",
            p8: "三个开奖号码其中一个与所选号码相同时、即为中奖。举例：如开奖号码为1、1、3，则投注单骰1或单骰3皆视为中奖。(不论当局指定点数出现几次，仅派彩一次不翻倍)",
            p9: "D：双骰",
            p10: "奖号码任两字同号、且与所选择的双骰组合相符时，即为中奖。举例：如开奖号码为1、1、3、则投注双骰1、1，即为中奖。",
            p11: "E：围骰、全围骰",
            p12: "围骰：开奖号码三字同号、且与所选择的围骰组合相符时，即为中奖。",
            p13: "全围骰：开奖号码三字同号，即为中奖。",
            p14: "F：二骰组合",
            p15: "任选一二骰组合，当开奖结果任2码与所选组合相同时，即为中奖。举例：如开奖号码为1、2、3、则投注二骰组合12、二骰组合23、二骰组合13皆视为中奖。",
        }
    },
    webadd: {
        hot: "热门",
        all: "全部",
        home: "大厅",
        hot_lottery: "热门彩票",
        select_channel: "选择充值渠道",
        select_bank: "选择银行",
        top_tab1: "中奖",
        top_tab2: "TOP",
        top_tab3: "我的",
        web_time: "网站时间",
        trade: "交易",
    },
    footer: {
        foot1: "首页",
        foot2: "游戏",
        foot3: "TOP",
        foot4: "活动",
        foot5: "我的",
    },
    navBar: {
        back_home: "返回首页",
        help_center: "帮助中心",
        contact_service: "联系客服",
        balance: "余额",
        my_account: "我的账户",
        account: "账户",
        record: "记录",
        proxy: "代理",
        other: "其他",
        deposit: "充值",
        withdraw: "提现",
        deposit_type1: "网银支付",
        deposit_type2: "银行转账",
        deposit_type3: "数字货币",
        deposit_type4: "OVO",
        logout: "退出",
        login: "登录",
        register: "注册",
        nav1: "彩票",
        nav2: "游戏",
        nav3: "活动",
        nav4: "手机",
        nav5: "我的账户",
    },
    activity: {
        tit1: "每日加奖",
        p1: "每日加奖是根据用户昨日投注金额进行加奖，奖金无上限！",
        activity_detail: "活动详情",
        yestoday_bet: "昨日投注",
        bonus_ratio: "加奖比例",
        now_level: "当前等级",
        receive_bonus: "可得加奖",
        receive: "领取",
        not_receive: "不可领取",
        activity_time: "活动时间",
        p2: "1、每日加奖在每日凌晨00:20后开放领取",
        p3: "2、加奖比例是根据用户等级以及昨日累计投注金额进行一定比例的加奖",
        p4: "3、撤单和其他无效投注将不计算在内",
        p5: "4、有效投注需满足【该注单已开奖结算】，如遇投注后该注单相隔多日才开奖时，该注单的有效投注金额将累计在【开奖结算日】。",
        p6: "5、例：10/1投注但10/15才开奖，则该注单的有效投注金额将累计在10/15，用户可于10/16时领取到该笔注单的加奖奖励。",
        p7: "6、活动奖金当天24点前未领取，视为自动放弃活动资格",
    },
    game: {
        history_tit: "历史开奖",
        play_tit: "玩法说明",
        official_award: "官方原奖",
        betting: "正在投注",
        waiting: "等待开奖",
        opening: "正在开奖",
        settled: "已结算",
        auto_change: "自动切换",
        height_win: "最高可赢",
        total: "总共",
        pour: "注",
        every_pour: "每注",
        total_pour: "总注数",
        total_amount: "总投注",
        now_bet: "立即投注",
        my_bet: "我的投注",
        winning: "中奖",
        top: "TOP",
        winning_tip: "中奖信息实时更新",
        top_tip: "TOP排行榜",
    },
    record: {
        lottery: "彩票",
        game: "游戏",
        today_profit_loss: "今日盈亏",
        label_formula: "盈亏公式",
        formula: "公式：中奖-投注+活动+返点",
        profitLoss_label1: "中奖",
        profitLoss_label2: "活动",
        profitLoss_label3: "投注",
        profitLoss_label4: "返点",
        profitLoss_label5: "转账",
        profitLoss_label6: "收款",
        profitLoss_label7: "发送红包",
        profitLoss_label8: "领取红包",
        trade_tab1: "全部",
        trade_tab2: "充值",
        trade_tab3: "提现",
    },
    table: {
        label_no: " No.",
        label_time: "时间",
        label_result: "结果",
        label_size: "大小",
        label_single_double: "单双",
        label_sun_value: "和值",
        no_data: "暂无数据",
        big: "大",
        small: "小",
        single: "单",
        double: "双",
        label_issue: "期号",
        label_bet_amount: "投注金额",
        label_reward: "奖金",
        no_reward: "未中奖",
        is_reward: "中奖",
        label_level: "等级",
        label_integral: "成长积分",
        label_award: "晋级奖励",
        label_skip_awardl: "跳级奖励",
        label_register_link: "注册链接",
        label_remark: "备注",
        label_rise_time: "生成时间",
        label_register_number: "注册数",
        label_operate: "操作",
        label_gametit: "彩种/游戏",
        label_lower_rebate: "下级返点",
        label_self_rebate: "自身返点",
        label_set_rebate_range: "返点范围",
        bonus_state: "奖金状态",
        label_serial_number: "流水号",
        label_income_amount: "收入金额",
        label_expend_amount: "支出金额",
        label_available_balance: "可用余额",
        label_abstract: "摘要",
        label_details: "详情",
        label_account: "账户",
        label_amount: "金额",
        label_bet_cont: "投注内容",
        label_ottery_number: "开奖号码",
        order_number: "单号",
        play_methods: "玩法",
        label_periods: "期数",
        label_number: "号码",
        label_color: "颜色",
    },
    my: {
        last_login_time: "上次登录时间",
        logout_account: "退出账户",
        my_account: "我的账户",
        account: "账户",
        personal_tit: "个人资料",
        security_tit: "安全中心",
        bank_tit: "银行账号",
        record: "记录",
        profit_loss_tit: "今日盈亏",
        trade_record_tit: "交易记录",
        bet_record: "投注记录",
        proxy: "代理",
        manag_tit: "代理管理",
        report_tit: "代理报表",
        lower_bet_tit: "下级投注",
        lower_trade_tit: "下级交易",
        other: "其他",
        gift_tit: "活动礼物",
        message_tit: "消息中心",
        personal_data: "个人资料",
        balance: "余额",
        last_sync_time: "上次同步时间",
        deposit: "充值",
        withdraw: "提现",
        yesterday_deposit_time: "昨日充值平均时间",
        yesterday_withdraw_time: "昨日提现平均时间",
        help_tit: "帮助中心",
        all: "全部",
        lower_report: "下级报表",
        about_us: "关于我们",
        logout: "登出",
        invite: "邀请好友",
        lang_change: "语言切换",
    },
    account: {
        add_bank_account: "添加银行账号",
        bank_tip: "最多可添加5个银行账号，成功提现的银行账号会自动锁定，无法删除和修改。未成功提现的银行账号可修改无法删除。",
        account_msg: "账户信息",
        growth_value: "成长值",
        noset_nickname: "昵称未设置",
        nationality: "国籍",
        next_level: "距离下一级还要",
        integral: "积分",
        integral_tip: "每充值1.00 $ 增加1积分",
        level_system: "等级制度",
        change_avatar: "更换头像",
        loginpsd_tip: "登录密码6～16位字母和数字的组合",
        modify_psd: "修改密码",
        securitypsd_tip: "安全密码(6位数字的组合)",
        forget_securitypsd: "忘记安全密码",
        confidentiality_tip: "密保问题账号或密码丢失时可找回用户账户信息",
        bank_account: "银行账号",
        edit_nickname: "编辑昵称",
        confidentiality: "密保问题",
        accumulated_winning: "累积中奖",
    },
    agent: {
        illustrate_p1: "当您能看到这个页面，说明您的账号既是玩家账号也是代理账号，既可以自己投注，也可以发展下级玩家，赚取返点佣金。",
        illustrate_p2: "如何赚取返点？",
        illustrate_p3: "可获得的返点，等于自身返点与下级返点的差值，如自身返点5，下级返点3，你将能获得下级投注金额2的返点，如下级投注100$，你将会获得2$。点击下级开户，可查看自身返点，也可为下级设置返点。",
        illustrate_p4: "如何为下级开户？",
        illustrate_p5: "点击下级开户，先为您的下级设置返点，设置成功后会生成一条邀请码，将邀请码发送给您的下级注册，注册后他就是您的下级，点击用户管理，就能查看他注册的账号。",
        illustrate_p6: "如果您为下级设置的是代理类型的账号，那么您的下级就能继续发展下级，如果设置的是玩家类型，那么您的下级只能投注，不能再发展下级，也看不到代理中心。 用户管",
        illustrate_tip1: "1、返点不同赔率也不同，点击返点赔率表，可查看返点赔率。",
        illustrate_tip2: "2、返点越低，赔率就越低，建议为下级设置的返点不要过低。",
        illustrate_tip3: "3、可在代理报表、下级投注、下级交易查看代理的发展情况。",
        illustrate_tip4: "4、建议开设的下级也是代理类型，无论发展了几级，您都能获得返点。",
        open_type: "开户类型：",
        open_type1: "全部",
        open_type2: "代理",
        open_type3: "玩家",
        invite_tip1: "1、不同的返点赔率不同，返点越高赔率越高。",
        invite_tip2: "2、代理可获得的佣金等于代理自身返点与下级返点的差值，如代理自身返点6，下级返点4，代理可获得下级投注金额的2%，即下级下注100元，代理可获得2元。",
        invite_tip3: "3、下级返点值设得越低，下级的赔率就越低，建议给下级设置的返点不要过低。",
        rebateDialog_tit: "查看返点",
        delete_invite_tip: "确定要删除这条邀请码吗？",
        rebate_table: "返点赔率表",
        generate_invitation_code: "生成邀请码",
        subordinate_tip1: "1、不同的返点赔率不同，返点越高赔率越高。",
        subordinate_tip2: "2、代理可获得的佣金等于代理自身返点与下级返点的差值，如代理自身返点6，下级返点4，代理可获得下级投注金额的2%，即下级下注100元，代理可获得2元。",
        subordinate_tip3: "3、下级返点值设得越低，下级的赔率就越低，建议给下级设置的返点不要过低。",
        manage_tab1: "代理说明",
        manage_tab2: "下级开户",
        manage_tab3: "邀请码",
        manage_tab4: "用户管理",
        lower_bet_tip: "交易记录最多保留21天。",
        report_label1: "投注",
        report_label2: "中奖",
        report_label3: "活动",
        report_label4: "团队返点",
        report_label5: "团队盈利",
        report_label6: "投注人数",
        report_label7: "首充人数",
        report_label8: "注册人数",
        report_label9: "下级人数",
        report_label10: "团队余额",
        report_label11: "充值",
        report_label12: "提现",
        report_label13: "代理返点",
        report_label14: "转账",
        report_label15: "收款",
        report_label16: "发送红包",
        report_label17: "领取红包",
    },
    fund: {
        tip1: "请转账到以下银行账户",
        tip2: "请认真填写你的转账信息",
        tip3: " (请务必转账后再提交订单,否则无法及时查到您的款项！)",
        deposit_rate: "存款汇率",
        currency_number: "货币数量",
        tip4: "请使用对应的数字货币平台进行转账",
        tip5: "单笔限额",
        tip6: "(若无法填写认证信息，请上传交易收据截图)",
        click_upload: "点击上传",
        upload_tip: "只能上传jpg/png文件",
        receiving_address: "收款地址",
        tip7: "请使用OVO转账到以下银行账户",
        tip8: "1、网银支付需开通网银才能进行支付。",
        tip9: "2、充值成功后，点击“返回商户”即可自动到账。",
    },
    other: {
        gift_tip1: "1、礼物必须达到领取条件后才可领取。",
        gift_tip2: "2、礼物过期后未领取的礼物自动失效，无法再领取。",
        other_tip1: "信件最多保留7天的记录；",
    },
    form: {
        input_inner: "请输入内容",
        label_username: "用户账号：",
        place_username: "请输入用户账号",
        label_loginpsd: "登录密码：",
        place_loginpsd: "请输入登录密码",
        label_invitecode: "邀请码：",
        place_invitecode: "请填写8位数字邀请码",
        label_oldpsd: "原密码",
        place_oldpsd: "请输入原密码",
        label_newpsd: "新密码",
        place_newpsd: "请输入新密码",
        label_crmpsd: "确认密码",
        place_crmpsd: "请再次输入新密码",
        label_remark: "备注",
        place_remark: "请输入备注",
        label_lower_account: "账号：",
        place_lower_account: "请输入下级账号",
        label_type: "类型：",
        place_select: "请选择",
        label_lottery_title: "彩种：",
        label_state: "状态",
        option_state1: "全部",
        option_state2: "待开奖",
        option_state3: "未中奖",
        option_state4: "已中奖",
        label_time: "时间：",
        times1: "今天",
        times2: "昨天",
        times3: "本月",
        times4: "上月",
        label_realname: "真实姓名",
        place_realname: "请输入真实姓名",
        label_bank_number: "银行卡号",
        place_bank_number: "请输入银行卡号",
        label_bank_name: "银行名称",
        place_bank_name: "请选择银行名称",
        label_abstract: "摘要:",
        label_channel: "充值通道",
        place_channel: "请选择充值通道",
        label_deposit_amount: "充值金额",
        place_deposit_amount: "请输入充值金额",
        label_holder: "开户人",
        label_deposit_name: "存款人姓名",
        place_deposit_name: "请输入存款人姓名",
        label_transfer_msg: "转账信息",
        place_transfer_msg: "请填写转账信息",
        label_transfer_pic: "交易收据截图",
        label_transfer_pic_tip: "(请上传交易收据截图)",
        label_bank_account_name: "银行户名",
        label_balance: "余额",
        label_available_withdraw: "可提现金额",
        label_available_number: "可提现次数",
        label_withdraw_amount: "提现金额",
        placce_withdraw_amount: "请输入提现金额)",
        available_withdraw: "可取",
        label_transfer_bank: "转账银行",
        label_security_psd: "安全密码",
        place_security_psd: "请输入安全密码",
        place_nickname: "昵称",
        message_nickname: "请输入昵称",
        place_phone: "请输入手机号",
        place_email: "请输入邮箱",
        place_invite_id: "请输入邀请码",
        place_verify_code: "请输入验证码",
        send: "发送",
        test_username: "Test 用户名",
        place_amount: "请输入金额",
    },
    login: {
        login_tit: "用户登录",
        forget_psd: "忘记密码？",
        login: "登录",
        register_tit: "用户注册",
        register: "注册",
        have_account: "已有账号，去登录",
        country_code: "国家代码",
        phone: "手机",
        email: "邮箱",
    },
    city: {
        albania: "阿尔巴尼亚",
        algeria: "阿尔及利亚",
        argentina: "阿根廷",
        armenia: "亚美尼亚",
        australia: "澳大利亚",
        pakistan: "巴基斯坦",
        austria: "奥地利",
        bahrain: "巴林",
        belgium: "比利时",
        bosnia_and_Herzegovina: "波黑",
        brazil: "巴西",
        brunei: "文莱",
        bulgaria: "保加利亚",
        cambodia: "柬埔寨",
        canada: "加拿大",
        cameroon: "喀麦隆",
        chile: "智利",
        colombia: "哥伦比亚",
        costa_Rica: "哥斯达黎加",
        croatia: "克罗地亚",
        cyprus: "塞浦路斯",
        czech_Republic: "捷克",
        denmark: "丹麦",
        dominican_Republic: "多米尼亚共和国",
        egypt: "埃及",
        estonia: "爱沙尼亚",
        ethiopia: "埃塞俄比亚",
        finland: "芬兰",
        france: "法国",
        georgia: "格鲁吉亚",
        germany: "德国",
        ghana: "加纳",
        greece: "希腊",
        guyana: "圭亚那",
        honduras: "洪都拉斯",
        hong_Kong_China: "中国香港",
        hungary: "匈牙利",
        iceland: "冰岛",
        ireland: "爱尔兰",
        italy: "意大利",
        india: "印度",
        indonesia: "印度尼西亚",
        israel: "以色列",
        iran: "伊朗",
        iraq: "伊拉克",
        japan: "日本",
        kazakstan: "哈萨克斯坦",
        kenya: "肯尼亚",
        korea: "韩国",
        kuwait: "科威特",
        kyrgyzstan: "吉尔吉斯斯坦",
        laos: "老挝",
        latvia: "拉脱维亚",
        lithuania: "立陶宛",
        luxembourg: "卢森堡",
        macao_China: "中国澳门",
        macedonia: "马其顿",
        malaysia: "马来西亚",
        malta: "马耳他",
        mexico: "墨西哥",
        moldova: "摩尔多瓦",
        monaco: "摩纳哥",
        mongolia: "蒙古",
        montenegro: "黑山",
        morocco: "摩洛哥",
        myanmar: "缅甸",
        netherlands: "荷兰",
        new_Zealand: "新西兰",
        nepal: "尼泊尔",
        nigeria: "尼日利亚",
        norway: "挪威",
        oman: "阿曼",
        palestine: "巴勒斯坦",
        panama: "巴拿马",
        paraguay: "巴拉圭",
        peru: "秘鲁",
        philippines: "菲律宾",
        poland: "波兰",
        portugal: "葡萄牙",
        puerto_Rico: "波多黎各",
        qatar: "卡塔尔",
        romania: "罗马尼亚",
        russia: "俄罗斯",
        republic_of_Trinidad_and_Tobago: "特立尼达和多巴哥",
        rwanda: "卢旺达",
        saudi_Arabia: "沙特阿拉伯",
        serbia: "塞尔维亚",
        singapore: "新加坡",
        slovakia: "斯洛伐克",
        slovenia: "斯洛文尼亚",
        south_Africa: "南非",
        spain: "西班牙",
        sri_Lanka: "斯里兰卡",
        sweden: "瑞典",
        switzerland: "瑞士",
        taiwan_China: "中国台湾",
        tajikistan: "塔吉克斯坦",
        tanzania: "坦桑尼亚",
        thailand: "泰国",
        turkey: "土耳其",
        turkmenistan: "土库曼斯坦",
        ukraine: "乌克兰",
        united_Arab_Emirates: "阿联酋",
        united_Kingdom: "英国",
        united_States: "美国",
        uzbekistan: "乌兹别克斯坦",
        venezuela: "委内瑞拉",
        vietnam: "越南",
        afghanistan: "阿富汗",
        angola: "安哥拉",
        azerbaijan: "阿塞拜疆",
        bangladesh: "孟加拉国",
        belarus: "白俄罗斯",
        belize: "伯利兹",
        benin: "贝宁",
        bhutan: "不丹",
        bolivia: "玻利维亚",
        botswana: "博茨瓦纳",
        british_Virgin_Islands: "英属维京群岛",
        burkina_Faso: "布基纳法索",
        burundi: "布隆迪",
        cape_Verde: "佛得角",
        cayman_Islands: "开曼群岛",
        central_African_Republic: "中非共和国",
        chad: "乍得",
        comoros: "科摩罗",
        the_Republic_of_the_Congo: "刚果（布）",
        democratic_Republic_of_the_Congo: "刚果（金）",
        djibouti: "吉布提",
        ecuador: "厄瓜多尔",
        el_Salvador: "萨尔瓦多",
        equatorial_Guinea: "赤道几内亚",
        eritrea: "厄立特里亚",
        fiji: "斐济",
        gabon: "加蓬",
        gambia: "冈比亚",
        greenland: "格陵兰",
        guatemala: "危地马拉",
        guinea: "几内亚",
        haiti: "海地",
        isle_of_Man: "马恩岛",
        cote_d_Ivoire: "科特迪瓦",
        jamaica: "牙买加",
        jordan: "约旦",
        lebanon: "黎巴嫩",
        lesotho: "莱索托",
        liberia: "利比里亚",
        libya: "利比亚",
        madagascar: "马达加斯加",
        malawi: "马拉维",
        maldives: "马尔代夫",
        mali: "马里",
        mauritania: "毛里塔尼亚",
        mauritius: "毛里求斯",
        mozambique: "莫桑比克",
        namibia: "纳米比亚",
        nicaragua: "尼加拉瓜",
        republic_of_Niger: "尼日尔",
        north_Korea: "朝鲜",
        reunion: "留尼汪",
        san_Marino: "圣马力诺",
        senegal: "塞内加尔",
        sierra_Leone: "塞拉利昂",
        somalia: "索马里",
        sudan: "苏丹",
        suriname: "苏里南",
        eswatini: "斯威士兰",
        syria: "叙利亚",
        togo: "多哥",
        tonga: "汤加",
        tunisia: "突尼斯",
        united_States_Virgin_Islands: "美属维尔京群岛",
        uganda: "乌干达",
        uruguay: "乌拉圭",
        vatican: "梵蒂冈",
        yemen: "也门",
        yugoslavia: "南斯拉夫",
        zambia: "赞比亚",
        zimbabwe: "津巴布韦",
        china: "中国",
    }
};
