import Vue from 'vue';
import VueI18n from 'vue-i18n';
import elementZHTW from 'element-ui/lib/locale/lang/zh-TW'
import elementZHCN from 'element-ui/lib/locale/lang/zh-CN'
import elementEN from 'element-ui/lib/locale/lang/en'
import elementID from 'element-ui/lib/locale/lang/id'
import elementVI from 'element-ui/lib/locale/lang/vi'
import elementTH from 'element-ui/lib/locale/lang/th'
import elementES from 'element-ui/lib/locale/lang/es'
import elementFR from 'element-ui/lib/locale/lang/fr'
import elementJA from 'element-ui/lib/locale/lang/ja'
import elementKO from 'element-ui/lib/locale/lang/ko'
import elementPT from 'element-ui/lib/locale/lang/pt'
import elementRU from 'element-ui/lib/locale/lang/ru-RU'
Vue.use(VueI18n);

//导入语言包
import En from '../lang/en.js'; // 英文语言包
import Zh from '../lang/zh.js'; // 中文简体语言包
import ZhTW from '../lang/zh-TW.js'; // 中文繁体语言包
import Id from '../lang/id.js'; // 印尼
import In from '../lang/in.js'; // 印度
import Vi from '../lang/vi.js'; // 越南
import Th from '../lang/th.js'; // 泰语
import Es from '../lang/es.js'; // 西班牙
import Fr from '../lang/fr.js'; // 法语
import Ja from '../lang/ja.js'; // 日语
import Ko from '../lang/ko.js'; // 韩语
import Pt from '../lang/pt.js'; // 葡萄牙
import Ru from '../lang/ru.js'; // 俄语


const messages = {
    en: {
        ...elementEN,
        ...En
    },
    zh: {
        ...elementZHCN,
        ...Zh
    },
    zhTW: {
        ...elementZHTW,
        ...ZhTW
    },
    id: {
        ...elementID,
        ...Id
    },
    in: {
        ...elementEN,
        ...In
    },
    vi: {
        ...elementVI,
        ...Vi
    },
    th: {
        ...elementTH,
        ...Th
    },
    es: {
        ...elementES,
        ...Es
    },
    fr: {
        ...elementFR,
        ...Fr
    },
    ja: {
        ...elementJA,
        ...Ja
    },
    ko: {
        ...elementKO,
        ...Ko
    },
    pt: {
        ...elementPT,
        ...Pt
    },
    ru: {
        ...elementRU,
        ...Ru
    },
}
window.localStorage.setItem("lang", 'en')
    // window.sessionStorage.setItem("lang", 'zh')
export default new VueI18n({
    locale: 'en', // set locale 默认显示英文
    fallbackLocale: 'en', //如果语言包没有，则默认从中文中抽取
    messages: messages // set locale messages
});
