export default function lang() {
    let lang = navigator.language; //浏览器语言判断
    lang = lang.substr(0, 2);
    switch (lang) {
        case "en": //英文
            lang = "en";
            break;
        case "zh": //
            lang = "zh";
            break;
        case "zhTW": //
            lang = "zhTW";
            break;
        case "id": //
            lang = "id";
            break;
        case "in": //
            lang = "in";
            break;
        case "vi": //
            lang = "vi";
            break;
        case "th": //
            lang = "th";
            break;
        case "es": //
            lang = "es";
            break;
        case "fr": //
            lang = "fr";
            break;
        case "ja": //
            lang = "ha";
            break;
        case "ko": //
            lang = "ko";
            break;
        case "pt": //
            lang = "pt";
            break;
        case "ru": //
            lang = "ru";
            break;
        default:
            lang = "en";
    }
    return lang;
}