import request from '@/utils/request'

export var uploadDomain = "https://api.apimegamillions.xyz/api/v1/storage/upload";

export var serviceUrl = "https://secure.livechatinc.com/licence/17801016/open_chat.cgi"

export var downloadUrl = "https://www.megamillionsapp.com"

export function getSystemParam(query) {
  return request({
    url: '/api/v1/system/system',
    method: 'get',
    params: query
  })
}

export function getItemList(query) {
  return request({
    url: '/api/v1/system/category',
    method: 'get',
    params: query
  })
}

export function getItemDetail(id) {
  return request({
    url: '/api/v1/system/category/' + id,
    method: 'get',
  })
}


export function getLinkList(query) {
  return request({
    url: '/api/v1/system/link',
    method: 'get',
    params: query
  })
}

export function getArticleList(query) {
  return request({
    url: '/api/v1/system/article',
    method: 'get',
    params: query
  })
}

export function getArticleDetail(id) {
  return request({
    url: '/api/v1/system/article/' + id,
    method: 'get'
  })
}

export function getChannelList(query) {
  return request({
    url: '/api/v1/system/channel',
    method: 'get',
    params: query
  })
}

export function getCustomList(query) {
  return request({
    url: '/api/v1/system/custom',
    method: 'get',
    params: query
  })
}

export function getCustomDetail(id) {
  return request({
    url: '/api/v1/system/custom/' + id,
    method: 'get'
  })
}

