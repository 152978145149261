import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/api/v1/auth/signin',
    method: 'post',
    data
  })
}

export function register(data) {
  return request({
    url: '/api/v1/auth/signup',
    method: 'post',
    data
  })
}

export function forget(data) {
  return request({
    url: '/api/v1/auth/forget',
    method: 'post',
    data
  })
}

export function registerCode(data) {
  return request({
    url: '/api/v1/auth/signup-captcha',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: '/api/v1/profile/profile',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: '/api/v1/auth/logout',
    method: 'post'
  })
}

export function settingUser(data) {
  return request({
    url: '/api/v1/profile/profile/setting',
    method: 'post',
    data
  })
}

export function changePass(data) {
  return request({
    url: "/api/v1/profile/profile/change",
    method: "post",
    data,
  });
}

export function changePay(data) {
  return request({
    url: "/api/v1/profile/profile/pay",
    method: "post",
    data,
  });
}

export function getMessageList(query) {
  return request({
    url: '/api/v1/profile/message',
    method: 'get',
    params: query
  })
}

export function getMessageDetail(id) {
  return request({
    url: "/api/v1/profile/message/" + id,
    method: "get",
  });
}

export function viewAllMessage() {
  return request({
    url: '/api/v1/profile/message/all',
    method: 'get',
  })
}
