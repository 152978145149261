import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'default',
    component: () => import('@/views/login/login'),
  },
  {
    path: '/web',
    name: 'web',
    component: () => import('@/views/home/web'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/login/register'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/hall',
    name: 'hall',
    component: () => import('@/views/hall/hall'),
  },
  {
    path: '/record',
    name: 'record',
    component: () => import('@/views/record/record'),
  },
  {
    path: '/record/history-5d',
    name: 'record-5d',
    component: () => import('@/views/record/history-5d'),
  },
  {
    path: '/record/history-lottery',
    name: 'record-lottery',
    component: () => import('@/views/record/history-lottery'),
  },
  {
    path: '/record/history-wingo',
    name: 'record-wingo',
    component: () => import('@/views/record/history-wingo'),
  },
  {
    path: '/record/history-racing',
    name: 'record-racing',
    component: () => import('@/views/record/history-racing'),
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('@/views/my/index'),
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('@/views/activity/activity'),
  },
  {
    path: '/activity/details',
    name: 'activity-details',
    component: () => import('@/views/activity/details'),
  },
  {
    path: '/my/personal',
    name: 'personal',
    component: () => import('@/views/my/account/personal/personal'),
  },
  {
    path: '/my/edit-nickname',
    name: 'nickname',
    component: () => import('@/views/my/account/personal/edit-nickname'),
  },
  {
    path: '/my/developMsg',
    name: 'developMsg',
    component: () => import('@/views/my/account/personal/developMsg'),
  },
  {
    path: '/my/security',
    name: 'security',
    component: () => import('@/views/my/account/security/security'),
  },
  {
    path: '/my/loginPsd',
    name: 'loginPsd',
    component: () => import('@/views/my/account/security/loginPsd'),
  },
  {
    path: '/my/securityPsd',
    name: 'securityPsd',
    component: () => import('@/views/my/account/security/securityPsd'),
  },
  {
    path: '/my/bank',
    name: 'bank',
    component: () => import('@/views/my/account/bank/bank'),
  },
  {
    path: '/my/addBank',
    name: 'addBank',
    component: () => import('@/views/my/account/bank/addBank'),
  },
  {
    path: '/my/wallet',
    name: 'wallet',
    component: () => import('@/views/my/account/wallet/wallet'),
  },
  {
    path: '/my/addWallet',
    name: 'addWallet',
    component: () => import('@/views/my/account/wallet/addWallet'),
  },
  {
    path: '/my/deposit',
    name: 'deposit',
    component: () => import('@/views/my/fund/deposit/type1'),
  },
  {
    path: '/my/deposit/type1',
    name: 'type1',
    component: () => import('@/views/my/fund/deposit/type1'),
  },
  {
    path: '/my/deposit/type2',
    name: 'type2',
    component: () => import('@/views/my/fund/deposit/type2'),
  },
  {
    path: '/my/deposit/type3',
    name: 'type3',
    component: () => import('@/views/my/fund/deposit/type3'),
  },
  {
    path: '/my/deposit/type4',
    name: 'type4',
    component: () => import('@/views/my/fund/deposit/type4'),
  },
  {
    path: '/my/withdraw',
    name: 'withdraw',
    component: () => import('@/views/my/fund/withdraw/withdraw'),
  },
  {
    path: '/my/bet',
    name: 'bet',
    component: () => import('@/views/my/record/bet'),
  },
  {
    path: '/my/tradeRecord',
    name: 'tradeRecord',
    component: () => import('@/views/my/record/trade'),
  },
  {
    path: '/my/manage',
    name: 'manage',
    component: () => import('@/views/my/agent/manage'),
  },
  {
    path: '/my/report',
    name: 'report',
    component: () => import('@/views/my/agent/report'),
  },
  {
    path: '/my/lower_bet',
    name: 'lower_bet',
    component: () => import('@/views/my/agent/lower_bet'),
  },
  {
    path: '/my/lower_trade',
    name: 'lower_trade',
    component: () => import('@/views/my/agent/lower_trade'),
  },
  {
    path: '/my/gift',
    name: 'gift',
    component: () => import('@/views/my/other/gift'),
  },
  {
    path: '/my/activity',
    name: 'activity',
    component: () => import('@/views/my/other/activity'),
  },
  {
    path: '/my/activity1',
    name: 'activity',
    component: () => import('@/views/my/other/activity1'),
  },
  {
    path: '/my/service',
    name: 'service',
    component: () => import('@/views/my/other/service'),
  },
  {
    path: '/my/message',
    name: 'message',
    component: () => import('@/views/my/other/message'),
  },
  {
    path: '/my/help',
    name: 'help',
    component: () => import('@/views/my/other/help'),
  },
  {
    path: '/my/about',
    name: 'about',
    component: () => import('@/views/my/other/about'),
  },
  {
    path: '/my/help2',
    name: 'help2',
    component: () => import('@/views/my/other/help2'),
  },
  {
    path: '/my/invite',
    name: 'invite',
    component: () => import('@/views/my/other/invite'),
  },
  {
    path: '/my/details',
    name: 'details',
    component: () => import('@/views/my/other/details'),
  },
  {
    path: '/my/details2',
    name: 'details2',
    component: () => import('@/views/my/other/details2'),
  },
  {
    path: '/games/lottery',
    name: 'lottery',
    component: () => import('@/views/games/lottery'),
  },
  {
    path: '/games/5d',
    name: '5d',
    component: () => import('@/views/games/5d'),
  },
  {
    path: '/games/racing',
    name: 'racing',
    component: () => import('@/views/games/racing'),
  },
  {
    path: '/games/wingo',
    name: 'wingo',
    component: () => import('@/views/games/wingo'),
  },
  {
    path: '/cards/cards_one',
    name: 'cards_one',
    component: () => import('@/views/cards/cards_one'),
  },
  {
    path: '/cards/cards_two',
    name: 'cards_two',
    component: () => import('@/views/cards/cards_two'),
  },
  {
    path: '/cards/cards_three',
    name: 'cards_three',
    component: () => import('@/views/cards/cards_three'),
  },

]

const router = new VueRouter({
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
