<template>
  <div id="app">
    <router-view></router-view>

    <Aside />
  </div>

</template>
<script>
window.onload = function() {
  document.addEventListener('touchstart', function(e) {
    if (e.touches.length > 1) {
      e.preventDefault()
    }
  })
  document.addEventListener('gesturestart', function(e) {
    e.preventDefault()
  })
}
export default {
  created() {},
};
</script>

<style lang="less">
// .pop-user-card {
//   color: #7c7f84;
//   background: #fff;
//   border: 1px solid #e4e7ed;
//   box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
//   border-radius: 6px;
//   font: 14px/1.8 Microsoft YaHei, SimSun, Arial;
//   box-sizing: border-box;
//   padding: 0;
//   overflow: hidden;
//   min-width: 320px;
//   margin-right: 17px;
// }

.app {
  padding-top: 50px;
}

body {
  --app-text: #000000;
  --app-main-height: calc(100vh - 694px);
  --app-btn-bg: #ec2829;
  --app-line: rgba(236, 40, 41, 0.5);
  --app-main: #ec2829;

  .up {
    color: #56e693;
  }

  .down {
    color: #e65656;
  }
}

.img-dicek {
  width: 30px;
  height: 30px;
  background-image: url(/static/image/dicek.png);
  display: inline-block;
  background-size: 200% 600%;

  &.dicek1 {
    background-position: 0 0% !important;
  }

  &.dicek2 {
    background-position: 0 -100% !important;
  }

  &.dicek3 {
    background-position: 0 -200% !important;
  }

  &.dicek4 {
    background-position: 0 -300% !important;
  }

  &.dicek5 {
    background-position: 0 -400% !important;
  }

  &.dicek6 {
    background-position: 0 -500% !important;
  }

  &.r-dicek1 {
    background-position: -100% 0 !important;
  }

  &.r-dicek2 {
    background-position: -100% -100% !important;
  }

  &.r-dicek3 {
    background-position: -100% -200% !important;
  }

  &.r-dicek4 {
    background-position: -100% -300% !important;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--font-icon-t3);
  background: transparent;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: var(--font-icon-t3);
}

::-webkit-scrollbar-track {
  background: var(--el-color-white);
}

.el-form {
  .el-input {
    .el-input__inner {
      height: 50px;
      // border-radius: 50px;
    }
  }

  .btn {
    .el-button {
      height: 50px;
      background: var(--app-main);
      border-radius: 25px;
      color: #fff;
      width: 100%;
    }
  }
}

.el-message-box {
  width: 90% !important;
}
</style>
