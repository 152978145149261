export default {
    common: {
        contactNow: "Contact Now",
        minPrice: "Min",
        maxPrice: "Max",
        address: {
            title: "Địa chỉ rút tiền",
            account_number: "Số tài khoản",
            add_btn: "Thêm địa chỉ",
            label_type: "Chọn loại",
            place_type: "Vui lòng chọn loại",
            label_number: "Số tài khoản",
            place_number: "Vui lòng nhập số tài khoản",
            wallet_address: 'Địa chỉ ví',
            bank_name: 'Tên ngân hàng',
            bank_address: "Địa chỉ ngân hàng",
            name: "Tên",
            payee_address: 'Địa chỉ người nhận',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Tên chi nhánh",
            branch_code: "Mã chi nhánh",
            bank_code: "Mã ngân hàng",
            phone: "Số điện thoại",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Vui lòng nhập nội dung",
        },
        priceType: {
            fast_sum: "Và giá trị",
            fast_three_all: "3 Chọn",
            fast_three_one: "Số 3 Single Choice",
            fast_three_diff: "3 Khác biệt",
            fast_three_continue: "Số 3 chọn",
            fast_two_some: "Số 2 Chọn",
            fast_two_one: "2 Chọn cùng số",
            fast_one_one: "Màn hình đơn 1 ăn 1",
            racing_winner: "Vô địch",
            racing_second: "Á quân",
            racing_third: "Quý quân.",
            lotto_one: "A",
            lotto_two: "B",
            lotto_three: "C",
            lotto_four: "D",
            lotto_five: "E",
            wingo_sum: "합계",
            wingo_color: "Color",
            Big: "Lớn",
            Small: "Nhỏ",
            Single: "Độc thân",
            Double: "Đôi",
            Red: "Đỏ",
            Green: "Xanh lá cây",
            Purple: "Tử",
        },
        img_error: "Tải ảnh không thành công",
        more: "Thêm",
        cancel: "Hủy bỏ",
        confirm: "Xác định",
        tips: "Gợi ý ấm áp：",
        no_set: "Hủy bỏ",
        submit: "Giới thiệu",
        copy_success: "Sao chép thành công！",
        copy_error: "Sao chép thất bại！",
        currency: "$",
        filter: "Lọc",
        edit: "Sửa đổi",
        gameStart: "Bắt đầu trò chơi",
        prizenumber: "Số trúng thưởng",
        mynumber: "Số của tôi",
        addresss: "Đây là địa chỉ liên kết.",
        gameAgain: "Một lần nữa.",
        gamerule: "Luật chơi",
        winning_numbers: "Số trúng thưởng",
        winning: "Chúc mừng anh đã trúng thưởng.！",
        notwin: "Rất tiếc, ngài không trúng thưởng!",
        logout: "Bạn có chắc chắn muốn thoát đăng nhập?？",
        success: "Thành công",
        search: "Tìm kiếm",
        withdraw0: "Ứng dụng",
        withdraw1: "Dưới tóc",
        withdraw2: "Thành công",
        withdraw3: "Thất bại",
        balanceNot: "Không đủ số dư tài khoản",
        canceled: "Đã hủy",
        bill: {
            bill101: "Nạp tiền người dùng",
            bill102: "Hệ thống tăng",
            bill103: "Hệ thống đóng băng",
            bill104: "Hệ thống rã đông",
            bill201: "Rút tiền người dùng",
            bill202: "Hệ thống khấu trừ",
            bill203: "Rút tiền thành công",
            bill204: "Đánh giá rút tiền thất bại",
            bill205: "Phí rút tiền",
            bill301: "Tham gia trò chơi",
            bill302: "Game lợi nhuận",
        }
    },
    add: {
        cqk: "Gửi/Rút tiền",
        activity: "Chương trình khuyến mãi",
        online: "Hỗ trợ trực tuyến",
        lottery: "Sảnh mua sắm màu",
        game: "Trò chơi",
        lotter: "Xổ số",
        search_lotter: "Tìm kiếm màu sắc",
        search: "Yêu cầu",
        di: "Số",
        qi: "Giai đoạn",
        ju: "Khoảng cách giây",
        feng: "Đĩa đóng kỳ",
        result: "Kết quả mở thưởng",
        logout: "Thoát",
        available_balance: "Số dư có sẵn",
        notice_title: "Thông báo nền tảng",
        tip30: "Không bật lên trong 30 phút nữa.",
        close: "Đóng cửa",
        personal: "Trung tâm cá nhân",
        level: "Lớp",
        common: "Thông thường",
        news: "Ưu đãi mới nhất",
        help_center: "Trung tâm trợ giúp",
        app_download:"Tải app",
    },
    addnew: {
        remainder: "Thời gian còn lại",
        history: "Lịch sử giải thưởng",
        trend: "Trang chủ",
        my_bet: "Đặt cược của tôi",
        bei: "Nhiều",
        agree: "Tôi đồng ý",
        rule: "Quy tắc pre-sale",
        total_amount: "Tổng số tiền",
        wallet_balance: "Số dư ví",
        total: "Tổng",
        random_bet: "Cược ngẫu nhiên",
    },
    playMethod: {
        p1: "Vui lòng chọn luật chơi để xem",
        p2: "Nghiên cứu luật sẽ tăng gấp đôi lợi nhuận.",
        des1: "Giới thiệu",
        des2: "Nếu bạn chi tiêu 100 để giao dịch, sau khi khấu trừ phí dịch vụ 2%, số tiền hợp đồng: 98",
        fived: {
            p1: "Luật chơi xổ số 5D",
            p2: "Vẽ hướng dẫn",
            p3: "5 chữ số được rút ngẫu nhiên mỗi kỳ (00000-99999)",
            p4: "Ví dụ:",
            p5: "Bài này là 12345.",
            p6: "A = 1",
            p7: "B = 2",
            p8: "C = 3",
            p9: "D = 4",
            p10: "E = 5",
            p11: "Và= A + B + C + D + E = 15",
            p12: "Cách chơi",
            p13: "Người chơi có thể chỉ định sáu kết quả và tổng số tiền đặt cược A, B, C, D, E",
            p14: "A, B, C, D, E Có thể mua",
            p15: "Số(0 1 2 3 4 5 6 7 8 9)",
            p16: "Thấp(0 1 2 3 4)",
            p17: "Cao (5 6 7 8 9)",
            p18: "Số lẻ (1 3 5 7 9)",
            p19: "Số chẵn (0 2 4 6 8)",
            p20: "Sum=A+B+C+D+E Có thể mua",
            p21: "Thấp(0-22)",
            p22: "Cao (23-45)",
            p23: "Số lẻ (1 3 * 43 45)",
            p24: "Số chẵn (0 2 × 42 44)",
        },
        racing: {
            p1: "Trò chơi",
            p2: "※Top 3 và：",
            p3: "Ba số xe đầu tiên cộng lại, và giá trị lớn hơn 16 là lớn, nhỏ hơn hoặc bằng 16 là nhỏ. Số chẵn gọi là đôi, số lẻ gọi là đơn. Top 3 và:",
            p4: "※Hạng nhất đến hạng ba：",
            p5: "Số xe chỉ định, mỗi một số xe là một tổ hợp đầu tư, kết quả mở thưởng 'Số xe đầu tư' tương ứng với thứ tự đầu tư coi là trúng thưởng, dư tình hình coi là không trúng thưởng.",
            p6: "※Hai mặt：",
            p7: "Chỉ đơn, đôi; Lớn, nhỏ。",
            p8: "※Đơn, đôi：",
            p9: "Số là số đôi gọi là đôi, như 2, 4, 6, 8, 10; Số là số lẻ gọi là đơn, như 1,3, 5, 7, 9.",
            p10: "※Lớn, nhỏ",
            p11: "Số mở ra lớn hơn hoặc bằng 6, ví dụ như 6, 7, 8, 9, 10; Nhỏ hơn hoặc bằng 5 là nhỏ như 1, 2, 3, 4, 5.",
        },
        wingo: {
            p1: "Trò chơi",
            p2: "※Số đơn：",
            p3: "Chọn một con số đặt cược, con số mở thưởng được coi là trúng thưởng giống như con số đặt cược, dư tình hình được coi là không trúng thưởng.",
            p4: "※Đỏ：",
            p5: "Đặt cược màu đỏ, nếu số mở thưởng là 0,2,4,6,8 được coi là trúng thưởng, dư tình hình của nó được coi là không trúng thưởng.",
            p6: "※Xanh lá cây：",
            p7: "Đặt cược màu xanh lá cây, nếu số mở thưởng là 1,3, 5, 7, 9 được coi là trúng thưởng, dư tình hình của nó được coi là không trúng thưởng.",
            p8: "※Màu tím：",
            p9: "Đặt cược màu tím, nếu số mở thưởng là 0,5 thì coi là trúng thưởng, dư tình hình là không trúng thưởng.",
            p10: "※Lớn, nhỏ：",
            p11: "Số mở thưởng lớn hơn hoặc bằng 5 là lớn, ví dụ như 5, 6, 7, 8, 9; Nhỏ hơn hoặc bằng 4 là nhỏ, chẳng hạn như 0, 1, 2, 3, 4.",
        },
        lottery: {
            p1: "Trò chơi - 6 ngẫu nhiên mở ra.",
            p2: "A：Kích thước Single Double",
            p3: "Kích thước: tổng giá trị của ba số mở thưởng là 11 - 18; Tổng giá trị 3 - 10 là nhỏ.",
            p4: "Đơn đôi: Ba số mở thưởng tổng giá trị là đơn số, thì đơn trúng thưởng, là đôi, thì đôi trúng thưởng.",
            p5: "B：Điểm",
            p6: "Số mở thưởng tổng giá trị là 3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18 là trúng thưởng; Ví dụ: nếu số mở thưởng là 1, 2, 3, tổng giá trị là 6, thì cược '6' tức là trúng thưởng.",
            p7: "C：Xúc xắc đơn",
            p8: "Ba số mở thưởng, một trong số đó trùng với số đã chọn, tức là trúng thưởng. Ví dụ: nếu số mở thưởng là 1,1,3, thì cược 1 hoặc 3 đều được coi là trúng thưởng. (Cho dù chính quyền chỉ định số điểm xuất hiện mấy lần, chỉ phát một lần không tăng gấp đôi)",
            p9: "D：Xúc xắc đôi",
            p10: "Số thưởng là hai chữ cùng số và phù hợp với tổ hợp xúc xắc đã chọn, tức là trúng thưởng. Ví dụ: nếu số mở thưởng là 1, 1, 3, thì cược xúc xắc đôi 1, 1, tức là trúng thưởng.",
            p11: "E：Lời bài hát: Complete Complete",
            p12: "Xúc xắc vây: Số mở thưởng ba chữ cùng số và phù hợp với tổ hợp xúc xắc vây đã chọn, tức là trúng thưởng",
            p13: "Xúc xắc toàn vòng: Số mở thưởng ba chữ cùng số, tức là trúng thưởng.",
            p14: "F：Kết hợp dice",
            p15: "Chọn tổ hợp xúc xắc một hai, khi kết quả mở thưởng là 2 mã cùng lúc với tổ hợp đã chọn, tức là trúng thưởng. Ví dụ: Nếu số mở thưởng là 1,2, 3, thì đặt vào tổ hợp cảnh hai là 12, tổ hợp cảnh hai là 23, tổ hợp cảnh hai là 13 đều được coi là trúng thưởng.",
        }
    },
    webadd: {
        hot: "Nóng bỏng",
        all: "Tất cả",
        home: "Trang chủ",
        hot_lottery: "Xổ số phổ biến",
        select_channel: "Chọn kênh nạp tiền",
        select_bank: "Chọn ngân hàng",
        top_tab1: "Giải thưởng",
        top_tab2: "TOP",
        top_tab3: "Của tôi",
        web_time: "Thời gian trang web",
        trade: "Giao dịch",
    },
    footer: {
        foot1: "Trang chủ",
        foot2: "Trò chơi",
        foot3: "TOP",
        foot4: "Hoạt động",
        foot5: "Của tôi",
    },
    navBar: {
        back_home: "Quay lại trang đầu",
        help_center: "Trung tâm trợ giúp",
        contact_service: "Liên hệ Hỗ trợ",
        balance: "Số dư",
        my_account: "Tài khoản của tôi",
        account: "Tài khoản",
        record: "Hồ sơ",
        proxy: "Đại lý",
        other: "Khác",
        deposit: "Nạp tiền",
        withdraw: "Rút tiền",
        deposit_type1: "Thanh toán trực tuyến",
        deposit_type2: "Chuyển khoản ngân hàng",
        deposit_type3: "数字货币",
        deposit_type4: "OVO",
        logout: "Thoát",
        login: "Đăng nhập",
        register: "Đăng ký",
        nav1: "Xổ số",
        nav2: "Trò chơi",
        nav3: "Hoạt động",
        nav4: "Điện thoại",
        nav5: "Tài khoản của tôi",
    },
    activity: {
        tit1: "Giải thưởng Daily Plus",
        p1: "Giải thưởng mỗi ngày là căn cứ vào số tiền người dùng đặt cược hôm qua tiến hành tăng giải, tiền thưởng không giới hạn!",
        activity_detail: "Chi tiết sự kiện",
        yestoday_bet: "Hôm qua cá cược",
        bonus_ratio: "Tỷ lệ giải thưởng",
        now_level: "Lớp hiện tại",
        receive_bonus: "Giải thưởng.",
        receive: "Nhận",
        not_receive: "Không chấp nhận",
        activity_time: "Thời gian hoạt động",
        p2: "1、Giải thưởng mở cửa hàng ngày sau 00:20 sáng",
        p3: "2、Tỷ lệ thưởng là tỷ lệ thưởng nhất định dựa trên đẳng cấp người dùng và số tiền đặt cược tích lũy hôm qua.",
        p4: "3、Rút tiền và các cược không hợp lệ khác sẽ không được tính",
        p5: "4、Số tiền đặt cược hợp lệ phải được đáp ứng [thanh toán giải thưởng đã mở] nếu sau khi đặt cược được mở cách nhau nhiều ngày, số tiền đặt cược hợp lệ sẽ được tích lũy vào [ngày thanh toán giải thưởng]。",
        p6: "5、Ví dụ: Đặt cược 10/1 nhưng 10/15 mới mở thưởng, thì số tiền đặt cược hợp lệ của đơn đặt cược này sẽ tích lũy vào ngày 15/10, người dùng có thể nhận được phần thưởng cộng thêm của đơn đặt cược này vào ngày 16/10.",
        p7: "6、Không nhận tiền thưởng trước 24 giờ ngày hoạt động được coi là tự động từ bỏ tư cách hoạt động",
    },
    game: {
        history_tit: "Lịch sử mở thưởng",
        play_tit: "Hướng dẫn chơi",
        official_award: "Giải thưởng chính thức",
        betting: "Đang đặt cược",
        waiting: "Chờ giải thưởng",
        opening: "Đang mở giải thưởng",
        settled: "Giải quyết",
        auto_change: "Tự động chuyển đổi",
        height_win: "Tối đa có thể thắng",
        total: "Tổng cộng",
        pour: "Ghi chú",
        every_pour: "Mỗi cược",
        total_pour: "Tổng số cược",
        total_amount: "Tổng số cược",
        now_bet: "Đặt cược ngay",
        my_bet: "Đặt cược của tôi",
        winning: "Giải thưởng",
        top: "TOP",
        winning_tip: "Cập nhật thông tin chiến thắng theo thời gian thực",
        top_tip: "Top bảng xếp hạng",
    },
    record: {
        lottery: "Xổ số",
        game: "Trò chơi",
        today_profit_loss: "Hôm nay lợi nhuận",
        label_formula: "Công thức lợi nhuận và lỗ",
        formula: "Công thức: Thắng - Cược+Hoạt động+Điểm trở lại",
        profitLoss_label1: "Giải thưởng",
        profitLoss_label2: "Hoạt động",
        profitLoss_label3: "Đặt cược",
        profitLoss_label4: "Điểm trở lại",
        profitLoss_label5: "Chuyển khoản",
        profitLoss_label6: "Nhận tiền",
        profitLoss_label7: "Gửi phong bì màu đỏ",
        profitLoss_label8: "Nhận phong bì màu đỏ",
        trade_tab1: "Tất cả hồ sơ",
        trade_tab2: "Hồ sơ nạp tiền",
        trade_tab3: "Hồ sơ rút tiền",
    },
    table: {
        label_no: " No.",
        label_time: "Thời gian",
        label_result: "Kết quả",
        label_size: "Kích thước",
        label_single_double: "Đôi đơn",
        label_sun_value: "Và giá trị",
        no_data: "Không có dữ liệu.",
        big: "Lớn",
        small: "Nhỏ",
        single: "Độc thân",
        double: "Đôi",
        label_issue: "Số kỳ",
        label_bet_amount: "Số tiền đặt cược",
        label_reward: "Tiền thưởng",
        no_reward: "Không trúng thưởng",
        is_reward: "Giải thưởng",
        label_level: "Lớp",
        label_integral: "Điểm phát triển",
        label_award: "Phần thưởng thăng hạng",
        label_skip_awardl: "Phần thưởng nhảy",
        label_register_link: "Liên kết đăng ký",
        label_remark: "Ghi chú",
        label_rise_time: "Thời gian tạo",
        label_register_number: "Số lượng đăng ký",
        label_operate: "Hoạt động",
        label_gametit: "Màu sắc/Trò chơi",
        label_lower_rebate: "Điểm trở lại cấp dưới",
        label_self_rebate: "Tự trở về",
        label_set_rebate_range: "Phạm vi trở lại có thể được thiết lập",
        bonus_state: "Trạng thái thưởng",
        label_serial_number: "Số dòng chảy",
        label_income_amount: "Số tiền thu nhập",
        label_expend_amount: "Số tiền chi tiêu",
        label_available_balance: "Số dư có sẵn",
        label_abstract: "Tóm tắt",
        label_details: "Chi tiết",
        label_account: "Tài khoản",
        label_amount: "Số lượng",
        label_bet_cont: "Nội dung cá cược",
        label_ottery_number: "Số mở thưởng",
        order_number: "Số đơn",
        play_methods: "Chơi",
        label_periods: "Game lợi nhuận",
        label_number: "Số",
        label_color: "Màu sắc",
    },
    my: {
        last_login_time: "Lần đăng nhập cuối",
        logout_account: "Thoát khỏi tài khoản",
        my_account: "Tài khoản của tôi",
        account: "Tài khoản",
        personal_tit: "Thông tin cá nhân",
        security_tit: "Trung tâm bảo mật",
        bank_tit: "Số tài khoản ngân hàng",
        record: "Hồ sơ",
        profit_loss_tit: "Hôm nay lợi nhuận",
        trade_record_tit: "Hồ sơ giao dịch",
        bet_record: "Hồ sơ cá cược",
        proxy: "Đại lý",
        manag_tit: "Quản lý đại lý",
        report_tit: "Báo cáo đại lý",
        lower_bet_tit: "Cược thấp hơn",
        lower_trade_tit: "Giao dịch thấp hơn",
        other: "Khác",
        gift_tit: "Quà tặng sự kiện",
        message_tit: "Trung tâm thông điệp",
        personal_data: "Thông tin cá nhân",
        balance: "Số dư",
        last_sync_time: "Thời gian đồng bộ hóa cuối cùng",
        deposit: "Nạp tiền",
        withdraw: "Rút tiền",
        yesterday_deposit_time: "Thời gian trung bình hôm qua",
        yesterday_withdraw_time: "Thời gian trung bình hôm qua",
        help_tit: "Trung tâm trợ giúp",
        all: "Tất cả",
        lower_report: "Báo cáo cấp dưới",
        about_us: "Về chúng tôi",
        logout: "Đăng xuất",
        invite: "Mời bạn bè",
        lang_change: "Chuyển đổi ngôn ngữ",
    },
    account: {
        add_bank_account: "Thêm tài khoản ngân hàng",
        bank_tip: "Có thể thêm tối đa 5 tài khoản ngân hàng, tài khoản ngân hàng rút tiền thành công sẽ tự động bị khóa, không thể xóa hoặc sửa đổi. Tài khoản ngân hàng không rút thành công có thể sửa đổi không thể xóa.",
        account_msg: "Thông tin tài khoản",
        growth_value: "Giá trị tăng trưởng",
        noset_nickname: "Biệt danh không được đặt",
        nationality: "Quốc tịch",
        next_level: "Khoảng cách tới cấp độ tiếp theo",
        integral: "Tích phân",
        integral_tip: "Thêm 1 điểm cho mỗi lần nạp 1.00 $",
        level_system: "Hệ thống phân cấp",
        change_avatar: "Thay thế avatar",
        loginpsd_tip: "Mật khẩu đăng nhập Kết hợp 6-16 chữ cái và số",
        modify_psd: "Thay đổi mật khẩu",
        securitypsd_tip: "Mật khẩu an toàn (kết hợp 6 chữ số)",
        forget_securitypsd: "Quên mật khẩu an toàn",
        confidentiality_tip: "Thông tin tài khoản người dùng có thể được khôi phục khi tài khoản hoặc mật khẩu bị mất",
        bank_account: "Số tài khoản ngân hàng",
        edit_nickname: "Biên tập Nickname",
        confidentiality: "Vấn đề bảo mật",
        accumulated_winning: "Giải thưởng tích lũy",
    },
    agent: {
        illustrate_p1: "Khi bạn có thể nhìn thấy trang này, chứng tỏ tài khoản của bạn vừa là tài khoản người chơi cũng là tài khoản đại lý, vừa có thể tự mình đặt cược, cũng có thể phát triển người chơi cấp dưới, kiếm được tiền hoa hồng.",
        illustrate_p2: "Cách kiếm Back Point？",
        illustrate_p3: "Điểm trở lại có thể đạt được bằng với sự khác biệt giữa điểm trở lại của chính nó và điểm trở lại của cấp dưới, chẳng hạn như điểm trở lại của chính nó 5 và điểm trở lại của cấp dưới 3, bạn sẽ nhận được điểm trở lại của số tiền đặt cược cấp dưới 2, chẳng hạn như đặt cược cấp dưới 100$, bạn sẽ nhận được 2$. Bấm vào cấp dưới mở tài khoản, có thể kiểm tra điểm trở về của bản thân, cũng có thể thiết lập điểm trở về cho cấp dưới.",
        illustrate_p4: "Cách mở tài khoản cho cấp dưới？",
        illustrate_p5: "Nhấn vào cấp dưới mở tài khoản, trước tiên thiết lập điểm trở lại cho cấp dưới của bạn, sau khi thiết lập thành công sẽ tạo ra một mã mời, gửi mã mời cho cấp dưới của bạn đăng ký, sau khi đăng ký anh ta chính là cấp dưới của bạn, nhấp vào quản lý người dùng, là có thể xem tài khoản anh ta đăng ký.",
        illustrate_p6: "Nếu như bạn thiết lập tài khoản loại đại lý cho cấp dưới, như vậy cấp dưới của bạn có thể tiếp tục phát triển cấp dưới, nếu như thiết lập loại người chơi, như vậy cấp dưới của bạn chỉ có thể đặt cược, không thể phát triển cấp dưới nữa, cũng không nhìn thấy trung tâm đại lý. Người dùng Tube",
        illustrate_tip1: "1、Tỷ lệ hoàn trả khác nhau cũng khác nhau, nhấp vào bảng tỷ lệ hoàn trả để xem tỷ lệ hoàn trả。",
        illustrate_tip2: "2、Điểm trở lại càng thấp, tỷ lệ cược càng thấp và điểm trở lại được đề xuất cho cấp dưới không quá thấp。",
        illustrate_tip3: "3、Có thể xem các đại lý đang phát triển như thế nào trên báo cáo đại lý, đặt cược thấp hơn, giao dịch thấp hơn。",
        illustrate_tip4: "4、Cấp dưới đề nghị mở cũng là loại đại lý, bất kể phát triển bao nhiêu cấp, bạn đều có thể đạt được điểm trở lại.。",
        open_type: "Loại tài khoản mở：",
        open_type1: "Tất cả",
        open_type2: "Đại lý",
        open_type3: "Người chơi",
        invite_tip1: "1. Tỷ lệ đặt cược khác nhau, tỷ lệ đặt cược càng cao càng cao.",
        invite_tip2: "2. Tiền hoa hồng mà đại lý có thể nhận được bằng với giá trị chênh lệch giữa điểm trả lại của đại lý và điểm trả lại của cấp dưới, ví dụ như điểm trả lại của đại lý là 6, điểm trả lại của cấp dưới là 4, đại lý có thể nhận được 2% số tiền đặt cược của cấp dưới, tức là đặt cược của cấp dưới là 100 tệ, đại lý có thể nhận được 2 tệ.",
        invite_tip3: "3. Giá trị trả lại của cấp dưới càng thấp, tỷ lệ đặt cược của cấp dưới lại càng thấp, đề nghị trả lại cho cấp dưới không nên quá thấp.",
        rebateDialog_tit: "Xem điểm trở lại",
        delete_invite_tip: "Bạn có chắc chắn muốn xóa mã mời này?？",
        rebate_table: "Bảng Return Odds",
        generate_invitation_code: "Tạo mã mời",
        subordinate_tip1: "1. Tỷ lệ đặt cược khác nhau, tỷ lệ đặt cược càng cao càng cao.",
        subordinate_tip2: "2. Tiền hoa hồng mà đại lý có thể nhận được bằng với giá trị chênh lệch giữa điểm trả lại của đại lý và điểm trả lại của cấp dưới, ví dụ như điểm trả lại của đại lý là 6, điểm trả lại của cấp dưới là 4, đại lý có thể nhận được 2% số tiền đặt cược của cấp dưới, tức là đặt cược của cấp dưới là 100 tệ, đại lý có thể nhận được 2 tệ.",
        subordinate_tip3: "3. Giá trị trả lại của cấp dưới càng thấp, tỷ lệ đặt cược của cấp dưới lại càng thấp, đề nghị trả lại cho cấp dưới không nên quá thấp.",
        manage_tab1: "Mô tả đại lý",
        manage_tab2: "Mở tài khoản cấp dưới",
        manage_tab3: "Mã mời",
        manage_tab4: "Quản lý người dùng",
        lower_bet_tip: "Hồ sơ giao dịch được lưu giữ tối đa 21 ngày.",
        report_label1: "Đặt cược",
        report_label2: "Giải thưởng",
        report_label3: "Hoạt động",
        report_label4: "Đội trở lại",
        report_label5: "Lợi nhuận nhóm",
        report_label6: "Số lượng cá cược",
        report_label7: "Số lượng đầu tiên",
        report_label8: "Số lượng đăng ký",
        report_label9: "Số lượng cá cược",
        report_label10: "Số dư đội",
        report_label11: "Nạp tiền",
        report_label12: "Rút tiền",
        report_label13: "Đại lý trở lại",
        report_label14: "Chuyển khoản",
        report_label15: "Nhận tiền",
        report_label16: "Gửi phong bì màu đỏ",
        report_label17: "Nhận phong bì màu đỏ",
    },
    fund: {
        tip1: "Vui lòng chuyển khoản vào tài khoản ngân hàng bên dưới",
        tip2: "Vui lòng điền đầy đủ thông tin chuyển tiền của bạn.",
        tip3: "(Hãy chắc chắn để gửi đơn đặt hàng của bạn sau khi chuyển tiền, nếu không bạn không thể kiểm tra thanh toán của bạn trong thời gian!)",
        deposit_rate: "Tỷ giá tiền gửi",
        currency_number: "Số tiền",
        tip4: "Vui lòng sử dụng nền tảng tiền điện tử tương ứng để chuyển tiền",
        tip5: "Giới hạn duy nhất",
        tip6: "(Nếu không thể điền thông tin xác thực, vui lòng tải lên ảnh chụp màn hình biên lai giao dịch)",
        click_upload: "Click vào Upload",
        upload_tip: "Chỉ có thể upload file jpg/png",
        receiving_address: "Địa chỉ nhận tiền",
        tip7: "Vui lòng sử dụng OVO để chuyển tiền vào các tài khoản ngân hàng sau:",
        tip8: "1. Thanh toán ngân hàng trực tuyến phải mở ngân hàng trực tuyến mới được thanh toán.",
        tip9: "2. Sau khi bổ sung thành công, nhấp vào 'Trở về thương hộ' là có thể tự động vào tài khoản.",
    },
    other: {
        gift_tip1: "1. Quà tặng phải đạt điều kiện nhận mới được nhận.",
        gift_tip2: "2. Quà chưa nhận sau khi hết hạn tự động hết hiệu lực, không thể nhận lại.",
        other_tip1: "Thư được lưu giữ tối đa 7 ngày;",
    },
    form: {
        input_inner: "Vui lòng nhập nội dung",
        label_username: "Tài khoản người dùng：",
        place_username: "Vui lòng nhập số tài khoản người dùng",
        label_loginpsd: "Mật khẩu đăng nhập：",
        place_loginpsd: "Vui lòng nhập mật khẩu đăng nhập",
        label_invitecode: "Mã mời：",
        place_invitecode: "Vui lòng điền mã mời 8 chữ số",
        label_oldpsd: "Mật khẩu gốc",
        place_oldpsd: "Vui lòng nhập mật khẩu gốc",
        label_newpsd: "Mật khẩu mới",
        place_newpsd: "Vui lòng nhập mật khẩu mới",
        label_crmpsd: "Xác nhận mật khẩu",
        place_crmpsd: "Vui lòng nhập lại mật khẩu mới",
        label_remark: "Ghi chú",
        place_remark: "Vui lòng nhập ghi chú",
        label_lower_account: "Số tài khoản：",
        place_lower_account: "Vui lòng nhập tài khoản cấp dưới",
        label_type: "Loại：",
        place_select: "Vui lòng chọn",
        label_lottery_title: "Màu sắc：",
        label_state: "Trạng thái：",
        option_state1: "Tất cả",
        option_state2: "Chờ giải thưởng",
        option_state3: "Không trúng thưởng",
        option_state4: "Đã trúng thưởng",
        label_time: "Thời gian：",
        times1: "Hôm nay",
        times2: "Hôm qua",
        times3: "Tháng này",
        times4: "Tháng trước",
        label_realname: "Tên thật",
        place_realname: "Vui lòng nhập tên thật",
        label_bank_number: "Số thẻ ngân hàng",
        place_bank_number: "Vui lòng nhập số thẻ ngân hàng",
        label_bank_name: "Tên ngân hàng",
        place_bank_name: "Vui lòng chọn tên ngân hàng",
        label_abstract: "Tóm tắt:",
        label_channel: "Kênh nạp tiền",
        place_channel: "Vui lòng chọn kênh nạp tiền",
        label_deposit_amount: "Số tiền nạp",
        place_deposit_amount: "Vui lòng nhập số tiền nạp",
        label_holder: "Mở tài khoản",
        label_deposit_name: "Tên người gửi tiền",
        place_deposit_name: "Vui lòng nhập tên người gửi tiền",
        label_transfer_msg: "Thông tin chuyển khoản",
        place_transfer_msg: "Vui lòng điền thông tin chuyển khoản",
        label_transfer_pic: "Ảnh chụp màn hình biên lai giao dịch",
        label_transfer_pic_tip: "(Vui lòng tải lên ảnh chụp biên lai giao dịch)",
        label_bank_account_name: "Tên tài khoản ngân hàng",
        label_balance: "Số dư",
        label_available_withdraw: "Số tiền rút ra",
        label_available_number: "Số lần rút tiền",
        label_withdraw_amount: "Số tiền rút ra",
        placce_withdraw_amount: "Vui lòng nhập số tiền rút)",
        available_withdraw: "Yêu cầu",
        label_transfer_bank: "Ngân hàng chuyển khoản",
        label_security_psd: "Mật khẩu an toàn",
        place_security_psd: "Vui lòng nhập mật khẩu an toàn",
        place_nickname: "Biệt danh",
        message_nickname: "Vui lòng nhập nickname",
        place_phone: "Vui lòng nhập số điện thoại",
        place_email: "Vui lòng nhập hộp thư",
        place_invite_id: "Vui lòng nhập mã mời",
        place_verify_code: "Vui lòng nhập CAPTCHA",
        send: "Gửi",
        test_username: "Kiểm tra tên người dùng",
        place_amount: "Vui lòng nhập số tiền",
    },
    login: {
        login_tit: "Đăng nhập người dùng",
        forget_psd: "Quên mật khẩu？",
        login: "Đăng nhập",
        register_tit: "Đăng ký thành viên",
        register: "Đăng ký",
        have_account: "Đã có tài khoản, đăng nhập",
        country_code: "Mã quốc gia",
        phone: "Điện thoại",
        email: "Hộp thư",
    },
    city: {
        albania: "Albania",
        algeria: "An- giê- riName",
        argentina: "Argentina",
        armenia: "Armenia",
        australia: "Châu Úc",
        pakistan: "Việt Nam",
        austria: "Áo",
        bahrain: "Bahrain",
        belgium: "Bỉ",
        bosnia_and_Herzegovina: "Bosnia và Herzegovina",
        brazil: "Việt Nam",
        brunei: "Brunei",
        bulgaria: "Bulgaria",
        cambodia: "Campuchia",
        canada: "Canada",
        cameroon: "Cameroon",
        chile: "Chi- lê",
        colombia: "Colombia",
        costa_Rica: "Costa Rica",
        croatia: "Croatia",
        cyprus: "Síp",
        czech_Republic: "Cộng hòa Séc",
        denmark: "Đan Mạch",
        dominican_Republic: "Cộng hòa Dominica",
        egypt: "Ai Cập",
        estonia: "Estonia",
        ethiopia: "Ethiopia",
        finland: "Phần Lan",
        france: "Pháp",
        georgia: "Georgia",
        germany: "Đức",
        ghana: "Ghana",
        greece: "Hy Lạp",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hồng Kông, Trung Quốc",
        hungary: "Việt Nam",
        iceland: "Iceland",
        ireland: "Việt Nam",
        italy: "Ý",
        india: "Ấn độ",
        indonesia: "Việt Nam",
        israel: "Israel",
        iran: "Iran",
        iraq: "Iraq",
        japan: "Nhật Bản",
        kazakstan: "Cộng hòa Kazakhstan",
        kenya: "Việt Nam",
        korea: "Hàn Quốc",
        kuwait: "Kuwait",
        kyrgyzstan: "Kyrgyzstan",
        laos: "Lào",
        latvia: "Latvia",
        lithuania: "Li- tu- a- niName",
        luxembourg: "Luxembourg",
        macao_China: "Ma Cao, Trung Quốc",
        macedonia: "Ma- xê- đô- ni- aName",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Mexico",
        moldova: "Moldova",
        monaco: "Monaco",
        mongolia: "Mông Cổ",
        montenegro: "Cộng hòa Montenegro",
        morocco: "Ma- rốc",
        myanmar: "Miến Điện",
        netherlands: "Hà Lan",
        new_Zealand: "New Zealand",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Na Uy",
        oman: "Oman",
        palestine: "Palestine",
        panama: "Panama.",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "Philippines",
        poland: "Ba Lan",
        portugal: "Bồ Đào Nha葡萄牙",
        puerto_Rico: "Puerto Rico",
        qatar: "Qatar",
        romania: "Rumani",
        russia: "Nga",
        republic_of_Trinidad_and_Tobago: "Cộng hòa Trinidad và Tobago",
        rwanda: "Rwanda",
        saudi_Arabia: "Ả Rập Saudi",
        serbia: "Serbia",
        singapore: "Singapore",
        slovakia: "Slovakia",
        slovenia: "Xlô- viName",
        south_Africa: "Nam Phi",
        spain: "Tây Ban Nha",
        sri_Lanka: "Sri Lanka",
        sweden: "Thụy Điển",
        switzerland: "Thụy Sĩ",
        taiwan_China: "Đài Loan taiwan_China",
        tajikistan: "Cộng hòa Tajik",
        tanzania: "Tanzania",
        thailand: "Thái Lan",
        turkey: "Thổ Nhĩ Kỳ",
        turkmenistan: "Cộng hòa Turkmen",
        ukraine: "Ukraine",
        united_Arab_Emirates: "Các Tiểu vương quốc Ả Rập Thống nhất",
        united_Kingdom: "United Kingdom",
        united_States: "Hoa Kỳ",
        uzbekistan: "Uzbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaijan",
        bangladesh: "Bangladesh",
        belarus: "Belarus",
        belize: "Belize",
        benin: "Bénin",
        bhutan: "Bhutan",
        bolivia: "Bolivia",
        botswana: "Botswana",
        british_Virgin_Islands: "Quần đảo Virgin thuộc Anh",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Cape Verde",
        cayman_Islands: "Quần đảo Cayman",
        central_African_Republic: "Cộng hòa Trung Phi",
        chad: "Chad",
        comoros: "Comoros",
        the_Republic_of_the_Congo: "Congo (bằng tiếng Anh).",
        democratic_Republic_of_the_Congo: "Congo (Kim)",
        djibouti: "Djibouti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Guinea Xích đạo",
        eritrea: "Eritrea",
        fiji: "Fiji",
        gabon: "Gabon",
        gambia: "Gambia",
        greenland: "Greenland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: "Đảo Man",
        cote_d_Ivoire: "Bờ Biển Ngà",
        jamaica: "Nha- mê- caName",
        jordan: "Jordan",
        lebanon: "Lebanon",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Li- bi- aName",
        madagascar: "Madagascar",
        malawi: "Ma- la- uy",
        maldives: "Maldives",
        mali: "Ma- li- ",
        mauritania: "Ma- ri- tanhName",
        mauritius: "Mauritius",
        mozambique: "Mozambique",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Niger",
        north_Korea: "Bắc Triều Tiên",
        reunion: "Réunion",
        san_Marino: "San Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "Sudan",
        suriname: "Suriname",
        eswatini: "Thụy Sĩ",
        syria: "Syria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisia",
        united_States_Virgin_Islands: "Quần đảo Virgin thuộc Mỹ",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatican",
        yemen: "Yemen",
        yugoslavia: "Yugoslavia",
        zambia: "Zambia",
        zimbabwe: "Zimbabwe",
        china: "Trung Quốc",
    }
};
