export default {
    common: {
        contactNow: "Contact Now",
        minPrice: "Min",
        maxPrice: "Max",
        address: {
            title: "Endereço de retirada",
            account_number: "Número da conta",
            add_btn: "Adicionar endereço",
            label_type: "Selecione o tipo",
            place_type: "Selecione o tipo",
            label_number: "Número da conta",
            place_number: "Digite o número de sua conta",
            wallet_address: 'Endereço da carteira',
            bank_name: 'Nome do banco',
            bank_address: "Endereço do banco",
            name: "Nome e sobrenome",
            payee_address: 'Endereço do beneficiário',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Nome da filial",
            branch_code: "Código de linha",
            bank_code: "Código do banco",
            phone: "Número de telefone celular",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Digite o conteúdo",
        },
        priceType: {
            fast_sum: "Soma",
            fast_three_all: "Três Mesmo Número Eleições Gerais",
            fast_three_one: "Três Mesmo Número Escolha única",
            fast_three_diff: "Três números diferentes",
            fast_three_continue: "Escolha múltipla de números triplos (TNT) (informática)",
            fast_two_some: "Índices duplos Escolha múltipla",
            fast_two_one: "Duplo Escolha individual",
            fast_two_diff: "Número da bissetriz (eletrónica)",
            fast_one_one: "Lit. peneira única paga um por um",
            racing_winner: "Campeões",
            racing_second: "Segundo lugar ",
            racing_third: "Medalha de bronze",
            lotto_one: "A",
            lotto_two: "B",
            lotto_three: "C",
            lotto_four: "D",
            lotto_five: "E",
            wingo_sum: "합계",
            wingo_color: "Color",
            Big: "Mais antigo",
            Small: "Poucos",
            Single: "Mono",
            Double: "Apelido Shuang",
            Red: "Bónus",
            Green: "Verde",
            Purple: "Purple",
        },
        img_error: "Falha no carregamento da imagem",
        more: "Mais",
        cancel: "Cancelamentos",
        confirm: "Definir",
        tips: "Dica：",
        no_set: "Não corrigido",
        submit: "Apresentar ",
        copy_success: "Sucesso da cópia！",
        copy_error: "Falha na reprodução！",
        currency: "$",
        filter: "Rastreio",
        edit: "Modificações",
        gameStart: "Iniciar o jogo",
        prizenumber: "Número vencedor",
        mynumber: "O meu número.",
        addresss: "Eis o endereço da ligação",
        gameAgain: "Mais uma vez.",
        gamerule: "Regras do jogo",
        winning_numbers: "Número vencedor",
        winning: "Parabéns pela vossa vitória!！",
        notwin: "Infelizmente, não ganhaste!",
        logout: "Tem a certeza de que pretende terminar a sessão?？",
        success: "Sucessos",
        search: "Procurar algo",
        withdraw0: "Candidatura ",
        withdraw1: "Em curso",
        withdraw2: "Sucessos",
        withdraw3: "Falhar",
        balanceNot: "Saldo insuficiente da conta",
        canceled: "Cancelado",
        bill: {
            bill101: "Recarga do utilizador",
            bill102: "Integrador de sistemas",
            bill103: "Congelamento do sistema",
            bill104: "Descongelamento do sistema",
            bill201: "Retiradas dos utilizadores",
            bill202: "Deduções do sistema",
            bill203: "Retirada com êxito",
            bill204: "Retirada Falha de auditoria",
            bill205: "Taxa de retirada",
            bill301: "Participar no jogo",
            bill302: "Lucros do jogo",
        }
    },
    add: {
        service: "Serviço",
        apoio_online: "Apoio online",
        online_tip: "Serviço ao cliente disponível para ajudar a soluço",
        chat: "CHAT",
        activity: "Atividade",
        activity_p1: "Primeira atividade de depósito",
        activity_p2: "Segunda atividade de depósito",
        activity_p3: "Terceiro evento de depósitoo",
        activity_table1: "Valor do depósito inicial",
        activity_table2: "Proporção",
        activity_table3: "Requisitos de saque",
        activity_tip1: "Condições do evento:",
        activity_tip2: "1. Requisitos de apostas válidos",
        activity_tip3: "a. Ao participar do evento, o saldo da conta &lt; valor do depósito, aposta válida = (saldo + bônus) x 2, você pode sacar dinheiro",
        activity_tip4: "b. Ao participar do evento, o saldo da conta &gt; o valor do depósito e a aposta efetiva = (valor do depósito + bônus) x 2, você pode sacar o dinheiro",
        activity_tip5: "2. Após o depósito ser bem-sucedido, participe da atividade antes de fazer apostas.",
        activity_tip6: "3. O primeiro depósito, o segundo depósito e o terceiro depósito só podem ser participados uma vez.",
        activity_tip7: "Regras da atividade:",
        activity_tip8: "1. Por favor, preencha seus dados pessoais antes de  do evento",
        activity_tip9: "2. Cada membro com o mesmo nome, mesmo endereço IP, mesmo código de dispositivo e mesmo número de telefone só poderá participar do evento uma vez. Se um membro registrar intencionalmente várias contas consecutivas, a empresa retirará ou revogará os direitos da agência e congelará permanentemente a conta e recuperará todos os ganhos.",
        activity_tip10: "3. Todas as atividades são preparadas para os membros, caso seja descoberto que um indivíduo ou organização cometeu fraude para lucrar com as atividades. A Empresa reserva-se o direito total de reter ou anular o valor total do prêmio desse indivíduo ou organização",
        activity_img1: "Os primeiros 3 bônus de recarga do novo membro",
        activity_img2: "Recomende amigos e ganhe bônus",
        activity_img3: "VIP",
        activity_img4: "Fundos de ajuda, até 100%",
        activity_img5: "fundo de ajuda",

    },
    playMethod: {
        p1: "Seleccione as regras do jogo que pretende visualizar",
        p2: "Se estudares as regras, poderás duplicar os teus ganhos.",
        des1: "Breve",
        des2: "Se negociar por 100, o montante do contrato após dedução da taxa de serviço de 2%: 98",
        fived: {
            p1: "Regras do jogo da lotaria 5D",
            p2: "Instruções de desenho",
            p3: "5 Dígitos seleccionados aleatoriamente (00000-99999) para cada emissão",
            p4: "Por exemplo:",
            p5: "O número do sorteio atual é 12345",
            p6: "A = 1",
            p7: "B = 2",
            p8: "C = 3",
            p9: "D = 4",
            p10: "E = 5",
            p11: "Responder com cânticos= A + B + C + D + E = 15",
            p12: "Como jogar",
            p13: "Os jogadores podem especificar apostas nos seis resultados e totais de A, B, C, D e E",
            p14: "A, B, C, D, E Disponível",
            p15: "Números (0 1 2 3 4 5 6 7 8 9)",
            p16: "Baixo (0 1 2 3 4)",
            p17: "Elevado (5 6 7 8 9)",
            p18: "Números ímpares (1 3 5 7 9)",
            p19: "Par (0 2 4 6 8)",
            p20: "Sum = A+B+C+D+E está disponível para compra",
            p21: "Baixo (0-22)",
            p22: "Elevado (23-45)",
            p23: "Números ímpares (1 3---43 45)",
            p24: "Mesmo (0 2---42 44)",
        },
        racing: {
            p1: "Jogabilidade",
            p2: "※Os três primeiros e：",
            p3: "A soma dos três primeiros números do carro é maior do que 16 para o grande, menor ou igual a 16 para o pequeno. Os números pares são chamados de duplos, os números ímpares são chamados de simples. ※As três primeiras somas:",
            p4: "※Do primeiro ao terceiro lugar：",
            p5: "Designação do número do carro, cada número de carro é uma combinação de apostas, o resultado da lotaria “número do carro apostado” correspondente ao nome da aposta é considerado vencedor, o resto da situação é considerado não vencedor.",
            p6: "※Ambos os lados：",
            p7: "Refere-se a simples e duplo; grande e pequeno.",
            p8: "※Simples e duplo：",
            p9: "Um número par é chamado de duplo, por exemplo, 2, 4, 6, 8, 10; um número ímpar é chamado de simples, por exemplo, 1, 3, 5, 7, 9.",
            p10: "※Grandes e pequenos",
            p11: "Os números maiores ou iguais a 6 são considerados grandes, por exemplo, 6, 7, 8, 9, 10; menores ou iguais a 5 são considerados pequenos, por exemplo, 1, 2, 3, 4, 5.",
        },
        wingo: {
            p1: "Jogabilidade",
            p2: "※Número único：",
            p3: "Escolher um número para apostar, o número sorteado e o número da aposta são considerados os mesmos que os vencedores, os restantes casos são considerados como não vencedores.",
            p4: "※Cor-de-rosa：",
            p5: "Apostar no vermelho é considerado uma vitória se os números da lotaria forem 0, 2, 4, 6, 8, e uma não vitória nos restantes cenários.",
            p6: "※Mais verde：",
            p7: "Se apostar no verde, é considerado vencedor se os números sorteados forem 1, 3, 5, 7, 9, e é considerado não vencedor nos restantes cenários.",
            p8: "※Violeta (cor)：",
            p9: "Se apostar na cor púrpura, será considerado um vencedor se os números da lotaria forem 0 e 5, e um não vencedor nos restantes casos.",
            p10: "※Grandes e pequenos：",
            p11: "Os números desenhados maiores ou iguais a 5 são considerados grandes, por exemplo, 5, 6, 7, 8, 9; menores ou iguais a 4 são considerados pequenos, por exemplo, 0, 1, 2, 3, 4.",
        },
        lottery: {
            p1: "Jogabilidade ~ 6 abertos aleatoriamente.",
            p2: "A: Grandes e pequenos, individuais e duplos",
            p3: "Tamanho: A soma dos três intervalos de números da lotaria de 11 a 18 é considerada grande; a soma de 3 a 10 é considerada pequena.",
            p4: "Simples e duplo: Se a soma total dos três números da lotaria for simples, ganha o simples e, se for duplo, ganha o duplo.",
            p5: "B：Verificar números",
            p6: "Se o valor da soma dos números da lotaria for 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, ganhará; por exemplo, se os números da lotaria forem 1, 2, 3 e o valor da soma for 6, a aposta no “6” será vencedora.",
            p7: "C：Dado único",
            p8: "Se um dos três números da lotaria for o mesmo que o número selecionado, é considerado vencedor. Exemplo: Se os números da lotaria forem 1, 1, 3, então a aposta no dado simples 1 ou no dado simples 3 será considerada vencedora. (Independentemente de quantas vezes o número de pontos especificado aparecer, o pagamento não será duplicado apenas uma vez).",
            p9: "D：Dados duplos",
            p10: "O prémio será ganho se quaisquer dois dos números do prémio forem iguais e corresponderem à combinação de dados duplos escolhida. Exemplo: Se o número do prémio for 1, 1, 3, então a aposta no dado duplo 1, 1, será a vencedora.",
            p11: "E：Dados, Dados de círculo completo",
            p12: "Dados: O prémio será ganho se os três números da lotaria forem iguais e corresponderem à combinação de dados escolhida.",
            p13: "Full Circle Dice: Um prémio é ganho se todos os três caracteres dos números da lotaria forem iguais.",
            p14: "F：Combinação de dois dados",
            p15: "Escolher qualquer combinação de 2 dados, quando o resultado da lotaria for o mesmo que a combinação escolhida, será considerado vencedor. Exemplo: Se os números da lotaria forem 1, 2, 3, então apostar na combinação de 2 dados 12, na combinação de 2 dados 23, na combinação de 2 dados 13 será considerado vencedor.",
        }
    },
    webadd: {
        hot: "Em voga",
        all: "Completo",
        home: "Salão",
        hot_lottery: "Lotaria popular",
        select_channel: "Selecionar o canal de carregamento",
        select_bank: "Selecionar um banco",
        top_tab1: "Ganhar um prémio",
        top_tab2: "TOP",
        top_tab3: "Meu",
        web_time: "Hora do sítio Web",
        trade: "Transacções",
    },
    footer: {
        foot1: "Fig. início",
        foot2: "Jogar",
        foot3: "TOP",
        foot4: "Função",
        foot5: "Meu",
    },
    navBar: {
        back_home: "Voltar à página inicial",
        help_center: "Centro de ajuda",
        contact_service: "Contactar o Serviço de Apoio ao Cliente",
        balance: "Balanços",
        my_account: "A minha conta",
        account: "Conta online",
        record: "Recorde ",
        proxy: "Proxy",
        other: "O resto",
        deposit: "Recarregar",
        withdraw: "Levantar fundos",
        deposit_type1: "Banco online",
        deposit_type2: "Transferência bancária",
        deposit_type3: "Moeda digital",
        deposit_type4: "OVO",
        logout: "Abortar",
        login: "Iniciar sessão",
        register: "Inscrição",
        nav1: "Bilhete de lotaria",
        nav2: "Jogar",
        nav3: "Função",
        nav4: "Telemóveis",
        nav5: "A minha conta",
    },
    activity: {
        tit1: "Bónus diário",
        p1: "O Jackpot Diário é um bónus baseado no montante que o utilizador apostou ontem, sem limite máximo para o prémio!",
        activity_detail: "Detalhes do evento",
        yestoday_bet: "Apostas de ontem",
        bonus_ratio: "Rácio de aumento do prémio",
        now_level: "Nível atual",
        receive_bonus: "Elegível para crédito extra",
        receive: "Obter",
        not_receive: "Indisponível",
        activity_time: "Tempo de atividade",
        p2: "1、O bónus diário está aberto para recolha a partir das 00:20 de cada dia",
        p3: "2、A percentagem do bónus baseia-se no nível do utilizador e no montante de apostas acumuladas de ontem para uma determinada percentagem do bónus!",
        p4: "3、As retiradas e outras apostas inválidas não serão contabilizadas",
        p5: "4、As apostas válidas devem satisfazer [o single foi sorteado e liquidado], no caso de o single ser sorteado muitos dias depois de a aposta ter sido colocada, o montante efetivo da aposta do single será acumulado na [Data de liquidação do sorteio].。",
        p6: "5、Exemplo: Se uma aposta for colocada a 1/10, mas o sorteio for apenas a 15/10, o montante efetivo da aposta será acumulado a 15/10 e o utilizador poderá receber o bónus dessa aposta a 16/10.。",
        p7: "6、O não levantamento do bónus antes das 24h00 do dia da prova é considerado como uma renúncia automática à qualificação para a prova",
    },
    game: {
        history_tit: "Prémios históricos",
        play_tit: "Instruções para jogar",
        official_award: "O prémio oficial original",
        betting: "Apostas em curso",
        waiting: "À espera da lotaria",
        opening: "Desenho em curso.",
        settled: "Resolvido",
        auto_change: "Comutação automática",
        height_win: "Prémio máximo",
        total: "Em suma",
        pour: "Pour",
        every_pour: "Cada um de",
        total_pour: "Número total de apostas",
        total_amount: "Total de apostas",
        now_bet: "Apostar agora",
        my_bet: "As minhas apostas",
        winning: "Ganhar um prémio",
        top: "TOP",
        winning_tip: "A informação sobre o vencedor é actualizada em tempo real",
        top_tip: "Classificação superior",
    },
    record: {
        lottery: "Bilhete de lotaria",
        game: "Jogar",
        today_profit_loss: "Lucros e perdas de hoje",
        label_formula: "Fórmula do ponto de equilíbrio",
        formula: "Fórmula: Ganhar - Apostas + Atividade + Descontos",
        profitLoss_label1: "Ganhar um prémio",
        profitLoss_label2: "Função",
        profitLoss_label3: "Apostadores",
        profitLoss_label4: "Recompensa de afiliado",
        profitLoss_label5: "Transferência",
        profitLoss_label6: "Beneficiário",
        profitLoss_label7: "Enviar pacote vermelho",
        profitLoss_label8: "Receber um pacote vermelho",
        trade_tab1: "Completo",
        trade_tab2: "Recarregar",
        trade_tab3: "Levantar fundos",
    },
    table: {
        label_no: " No.",
        label_time: "Tempos",
        label_result: "No final",
        label_size: "Magnitude",
        label_single_double: "Pares e ímpares",
        label_sun_value: "Soma",
        no_data: "Não existem dados disponíveis",
        big: "Mais antigo",
        small: "Poucos",
        single: "Mono",
        double: "Apelido Shuang",
        label_issue: "Número da edição",
        label_bet_amount: "Montante da aposta",
        label_reward: "Prémio em dinheiro",
        no_reward: "prémio em dinheiro",
        is_reward: "Prémio em dinheiro",
        label_level: "Hierarquia",
        label_integral: "Pontos de crescimento",
        label_award: "Prémios de progressão",
        label_skip_awardl: "Bónus de salto de nível",
        label_register_link: "Ligação de registo",
        label_remark: "Nota",
        label_rise_time: "Tempo de geração",
        label_register_number: "Número de registos",
        label_operate: "Equipamento",
        label_gametit: "Cores/jogos",
        label_lower_rebate: "Recompensa de afiliado",
        label_self_rebate: "Desconto próprio",
        label_set_rebate_range: "Gama de descontos",
        bonus_state: "Estatuto de bónus",
        label_serial_number: "Sinal de emboscada",
        label_income_amount: "Montante dos rendimentos",
        label_expend_amount: "Montante das despesas",
        label_available_balance: "Saldo disponível",
        label_abstract: "Resumos",
        label_details: "Informações",
        label_account: "Conta online",
        label_amount: "Soma de dinheiro",
        label_bet_cont: "Conteúdo das apostas",
        label_ottery_number: "Número da lotaria",
        order_number: "Número ímpar ",
        play_methods: "Forma de jogar",
        label_periods: "Fase",
        label_number: "Números",
        label_color: "Cor",
    },
    my: {
        last_login_time: "Última hora de início de sessão",
        logout_account: "Levantamento da conta",
        my_account: "A minha conta",
        account: "Conta online",
        personal_tit: "Perfil pessoal",
        security_tit: "Centro de segurança",
        bank_tit: "Número da conta bancária",
        record: "Recorde",
        profit_loss_tit: "Lucros e perdas de hoje",
        trade_record_tit: "Registos de transacções",
        bet_record: "Registos de apostas",
        proxy: "Proxy",
        manag_tit: "Gestão de agências",
        report_tit: "Declarações de procuração",
        lower_bet_tit: "Apostas no nível inferior",
        lower_trade_tit: "Transacções subordinadas",
        other: "O resto",
        gift_tit: "Presentes para eventos",
        message_tit: "Centro de Notícias",
        personal_data: "Perfil pessoal",
        balance: "balanços",
        last_sync_time: "Última hora de sincronização",
        deposit: "Recarregar",
        withdraw: "Levantar fundos",
        yesterday_deposit_time: "Tempo médio para reabastecer ontem",
        yesterday_withdraw_time: "Tempo médio para levantar dinheiro ontem",
        help_tit: "Centro de ajuda",
        all: "Completo",
        lower_report: "Declarações subordinadas",
        about_us: "Sobre nós",
        logout: "Aparecer",
        invite: "Convidar amigos",
        lang_change: "Mudança de língua",
    },
    account: {
        add_bank_account: "Adicionar o número da conta bancária",
        bank_tip: "Podem ser adicionadas até 5 contas bancárias. As contas bancárias que foram levantadas com sucesso serão bloqueadas automaticamente e não podem ser apagadas ou modificadas. Os números de contas bancárias que não tenham sido levantados com sucesso podem ser modificados e não podem ser eliminados.",
        account_msg: "Informações sobre a conta",
        growth_value: "Taxa de crescimento",
        noset_nickname: "Apelido não definido",
        nationality: "Cidadania",
        next_level: "Próximo nível",
        integral: "Integral",
        integral_tip: "Por cada 1,00 $ que carregar, ganha 1 ponto.",
        level_system: "Hierarquia",
        change_avatar: "Alterar Avatar",
        loginpsd_tip: "Palavra-passe de início de sessão Combinação de letras e números de 6 a 16 dígitos",
        modify_psd: "Alterar a sua palavra-passe",
        securitypsd_tip: "Palavra-passe de segurança (combinação de 6 dígitos)",
        forget_securitypsd: "Esqueceu-se do código de segurança",
        confidentiality_tip: "Recuperar informações da conta do utilizador em caso de problemas de segurança, perda do número da conta ou da palavra-passe.",
        bank_account: "Número da conta bancária",
        edit_nickname: "Editar alcunha",
        confidentiality: "Questões de segurança",
        accumulated_winning: "Jackpot progressivo",
    },
    agent: {
        illustrate_p1: "Quando vê esta página, significa que a sua conta é simultaneamente uma conta de jogador e uma conta de agente, o que lhe permite fazer as suas próprias apostas, bem como desenvolver jogadores de nível inferior e ganhar comissões de desconto.",
        illustrate_p2: "Como ganhar descontos？",
        illustrate_p3: "O desconto que pode obter é igual à diferença entre o seu próprio desconto e o desconto do nível inferior, tal como o seu próprio desconto de 5 e o desconto do nível inferior de 3, poderá obter o desconto do montante de aposta do nível inferior de 2, tal como o nível inferior apostando nos 100 $, receberá 2 $. Clique no nível inferior da conta, pode ver o seu próprio desconto, e pode também definir o desconto para o nível inferior.",
        illustrate_p4: "Como abrir uma conta para um subordinado？",
        illustrate_p5: "Clique na conta de subordinado, primeiro configure descontos para os seus subordinados, configure um código de convite bem sucedido será gerado, o código de convite será enviado para o registo do seu subordinado, registo, ele é o seu subordinado, clique na gestão de utilizadores, pode ver a sua conta registada.",
        illustrate_p6: "Se criar uma conta de tipo agente para os seus subordinados, estes poderão continuar a desenvolver os seus subordinados. Se criar um tipo jogador, os seus subordinados só poderão fazer apostas, não poderão desenvolver os seus subordinados e não poderão ver o centro de agentes. Gestão de utilizadores",
        illustrate_tip1: "1、As probabilidades de desconto são diferentes, clique na tabela de probabilidades de desconto, pode ver as probabilidades de desconto。",
        illustrate_tip2: "2、Quanto mais baixo for o desconto, mais baixas serão as probabilidades, pelo que se recomenda que o desconto fixado para o nível inferior não seja demasiado baixo!。",
        illustrate_tip3: "3、O desenvolvimento dos agentes pode ser visualizado em Relatórios de agentes, Apostas de desvantagem, Transacções de desvantagem。",
        illustrate_tip4: "4、Recomenda-se que a abertura do nível seguinte seja também do tipo de agente, independentemente do número de níveis desenvolvidos, receberá descontos。",
        open_type: "Tipo de abertura de conta：",
        open_type1: "Completo",
        open_type2: "Posição",
        open_type3: "Jogador",
        invite_tip1: "1、Diferentes descontos têm diferentes probabilidades, mas quanto maior for o desconto, maiores são as probabilidades!。",
        invite_tip2: "2、O agente pode obter a comissão é igual ao desconto do próprio agente e a diferença entre o desconto inferior, como o desconto do próprio agente de 6, o desconto inferior de 4, o agente pode obter o nível inferior do montante de apostas de 2%, ou seja, o nível inferior de apostas 100 yuan, o agente pode obter 2 yuan!。",
        invite_tip3: "3、Quanto mais baixo for o valor do desconto do nível inferior, mais baixas serão as probabilidades do nível inferior, pelo que se recomenda que o desconto fixado para o nível inferior não seja demasiado baixo.",
        rebateDialog_tit: "Ver descontos",
        delete_invite_tip: "Tem a certeza de que pretende apagar este código de convite?？",
        rebate_table: "Tabela de probabilidades de desconto",
        generate_invitation_code: "Gerar código de convite",
        subordinate_tip1: "1、Diferentes descontos têm diferentes probabilidades, mas quanto maior for o desconto, maiores são as probabilidades!。",
        subordinate_tip2: "2、O agente pode obter a comissão é igual ao desconto do próprio agente e a diferença entre o desconto inferior, como o desconto do próprio agente de 6, o desconto inferior de 4, o agente pode obter o nível inferior do montante de apostas de 2%, ou seja, o nível inferior de apostas 100 yuan, o agente pode obter 2 yuan!。",
        subordinate_tip3: "3、Quanto mais baixo for o valor do desconto do nível inferior, mais baixas serão as probabilidades do nível inferior, pelo que se recomenda que o desconto fixado para o nível inferior não seja demasiado baixo.。",
        manage_tab1: "Descrição do agente",
        manage_tab2: "Abertura de contas subordinadas",
        manage_tab3: "Código de convite",
        manage_tab4: "Gestão de utilizadores",
        lower_bet_tip: "Os registos das transacções são conservados por um período máximo de 21 dias.",
        report_label1: "Apostadores",
        report_label2: "Ganhar um prémio",
        report_label3: "Função",
        report_label4: "Descontos para equipas",
        report_label5: "Lucro da equipa",
        report_label6: "Número de apostadores",
        report_label7: "Número da primeira carga",
        report_label8: "Número de registados",
        report_label9: "Número de subordinados",
        report_label10: "Equilíbrio da equipa",
        report_label11: "Recarregar",
        report_label12: "Levantar fundos",
        report_label13: "Desconto de agente",
        report_label14: "Transferência",
        report_label15: "Beneficiário",
        report_label16: "Enviar pacote vermelho",
        report_label17: "Receber um pacote vermelho",
    },
    fund: {
        tip1: "Por favor, transfira para a seguinte conta bancária",
        tip2: "Preencha cuidadosamente as informações sobre a sua transferência",
        tip3: " (Certifique-se de que efectua a transferência de fundos antes de enviar a sua encomenda, caso contrário, o seu pagamento não será rastreado a tempo!)",
        deposit_rate: "Taxa de câmbio do depósito",
        currency_number: "Montante da moeda",
        tip4: "Utilize a plataforma de moeda digital correspondente para transferir dinheiro",
        tip5: "Limite de um só tempo",
        tip6: "(Se não conseguir preencher as informações de autenticação, carregue uma captura de ecrã do recibo da transação.)",
        click_upload: "Clique para carregar",
        upload_tip: "Só podem ser carregados ficheiros jpg/png",
        receiving_address: "Endereço do beneficiário",
        tip7: "Utilize o OVO para transferir fundos para as seguintes contas bancárias",
        tip8: "1、Para efetuar o pagamento, é necessário recorrer à banca Internet.",
        tip9: "2、Após um carregamento bem sucedido, clique em 'Regressar ao comerciante' para receber automaticamente o crédito.。",
    },
    other: {
        gift_tip1: "1、Os donativos só podem ser reclamados quando estiverem preenchidas as condições para os reclamar。",
        gift_tip2: "2、礼Os presentes não reclamados após a data de expiração expiram automaticamente e não podem ser reclamados novamente.。",
        other_tip1: "As cartas são conservadas em registo durante um período máximo de 7 dias；",
    },
    form: {
        input_inner: "Introduzir conteúdo",
        label_username: "Conta de utilizador：",
        place_username: "Introduza a sua conta de utilizador",
        label_loginpsd: "Palavra-passe de início de sessão：",
        place_loginpsd: "Introduza a sua palavra-passe de acesso",
        label_invitecode: "Código de convite：",
        place_invitecode: "Preencher o código de convite com 8 dígitos",
        label_oldpsd: "Palavra-passe original",
        place_oldpsd: "Introduza a palavra-passe original",
        label_newpsd: "Nova palavra-passe",
        place_newpsd: "Introduzir uma nova palavra-passe",
        label_crmpsd: "Confirmar a palavra-passe",
        place_crmpsd: "Introduza novamente a nova palavra-passe",
        label_remark: "Nota",
        place_remark: "Introduzir uma nota",
        label_lower_account: "Nome de utilizador：",
        place_lower_account: "Introduzir o número da conta subordinada",
        label_type: "Tipologia：",
        place_select: "Selecionar",
        label_lottery_title: "Prémio da lotaria：",
        label_state: "Estado das coisas：",
        option_state1: "Completo",
        option_state2: "Lotaria pendente",
        option_state3: "Não é um vencedor",
        option_state4: "Ganharam um prémio",
        label_time: "Tempos：",
        times1: "Atualmente",
        times2: "Ontem",
        times3: "O mês atual",
        times4: "Ultimo",
        label_realname: "Nome e apelido verdadeiros",
        place_realname: "Introduza o seu nome verdadeiro",
        label_bank_number: "Número do cartão multibanco",
        place_bank_number: "Introduza o número do seu cartão bancário",
        label_bank_name: "Nome do banco",
        place_bank_name: "Selecionar o nome do banco",
        label_abstract: "Resumos:",
        label_channel: "Canal de recarga",
        place_channel: "Seleccione o canal de carregamento",
        label_deposit_amount: "Montante da recarga",
        place_deposit_amount: "Introduzir o montante da recarga",
        label_holder: "Titular da conta",
        label_deposit_name: "Nome do depositante",
        place_deposit_name: "Introduzir o nome do depositante",
        label_transfer_msg: "Informações sobre transferências",
        place_transfer_msg: "Preencher as informações relativas à transferência",
        label_transfer_pic: "Captura de ecrã do recibo da transação",
        label_transfer_pic_tip: "(Carregue uma captura de ecrã do recibo da transação)",
        label_bank_account_name: "Nome da conta bancária",
        label_balance: "Balanços",
        label_available_withdraw: "Montante suscetível de ser retirado",
        label_available_number: "Número de levantamentos",
        label_withdraw_amount: "Montante da retirada",
        placce_withdraw_amount: "Introduzir o montante do levantamento)",
        available_withdraw: "Conveniência",
        label_transfer_bank: "Débito bancário",
        label_security_psd: "Código de segurança",
        place_security_psd: "Introduzir o código de segurança",
        place_nickname: "Termo carinhoso",
        message_nickname: "Introduzir uma alcunha",
        place_phone: "Introduza o seu número de telemóvel",
        place_email: "Introduza o seu endereço de correio eletrónico",
        place_invite_id: "Introduzir o código do convite",
        place_verify_code: "Introduzir o código de verificação",
        send: "Envio",
        test_username: "Nome do utilizador de teste",
        place_amount: "Introduzir o montante",
    },
    login: {
        login_tit: "Início de sessão do utilizador",
        forget_psd: "Esqueceu-se da palavra-passe?？",
        login: "Iniciar sessão",
        register_tit: "Registo do utilizador",
        register: "Inscrição",
        have_account: "Já tem uma conta, inicie sessão",
        country_code: "Código do país",
        phone: "Telemóveis",
        email: "Caixa de entrada",
    },
    city: {
		albania: "Albânia",
		algeria: "Argélia",
		argentina: "Argentina",
		armenia: "Arménia",
		australia: "Austrália",
		pakistan: "Paquistão",
		austria: "Áustria",
		bahrain: "Bahrein",
		belgium: "Bélgica",
		bosnia_and_Herzegovina: "Bósnia e Herzegovina",
		brazil: "Brasil",
		brunei: "Brunei",
		bulgaria: "Bulgária",
		cambodia: "Camboja",
		canada: "Canadá",
		cameroon: "Camarões",
		chile: "Chile",
		colombia: "Colômbia",
		costa_Rica: "Costa Rica",
		croatia: "Croácia",
		cyprus: "Chipre",
		czech_Republic: "República Checa",
		denmark: "Dinamarca",
		dominican_Republic: "República Dominicana",
		egypt: "Egipto",
		estonia: "Estónia",
		ethiopia: "Etiópia",
		finland: "Finlândia",
		france: "França",
		georgia: "Geórgia",
		germany: "Alemanha",
		ghana: "Gana",
		greece: "Grécia",
		guyana: "Guiana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, China",
		hungary: "Hungria",
		iceland: "Islândia",
		ireland: "Irlanda",
		italy: "Itália",
		india: "Índia",
		indonesia: "Indonésia",
		israel: "Israel",
		iran: "Irão",
		iraq: "Iraque",
		japan: "Japão",
		kazakstan: "Cazaquistão",
		kenya: "Quénia",
		korea: "Coreia",
		kuwait: "Kuwait",
		kyrgyzstan: "Quirguizistão",
		laos: "Laos",
		latvia: "Letónia",
		lithuania: "Lituânia",
		luxembourg: "Luxemburgo",
		macao_China: "Macau, China",
		macedonia: "Macedónia",
		malaysia: "Malásia",
		malta: "Malta",
		mexico: "México",
		moldova: "Moldávia",
		monaco: "Mônaco",
		mongolia: "Mongólia",
		montenegro: "Montenegro",
		morocco: "Marrocos",
		myanmar: "MianmarName",
		netherlands: "Países Baixos",
		new_Zealand: "Nova Zelândia",
		nepal: "Nepal",
		nigeria: "Nigéria",
		norway: "Noruega",
		oman: "Omã",
		palestine: "Palestina",
		panama: "Panamá",
		paraguay: "Paraguai",
		peru: "Peru",
		philippines: "Filipinas",
		poland: "Poland",
		portugal: "Portugal",
		puerto_Rico: "Porto Rico",
		qatar: "Qatar",
		romania: "Roménia",
		russia: "Rússia",
		republic_of_Trinidad_and_Tobago: "Trinidad e Tobago",
		rwanda: "Ruanda",
		saudi_Arabia: "Arábia Saudita",
		serbia: "Sérvia",
		singapore: "Singapura",
		slovakia: "Eslováquia",
		slovenia: "Eslovénia",
		south_Africa: "África do Sul",
		spain: "Espanha",
		sri_Lanka: "Sri Lanka",
		sweden: "Suécia",
		switzerland: "Suíça",
		taiwan_China: "Taiwan, China",
		tajikistan: "Tajiquistão",
		tanzania: "Tanzânia",
		thailand: "Tailândia",
		turkey: "Türkiye",
		turkmenistan: "Turquemenistão",
		ukraine: "Ucrânia",
		united_Arab_Emirates: "EAU",
		united_Kingdom: "Grã-Bretanha",
		united_States: "Estados Unidos",
		uzbekistan: "Usbequistão",
		venezuela: "Venezuela",
		vietnam: "Vietname",
		afghanistan: "Afeganistão",
		angola: "Angola",
		azerbaijan: "Azerbaijão",
		bangladesh: "Bangladesh",
		belarus: "Bielorrússia",
		belize: "Belizefrance. kgm",
		benin: "Benin",
		bhutan: "Butão",
		bolivia: "Bolívia",
		botswana: "Botsuana",
		british_Virgin_Islands: "Ilhas Virgens Britânicas",
		burkina_Faso: "Burkina faso",
		burundi: "Burundi",
		cape_Verde: "Cabo Verde",
		cayman_Islands: "Ilhas Caimão",
		central_African_Republic: "República Centro-Africana",
		chad: "ChadeName",
		comoros: "Comores",
		the_Republic_of_the_Congo: "Congo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Congo (Kinshasa)",
		djibouti: "JibutiName",
		ecuador: "Equador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Guiné Equatorial",
		eritrea: "Eritreia",
		fiji: "Fiji",
		gabon: "Gabão",
		gambia: "Gâmbia",
		greenland: "Gronelândia",
		guatemala: "Guatemala",
		guinea: "Guiné",
		haiti: "Haiti",
		isle_of_Man: "Ilha de Man",
		cote_d_Ivoire: "Costa do Marfim",
		jamaica: "Jamaica",
		jordan: "Jordânia",
		lebanon: "Líbano",
		lesotho: "Lesoto",
		liberia: "Libéria",
		libya: "Líbia",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldivas",
		mali: "Mali",
		mauritania: "Mauritânia",
		mauritius: "Maurícia",
		mozambique: "Moçambique",
		namibia: "Namíbia",
		nicaragua: "Nicarágua",
		republic_of_Niger: "Níger",
		north_Korea: "Coreia do Norte",
		reunion: "Reunião",
		san_Marino: "San Marino",
		senegal: "SenegalName",
		sierra_Leone: "sierra leone",
		somalia: "Somália",
		sudan: "Sudão",
		suriname: "Suriname",
		eswatini: "Eswatini",
		syria: "Síria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunísia",
		united_States_Virgin_Islands: "Ilhas Virgens, Estados Unidos",
		uganda: "Uganda",
		uruguay: "Uruguai",
		vatican: "Vaticano",
		yemen: "Iémen",
		yugoslavia: "Jugoslávia",
		zambia: "Zâmbia",
		zimbabwe: "zimbabwe",
		china: "China",
	}
};
