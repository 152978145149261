export default {
    common: {
        contactNow: "Contact Now",
        minPrice: "Min",
        maxPrice: "Max",
        address: {
            title: "退会住所",
            account_number: "口座番号",
            add_btn: "アドレスの追加",
            label_type: "タイプを選択",
            place_type: "タイプを選択してください",
            label_number: "口座番号",
            place_number: "口座番号を入力してください。",
            wallet_address: '財布の住所',
            bank_name: '銀行名',
            bank_address: "銀行住所",
            name: "口座名義",
            payee_address: '受取人の住所',
            swift_code: "スウィフト/スウィフトコード/BIC",
            aba: "ABA",
            branch_number: "支店名",
            branch_code: "支店番号",
            bank_code: "シルバー・ストローク・コード",
            phone: "携帯電話番号",
            iban: "IBAN",
            sort_code: "ソートコード",
            place_inner: "内容を入力してください",
        },
        priceType: {
            fast_sum: "合計",
            fast_three_all: "総選挙",
            fast_three_one: "スリー・セイム・ナンバー・シングル・チョイス",
            fast_three_diff: "3つの異なる数字",
            fast_three_continue: "トリプル・ナンバーズ・マルチプル・チョイス（TNT）（コンピューティング）",
            fast_two_some: "ダブル・インデックス",
            fast_two_one: "ダブル個人選択",
            fast_two_diff: "二等分数（エレクトロニクス）",
            fast_one_one: "ふるい1枚で1回分の料金",
            racing_winner: "チャンピオンズ",
            racing_second: "二位",
            racing_third: "銅メダリスト",
            lotto_one: "A",
            lotto_two: "B",
            lotto_three: "C",
            lotto_four: "D",
            lotto_five: "E",
            wingo_sum: "Some",
            wingo_color: "Color",
            Big: "最高齢",
            Small: "少数",
            Single: "モノ",
            Double: "シュアン姓",
            Red: "ボーナス",
            Green: "グリーン",
            Purple: "紫根",
        },
        img_error: "画像の読み込みに失敗しました",
        more: "もっと見る",
        cancel: "キャンセル",
        confirm: "決める",
        tips: "ヒント：",
        no_set: "滑脱",
        submit: "だす",
        copy_success: "コピー成功！",
        copy_error: "生殖の失敗！",
        currency: "$",
        filter: "スクリーニング",
        edit: "修正",
        gameStart: "試合開始",
        prizenumber: "当選番号",
        mynumber: "私の番号だ。",
        addresss: "リンクアドレスはこちら",
        gameAgain: "もう1度だけ。",
        gamerule: "ゲームのルール",
        winning_numbers: "当選番号",
        winning: "優勝おめでとう！！",
        notwin: "残念ながら、あなたは勝てなかった！",
        logout: "本当にログアウトしますか？？",
        success: "成功",
        search: "ものを探す。",
        withdraw0: "しがんしょ",
        withdraw1: "進行中",
        withdraw2: "成功",
        withdraw3: "挫く",
        balanceNot: "口座残高不足",
        canceled: "キャンセル",
        bill: {
            bill101: "ユーザー・リチャージ",
            bill102: "システムインテグレータ",
            bill103: "システムフリーズ",
            bill104: "システム凍結解除",
            bill201: "ユーザーの引き出し",
            bill202: "システム控除",
            bill203: "撤退成功",
            bill204: "退会監査不合格",
            bill205: "出金手数料",
            bill301: "ゲームに参加する",
            bill302: "ゲームの利益",
        }
    },
    add: {
        cqk: "入出金",
        activity: "プロモーション",
        online: "オンラインカスタマーサービス",
        lottery: "宝くじ売り場",
        game: "プレー",
        lotter: "抽選券",
        search_lotter: "カラー検索",
        search: "諮る",
        di: "番",
        qi: "ピリオド",
        ju: "の最初の月が過ぎるまで。",
        feng: "サーキットブレーカー",
        result: "抽選結果",
        logout: "アボート",
        available_balance: "利用可能残高",
        notice_title: "プラットフォーム発表",
        tip30: "30分間ポップアップが表示されない",
        close: "凝固",
        personal: "パーソナルセンター",
        level: "ヒエラルキー",
        common: "よく見られる",
        news: "最新のオファー",
        help_center: "ヘルプセンター",
        app_download:"APPダウンロード",
    },
    addnew: {
        remainder: "ざんりゅうじかん",
        history: "受賞歴",
        trend: "動き",
        my_bet: "私の注文書",
        bei: "の数に総計がなる",
        agree: "同意します",
        rule: "『前売りルール』",
        total_amount: "合計金額",
        wallet_balance: "ウォレット残高",
        total: "合計＃ゴウケイ＃",
        random_bet: "ランダムベット",
    },
    playMethod: {
        p1: "ご覧になりたいゲームのルールを選択してください。",
        p2: "ルールを勉強すれば、収入は倍増する。",
        des1: "簡単",
        des2: "100で取引した場合、サービス料2％を差し引いた契約金額：98",
        fived: {
            p1: "5D宝くじゲームルール",
            p2: "ドローインストラクション",
            p3: "各号ごとに無作為に選んだ5桁の数字（00000～999999",
            p4: "例えば:",
            p5: "現在の抽選番号は12345",
            p6: "A = 1",
            p7: "B = 2",
            p8: "C = 3",
            p9: "D = 4",
            p10: "E = 5",
            p11: "歌で応える= A + B + C + D + E = 15",
            p12: "プレーの仕方",
            p13: "プレイヤーは、A、B、C、D、Eの6つの結果と合計にベットすることができる。",
            p14: "A, B, C, D, E利用可能",
            p15: "数値(0 1 2 3 4 5 6 7 8 9)",
            p16: "低い (0 1 2 3 4)",
            p17: "高い (5 6 7 8 9)",
            p18: "奇数（1 3 5 7 9）",
            p19: "偶数 (0 2 4 6 8)",
            p20: "Sum = A+B+C+D+E購入可能",
            p21: "低い（0-22）",
            p22: "高（23-45）",
            p23: "奇数（1 3--43 45）",
            p24: "イーブン（0 2--42 44）",
        },
        racing: {
            p1: "ゲームプレイ",
            p2: "※トップ3と：",
            p3: "最初の3台の数字の和が大きい場合は16より大きく、小さい場合は16以下となる。 偶数をダブル、奇数をシングルと呼ぶ。 最初の3つの和：",
            p4: "※1位から3位まで：",
            p5: "車番指定、各車番は賭けの組み合わせであり、賭けの名前に対応する抽選結果「賭けの車番」は勝者とみなされ、それ以外の状況は勝者ではないとみなされる。",
            p6: "※両側：",
            p7: "シングルとダブル、ラージとスモールを指す。",
            p8: "※シングルとダブル：",
            p9: "偶数は2,4,6,8,10などのようにダブルと呼ばれ、奇数は1,3,5,7,9などのようにシングルと呼ばれる。",
            p10: "※大、小",
            p11: "6以上の数は6、7、8、9、10など大きい数とみなされ、5以下は1、2、3、4、5など小さい数とみなされる。",
        },
        wingo: {
            p1: "ゲームプレイ",
            p2: "※シングルフィギュア：",
            p3: "ベットする数字を選び、抽選番号とベットした数字が当選となり、それ以外はハズレとなる。",
            p4: "※ピンク：",
            p5: "赤に賭けることは、抽選番号が0、2、4、6、8であれば勝ちとみなされ、残りのシナリオでは勝ちなしとなる。",
            p6: "※よりグリーン：",
            p7: "緑にベットした場合、抽選数字が1、3、5、7、9の場合は勝者とみなされ、それ以外のシナリオの場合は非勝者とみなされます。",
            p8: "※すみれいろ：",
            p9: "紫に賭けた場合、抽選番号が0と5の場合は当選となり、それ以外の場合は非当選となる。",
            p10: "※大、小：",
            p11: "5以上の抽選数字は、5、6、7、8、9のように大きいとみなされ、4以下は0、1、2、3、4のように小さいとみなされる。",
        },
        lottery: {
            p1: "ゲームプレイ～ランダムに6オープン",
            p2: "A：大小、シングル、ダブル",
            p3: "サイズ：11から18まで3抽選番号の範囲の合計は大きいとみなされ、3から10の合計は小さいとみなされます。",
            p4: "シングルとダブル：3つの抽選番号の合計がシングルであればシングルが勝ち、ダブルであればダブルが勝つ。",
            p5: "B：チェックナンバー",
            p6: "宝くじ番号の合計値が3、4、5、6、7、8、9、10、11、12、13、14、15、16、17、18の場合、あなたは勝つでしょう、例えば、宝くじ番号が1、2、3であり、合計値が6の場合、 「6 」に賭けると勝つでしょう。",
            p7: "C：サイコロ",
            p8: "3つの抽選番号のいずれかが選択された番号と同じである場合、それは勝者とみなされます。 例：抽選数字が1、1、3の場合、サイコロ1またはサイコロ3に賭けると当選となります。 (指定された点数が何回出現しても、配当は1回だけでは2倍にはなりません）。",
            p9: "D：ダブルダイス",
            p10: "つの賞の数字が同じで、選択されたダブルダイスの組み合わせと一致した場合、賞は獲得されます。 例: 賞の数字が1, 1, 3の場合、ダブルサイコロの1, 1,に賭ければ勝ちとなります。",
            p11: "E：ラウンドロビン、フルラウンドロビン",
            p12: "サイコロ：3つの抽選数字が同じで、選択されたサイコロの組み合わせと一致する場合、賞が獲得されます。",
            p13: "フルサークルダイス：抽選番号の3つの文字がすべて同じなら賞品が当たる。",
            p14: "F：つのサイコロの組み合わせ",
            p15: "任意の2つのサイコロの組み合わせを選択し、抽選結果が選択された組み合わせと同じである場合、それは勝者とみなされます。 例：宝くじの数字が1、2、3だった場合、2ダイスの組み合わせ12、2ダイスの組み合わせ23、2ダイスの組み合わせ13に賭けると勝ちとみなされます。",
        }
    },
    webadd: {
        hot: "流行中",
        all: "フル",
        home: "パーラー",
        hot_lottery: "人気宝くじ",
        select_channel: "トップアップ・チャンネルを選択",
        select_bank: "銀行を選択",
        top_tab1: "懸賞を当てる",
        top_tab2: "TOP",
        top_tab3: "私の",
        web_time: "ウェブサイト時間",
        trade: "取引",
    },
    footer: {
        foot1: "図頭",
        foot2: "プレー",
        foot3: "TOP",
        foot4: "機能",
        foot5: "私の",
    },
    navBar: {
        back_home: "ホームページに戻る",
        help_center: "ヘルプセンター",
        contact_service: "カスタマーサービス",
        balance: "バランス",
        my_account: "マイアカウント",
        account: "オンラインアカウント",
        record: "きろく",
        proxy: "責任ある立場で代行する",
        other: "その他",
        deposit: "リチャージ",
        withdraw: "資金を引き出す",
        deposit_type1: "オンラインバンキング",
        deposit_type2: "銀行振込",
        deposit_type3: "デジタル通貨",
        deposit_type4: "OVO",
        logout: "アボート",
        login: "サインイン",
        register: "在籍",
        nav1: "抽選券",
        nav2: "プレー",
        nav3: "機能",
        nav4: "携帯電話",
        nav5: "マイアカウント",
    },
    activity: {
        tit1: "デイリーボーナス",
        p1: "デイリージャックポットは、ユーザーが昨日賭けた金額に応じてボーナスが支払われるもので、ボーナスに上限はありません！",
        activity_detail: "イベント詳細",
        yestoday_bet: "昨日の賭け",
        bonus_ratio: "賞金増加率",
        now_level: "現在レベル",
        receive_bonus: "エクストラ・クレジット対象",
        receive: "得る",
        not_receive: "使用不可",
        activity_time: "活動時間",
        p2: "1、日刊ボーナスは毎日午前0時20分以降に受け取ることができる",
        p3: "2、ボーナスのパーセンテージは、ユーザーのレベルと昨日の累積ベット額に応じて、一定のパーセンテージが設定される！",
        p4: "3、出金やその他の無効なベットはカウントされません。",
        p5: "4、有効なベットは[シングルがオープンし、決済された]を満たす必要があり、例えば、抽選の数日前にベットした後にシングルにベットした場合、シングルの有効なベットは[抽選の決済日]に累積されます。",
        p6: "5、例：10/1に賭けるが、10/15は引き分けだけで、有効ベット額は10/15に蓄積され、ユーザーは10/16にボーナスボーナスのベットオーダーを収集することができます。",
        p7: "6、活動当日の24時までに活動ボーナスが集まらなかった場合、自動的に活動資格を放棄する。",
    },
    game: {
        history_tit: "歴史的な賞",
        play_tit: "遊び方",
        official_award: "オリジナルグッズ",
        betting: "ベッティング中",
        waiting: "抽選を待つ",
        opening: "ドローイング中。",
        settled: "解決済み",
        auto_change: "自動切り替え",
        height_win: "最高勝率",
        total: "つまり",
        pour: "金額分類器",
        every_pour: "それぞれ",
        total_pour: "ベット総数",
        total_amount: "ベット総額",
        now_bet: "ベット・ナウ",
        my_bet: "私の賭け",
        winning: "懸賞を当てる",
        top: "TOP",
        winning_tip: "優勝情報はリアルタイムで更新される",
        top_tip: "トップ・ランキング",
    },
    record: {
        lottery: "抽選券",
        game: "プレー",
        today_profit_loss: "本日の損益",
        label_formula: "損益分岐点公式",
        formula: "計算式：勝利-ベッティング＋アクティビティ＋リベート",
        profitLoss_label1: "懸賞を当てる",
        profitLoss_label2: "機能",
        profitLoss_label3: "賭博師",
        profitLoss_label4: "アフィリエイト報酬",
        profitLoss_label5: "振り込む",
        profitLoss_label6: "受取人",
        profitLoss_label7: "赤いパケットを送る",
        profitLoss_label8: "赤いパケットを受け取る",
        trade_tab1: "フル",
        trade_tab2: "リチャージ",
        trade_tab3: "資金を引き出す",
    },
    table: {
        label_no: " No.",
        label_time: "回",
        label_result: "結局",
        label_size: "マグニチュード",
        label_single_double: "奇偶",
        label_sun_value: "合計",
        no_data: "データなし",
        big: "最高齢",
        small: "少数",
        single: "モノ",
        double: "シュアン姓",
        label_issue: "号",
        label_bet_amount: "賭け金額",
        label_reward: "賞金",
        no_reward: "不戦勝",
        is_reward: "懸賞を当てる",
        label_level: "ヒエラルキー",
        label_integral: "成長ポイント",
        label_award: "昇格報奨金",
        label_skip_awardl: "スキップ・レベル・ボーナス",
        label_register_link: "登録リンク",
        label_remark: "備考",
        label_rise_time: "生成時間",
        label_register_number: "登録者数",
        label_operate: "リグ",
        label_gametit: "カラー/ゲーム",
        label_lower_rebate: "アフィリエイト報酬",
        label_self_rebate: "自社リベート",
        label_set_rebate_range: "リベート範囲",
        bonus_state: "ボーナスステータス",
        label_serial_number: "待ち伏せ信号",
        label_income_amount: "収入金額",
        label_expend_amount: "支出額",
        label_available_balance: "利用可能残高",
        label_abstract: "抄録",
        label_details: "しょうさい",
        label_account: "オンラインアカウント",
        label_amount: "金額",
        label_bet_cont: "ベット内容",
        label_ottery_number: "抽選番号",
        order_number: "奇数",
        play_methods: "遊び方",
        label_periods: "フェーズ",
        label_number: "数字",
        label_color: "カラー",
    },
    my: {
        last_login_time: "最終ログイン時間",
        logout_account: "口座からの引き出し",
        my_account: "マイアカウント",
        account: "オンラインアカウント",
        personal_tit: "自己紹介",
        security_tit: "セキュリティセンター",
        bank_tit: "銀行口座番号",
        record: "きろく",
        profit_loss_tit: "本日の損益",
        trade_record_tit: "取引記録",
        bet_record: "ベッティング記録",
        proxy: "責任ある立場で代行する",
        manag_tit: "代理店管理",
        report_tit: "委任状",
        lower_bet_tit: "下段賭け",
        lower_trade_tit: "劣後取引",
        other: "その他",
        gift_tit: "イベントギフト",
        message_tit: "ニュースセンター",
        personal_data: "自己紹介",
        balance: "バランス",
        last_sync_time: "最終同期時刻",
        deposit: "リチャージ",
        withdraw: "資金を引き出す",
        yesterday_deposit_time: "昨日の平均補給時間",
        yesterday_withdraw_time: "昨日の現金引き出し平均時間",
        help_tit: "ヘルプセンター",
        all: "フル",
        lower_report: "従属ステートメント",
        about_us: "会社概要",
        logout: "載る",
        invite: "友人を招待する",
        lang_change: "言語の切り替え",
    },
    account: {
        add_bank_account: "銀行口座番号の追加",
        bank_tip: "出金に成功した銀行口座は自動的にロックされ、削除や変更はできません。 出金に成功していない銀行口座番号は変更可能で、削除することはできません。",
        account_msg: "口座情報",
        growth_value: "成長率",
        noset_nickname: "ニックネーム未設定",
        nationality: "市民権",
        next_level: "次のレベルへ",
        integral: "学生の総取得単位数",
        integral_tip: "1ポイント＝1.00$で1ポイント。",
        level_system: "ヒエラルキー",
        change_avatar: "アバターの変更",
        loginpsd_tip: "ログインパスワード 6～16桁の英数字の組み合わせ",
        modify_psd: "パスワードを変更する",
        securitypsd_tip: "セキュリティパスワード（6桁の組み合わせ）",
        forget_securitypsd: "セキュリティコードをお忘れの場合",
        confidentiality_tip: "セキュリティ問題、アカウント番号やパスワードの紛失時にユーザーアカウント情報を取得。",
        bank_account: "銀行口座番号",
        edit_nickname: "ニックネームの編集",
        confidentiality: "セキュリティ問題",
        accumulated_winning: "プログレッシブ・ジャックポット",
    },
    agent: {
        illustrate_p1: "このページが表示されているということは、あなたのアカウントがプレイヤーアカウントであると同時にエージェントアカウントでもあるということです。これにより、あなた自身のベットだけでなく、下位レベルのプレイヤーを育成し、リベートコミッションを得ることができます。",
        illustrate_p2: "リベートの獲得方法？",
        illustrate_p3: "例えば、自分のリベートが5で下級のリベートが3であれば、下級のベット額のリベートが2、例えば下級のベットが100$であれば、2$を得ることができます。",
        illustrate_p4: "部下の口座開設方法？",
        illustrate_p5: "部下のアカウントをクリックして、まず部下のためのリベートを設定し、正常に招待コードが生成されます設定し、招待コードは、部下の登録、登録に送信されます、彼はあなたの部下であり、ユーザー管理をクリックして、彼の登録アカウントを表示することができます。",
        illustrate_p6: "部下にエージェントタイプのアカウントを設定した場合、部下は引き続き部下を育成することができます。 プレイヤータイプのアカウントを設定した場合、部下はベットすることしかできず、部下を育成することはできず、エージェントセンターを見ることもできません。ユーザー管理",
        illustrate_tip1: "1、リベートのオッズが違う、リベートのオッズ表をクリックして、リベートのオッズを見ることができる。",
        illustrate_tip2: "2、リベートが低ければ低いほど確率は下がるので、低レベルに設定するリベートは低すぎないことを推奨する。",
        illustrate_tip3: "3、あなたは、エージェントのステートメント、低レベルの賭け、低レベルの取引でエージェントの開発を見ることができます。",
        illustrate_tip4: "4、それは次のレベルを開くことをお勧めしますまた、エージェントの種類に関係なく、いくつかのレベルの開発は、リベートを得ることができます。",
        open_type: "口座開設タイプ：",
        open_type1: "フル",
        open_type2: "責任ある立場で代行する",
        open_type3: "プレイヤ",
        invite_tip1: "1、リベートのオッズはそれぞれ異なり、リベートが高いほどオッズは高くなる。",
        invite_tip2: "2、エージェントは、手数料を得ることができるエージェント自身のリベートと部下リベートの差に等しい、例えば、エージェント自身のリベート6、部下リベート4、エージェントは、ベット額の2％の部下を得ることができる、つまり、部下は100元を賭けて、エージェントは2元を得ることができます。",
        invite_tip3: "3、低レベルのリベートの値が低いほど、低い確率を設定し、それは低レベルのリベートが低すぎない設定することをお勧めします。",
        rebateDialog_tit: "リベートを見る",
        delete_invite_tip: "この招待コードを削除してもよろしいですか？？",
        rebate_table: "リベート・オッズ表",
        generate_invitation_code: "招待コードの生成",
        subordinate_tip1: "1、リベートのオッズはそれぞれ異なり、リベートが高いほどオッズは高くなる。",
        subordinate_tip2: "2、エージェントは、手数料を得ることができるエージェント自身のリベートと部下リベートの差に等しい、例えば、エージェント自身のリベート6、部下リベート4、エージェントは、ベット額の2％の部下を得ることができる、つまり、部下は100元を賭けて、エージェントは2元を得ることができます。",
        subordinate_tip3: "3、低レベルのリベートの値が低いほど、低い確率を設定し、それは低レベルのリベートが低すぎない設定することをお勧めします。",
        manage_tab1: "エージェントの説明",
        manage_tab2: "下位口座開設",
        manage_tab3: "招待コード",
        manage_tab4: "ユーザー管理",
        lower_bet_tip: "取引記録は最大21日間保存される。",
        report_label1: "賭博師",
        report_label2: "懸賞を当てる",
        report_label3: "機能",
        report_label4: "チーム・リベート",
        report_label5: "チーム利益",
        report_label6: "ベッター数",
        report_label7: "初回チャージ数",
        report_label8: "登録者数",
        report_label9: "部下の数",
        report_label10: "チーム・バランス",
        report_label11: "リチャージ",
        report_label12: "資金を引き出す",
        report_label13: "代理店リベート",
        report_label14: "振り込む",
        report_label15: "受取人",
        report_label16: "赤いパケットを送る",
        report_label17: "赤いパケットを受け取る",
    },
    fund: {
        tip1: "以下の銀行口座にお振込みください。",
        tip2: "お振込み先の情報を慎重にご記入ください。",
        tip3: " (ご注文を送信する前に必ずお振込みください。そうしないと、お支払いが間に合いません。！)",
        deposit_rate: "預金為替レート",
        currency_number: "通貨量",
        tip4: "送金には、対応するデジタル通貨プラットフォームをご利用ください。",
        tip5: "シングルストロークリミット",
        tip6: "(認証情報を入力できない場合は、取引レシートのスクリーンショットをアップロードしてください。)",
        click_upload: "クリックしてアップロード",
        upload_tip: "アップロードできるのはjpg/pngファイルのみです。",
        receiving_address: "受取人住所",
        tip7: "以下の銀行口座へのお振込みはOVOをご利用ください。",
        tip8: "1、インターネットバンキング決済を利用するには、インターネットバンキングを開設する必要がある。",
        tip9: "2、チャージに成功した後、'Return to Merchant ‘'をクリックして、自動的にアカウントを取得します。",
    },
    other: {
        gift_tip1: "1、贈与は、贈与を請求するための条件が満たされて初めて請求できる。。",
        gift_tip2: "2、未受領のギフトは、有効期限が過ぎると自動的に失効し、取り戻すことはできません。。",
        other_tip1: "手紙は最大7日間記録される；",
    },
    form: {
        input_inner: "内容を入力してください",
        label_username: "ユーザーアカウント：",
        place_username: "ユーザーアカウントを入力してください。",
        label_loginpsd: "ログインパスワード：",
        place_loginpsd: "ログインパスワードを入力してください",
        label_invitecode: "招待コード：",
        place_invitecode: "8桁の招待コードをご記入ください。",
        label_oldpsd: "オリジナルパスワード",
        place_oldpsd: "元のパスワードを入力してください。",
        label_newpsd: "新しいパスワード",
        place_newpsd: "新しいパスワードを入力してください。",
        label_crmpsd: "パスワードの確認",
        place_crmpsd: "新しいパスワードをもう一度入力してください。",
        label_remark: "備考",
        place_remark: "メモを入力してください",
        label_lower_account: "ユーザー名：",
        place_lower_account: "下位の口座番号を入力してください。",
        label_type: "類型論：",
        place_select: "選択してください",
        label_lottery_title: "宝くじ：",
        label_state: "情勢：",
        option_state1: "フル",
        option_state2: "保留抽選",
        option_state3: "不戦勝",
        option_state4: "賞をとる",
        label_time: "回：",
        times1: "現在のところ",
        times2: "昨日",
        times3: "当月",
        times4: "ウルチモ",
        label_realname: "本姓",
        place_realname: "本名を入力してください",
        label_bank_number: "ATMカード番号",
        place_bank_number: "銀行カード番号を入力してください。",
        label_bank_name: "銀行名",
        place_bank_name: "銀行名を選択してください",
        label_abstract: "抄録:",
        label_channel: "リチャージ・チャンネル",
        place_channel: "トップアップチャンネルを選択してください",
        label_deposit_amount: "上乗せ額",
        place_deposit_amount: "上乗せ金額を入力してください。",
        label_holder: "口座名義人",
        label_deposit_name: "預金者名",
        place_deposit_name: "預金者名を入力してください。",
        label_transfer_msg: "移籍情報",
        place_transfer_msg: "振込先情報をご記入ください。",
        label_transfer_pic: "取引領収書のスクリーンショット",
        label_transfer_pic_tip: "(領収書のスクリーンショットをアップロードしてください。）",
        label_bank_account_name: "銀行口座名",
        label_balance: "バランス",
        label_available_withdraw: "バランス",
        label_available_number: "引き出し数",
        label_withdraw_amount: "出金額",
        placce_withdraw_amount: "引き出し金額を入力してください。)",
        available_withdraw: "望ましさ",
        label_transfer_bank: "デビットバンク",
        label_security_psd: "セキュリティコード",
        place_security_psd: "セキュリティコードを入力してください。",
        place_nickname: "愛称",
        message_nickname: "ニックネームを入力してください",
        place_phone: "携帯電話番号を入力してください。",
        place_email: "メールアドレスを入力してください",
        place_invite_id: "招待コードを入力してください",
        place_verify_code: "認証コードを入力してください。",
        send: "送信",
        test_username: "テストユーザー名",
        place_amount: "金額を入力してください",
    },
    login: {
        login_tit: "ユーザーログイン",
        forget_psd: "パスワードをお忘れですか？？",
        login: "サインイン",
        register_tit: "ユーザー登録",
        register: "在籍",
        have_account: "すでにアカウントをお持ちの方は、ログインしてください。",
        country_code: "国コード",
        phone: "携帯電話",
        email: "受信箱",
    },
    city: {
        albania: "アルバニア",
        algeria: "アルジェリア",
        argentina: "アルゼンチン",
        armenia: "アルメニア",
        australia: "オーストラリア",
        pakistan: "パキスタン",
        austria: "オーストリア",
        bahrain: "オーストリア",
        belgium: "ベルギー",
        bosnia_and_Herzegovina: "ボスニア・ヘルツェゴビナ",
        brazil: "ブラジル",
        brunei: "ブルネイ",
        bulgaria: "ブルガリア",
        cambodia: "カンボジア",
        canada: "カナダ",
        cameroon: "カメルーン",
        chile: "チリ",
        colombia: "コロンビア",
        costa_Rica: "コスタリカ",
        croatia: "クロアチア",
        cyprus: "キプロス",
        czech_Republic: "チェコ共和国",
        denmark: "デンマーク",
        dominican_Republic: "ドミニカ共和国",
        egypt: "エジプト",
        estonia: "エストニア",
        ethiopia: "エチオピア",
        finland: "フィンランド",
        france: "フランス",
        georgia: "グルジア",
        germany: "ドイツ",
        ghana: "ガーナ",
        greece: "ギリシャ",
        guyana: "ガイアナ",
        honduras: "ホンジュラス",
        hong_Kong_China: "中国香港",
        hungary: "ハンガリー",
        iceland: "アイスランド",
        ireland: "アイルランド",
        italy: "イタリア",
        india: "インド",
        indonesia: "インドネシア",
        israel: "イスラエル",
        iran: "イラン",
        iraq: "イラク",
        japan: "日本",
        kazakstan: "カザフスタン",
        kenya: "ケニア",
        korea: "韓国",
        kuwait: "クウェート",
        kyrgyzstan: "キルギス",
        laos: "ラオス",
        latvia: "ラトビア",
        lithuania: "リトアニア",
        luxembourg: "ルクセンブルク",
        macao_China: "中国マカオ",
        macedonia: "マケドニア",
        malaysia: "マレーシア",
        malta: "マルタ",
        mexico: "メキシコ",
        moldova: "モルドバ",
        monaco: "モナコ",
        mongolia: "モンゴル",
        montenegro: "モンテネグロ共和国",
        morocco: "モロッコ",
        myanmar: "ミャンマー",
        netherlands: "オランダ",
        new_Zealand: "ニュージーランド",
        nepal: "ネパール",
        nigeria: "ナイジェリア",
        norway: "ノルウェー",
        oman: "オマーン",
        palestine: "パレスチナ",
        panama: "パナマ",
        paraguay: "パラグアイ",
        peru: "ペルー",
        philippines: "フィリピン",
        poland: "ポーランド",
        portugal: "ポルトガル",
        puerto_Rico: "プエルトリコ",
        qatar: "カタール",
        romania: "ルーマニア",
        russia: "ロシア",
        republic_of_Trinidad_and_Tobago: "トリニダード・トバゴ共和国",
        rwanda: "ルワンダ",
        saudi_Arabia: "サウジアラビア",
        serbia: "セルビア",
        singapore: "シンガポール",
        slovakia: "スロバキア",
        slovenia: "スロベニア",
        south_Africa: "南アフリカ共和国",
        spain: "スペイン",
        sri_Lanka: "スリランカ",
        sweden: "スウェーデン",
        switzerland: "スイス",
        taiwan_China: "中国台湾",
        tajikistan: "タジキスタン",
        tanzania: "タンザニア",
        thailand: "タイ",
        turkey: "トルコ",
        turkmenistan: "トルクメニスタン",
        ukraine: "ウクライナ",
        united_Arab_Emirates: "アラブ首長国連邦",
        united_Kingdom: "英国",
        united_States: "アメリカ",
        uzbekistan: "ウズベキスタン",
        venezuela: "ベネズエラ",
        vietnam: "ベトナム",
        afghanistan: "アフガニスタン",
        angola: "アンゴラ",
        azerbaijan: "アゼルバイジャン",
        bangladesh: "バングラデシュ",
        belarus: "ベラルーシ",
        belize: "ベリーズ",
        benin: "ベニン",
        bhutan: "ブータン",
        bolivia: "ボリビア",
        botswana: "ボツワナ",
        british_Virgin_Islands: "イギリス領ヴァージン諸島",
        burkina_Faso: "ブルキナファソ",
        burundi: "ブルンジ",
        cape_Verde: "カーボベルデ角",
        cayman_Islands: "ケイマン諸島",
        central_African_Republic: "中央アフリカ共和国",
        chad: "チャド",
        comoros: "コモロ",
        the_Republic_of_the_Congo: "コンゴ（布）",
        democratic_Republic_of_the_Congo: "コンゴ（金）",
        djibouti: "ジブチ",
        ecuador: "エクアドル",
        el_Salvador: "エルサルバドル",
        equatorial_Guinea: "赤道ギニア",
        eritrea: "エリトリア",
        fiji: "フィジー",
        gabon: "ガボン",
        gambia: "ガンビア",
        greenland: "グリーンランド",
        guatemala: "グアテマラ",
        guinea: "ギニア",
        haiti: "ハイチ",
        isle_of_Man: "マーン島",
        cote_d_Ivoire: "コートジボワール",
        jamaica: "ジャマイカ",
        jordan: "ヨルダン",
        lebanon: "レバノン",
        lesotho: "レソト",
        liberia: "リベリア",
        libya: "リビア",
        madagascar: "マダガスカル",
        malawi: "マラウイ",
        maldives: "モルディブ",
        mali: "マリ",
        mauritania: "モーリタニア",
        mauritius: "モーリシャス",
        mozambique: "モザンビーク",
        namibia: "ナミビア",
        nicaragua: "ニカラグア",
        republic_of_Niger: "ニジェール",
        north_Korea: "朝鲜",
        reunion: "レユニオン",
        san_Marino: "サンマリノ",
        senegal: "セネガル",
        sierra_Leone: "シエラレオネ",
        somalia: "ソマリア",
        sudan: "スーダン",
        suriname: "スリナム",
        eswatini: "スワジランド",
        syria: "シリア",
        togo: "トーゴ",
        tonga: "トンガ",
        tunisia: "チュニジア",
        united_States_Virgin_Islands: "アメリカ領ヴァージン諸島",
        uganda: "ウガンダ",
        uruguay: "ウルグアイ",
        vatican: "バチカン",
        yemen: "イエメン",
        yugoslavia: "ユーゴスラビア",
        zambia: "ザンビア",
        zimbabwe: "ジンバブエ",
        china: "中国",
    }
};
