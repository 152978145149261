export default {
    common: {
        contactNow: "Contact Now",
        minPrice: "Min",
        maxPrice: "Max",
        address: {
            title: "Wallet address",
            account_number: "Account number",
            add_btn: "Add address",
            label_type: "Select type",
            place_type: "Please select type",
            label_number: "Account",
            place_number: "Please enter the account number",
            wallet_address: 'Wallet address',
            bank_name: 'BANK NAME',
            bank_address: "Bank address",
            name: "Name",
            payee_address: 'Beneficiary Address',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Branch Name",
            branch_code: "BRANCH CODE",
            bank_code: "Bank code",
            phone: "Cell-phone number",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Please enter content",
        },
        priceType: {
            fast_sum: "Sum",
            fast_three_all: "Three All",
            fast_three_one: "Three One",
            fast_three_diff: "Three Diff",
            fast_three_continue: "Three Continue",
            fast_two_some: "Two Some",
            fast_two_one: "Two One",
            fast_two_diff: "Two Diff",
            fast_one_one: "Single screening",
            racing_winner: "Champions",
            racing_second: "Runner",
            racing_third: "Bronze medalist",
            lotto_one: "Myriabit",
            lotto_two: "Kilobit",
            lotto_three: "Hundred places",
            lotto_four: "Ten",
            lotto_five: "Unit",
            wingo_sum: "Sum",
            wingo_color: "Color",
            Big: "Big",
            Small: "Small",
            Single: "Single",
            Double: "Double",
            Red: "Bonus",
            Green: "Green",
            Purple: "Purple",
        },
        img_error: "Image failed to load",
        more: "More",
        cancel: "Abolish",
        confirm: "Recognize",
        tips: "Hint：",
        no_set: "Not set",
        submit: "Submit",
        copy_success: "Copy Success！",
        copy_error: "Reproduction Failure！",
        currency: "$",
        filter: "Screen",
        edit: "Modify",
        gameStart: "Start the game",
        prizenumber: "Winning number",
        mynumber: "My number.",
        addresss: "Here is the link address",
        gameAgain: "One more time.",
        gamerule: "Rules of the game",
        winning_numbers: "Winning number",
        winning: "Congratulations on your win!！",
        notwin: "Unfortunately, you did not win!",
        logout: "Are you sure you want to log out?？",
        success: "Successes",
        search: "Look for sth.",
        withdraw0: "Application",
        withdraw1: "Underway",
        withdraw2: "Successes",
        withdraw3: "Fail",
        balanceNot: "Insufficient account balance",
        canceled: "Cancelled",
        bill: {
            bill101: "User Recharge",
            bill102: "System additions",
            bill103: "System Freeze",
            bill104: "System unfreeze",
            bill201: "User withdrawals",
            bill202: "System deductions",
            bill203: "Withdrawal Successful",
            bill204: "Withdrawal Audit Failure",
            bill205: "Withdrawal fee",
            bill301: "Join the game",
            bill302: "Gaming Profits",
        }
    },
    add: {
        service: "Service",
        apoio_online: "Online Support",
        online_tip: "Customer service can help resolve issues",
        chat: "CHAT",
        activity: "Activity",
        activity_p1: "First deposit activity",
        activity_p2: "Second deposit activity",
        activity_p3: "Third deposit event",
        activity_table1: "First deposit amount",
        activity_table2: "Part",
        activity_table3: "Drawing requirements",
        activity_tip1: "Event conditions:",
        activity_tip2: "1.Effective betting requirements",
        activity_tip3: "a. When participating in activities, account balance< Deposit amount, valid bet=(balance+bonus) x2, you can withdraw",
        activity_tip4: "b. When participating in activities, account balance> Deposit amount and valid bet=(deposit amount+bonus) x2, you can withdraw",
        activity_tip5: "After successfully depositing, please participate in the activity first and then place your bet.",
        activity_tip6: "3. The first deposit, second deposit, and third deposit can only be made once",
        activity_tip7: "Activity rules:",
        activity_tip8: "1.Please fill in your personal information before the event",
        activity_tip9: "2.Each member with the same name, IP address, device code, and phone number can only participate in the event once. If a member intentionally registers multiple accounts consecutively, the company will revoke or revoke the institution's rights, permanently freeze the account, and recover all bonuses.",
        activity_tip10: "3. If individuals or organizations are found to engage in fraud to profit from activities, all activities are prepared for members. The company reserves the full right to withhold or cancel the total bonus of the individual or organization",
        activity_img1: "The first 3 bonus reloads for new members",
        activity_img2: "Recommend friends and earn bonuses",
        activity_img3: "VIP",
        activity_img4: "Aid funds up to 100%",
        activity_img5: "Assistance Fund",
        go_bet: "Direct betting",
        cqk: "Depo/with",
        online: "Online Service",
        lottery: "Lottery Hall",
        game: "Play",
        lotter: "Lotteries",
        search_lotter: "Search for a color",
        search: "Consult",
        di: "No",
        qi: "Close",
        ju: "Before the end of",
        feng: "Circuit breaker",
        result: "Lottery result",
        logout: "Abort",
        available_balance: "Available Balance",
        notice_title: "Platform Announcement",
        tip30: "No more pop-ups for 30 minutes",
        close: "Cloture",
        personal: "Personal center",
        level: "Hierarchy",
        common: "Commonly seen",
        news: "Latest Offers",
        help_center: "Help Center",
        app_download:"APP Download",
    },
    addnew: {
        remainder: "remainder",
        history: "History",
        trend: "Trend",
        my_bet: "Order",
        bei: "Multiple",
        agree: "I agree",
        rule: "《Pre sale Rules》",
        total_amount: "Total amount",
        wallet_balance: "Wallet balance",
        total: "Sum",
        random_bet: "Random betting",
    },
    playMethod: {
        p1: "Please select the rules of the game you want to view",
        p2: "If you study the rules, you'll double your earnings.",
        des1: "Synopsis",
        des2: "If you trade for 100, after deducting the service fee of 2%, the contract amount: 98",
        fived: {
            p1: "5D Lottery Game Rules",
            p2: "Drawing instructions",
            p3: "5 randomly selected digits (00000-99999) for each issue",
            p4: "For example:",
            p5: "The current draw number is 12345",
            p6: "A = 1",
            p7: "B = 2",
            p8: "C = 3",
            p9: "D = 4",
            p10: "E = 5",
            p11: "Cap = A + B + C + D + E = 15",
            p12: "How to play.",
            p13: "Players can specify bets on the six outcomes and totals of A, B, C, D and E",
            p14: "A, B, C, D, E are available",
            p15: "Number (0 1 2 3 4 5 6 7 8 9)",
            p16: "Low (0 1 2 3 4)",
            p17: "High (5 6 7 8 9)",
            p18: "Odd numbers (1 3 5 7 9)",
            p19: "Even (0 2 4 6 8)",
            p20: "Sum = A+B+C+D+E available for purchase",
            p21: "Low (0-22)",
            p22: "High (23-45)",
            p23: "Odd numbers (1 3---43 45)",
            p24: "Even (0 2---42 44)",
        },
        racing: {
            p1: "Gameplay",
            p2: "※Top three and：",
            p3: "The sum of the first three car numbers is greater than 16 for large, less than or equal to 16 for small. Even numbers are called double, odd numbers are called single. ※The first three sums:",
            p4: "※First to third place：",
            p5: "Car number designation, each car number is a betting combination, the lottery result 'betting car number' corresponding to the name of the bet is regarded as a winner, and the rest of the situation is regarded as a non-winner.",
            p6: "※Both sides：",
            p7: "Refers to single and double; large and small.",
            p8: "※Single and double:",
            p9: "An even number is called double, e.g. 2, 4, 6, 8, 10; an odd number is called single, e.g. 1, 3, 5, 7, 9.",
            p10: "※Large and small",
            p11: "Numbers greater than or equal to 6 are considered large, e.g. 6, 7, 8, 9, 10; numbers less than or equal to 5 are considered small, e.g. 1, 2, 3, 4, 5.",
        },
        wingo: {
            p1: "Gameplay",
            p2: "※Single figure：",
            p3: "Choose a number to bet on, the drawing number and the betting number are regarded as the same as winning, the rest of the cases are regarded as not winning.",
            p4: "※Red：",
            p5: "Betting on red is considered a win if the lottery numbers are 0, 2, 4, 6, 8, and a no win for the rest of the scenarios.",
            p6: "※Greener：",
            p7: "If you bet on green, you are considered a winner if the draw numbers are 1, 3, 5, 7, 9, and you are considered a non-winner in the rest of the cases.",
            p8: "※Purple：",
            p9: "If you bet on purple, you will be considered a winner if the draw numbers are 0 and 5, and you will be considered a non-winner in the rest of the cases.",
            p10: "※Large and small",
            p11: "Drawing numbers greater than or equal to 5 are considered large, e.g. 5, 6, 7, 8, 9; less than or equal to 4 are considered small, e.g. 0, 1, 2, 3, 4.",
        },
        lottery: {
            p1: "Gameplay ~ 6 randomly opened.",
            p2: "A: Big and small",
            p3: "Size: The total sum of the three lottery numbers ranging from 11 to 18 is considered large; the total sum ranging from 3 to 10 is considered small.",
            p4: "Single and Double: If the total sum of the three lottery numbers is single, then single wins, and if it is double, then double wins.",
            p5: "B: Points",
            p6: "If the sum value of the lottery numbers is 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, you will win; for example, if the lottery numbers are 1, 2, 3 and the sum value is 6, then betting on “6” will win.",
            p7: "C: Single Dice",
            p8: "If one of the three lottery numbers is the same as the selected number, it is considered a winner. Example: If the lottery numbers are 1, 1, 3, then betting on single dice 1 or single dice 3 will be considered as winning. (Regardless of how many times the designated number appears, the payout will not be doubled only once.)",
            p9: "D: Double Dice",
            p10: "The prize will be won if any two of the prize numbers are the same and match the selected double dice combination. Example: If the prize number is 1, 1, 3, then bet on the double dice 1, 1, will be the winner.",
            p11: "E: Round Robin, Full Round Robin",
            p12: "Dice: The prize will be won if the lottery numbers are the same in all three characters and match the chosen dice combination.",
            p13: "Full Circle Dice: Drawing numbers with three characters in the same number is a win.",
            p14: "F: Two-dice combinations",
            p15: "Choose any 2-dice combination, when the lottery result is the same as the chosen combination, it will be regarded as a winner. Example: If the lottery numbers are 1, 2, 3, then betting on 2-dice combination 12, 2-dice combination 23, 2-dice combination 13 will be considered as winning.",
        }
    },
    webadd: {
        hot: "Popular",
        all: "All",
        home: "Hall",
        hot_lottery: "Popular Lottery",
        select_channel: "Choose a recharge channel",
        select_bank: "Select Bank",
        top_tab1: "Winning",
        top_tab2: "TOP",
        top_tab3: "Mine",
        web_time: "Website time",
        trade: "Trans",
    },
    footer: {
        foot1: "Home",
        foot2: "Games",
        foot3: "TOP",
        foot4: "Activity",
        foot5: "My",
    },
    navBar: {
        back_home: "Back to home page",
        help_center: "Help Center",
        contact_service: "Contact Customer Service",
        balance: "Balances",
        my_account: "My Account",
        account: "Account",
        record: "Record",
        proxy: "Agent",
        other: "Other",
        deposit: "Recharge",
        withdraw: "Withdraw",
        deposit_type1: "Online banking",
        deposit_type2: "Bank transfers",
        deposit_type3: "Digital currency",
        deposit_type4: "OVO",
        logout: "Abort",
        login: "Log in",
        register: "Registrations",
        nav1: "Lotteries",
        nav2: "Play",
        nav3: "Maneuver",
        nav4: "Cell phone",
        nav5: "My Account",
    },
    activity: {
        tit1: "Daily Bonus",
        p1: "The Daily Jackpot is a bonus based on the amount the user wagered yesterday, with no upper limit on the bonus!",
        activity_detail: "Event Details",
        yestoday_bet: "Yesterday's bets",
        bonus_ratio: "Ratio of Prize Increase",
        now_level: "Current level",
        receive_bonus: "Bonus",
        receive: "Get",
        not_receive: "Unavailable",
        activity_time: "Activity time",
        p2: "1、Daily bonus is open for collection after 00:20 a.m. every day.",
        p3: "2, The bonus percentage is based on the user level and yesterday's cumulative betting amount for a certain percentage of the bonus",
        p4: "3. Withdrawals and other invalid bets will not be counted",
        p5: "4, Valid bets need to meet the [the single has been opened and settled], such as the case of betting on the single after the betting on a number of days before the opening of the single, the single valid bets will be accumulated in the [opening and settlement of the day].",
        p6: "5, Example: 10/1 betting but 10/15 only draw, then the effective betting amount of the betting order will be accumulated in 10/15, the user can be 10/16 time to collect the betting order of the bonus bonus.",
        p7: "6、Activity bonuses are not collected before 24:00 on the day of the event, as an automatic waiver of the activity qualification",
    },
    game: {
        history_tit: "Historical Prizes",
        play_tit: "Instructions for playing",
        official_award: "The original official prize",
        betting: "Betting in progress",
        waiting: "Waiting",
        opening: "The prize is being drawn.",
        settled: "Settled",
        auto_change: "Auto Switching",
        height_win: "Maximum win",
        total: "In sum",
        pour: "Stake (gambling)",
        every_pour: "Each bet",
        total_pour: "Total bets",
        total_amount: "Total bets",
        now_bet: "Bet Now",
        my_bet: "My bets",
        winning: "Win a prize",
        top: "TOP",
        winning_tip: "Winning information is updated in real time",
        top_tip: "Top Ranking",
    },
    record: {
        lottery: "Lotteries",
        game: "Play",
        today_profit_loss: "Today's Profit and Loss",
        label_formula: "Break-even formula",
        formula: "Formula: Winning - Betting + Activity + Rebates",
        profitLoss_label1: "Sin a prize",
        profitLoss_label2: "Maneuver",
        profitLoss_label3: "Bettors",
        profitLoss_label4: "Affiliate reward",
        profitLoss_label5: "Transfer",
        profitLoss_label6: "Payee",
        profitLoss_label7: "Send Red Packet",
        profitLoss_label8: "Receive a red packet",
        trade_tab1: "All",
        trade_tab2: "Recharge",
        trade_tab3: "Withdrawal",
    },
    table: {
        label_no: " No.",
        label_time: "Timing",
        label_result: "Bear fruit",
        label_size: "Size ",
        label_single_double: "Odd and even",
        label_sun_value: "Sum",
        no_data: "No data",
        big: "Big",
        small: "Small",
        single: "Single",
        double: "Dual",
        label_issue: "Issue number",
        label_bet_amount: "Betting amount",
        label_reward: "Gratuities",
        no_reward: "Not a winner",
        is_reward: "Win a prize",
        label_level: "Hierarchy",
        label_integral: "Growth Points",
        label_award: "Advancement Rewards",
        label_skip_awardl: "Level Jumping Bonus",
        label_register_link: "Registration Link",
        label_remark: "Remark",
        label_rise_time: "Generation time",
        label_register_number: "Number of register",
        label_operate: "Manipulate",
        label_gametit: "Games",
        label_lower_rebate: "Affiliate reward",
        label_self_rebate: "Rebate",
        label_set_rebate_range: "Rebate range can be set",
        bonus_state: "Bonus status",
        label_serial_number: "Ambush signal",
        label_income_amount: "Amount income",
        label_expend_amount: "AmountExpendi",
        label_available_balance: "Balance",
        label_abstract: "Summaries",
        label_details: "Particulars",
        label_account: "Online account",
        label_amount: "Money",
        label_bet_cont: "Contents bets",
        label_ottery_number: "Lottery number",
        order_number: "Odd Numbers",
        play_methods: "Playing Method",
        label_periods: "Phase",
        label_number: "Numbers",
        label_color: "Color",
    },
    my: {
        last_login_time: "Last login time",
        logout_account: "Withdrawal from account",
        my_account: "My Account",
        account: "Online Account",
        personal_tit: "Personal Profile",
        security_tit: "Security Center",
        bank_tit: "Bank Account Number",
        record: "Record",
        profit_loss_tit: "Today's Profit and Loss",
        trade_record_tit: "Transaction records",
        bet_record: "Betting Records",
        proxy: "Agent",
        manag_tit: "Agency Management",
        report_tit: "Proxy statements",
        lower_bet_tit: "Lower level betting",
        lower_trade_tit: "Subordinate transactions",
        other: "The rest",
        gift_tit: "Activity Gift",
        message_tit: "News Center",
        personal_data: "Personal data",
        balance: "Balances",
        last_sync_time: "Last synchronization time",
        deposit: "Recharge",
        withdraw: "Withdraw funds",
        yesterday_deposit_time: "Time to deposit yesterday",
        yesterday_withdraw_time: "Time to withdraw yesterday",
        help_tit: "Help Center",
        all: "All",
        lower_report: "Subordinate Reports",
        about_us: "About us",
        logout: "Log out",
        invite: "Invite Friends",
        lang_change: "Language switching",
    },
    account: {
        add_bank_account: "Add Bank",
        bank_tip: "Up to 5 bank accounts can be added. The bank accounts that have been successfully withdrawn will be automatically locked and cannot be deleted or modified. The bank account that has not been successfully withdrawn can be modified and cannot be deleted.",
        account_msg: "Account Information",
        growth_value: "Growth rate",
        noset_nickname: "Nickname not set",
        nationality: "Citizenship",
        next_level: "Next level to go",
        integral: "Bonus points",
        integral_tip: "Add 1 point for every 1.00 $ reloaded",
        level_system: "Hierarchy",
        change_avatar: "Change Avatar",
        loginpsd_tip: "Login password 6 to 16 digits combination of letters and numbers",
        modify_psd: "Modify a password",
        securitypsd_tip: "Security password (6-digit combination)",
        forget_securitypsd: "Forgot Security Code",
        confidentiality_tip: "Recovery of user account information in case of loss of account number or password in case of password protection problems.",
        bank_account: "Bank account",
        edit_nickname: "Edit nickname",
        confidentiality: "Security question",
        accumulated_winning: "Progressive Jackpot",
    },
    agent: {
        illustrate_p1: "When you can see this page, it means that your account is both a player account and an agent account, which allows you to place your own bets as well as develop lower level players and earn rebate commissions.",
        illustrate_p2: "How to earn rebates？",
        illustrate_p3: "The rebate you can get is equal to the difference between your own rebate and the rebate of the lower level, such as your own rebate of 5, the lower level rebate of 3, you will be able to get the rebate of the lower level of the amount of 2 bets, such as the lower level of the bet of 100 $, you will get 2 $. click on the lower level of the account, you can see your own rebate, but also for the lower level to set the rebate.",
        illustrate_p4: "How to open an account for a subordinate",
        illustrate_p5: "Click on the next level to open an account, first set up rebates for your subordinates, set up a successful invitation code will be generated, the invitation code will be sent to your subordinates to register, after registration, he is your subordinates, click on the user management, you can view his registered account.",
        illustrate_p6: "If you set up an agent type account for your subordinates, then your subordinates will be able to continue to develop their subordinates. If you set up a player type, then your subordinates will only be able to place bets and will not be able to develop their subordinates and will not be able to see the agent center. User Management",
        illustrate_tip1: "1, Rebate different odds are different, click on the rebate odds table, you can view the rebate odds.",
        illustrate_tip2: "2, The lower The rebate, the lower the odds, it is recommended that the rebate set for the lower level is not too low.",
        illustrate_tip3: "3, You can view the development of the agent in the agent statement, lower level betting, lower level trading.",
        illustrate_tip4: "4, It is recommended to open the next level is also an agent type, regardless of the development of several levels, you can get rebates.",
        open_type: "Type of account opening：",
        open_type1: "Full",
        open_type2: "Agent",
        open_type3: "Gamer",
        invite_tip1: "1, Different rebate odds are different, the higher the rebate the higher the odds.",
        invite_tip2: "2, The agent can get the commission is equal to the difference between the agent's own rebate and the lower rebate, such as the agent's own rebate 6, the lower rebate 4, the agent can get the lower level of the betting amount of 2%, that is, the lower level of the bet 100 yuan, the agent can get 2 yuan.",
        invite_tip3: "3, The lower the value of the lower level rebate set the lower, the lower the odds, it is recommended that the lower level set the rebate is not too low.",
        rebateDialog_tit: "View Rebates",
        delete_invite_tip: "Are you sure you want to delete this invitation code?？",
        rebate_table: "Rebate Odds Table",
        generate_invitation_code: "Generate Invitation Code",
        subordinate_tip1: "1, Different rebate odds are different, the higher the rebate the higher the odds.",
        subordinate_tip2: "2, The agent can get the commission is equal to the difference between the agent's own rebate and the lower rebate, such as the agent's own rebate 6, the lower rebate 4, the agent can get the lower level of the betting amount of 2%, that is, the lower level of the bet 100 yuan, the agent can get 2 yuan.",
        subordinate_tip3: "3, The lower the value of the lower level rebate set the lower, the lower the odds, it is recommended that the lower level set the rebate is not too low.",
        manage_tab1: "Agent Description",
        manage_tab2: "Subordinate account opening",
        manage_tab3: "Invitation code",
        manage_tab4: "User management",
        lower_bet_tip: "Transaction records are retained for a maximum of 21 days.",
        report_label1: "Bettors",
        report_label2: "Win a prize",
        report_label3: "Maneuver",
        report_label4: "Team Rebates",
        report_label5: "Team Profit",
        report_label6: "Number of bettors",
        report_label7: "Number of First Charge",
        report_label8: "Number of Registrants",
        report_label9: "Number of subordinates",
        report_label10: "Team Balance",
        report_label11: "Recharge",
        report_label12: "Withdraw funds",
        report_label13: "Agent Rebates",
        report_label14: "Transfer",
        report_label15: "Payee",
        report_label16: "Send Red Packet",
        report_label17: "Receive a red packet",
    },
    fund: {
        tip1: "Please transfer to the following bank account",
        tip2: "Please fill in your transfer information carefully",
        tip3: "(Please make sure to transfer the money before submitting the order, otherwise you will not be able to check your payment in time!)",
        deposit_rate: "Deposit exchange rate",
        currency_number: "Amount of currency",
        tip4: "Please use the corresponding digital currency platform to transfer money",
        tip5: "Single-stroke limit",
        tip6: "(If you are unable to fill in the authentication information, please upload a screenshot of the transaction receipt)",
        click_upload: "Click to upload",
        upload_tip: "Only jpg/png files can be uploaded",
        receiving_address: "Payee address",
        tip7: "Please use OVO to transfer funds to the following bank accounts",
        tip8: "1. Online banking payment requires the activation of online banking to proceed with payment.",
        tip9: "2. After successful recharge, click 'Return to Merchant' to automatically receive the account.",
    },
    other: {
        gift_tip1: "1. Gifts must meet the conditions for receiving them before they can be received.",
        gift_tip2: "2. Gifts not claimed after the expiration date are automatically invalidated and cannot be claimed again.",
        other_tip1: "Letters are kept on record for a maximum of 7 days;",
    },
    form: {
        input_inner: "Please enter content",
        label_username: "User account：",
        place_username: "Please enter your user account",
        label_loginpsd: "Login password：",
        place_loginpsd: "Please enter your login password",
        label_invitecode: "Invitation code：",
        place_invitecode: "Please fill in the 8-digit invitation code",
        label_oldpsd: "Original password",
        place_oldpsd: "Please enter the original password",
        label_newpsd: "New password",
        place_newpsd: "Please enter a new password",
        label_crmpsd: "Confirm Password",
        place_crmpsd: "Please enter the new password again",
        label_remark: "Note",
        place_remark: "Please enter a note",
        label_lower_account: "Username：",
        place_lower_account: "Please enter the subordinate account number",
        label_type: "Typology：",
        place_select: "Please select",
        label_lottery_title: "Color：",
        label_state: "State",
        option_state1: "Full",
        option_state2: "Pending",
        option_state3: "Lose",
        option_state4: "Won",
        label_time: "Timing：",
        times1: "Today",
        times2: "Yesterday",
        times3: "This month",
        times4: "Ultimo",
        label_realname: "Real name",
        place_realname: "Please enter your real name",
        label_bank_number: "ATM card number",
        place_bank_number: "Please enter your bank card number",
        label_bank_name: "Bank name",
        place_bank_name: "Please select bank name",
        label_abstract: "Abstracts:",
        label_channel: "Recharge Channel",
        place_channel: "Please select the recharge channel",
        label_deposit_amount: "Recharge amount",
        place_deposit_amount: "Please enter the recharge amount",
        label_holder: "Account holder",
        label_deposit_name: "Depositor's Name",
        place_deposit_name: "Please enter the name of the depositor",
        label_transfer_msg: "Transfer Info",
        place_transfer_msg: "Please fill in the transfer information",
        label_transfer_pic: "Screenshot of transaction receipt",
        label_transfer_pic_tip: "(Please upload a screenshot of the transaction receipt)",
        label_bank_account_name: "Bank account name",
        label_balance: "Balances",
        label_available_withdraw: "Withdrawable amount",
        label_available_number: "Number of withdrawals",
        label_withdraw_amount: "Withdrawal Amount",
        placce_withdraw_amount: "Please enter the withdrawal amount)",
        available_withdraw: "Amount",
        label_transfer_bank: "Debit bank",
        label_security_psd: "Security code",
        place_security_psd: "Please enter the security code",
        place_nickname: "Nickname",
        message_nickname: "Please enter a nickname",
        place_phone: "Please enter your cell phone number",
        place_email: "Please enter your e-mail address",
        place_invite_id: "Please enter the invitation code",
        place_verify_code: "Please enter the verification code",
        send: "Dispatch",
        test_username: "Test User Name",
        place_amount: "Please enter the amount",
    },
    login: {
        login_tit: "User login",
        forget_psd: "Forgotten password?？",
        login: "Log in",
        register_tit: "User Registration",
        register: "Registered",
        have_account: "Existing account, log in",
        country_code: "Country code",
        phone: "Cell phone",
        email: "Email",
    },
    city: {
        albania: "Albania",
        algeria: "Algeria",
        argentina: "Argentina",
        armenia: "Armenia",
        australia: "Australia",
        pakistan: "Pakistan",
        austria: "Austria",
        bahrain: "Bahrain",
        belgium: "Belgium",
        bosnia_and_Herzegovina: "Bosnia and Herzegovina",
        brazil: "Brazil",
        brunei: "Brunei",
        bulgaria: "Bulgaria",
        cambodia: "Cambodia",
        canada: "Canada",
        cameroon: "Cameroon",
        chile: "Chile",
        colombia: "Colombia",
        costa_Rica: "Costa Rica",
        croatia: "Croatia",
        cyprus: "Cyprus",
        czech_Republic: "Czech Republic",
        denmark: "Denmark",
        dominican_Republic: "Dominican Republic",
        egypt: "Egypt",
        estonia: "Estonia",
        ethiopia: "Ethiopia",
        finland: "Finland",
        france: "France",
        georgia: "Georgia",
        germany: "Germany",
        ghana: "Ghana",
        greece: "Greece",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, China",
        hungary: "Hungary",
        iceland: "Iceland",
        ireland: "Ireland",
        italy: "Italy",
        india: "India",
        indonesia: "Indonesia",
        israel: "Israel",
        iran: "Iran",
        iraq: "Iraq",
        japan: "Japan",
        kazakstan: "Kazakhstan",
        kenya: "Kenya",
        korea: "Korea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kyrgyzstan",
        laos: "Laos",
        latvia: "Latvia",
        lithuania: "Lithuania",
        luxembourg: "Luxembourg",
        macao_China: "Macao_China",
        macedonia: "Macedonia",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Mexico",
        moldova: "Moldova",
        monaco: "Monaco",
        mongolia: "Mongolia",
        montenegro: "Montenegro",
        morocco: "Morocco",
        myanmar: "Myanmar",
        netherlands: "Netherlands",
        new_Zealand: " New_Zealand",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Norse",
        oman: "Oman",
        palestine: "Palestine",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "Philippines",
        poland: "Poland",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Qatar",
        romania: "Romania",
        russia: "Russia",
        republic_of_Trinidad_and_Tobago: "Republic of Trinidad and Tobago",
        rwanda: "Rwanda",
        saudi_Arabia: "Saudi Arabia",
        serbia: "Serbia",
        singapore: "Singapore",
        slovakia: "Slovakia",
        slovenia: "Slovenia",
        south_Africa: "South Africa",
        spain: "Spain",
        sri_Lanka: "Sri Lanka",
        sweden: "Sweden",
        switzerland: "Switzerland",
        taiwan_China: "Taiwan_China",
        tajikistan: "Tajikistan",
        tanzania: "Tanzania",
        thailand: "Thailand",
        turkey: "Turkey",
        turkmenistan: "Turkmenistan",
        ukraine: "Ukraine",
        united_Arab_Emirates: "United Arab Emirates",
        united_Kingdom: "United Kingdom",
        united_States: " United_States",
        uzbekistan: "Uzbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaijan",
        bangladesh: "Bangladesh",
        belarus: "Belarus",
        belize: "Belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bolivia",
        botswana: "Botswana",
        british_Virgin_Islands: "British Virgin Islands",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Cape Verde",
        cayman_Islands: "Cayman Islands",
        central_African_Republic: "Central African Republic",
        chad: "Chad",
        comoros: "Comoros",
        the_Republic_of_the_Congo: " The_Republic_of_the_Congo",
        democratic_Republic_of_the_Congo: "Democratic Republic of the Congo",
        djibouti: "Djibouti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Equatorial Guinea",
        eritrea: "Eritrea",
        fiji: "Fiji",
        gabon: "Gabon",
        gambia: "Gambia",
        greenland: "Greenland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: " Isle_of_Man",
        cote_d_Ivoire: "Cote_d_Ivoir",
        jamaica: "Jamaica",
        jordan: "Jordan",
        lebanon: "Lebanon",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Libya",
        madagascar: "Madagascar",
        malawi: "Malawi",
        maldives: "Maldives",
        mali: "Mali",
        mauritania: "Mauritania",
        mauritius: "Mauritius",
        mozambique: "Mozambique",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Republic_of_Niger",
        north_Korea: " North_Korea",
        reunion: "Reunion",
        san_Marino: "SAN Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "Sudan",
        suriname: "Suriname",
        eswatini: "Eswatini",
        syria: "Syria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisia",
        united_States_Virgin_Islands: "United States Virgin Islands",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatican",
        yemen: "Yemen",
        yugoslavia: "Yugoslavia",
        zambia: "Zambia",
        zimbabwe: "Zimbabwe",
        china: "China",
     }
};
