export default {
    common: {
        contactNow: "Contact Now",
        minPrice: "Min",
        maxPrice: "Max",
        address: {
            title: "Wallet address",
            account_number: "Account number",
            add_btn: "Add address",
            label_type: "Select type",
            place_type: "Please select type",
            label_number: "Account",
            place_number: "Please enter the account number",
            wallet_address: 'Wallet address',
            bank_name: 'BANK NAME',
            bank_address: "Bank address",
            name: "Name",
            payee_address: 'Beneficiary Address',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Branch Name",
            branch_code: "BRANCH CODE",
            bank_code: "Bank code",
            phone: "Cell-phone number",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Please enter content",
        },
        priceType: {
            fast_sum: "Somme",
            fast_three_all: "Trois Même nombre Élection générale",
            fast_three_one: "Trois Même nombre Choix unique",
            fast_three_diff: "Trois numéros différents",
            fast_three_continue: "Choix multiple de nombres triples (TNT) (informatique)",
            fast_two_some: "Indices doubles Choix multiples",
            fast_two_one: "Double Choix individuel",
            fast_two_diff: "Nombre bissecteur (électronique)",
            fast_one_one: "Lit. un seul tamis paie un pour un",
            racing_winner: "Champions",
            racing_second: "Deuxième place ",
            racing_third: "Médaillé de bronze",
            lotto_one: "A",
            lotto_two: "B",
            lotto_three: "C",
            lotto_four: "D",
            lotto_five: "E",
            wingo_sum: "Somme",
            wingo_color: "Color",
            Big: "Le plus ancien",
            Small: "Peu",
            Single: "Mono",
            Double: "Nom de famille Shuang",
            Red: "Nom de famille Shuang",
            Green: "Vert",
            Purple: "Lithospermum erythrorhizon ",
        },
        img_error: "L'image n'a pas pu être chargée",
        more: "Plus",
        cancel: "Annulations",
        confirm: "Définir",
        tips: "Indice：",
        no_set: "Non corrigé",
        submit: "Soumettre",
        copy_success: "Succès de copie！",
        copy_error: "Échec de la reproduction！",
        currency: "$",
        filter: "Dépistage",
        edit: "Modifications",
        gameStart: "Démarrer le jeu",
        prizenumber: "Numéro gagnant",
        mynumber: "Mon numéro.",
        addresss: "Voici l'adresse du lien",
        gameAgain: "Encore une fois.",
        gamerule: "Règles du jeu",
        winning_numbers: "Numéro gagnant",
        winning: "Félicitations pour votre victoire !！",
        notwin: "Malheureusement, vous n'avez pas gagné !",
        logout: "Êtes-vous sûr de vouloir vous déconnecter ?？",
        success: "Succès",
        search: "Rechercher qqch.",
        withdraw0: "Demande",
        withdraw1: "En cours",
        withdraw2: "Succès",
        withdraw3: "Échouer",
        balanceNot: "Solde du compte insuffisant",
        canceled: "Annulé",
        bill: {
            bill101: "Recharge de l'utilisateur",
            bill102: "Intégrateur de systèmes",
            bill103: "Gel du système",
            bill104: "Dégel du système",
            bill201: "Retraits des utilisateurs",
            bill202: "Déductions du système",
            bill203: "Retrait réussi",
            bill204: "Retrait Échec de l'audit",
            bill205: "Frais de retrait",
            bill301: "Participer au jeu",
            bill302: "Profits des jeux",
        }
    },
    playMethod: {
        p1: "Veuillez sélectionner les règles du jeu que vous souhaitez consulter",
        p2: "Si vous étudiez les règles, vous doublerez vos gains.",
        des1: "Bref",
        des2: "Si vous négociez pour 100, le montant du contrat après déduction des frais de service de 2 % : 98",
        fived: {
            p1: "Règles du jeu de la loterie 5D",
            p2: "Instructions de dessin",
            p3: "5 chiffres choisis au hasard (00000-99999) pour chaque numéro",
            p4: "Par exemple:",
            p5: "Le numéro de tirage actuel est 12345",
            p6: "A = 1",
            p7: "B = 2",
            p8: "C = 3",
            p9: "D = 4",
            p10: "E = 5",
            p11: "Et = A + B + C + D + E = 15",
            p12: "Comment jouer",
            p13: "Les joueurs peuvent parier sur les six résultats et les totaux de A, B, C, D et E.",
            p14: "A, B, C, D, E Peuvent être achetés",
            p15: "Chiffres (0 1 2 3 4 5 6 7 8 9)",
            p16: "Faible (0 1 2 3 4)",
            p17: "Haut (5 6 7 8 9)",
            p18: "Chiffres impairs (1 3 5 7 9)",
            p19: "Même (0 2 4 6 8)",
            p20: "Sum = A+B+C+D+E est disponible à l'achat",
            p21: "Faible (0-22)",
            p22: "Haut (23-45)",
            p23: "Nombres impairs (1 3---43 45)",
            p24: "Même (0 2---42 44)",
        },
        racing: {
            p1: "Gameplay",
            p2: "※Les trois premiers et：",
            p3: "La somme des trois premiers numéros de voiture est supérieure à 16 pour les grands, inférieure ou égale à 16 pour les petits. Les nombres pairs sont dits doubles, les nombres impairs sont dits simples. ※Les trois premières sommes :",
            p4: "※De la première à la troisième place：",
            p5: "Désignation du numéro de voiture, chaque numéro de voiture est une combinaison de pari, le résultat de la loterie « numéro de voiture de pari » correspondant au nom du pari est considéré comme gagnant, le reste de la situation est considéré comme non gagnant.",
            p6: "※Les deux côtés：",
            p7: "Se réfère à simple, double ; grand, petit。",
            p8: "※Simple et double：",
            p9: "Un nombre pair est appelé double, par exemple 2, 4, 6, 8, 10 ; un nombre impair est appelé simple, par exemple 1, 3, 5, 7, 9.",
            p10: "※Grand 、 Petit",
            p11: "Les nombres supérieurs ou égaux à 6 sont considérés comme grands, par exemple 6, 7, 8, 9, 10 ; les nombres inférieurs ou égaux à 5 sont considérés comme petits, par exemple 1, 2, 3, 4, 5.",
        },
        wingo: {
            p1: "Gameplay",
            p2: "※Chiffre unique：",
            p3: "Choisissez un numéro sur lequel parier, le numéro du tirage et le numéro du pari sont considérés comme gagnants, les autres cas sont considérés comme non gagnants.",
            p4: "※Rose：",
            p5: "Les paris sur le rouge sont considérés comme gagnants si les numéros de la loterie sont 0, 2, 4, 6, 8, et comme non gagnants pour les autres scénarios.",
            p6: "※Plus vert：",
            p7: "Si vous misez sur le vert, vous êtes considéré comme gagnant si les numéros tirés sont 1, 3, 5, 7, 9, et vous êtes considéré comme non gagnant pour les autres scénarios.",
            p8: "※Violet：",
            p9: "Si vous misez sur le violet, vous serez considéré comme gagnant si les numéros de la loterie sont 0 et 5, et comme non gagnant dans les autres cas.",
            p10: "※Grand、 Petit、：",
            p11: "Les nombres supérieurs ou égaux à 5 sont considérés comme grands, par exemple 5, 6, 7, 8, 9 ; les nombres inférieurs ou égaux à 4 sont considérés comme petits, par exemple 0, 1, 2, 3, 4.",
        },
        lottery: {
            p1: "Gameplay ~ 6 ouvertures aléatoires.",
            p2: "A：Grands et petits, simples et doubles",
            p3: "Taille : la somme des trois numéros de loterie compris entre 11 et 18 est considérée comme grande ; la somme des numéros compris entre 3 et 10 est considérée comme petite.",
            p4: "Simple et double : Si la somme totale des trois numéros de loterie est simple, le simple gagne, et si elle est double, le double gagne.",
            p5: "B：Vérifier les numéros",
            p6: "Si la valeur totale des numéros de la loterie est 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, vous gagnerez ; par exemple, si les numéros de la loterie sont 1, 2, 3, et que la valeur totale est 6, vous gagnerez en misant sur le « 6 ».",
            p7: "C：Un seul dé",
            p8: "Si l'un des trois numéros de la loterie est identique au numéro sélectionné, il est considéré comme gagnant. Exemple : si les numéros de la loterie sont 1, 1, 3, les paris sur le dé 1 ou le dé 3 seront considérés comme gagnants. (Quel que soit le nombre de fois que le nombre de points spécifié apparaît, le gain ne sera pas doublé une seule fois).",
            p9: "D：Double dé",
            p10: "Le prix sera gagné si deux des numéros du prix sont identiques et correspondent à la double combinaison de dés choisie. Exemple : si le numéro du lot est 1, 1, 3, le pari sur le double dé 1, 1, sera gagnant.",
            p11: "E：Round Robin, Full Round Robin",
            p12: "Dés : Le prix sera gagné si les trois numéros de la loterie sont identiques et correspondent à la combinaison de dés choisie.",
            p13: "Full Circle Dice : Un prix est gagné si les trois caractères des numéros de la loterie sont identiques.",
            p14: "F : Combinaisons à deux dés",
            p15: "Choisissez n'importe quelle combinaison de 2 dés, lorsque le résultat de la loterie est identique à la combinaison choisie, elle sera considérée comme gagnante. Exemple : si les numéros de la loterie sont 1, 2, 3, parier sur la combinaison de 2 dés 12, la combinaison de 2 dés 23, la combinaison de 2 dés 13 sera considéré comme gagnant.",
        }
    },
    webadd: {
        hot: "En vogue",
        all: "Complet",
        home: "Salon",
        hot_lottery: "Loterie populaire",
        select_channel: "Sélectionner le canal de recharge",
        select_bank: "Sélectionner une banque",
        top_tab1: "Gagner un prix",
        top_tab2: "TOP",
        top_tab3: "Mon",
        web_time: "Durée du site web",
        trade: "Transactions",
    },
    footer: {
        foot1: "Fig. début",
        foot2: "Jouer",
        foot3: "TOP",
        foot4: "Fonction",
        foot5: "Mon",
    },
    navBar: {
        back_home: "Retour à la page d'accueil",
        help_center: "Centre d'aide",
        contact_service: "Contacter le service clientèle",
        balance: "Soldes",
        my_account: "Mon compte",
        account: "Compte en ligne",
        record: "Record",
        proxy: "Proxy",
        other: "Autre",
        deposit: "Recharge",
        withdraw: "Retirer des fonds",
        deposit_type1: "Banque en ligne",
        deposit_type2: "Virement bancaire",
        deposit_type3: "Monnaie numérique",
        deposit_type4: "OVO",
        logout: "Annuler",
        login: "S'inscrire",
        register: "Inscription",
        nav1: "Billet de loterie",
        nav2: "Jouer",
        nav3: "Fonction",
        nav4: "Téléphones mobiles",
        nav5: "Mon compte",
    },
    activity: {
        tit1: "Bonus journalier",
        p1: "Le Jackpot quotidien est un bonus basé sur le montant que l'utilisateur a misé hier, sans limite de prix !",
        activity_detail: "Détails de l'événement",
        yestoday_bet: "Les paris d'hier",
        bonus_ratio: "Ratio d'augmentation du prix",
        now_level: "Niveau actuel",
        receive_bonus: "Éligibles à un crédit supplémentaire",
        receive: "Obtenir",
        not_receive: "Indisponible",
        activity_time: "Temps d'activité",
        p2: "1、Le bonus journalier peut être encaissé tous les jours à partir de 00h20.",
        p3: "2、Le pourcentage du bonus est basé sur le niveau de l'utilisateur et le montant des paris accumulés la veille pour un certain pourcentage du bonus !",
        p4: "3、Les retraits et autres paris non valides ne seront pas pris en compte.",
        p5: "4、Les mises valides doivent respecter la [date de règlement du tirage], si le tirage a lieu plusieurs jours après la mise, le montant de la mise valide du tirage sera cumulé à la [date de règlement du tirage].",
        p6: "5、Exemple : pari 10/1 mais 10/15 seulement un match nul, alors le montant effectif de l'ordre de pari sera accumulé dans 10/15, l'utilisateur peut être 10/16 temps pour collecter l'ordre de pari du bonus.",
        p7: "6、La prime d'activité n'est pas perçue avant 24 heures le jour de l'activité, ce qui constitue une renonciation automatique à la qualification de l'activité.",
    },
    game: {
        history_tit: "Prix historiques",
        play_tit: "Instructions pour jouer",
        official_award: "Le prix officiel original",
        betting: "Pari en cours",
        waiting: "En attendant la loterie",
        opening: "Dessin en cours.",
        settled: "Réglée",
        auto_change: "Commutation automatique",
        height_win: "Gain maximum",
        total: "En résumé",
        pour: "Pour",
        every_pour: "Chacun des",
        total_pour: "Nombre total de paris",
        total_amount: "Total des mises",
        now_bet: "Parier maintenant",
        my_bet: "Mes paris",
        winning: "Gagner un prix",
        top: "TOP",
        winning_tip: "Les informations sur les gagnants sont mises à jour en temps réel",
        top_tip: "Haut du classement",
    },
    record: {
        lottery: "Billet de loterie",
        game: "Jouer",
        today_profit_loss: "Profits et pertes du jour",
        label_formula: "Formule du seuil de rentabilité",
        formula: "Formule : Gagner - Mise + Activité + Ristournes",
        profitLoss_label1: "Gagner un prix",
        profitLoss_label2: "Fonction",
        profitLoss_label3: "Parieurs",
        profitLoss_label4: "Récompense des affiliés",
        profitLoss_label5: "Transfert",
        profitLoss_label6: "Bénéficiaire",
        profitLoss_label7: "Envoi d'un paquet rouge",
        profitLoss_label8: "Recevoir un paquet rouge",
        trade_tab1: "Complet",
        trade_tab2: "Recharge",
        trade_tab3: "Retirer des fonds",
    },
    table: {
        label_no: " No.",
        label_time: "Fois",
        label_result: "À la fin",
        label_size: "Ampleur",
        label_single_double: "Pair et impair",
        label_sun_value: "Somme",
        no_data: "Pas de données disponibles",
        big: "Le plus ancien",
        small: "Peu",
        single: "Mono",
        double: "Nom de famille Shuang",
        label_issue: "Numéro d'édition",
        label_bet_amount: "Montant des paris",
        label_reward: "L'argent des bourses",
        no_reward: "Pas un vainqueur",
        is_reward: "Gagner un prix",
        label_level: "Hiérarchie",
        label_integral: "Points de croissance",
        label_award: "Récompenses d'avancement",
        label_skip_awardl: "Bonus de saut de niveau",
        label_register_link: "Lien d'inscription",
        label_remark: "Note",
        label_rise_time: "Temps de génération",
        label_register_number: "Nombre d'inscriptions",
        label_operate: "Gréement",
        label_gametit: "Couleurs/jeux",
        label_lower_rebate: "Récompense des affiliés",
        label_self_rebate: "Propre remboursement",
        label_set_rebate_range: "Fourchette de remboursement",
        bonus_state: "Statut de bonus",
        label_serial_number: "Signal d'embuscade ",
        label_income_amount: "Montant des revenus",
        label_expend_amount: "Montant des dépenses",
        label_available_balance: "Solde disponible",
        label_abstract: "Résumés",
        label_details: "Particularités",
        label_account: "Compte en ligne",
        label_amount: "Somme d'argent",
        label_bet_cont: "Contenu des paris",
        label_ottery_number: "Numéro de loterie",
        order_number: "Nombre impair",
        play_methods: "Façon de jouer",
        label_periods: "Phase",
        label_number: "Chiffres",
        label_color: "Couleur",
    },
    my: {
        last_login_time: "Dernière heure de connexion",
        logout_account: "Retrait du compte",
        my_account: "Mon compte",
        account: "Compte en ligne",
        personal_tit: "Profil personnel",
        security_tit: "Centre de sécurité",
        bank_tit: "Numéro de compte bancaire",
        record: "Record ",
        profit_loss_tit: "Profits et pertes du jour",
        trade_record_tit: "Registres des transactions",
        bet_record: "Records de paris",
        proxy: "Proxy",
        manag_tit: "Gestion de l'agence",
        report_tit: "Les déclarations de sollicitation de procurations",
        lower_bet_tit: "Niveau inférieur de paris",
        lower_trade_tit: "Transactions subordonnées",
        other: "Autre",
        gift_tit: "Cadeaux d'événements",
        message_tit: "Centre d'information",
        personal_data: "Profil personnel",
        balance: "Soldes",
        last_sync_time: "Dernière heure de synchronisation",
        deposit: "Recharge",
        withdraw: "Retirer des fonds",
        yesterday_deposit_time: "Temps moyen pour faire le plein hier",
        yesterday_withdraw_time: "Temps moyen pour retirer de l'argent hier",
        help_tit: "Centre d'aide",
        all: "Complet",
        lower_report: "Déclarations subordonnées",
        about_us: "À propos de nous",
        logout: "Paraître",
        invite: "Inviter des amis",
        lang_change: "Changement de langue",
    },
    account: {
        add_bank_account: "Ajouter le numéro de compte bancaire",
        bank_tip: "Il est possible d'ajouter jusqu'à 5 comptes bancaires. Les comptes bancaires qui ont été retirés avec succès sont automatiquement verrouillés et ne peuvent être ni supprimés ni modifiés. Les numéros de comptes bancaires qui n'ont pas été retirés avec succès peuvent être modifiés mais ne peuvent pas être supprimés.",
        account_msg: "Informations sur le compte",
        growth_value: "Taux de croissance",
        noset_nickname: "Surnom non défini",
        nationality: "La citoyenneté",
        next_level: "Prochain niveau à franchir",
        integral: "Integral",
        integral_tip: "Pour chaque 1,00 $ que vous rechargez, vous obtenez 1 point.",
        level_system: "Hiérarchie",
        change_avatar: "Changer d'avatar",
        loginpsd_tip: "Mot de passe de connexion 6 à 16 chiffres combinaison de lettres et de chiffres",
        modify_psd: "Modifier votre mot de passe",
        securitypsd_tip: "Mot de passe de sécurité (combinaison de 6 chiffres)",
        forget_securitypsd: "Code de sécurité oublié",
        confidentiality_tip: "Récupérer les informations du compte de l'utilisateur en cas de problèmes de sécurité, de perte du numéro de compte ou du mot de passe.",
        bank_account: "Numéro de compte bancaire",
        edit_nickname: "Modifier le surnom",
        confidentiality: "Questions de sécurité",
        accumulated_winning: "Jackpot progressif",
    },
    agent: {
        illustrate_p1: "Lorsque vous voyez cette page, cela signifie que votre compte est à la fois un compte de joueur et un compte d'agent, ce qui vous permet de placer vos propres paris ainsi que de développer des joueurs de niveau inférieur et de gagner des commissions de ristourne.",
        illustrate_p2: "Comment obtenir des remises？",
        illustrate_p3: "Le rabais que vous pouvez obtenir est égal à la différence entre votre propre rabais et le rabais du niveau inférieur, par exemple votre propre rabais de 5 et le rabais du niveau inférieur de 3, vous pourrez obtenir le rabais du montant du pari du niveau inférieur de 2, par exemple le pari du niveau inférieur sur 100 $, vous obtiendrez 2 $.",
        illustrate_p4: "Comment ouvrir un compte pour un subordonné ?？",
        illustrate_p5: "Cliquez sur le compte subordonné, configurez d'abord les remises pour vos subordonnés, configurez un code d'invitation réussi sera généré, le code d'invitation sera envoyé à l'enregistrement de votre subordonné, l'enregistrement, il est votre subordonné, cliquez sur la gestion de l'utilisateur, vous pouvez voir son compte enregistré.",
        illustrate_p6: "Si vous créez un compte de type agent pour vos subordonnés, ceux-ci pourront continuer à développer leurs subordonnés. Si vous créez un compte de type joueur, vos subordonnés ne pourront que placer des paris et ne pourront pas développer leurs subordonnés et ne pourront pas voir le centre d'agents. Gestion des utilisateurs",
        illustrate_tip1: "1、Les cotes des remises sont différentes, cliquez sur le tableau des cotes des remises, vous pourrez voir les cotes des remises.。",
        illustrate_tip2: "2、Plus le rabais est faible, plus les chances sont faibles. Il est donc recommandé de ne pas fixer un rabais trop faible pour le niveau inférieur !。",
        illustrate_tip3: "3、L'évolution des agents peut être consultée dans les rapports sur les agents, les paris à la baisse et les transactions à la baisse.",
        illustrate_tip4: "4、Il est recommandé que l'ouverture du niveau suivant soit également le type d'agent, quel que soit le nombre de niveaux développés, vous recevrez des rabais.。",
        open_type: "Type d'ouverture de compte：",
        open_type1: "Complet",
        open_type2: "Position",
        open_type3: "Joueur",
        invite_tip1: "1、Les chances varient en fonction des rabais, mais plus le rabais est élevé, plus les chances le sont aussi !",
        invite_tip2: "2、L'agent peut obtenir une commission égale à sa propre ristourne et à la différence entre la ristourne du niveau inférieur, par exemple la ristourne de l'agent 6, la ristourne du niveau inférieur 4, l'agent peut obtenir la ristourne du niveau inférieur à hauteur de 2 % du montant des paris, c'est-à-dire que pour un niveau de paris inférieur à 100 yuans, l'agent peut obtenir 2 yuans.",
        invite_tip3: "3、Plus la valeur du rabais est basse, plus la cote est basse. Il est recommandé de ne pas fixer un niveau de rabais trop bas.",
        rebateDialog_tit: "Voir les rabais",
        delete_invite_tip: "Êtes-vous sûr de vouloir supprimer ce code d'invitation ?？",
        rebate_table: "Tableau des chances de remboursement",
        generate_invitation_code: "Générer le code d'invitation",
        subordinate_tip1: "1、Les chances varient en fonction des rabais, mais plus le rabais est élevé, plus les chances le sont aussi !。",
        subordinate_tip2: "2、L'agent peut obtenir une commission égale à sa propre ristourne et à la différence entre la ristourne du niveau inférieur, par exemple la ristourne de l'agent 6, la ristourne du niveau inférieur 4, l'agent peut obtenir la ristourne du niveau inférieur à hauteur de 2 % du montant des paris, c'est-à-dire que pour un niveau de paris inférieur à 100 yuans, l'agent peut obtenir 2 yuans.",
        subordinate_tip3: "3、Plus la valeur du rabais est basse, plus la cote est basse. Il est recommandé de ne pas fixer un niveau de rabais trop bas.",
        manage_tab1: "Description de l'agent",
        manage_tab2: "Ouverture d'un compte subordonné",
        manage_tab3: "Code d'invitation",
        manage_tab4: "Gestion des utilisateurs",
        lower_bet_tip: "Les relevés de transactions sont conservés pendant 21 jours au maximum.",
        report_label1: "Parieurs",
        report_label2: "Gagner un prix",
        report_label3: "Fonction",
        report_label4: "Rabais d'équipe",
        report_label5: "Profit de l'équipe",
        report_label6: "Nombre de parieurs",
        report_label7: "Numéro de la première charge",
        report_label8: "Nombre d'inscrits",
        report_label9: "Nombre de subordonnés",
        report_label10: "Équilibre de l'équipe",
        report_label11: "Recharge",
        report_label12: "Retirer des fonds",
        report_label13: "Remise à l'agent",
        report_label14: "Transfert",
        report_label15: "Bénéficiaire",
        report_label16: "Envoi d'un paquet rouge",
        report_label17: "Recevoir un paquet rouge",
    },
    fund: {
        tip1: "Veuillez effectuer un virement sur le compte bancaire suivant",
        tip2: "Veuillez remplir soigneusement les informations relatives à votre transfert",
        tip3: "(Veillez à effectuer le virement avant de soumettre la commande, sinon vous ne pourrez pas vérifier votre paiement à temps !)",
        deposit_rate: "Taux de change des dépôts",
        currency_number: "Montant de la monnaie",
        tip4: "Veuillez utiliser la plateforme de monnaie numérique correspondante pour transférer de l'argent",
        tip5: "Limite de la course unique",
        tip6: "(Si vous n'êtes pas en mesure de remplir les informations d'authentification, veuillez télécharger une capture d'écran du reçu de la transaction).",
        click_upload: "Cliquez pour télécharger",
        upload_tip: "Seuls les fichiers jpg/png peuvent être téléchargés.",
        receiving_address: "Adresse du bénéficiaire",
        tip7: "Veuillez utiliser OVO pour transférer des fonds vers les comptes bancaires suivants",
        tip8: "1、La banque en ligne est nécessaire pour effectuer le paiement。",
        tip9: "2、Une fois la recharge effectuée, cliquez sur 'Return to Merchant' 'pour être automatiquement crédité.",
    },
    other: {
        gift_tip1: "1、Les cadeaux ne peuvent être réclamés qu'une fois que les conditions d'obtention sont remplies。",
        gift_tip2: "2、Les cadeaux non réclamés expirent automatiquement après la date d'expiration et ne peuvent pas être réclamés.。",
        other_tip1: "Les lettres sont conservées pendant un maximum de 7 jours ;",
    },
    form: {
        input_inner: "Veuillez saisir le contenu",
        label_username: "Compte d'utilisateur：",
        place_username: "Veuillez saisir votre compte d'utilisateur",
        label_loginpsd: "Mot de passe de connexion：",
        place_loginpsd: "Veuillez saisir votre mot de passe de connexion",
        label_invitecode: "Code d'invitation：",
        place_invitecode: "Veuillez remplir le code d'invitation à 8 chiffres",
        label_oldpsd: "Mot de passe original",
        place_oldpsd: "Veuillez saisir le mot de passe original",
        label_newpsd: "Nouveau mot de passe",
        place_newpsd: "Veuillez saisir un nouveau mot de passe",
        label_crmpsd: "Confirmer le mot de passe",
        place_crmpsd: "Veuillez saisir à nouveau le nouveau mot de passe",
        label_remark: "Note",
        place_remark: "Veuillez saisir une note",
        label_lower_account: "Nom d'utilisateur：",
        place_lower_account: "Veuillez saisir le numéro de compte subordonné",
        label_type: "Typologie：",
        place_select: "Veuillez sélectionner",
        label_lottery_title: "Prix de loterie：",
        label_state: "État des lieux：",
        option_state1: "Complet",
        option_state2: "Loterie en cours",
        option_state3: "Pas un vainqueur",
        option_state4: "Ont gagné un prix",
        label_time: "Fois：",
        times1: "À l'heure actuelle",
        times2: "Hier",
        times3: "Le mois en cours",
        times4: "Ultimo",
        label_realname: "Nom et prénom",
        place_realname: "Veuillez saisir votre nom réel",
        label_bank_number: "Numéro de la carte ATM",
        place_bank_number: "Veuillez saisir votre numéro de carte bancaire",
        label_bank_name: "Nom de la banque",
        place_bank_name: "Veuillez sélectionner le nom de la banque",
        label_abstract: "Résumés:",
        label_channel: "Canal de recharge",
        place_channel: "Veuillez sélectionner le canal de recharge",
        label_deposit_amount: "Montant complémentaire",
        place_deposit_amount: "Veuillez saisir le montant du complément",
        label_holder: "Titulaire du compte",
        label_deposit_name: "Nom du déposant",
        place_deposit_name: "Veuillez saisir le nom du déposant",
        label_transfer_msg: "Informations sur les transferts",
        place_transfer_msg: "Veuillez remplir les informations relatives au transfert",
        label_transfer_pic: "Capture d'écran du reçu de la transaction",
        label_transfer_pic_tip: "(Veuillez télécharger une capture d'écran de votre reçu de transaction)",
        label_bank_account_name: "Nom du compte bancaire",
        label_balance: "Soldes",
        label_available_withdraw: "Montant retirable",
        label_available_number: "Nombre de retraits",
        label_withdraw_amount: "Montant du retrait",
        placce_withdraw_amount: "Veuillez saisir le montant du retrait)",
        available_withdraw: "Désirabilité",
        label_transfer_bank: "Banque de débit",
        label_security_psd: "Code de sécurité",
        place_security_psd: "Veuillez saisir le code de sécurité",
        place_nickname: "Terme affectueux",
        message_nickname: "Veuillez saisir un surnom",
        place_phone: "Veuillez saisir votre numéro de téléphone mobile",
        place_email: "Veuillez saisir votre adresse électronique",
        place_invite_id: "Veuillez saisir le code d'invitation",
        place_verify_code: "Veuillez saisir le code de vérification",
        send: "Envoi",
        test_username: "Nom de l'utilisateur du test",
        place_amount: "Veuillez saisir le montant",
    },
    login: {
        login_tit: "Login de l'utilisateur",
        forget_psd: "Mot de passe oublié ?？",
        login: "S'inscrire",
        register_tit: "Enregistrement de l'utilisateur",
        register: "Inscription",
        have_account: "Vous avez déjà un compte, connectez-vous",
        country_code: "Code pays",
        phone: "Téléphones mobiles",
        email: "Boîte de réception",
    },
    city: {
		albania: "Albanie",
		algeria: "Algérie",
		argentina: "Argentine",
		armenia: "Arménie",
		australia: "Australie",
		pakistan: "Pakistan",
		austria: "Autriche",
		bahrain: "Bahreïn",
		belgium: "Belgique",
		bosnia_and_Herzegovina: "Bosnie _ Herzégovine",
		brazil: "Brésil",
		brunei: "Brunei",
		bulgaria: "Bulgarie",
		cambodia: "Cambodge",
		canada: "Au Canada",
		cameroon: "Cameroun",
		chile: "Chili",
		colombia: "Colombie",
		costa_Rica: "Costa Rica",
		croatia: "Croatie",
		cyprus: "Chypre",
		czech_Republic: "République tchèque",
		denmark: "Danemark",
		dominican_Republic: "République dominicaine",
		egypt: "Égypte",
		estonia: "Estonie",
		ethiopia: "Éthiopie",
		finland: "Finlande",
		france: "France",
		georgia: "Géorgie",
		germany: "Allemagne",
		ghana: "Ghana",
		greece: "Grèce",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, Chine",
		hungary: "Hongrie",
		iceland: "Islande",
		ireland: "Irlande",
		italy: "Italie",
		india: "Inde",
		indonesia: "Indonésie",
		israel: "Israël",
		iran: "Iran",
		iraq: "Irak",
		japan: "Japon",
		kazakstan: "Kazakhstan",
		kenya: "Kenya",
		korea: "Corée du Sud",
		kuwait: "Koweït",
		kyrgyzstan: "Kirghizistan",
		laos: "Laos",
		latvia: "Lettonie",
		lithuania: "Lituanie",
		luxembourg: "Luxembourg",
		macao_China: "Macao, Chine",
		macedonia: "Macédoine",
		malaysia: "Malaisie",
		malta: "Malte",
		mexico: "Mexique",
		moldova: "Moldavie",
		monaco: "Monaco",
		mongolia: "Mongolie",
		montenegro: "République du Monténégro",
		morocco: "Maroc",
		myanmar: "Myanmar",
		netherlands: "Pays _ Ba",
		new_Zealand: "Nouvelle _ Zélande",
		nepal: "Népal",
		nigeria: "Nigéria",
		norway: "Norvège",
		oman: "Oman",
		palestine: "Palestine",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Pérou",
		philippines: "Philippines",
		poland: "Pologne",
		portugal: "Portugal",
		puerto_Rico: "Porto Rico",
		qatar: "Qatar",
		romania: "Roumanie",
		russia: "Russie",
		republic_of_Trinidad_and_Tobago: "République de Trinité _ et _ Tobago",
		rwanda: "Rwanda",
		saudi_Arabia: "Arabie saoudite",
		serbia: "Serbie",
		singapore: "Singapour",
		slovakia: "Slovaquie",
		slovenia: "Slovénie",
		south_Africa: "Afrique du Sud",
		spain: "Espagne",
		sri_Lanka: "Sri Lanka",
		sweden: "Suède",
		switzerland: "Suisse",
		taiwan_China: "Taïwan, Chine",
		tajikistan: "Tadjikistan",
		tanzania: "Tanzanie",
		thailand: "Thaïlande",
		turkey: "Turquie",
		turkmenistan: "Turkménistan",
		ukraine: "Ukraine",
		united_Arab_Emirates: "Émirats arabes unis",
		united_Kingdom: "Royaume _ Uni",
		united_States: "États _ Unis",
		uzbekistan: "Ouzbékistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaïdjan",
		bangladesh: "Bangladesh",
		belarus: "Biélorussie",
		belize: "Belize",
		benin: "Bénin",
		bhutan: "Bhoutan",
		bolivia: "Bolivie",
		botswana: "Botswana",
		british_Virgin_Islands: "Îles Vierges britanniques",
		burkina_Faso: "Burkina Faso",
		burundi: "Burundi",
		cape_Verde: "Cap _ Vert",
		cayman_Islands: "Îles Caïmans",
		central_African_Republic: "République centrafricaine",
		chad: "Tchad",
		comoros: "Comores",
		the_Republic_of_the_Congo: "Congo (Bu)",
		democratic_Republic_of_the_Congo: "Congo (or)",
		djibouti: "Djibouti",
		ecuador: "Équateur",
		el_Salvador: "Salvador",
		equatorial_Guinea: "Guinée équatoriale",
		eritrea: "Érythrée",
		fiji: "Fidji",
		gabon: "Gabon",
		gambia: "Gambie",
		greenland: "Groenland",
		guatemala: "Guatemala",
		guinea: "Guinée",
		haiti: "Haïti",
		isle_of_Man: "Île de Man",
		cote_d_Ivoire: "Côte d'Ivoire",
		jamaica: "Jamaïque",
		jordan: "Jordanie",
		lebanon: "Liban",
		lesotho: "Lesotho",
		liberia: "Libéria",
		libya: "Libye",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldives",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Maurice",
		mozambique: "Mozambique",
		namibia: "Namibie",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger",
		north_Korea: "Corée du Nord",
		reunion: "La réunion",
		san_Marino: "Saint _ Marin",
		senegal: "Sénégal",
		sierra_Leone: "Sierra Leone",
		somalia: "Somalie",
		sudan: "Soudan",
		suriname: "Suriname",
		eswatini: "Swaziland",
		syria: "Syrie",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisie",
		united_States_Virgin_Islands: "Îles Vierges américaines",
		uganda: "Ouganda",
		uruguay: "Uruguay",
		vatican: "Le Vatican",
		yemen: "Yémen",
		yugoslavia: "Yougoslavie",
		zambia: "Zambie",
		zimbabwe: "Zimbabwe",
		china: "Chine",
	}
};
