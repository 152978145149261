export default {
    common: {
        contactNow: "Contact Now",
        minPrice: "Min",
        maxPrice: "Max",
        address: {
            title: "ที่อยู่การถอนเงิน",
            account_number: "หมายเลขบัญชี",
            add_btn: "เพิ่มที่อยู่",
            label_type: "เลือกประเภท",
            place_type: "กรุณาเลือกประเภท",
            label_number: "หมายเลขบัญชี",
            place_number: "กรุณากรอกหมายเลขบัญชี",
            wallet_address: 'ที่อยู่กระเป๋าสตางค์',
            bank_name: 'ชื่อธนาคาร',
            bank_address: "ที่อยู่ธนาคาร",
            name: "ชื่อ-นามสกุล",
            payee_address: 'ที่อยู่ผู้รับเงิน',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "ชื่อสาขา",
            branch_code: "รหัสสาขา",
            bank_code: "รหัสธนาคาร",
            phone: "หมายเลขโทรศัพท์มือถือ",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "โปรดป้อนเนื้อหา",
        },
        priceType: {
            fast_sum: "และค่า",
            fast_three_all: "Santong ผ่านการเลือกตั้ง",
            fast_three_one: "เขตเลือกตั้งที่ 3 ตำบลสันถง",
            fast_three_diff: "สามหมายเลขที่แตกต่างกัน",
            fast_three_continue: "สามแถวผ่านการเลือก",
            fast_two_some: "เลือกเบอร์ 2",
            fast_two_one: "ผู้สมัครรับเลือกตั้งหมายเลข 2",
            fast_two_diff: "หมายเลขที่แตกต่างกันสอง",
            fast_one_one: "หน้าจอเดี่ยวหนึ่งสำหรับหนึ่ง",
            racing_winner: "แชมป์",
            racing_second: "รองชนะเลิศ",
            racing_third: "กองทัพภาคที่ 3",
            lotto_one: "A",
            lotto_two: "B",
            lotto_three: "C",
            lotto_four: "D",
            lotto_five: "E",
            wingo_sum: "합계",
            wingo_color: "Color",
            Big: "ใหญ่",
            Small: "เล็ก",
            Single: "เดี่ยว",
            Double: "คู่",
            Red: "สีแดง",
            Green: "สีเขียว",
            Purple: "สีม่วง",
        },
        img_error: "การโหลดรูปภาพล้มเหลว",
        more: "มากกว่า",
        cancel: "การยกเลิก",
        confirm: "ระบุ",
        tips: "เคล็ดลับที่อบอุ่น：",
        no_set: "ยังไม่ได้ตั้งค่า",
        submit: "ส่ง",
        copy_success: "คัดลอกสำเร็จ！",
        copy_error: "การคัดลอกล้มเหลว！",
        currency: "$",
        filter: "การคัดกรอง",
        edit: "การปรับเปลี่ยน",
        gameStart: "เริ่มเกม",
        prizenumber: "หมายเลขที่ชนะ",
        mynumber: "เบอร์ของฉัน",
        addresss: "นี่คือที่อยู่เชื่อมโยง",
        gameAgain: "อีกครั้ง",
        gamerule: "กฎของเกม",
        winning_numbers: "หมายเลขที่ชนะ",
        winning: "ขอแสดงความยินดีที่คุณได้รับรางวัล！",
        notwin: "น่าเสียดายที่คุณไม่ได้รับรางวัล!",
        logout: "แน่ใจว่าคุณต้องการออกจากการเข้าสู่ระบบ？",
        success: "ความสำเร็จ",
        search: "ค้นหา",
        withdraw0: "ใบสมัคร",
        withdraw1: "ในเส้นผมล่าง",
        withdraw2: "ความสำเร็จ",
        withdraw3: "ล้มเหลว",
        balanceNot: "ยอดเงินในบัญชีไม่เพียงพอ",
        canceled: "ยกเลิกแล้ว",
        bill: {
            bill101: "การเติมเงินผู้ใช้",
            bill102: "การเพิ่มระบบ",
            bill103: "การแช่แข็งระบบ",
            bill104: "ระบบละลายน้ำแข็ง",
            bill201: "การถอนเงินของผู้ใช้",
            bill202: "การหักลดหย่อนระบบ",
            bill203: "ถอนเงินสำเร็จ",
            bill204: "การตรวจสอบการถอนเงินล้มเหลว",
            bill205: "ค่าธรรมเนียมการถอนเงิน",
            bill301: "เข้าร่วมเกม",
            bill302: "กำไรจากเกม",
        }
    },
    playMethod: {
        p1: "โปรดเลือกกฎของเกมเพื่อดู",
        p2: "กฎการศึกษาอย่างละเอียดจะเพิ่มรายได้เป็นสองเท่า",
        des1: "บทนำ",
        des2: "หากคุณใช้จ่าย 100 รายการหลังจากหักค่าบริการ 2% จำนวนของสัญญา: 98",
        fived: {
            p1: "กฎของเกมหวย 5D",
            p2: "วาดคำแนะนำ",
            p3: "สุ่มเลข 5 หลัก (000000-99999) ทุกงวด",
            p4: "ตัวอย่างเช่น:",
            p5: "งวดนี้จับสลากหมายเลข 12345",
            p6: "A = 1",
            p7: "B = 2",
            p8: "C = 3",
            p9: "D = 4",
            p10: "E = 5",
            p11: "และ= A + B + C + D + E = 15",
            p12: "วิธีการเล่น",
            p13: "ผู้เล่นสามารถระบุผลการเดิมพันและผลรวมของหกประเภทของการเดิมพัน A, B, C, D, E",
            p14: "A, B, C, D, E สามารถซื้อได้",
            p15: "ตัวเลข(0 1 2 3 4 5 6 7 8 9)",
            p16: "ต่ำ (0 1 2 3 4)",
            p17: "สูง (5 6 7 8 9)",
            p18: "เลขคี่ (1 3 5 7 9)",
            p19: "เลขคู่ (0 2 4 6 8)",
            p20: "Sum = A + B + C + D + E สามารถซื้อได้",
            p21: "ต่ำ (0-22)",
            p22: "สูง (23-45)",
            p23: "เลขคี่ ( 1 3 · 43 45 )",
            p24: "เลขคู่ (0 2 · · 42 44)",
        },
        racing: {
            p1: "การเล่นเกม",
            p2: "※สามอันดับแรก：",
            p3: "เลขรถ 3 อันดับแรกรวมกัน และค่าที่มากกว่า 16 นั้นใหญ่ น้อยกว่าหรือเท่ากับ 16 คือเล็ก และค่าเป็นเลขคู่เรียกว่าคู่เป็นเลขคี่เรียกว่าเดี่ยว ※ สามอันดับแรก:",
            p4: "※อันดับที่ 1-3：",
            p5: "หมายเลขรถถูกระบุ หมายเลขรถแต่ละใบเป็นค่าผสมการเดิมพัน ผลการออกรางวัล 'หมายเลขรถเดิมพัน' จะถือว่าชนะตามจำนวนที่วางไว้ ส่วนที่เหลือจะถือว่าไม่ชนะ",
            p6: "※สองด้าน：",
            p7: "หมายถึงเดี่ยวคู่ ใหญ่ เล็ก",
            p8: "※เดี่ยวคู่：",
            p9: "ตัวเลขเป็นเลขคู่เรียกว่าเลขคู่ เช่น 2, 4, 6, 8, 10 ตัวเลขเป็นเอกพจน์เรียกว่าใบเดียว เช่น 1, 3, 5, 7, 9",
            p10: "※ใหญ่ เล็ก",
            p11: "หมายเลขที่ออกมากกว่าหรือเท่ากับ 6 มีขนาดใหญ่เช่น 6, 7, 8, 9, 10; น้อยกว่าหรือเท่ากับ 5 มีขนาดเล็ก เช่น 1, 2, 3, 4, 5",
        },
        wingo: {
            p1: "การเล่นเกม",
            p2: "※หมายเลขเดี่ยว：",
            p3: "เลือกหมายเลขสำหรับการเดิมพัน หมายเลขที่ออกจะถือว่าชนะเช่นเดียวกับหมายเลขการเดิมพัน ส่วนที่เหลือจะถือว่าไม่ชนะ",
            p4: "※สีแดง：",
            p5: "เดิมพันสีแดง หากหมายเลขที่ออกเป็น 0, 2, 4, 6, 8 จะถือว่าชนะ ส่วนที่เหลือจะถือว่าไม่ชนะ",
            p6: "※สีเขียว：",
            p7: "เดิมพันสีเขียว หากหมายเลขออกรางวัลคือ 1, 3, 5, 7, 9 จะถือว่าชนะ ส่วนที่เหลือจะถือว่าไม่ชนะ",
            p8: "※สีม่วง：",
            p9: "เดิมพันสีม่วง หากหมายเลขที่ออกเป็น 0, 5 ถือว่าชนะ ส่วนที่เหลือถือว่าไม่ชนะ",
            p10: "※ใหญ่ เล็ก：",
            p11: "ตัวเลขที่ออกรางวัลมากกว่าหรือเท่ากับ 5 มีขนาดใหญ่ เช่น 5, 6, 7, 8, 9 น้อยกว่า เท่ากับ 4 มีขนาดเล็ก เช่น 0, 1, 2, 3, 4",
        },
        lottery: {
            p1: "การเล่นเกม ~ 6 เปิดแบบสุ่ม",
            p2: "A：ขนาด เดี่ยว คู่",
            p3: "ขนาด: ผลรวมของหมายเลขที่ออกรางวัลสามตัว 11 ~ 18 มีขนาดใหญ่ ผลรวม 3 ~ 10 มีขนาดเล็ก",
            p4: "เดี่ยวและคู่: ผลรวมของหมายเลขที่ออกรางวัลทั้งสามเป็นเลขเดี่ยว จากนั้นชนะเดี่ยวเป็นเลขคู่ จากนั้นชนะคู่",
            p5: "B：คะแนน",
            p6: "ผลรวมของหมายเลขที่ออกรางวัลคือ 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18 นั่นคือการชนะ ตัวอย่าง: ถ้าหมายเลขที่ออกคือ 1, 2, 3 และมูลค่ารวมเป็น 6 การเดิมพัน '6' คือการชนะ",
            p7: "C：ลูกเต๋าเดี่ยว",
            p8: "เมื่อหนึ่งในสามหมายเลขที่ออกรางวัลเหมือนกับหมายเลขที่เลือกจะถูกรางวัล ตัวอย่าง:การเดิมพันลูกเต๋าลูกเต๋าลูกเต๋าลูกเต๋าลูกเต๋าลูกเต๋าลูกเต๋าลูกเต๋าลูกเต๋าลูกเต๋าลูกเดียวลูกเต๋าลูกเต๋าลูกเต๋าลูกเต๋าลูกเต๋าลูกเต๋าลูกเต๋าลูกเต๋าลูกเต๋าลูกเต๋าลูกเต๋าลูกเต๋าลูกเต๋าลูกเต๋าลูกเต๋าลูกเ (ไม่ว่าคะแนนที่ทางการกําหนดจะปรากฏกี่ครั้ง ส่งสีเพียงครั้งเดียวจะไม่เพิ่มขึ้นเป็นสองเท่า)",
            p9: "D：ลูกเต๋าคู่",
            p10: "หมายเลขรางวัลจะถูกรางวัลเมื่อคําสองคํามีหมายเลขเดียวกันและตรงกับลูกเต๋าคู่ที่เลือก ตัวอย่าง: ถ้าหมายเลขที่ออกคือ 1, 1, 3 การเดิมพันลูกเต๋าคู่ 1, 1 นั่นคือการชนะ",
            p11: "E: ลูกเต๋ารอบ, ลูกเต๋ารอบทั้งหมด",
            p12: "ลูกเต๋า: การชนะเมื่อหมายเลขที่ออกรางวัลมีหมายเลขเดียวกันสามตัวและตรงกับลูกเต๋าที่เลือก",
            p13: "ลูกเต๋ารอบทั้งหมด: หมายเลขที่ออกเป็นตัวเลขเดียวกันสามตัวคือชนะ",
            p14: "F：ชุดลูกเต๋า",
            p15: "เลือกชุดลูกเต๋าหนึ่งหรือสองครั้งเมื่อผลการออกรางวัลเท่ากับ 2 หลาพร้อมกับชุดที่เลือกนั่นคือการชนะ ตัวอย่าง: เช่น หมายเลขที่ออกคือ 1, 2, 3 การเดิมพันลูกเต๋าคอมโบ 12, ลูกเต๋าคอมโบ 23, ลูกเต๋าคอมโบ 13 ถือว่าเป็นการชนะ",
        }
    },
    webadd: {
        hot: "ร้อน",
        all: "ทั้งหมด",
        home: "ล็อบบี้",
        hot_lottery: "ลอตเตอรี่ยอดนิยม",
        select_channel: "เลือกช่องทางการเติมเงิน",
        select_bank: "เลือกธนาคาร",
        top_tab1: "ชนะรางวัล",
        top_tab2: "TOP",
        top_tab3: "ของฉัน",
        web_time: "เวลาเว็บไซต์",
        trade: "การซื้อขาย",
    },
    footer: {
        foot1: "หน้าหลัก",
        foot2: "เกม",
        foot3: "TOP",
        foot4: "กิจกรรม",
        foot5: "ของฉัน",
    },
    navBar: {
        back_home: "กลับไปที่หน้าแรก",
        help_center: "ศูนย์ช่วยเหลือ",
        contact_service: "ติดต่อฝ่ายบริการลูกค้า",
        balance: "ยอดคงเหลือ",
        my_account: "บัญชีของฉัน",
        account: "บัญชี",
        record: "บันทึก",
        proxy: "ตัวแทน",
        other: "อื่นๆ",
        deposit: "เติมเงิน",
        withdraw: "ถอนเงินสด",
        deposit_type1: "การชำระเงินผ่านธนาคารทางอินเทอร์เน็ต",
        deposit_type2: "โอนเงินผ่านธนาคาร",
        deposit_type3: "สกุลเงินดิจิตอล",
        deposit_type4: "OVO",
        logout: "ออกจาก",
        login: "เข้าสู่ระบบ",
        register: "ลงทะเบียน",
        nav1: "ลอตเตอรี่",
        nav2: "เกม",
        nav3: "กิจกรรม",
        nav4: "โทรศัพท์มือถือ",
        nav5: "บัญชีของฉัน",
    },
    activity: {
        tit1: "รางวัล Daily Plus",
        p1: "โบนัสรายวันเป็นบวกตามจำนวนเงินเดิมพันของผู้ใช้เมื่อวานนี้และโบนัสไม่ จำกัด!",
        activity_detail: "รายละเอียดกิจกรรม",
        yestoday_bet: "เดิมพันเมื่อวานนี้",
        bonus_ratio: "บวกสัดส่วนของรางวัล",
        now_level: "เกรดปัจจุบัน",
        receive_bonus: "มีรางวัลเพิ่มเติม",
        receive: "รับ",
        not_receive: "ไม่รับ",
        activity_time: "ระยะเวลากิจกรรม",
        p2: "1、 Daily Plus เปิดให้รับหลังเวลา 00.20 น. ของทุกวัน",
        p3: "2, อัตราส่วนโบนัสเป็นสัดส่วนโบนัสขึ้นอยู่กับระดับของผู้ใช้เช่นเดียวกับจำนวนเงินเดิมพันสะสมเมื่อวานนี้",
        p4: "3, การถอนเงินและการเดิมพันที่ไม่ถูกต้องอื่น ๆ จะไม่ถูกนับ",
        p5: "4, การเดิมพันที่ถูกต้องต้องเป็นไปตาม [การเดิมพันนี้ได้รับการตัดสิน] และถ้าการเดิมพันนี้ได้รับการตัดสินหลังจากหลายวันจำนวนเงินเดิมพันที่ถูกต้องของใบเดิมพันนี้จะสะสมใน [วันเริ่มต้นและการตัดสิน]",
        p6: "5, ตัวอย่าง: การเดิมพัน 10/1 แต่ 10/15 ได้รับรางวัลเท่านั้นยอดเดิมพันที่ถูกต้องของใบเดิมพันนี้จะสะสมอยู่ที่ 10/15 และผู้ใช้สามารถรับรางวัลเพิ่มเติมของใบเดิมพันที่ 10/16",
        p7: "6, โบนัสที่ไม่ได้รับก่อน 24:00 ของวันที่โบนัสที่ใช้งานจะถือว่าสละสิทธิ์โดยอัตโนมัติ",
    },
    game: {
        history_tit: "ประวัติศาสตร์การเปิดรางวัล",
        play_tit: "คำแนะนำในการเล่น",
        official_award: "รางวัลเดิมอย่างเป็นทางการ",
        betting: "รางวัลเดิมอย่างเป็นทางการ",
        waiting: "รอการออกรางวัล",
        opening: "กำลังออกรางวัล",
        settled: "ชำระแล้ว",
        auto_change: "สลับอัตโนมัติ",
        height_win: "ชนะสูงสุด",
        total: "ทั้งหมด",
        pour: "หมายเหตุ",
        every_pour: "เดิมพันแต่ละครั้ง",
        total_pour: "จำนวนเดิมพันทั้งหมด",
        total_amount: "เดิมพันทั้งหมด",
        now_bet: "เดิมพันตอนนี้",
        my_bet: "เดิมพันของฉัน",
        winning: "ชนะรางวัล",
        top: "TOP",
        winning_tip: "อัปเดตข้อมูลการชนะแบบเรียลไทม์",
        top_tip: "ผู้นำ TOP",
    },
    record: {
        lottery: "ลอตเตอรี่",
        game: "เกม",
        today_profit_loss: "กำไรและขาดทุนในวันนี้",
        label_formula: "สูตรกำไรและขาดทุน",
        formula: "สูตร: ชนะ - เดิมพัน + กิจกรรม + จุดคืนเงิน",
        profitLoss_label1: "ชนะรางวัล",
        profitLoss_label2: "กิจกรรม",
        profitLoss_label3: "เดิมพัน",
        profitLoss_label4: "จุดกลับ",
        profitLoss_label5: "โอนเงิน",
        profitLoss_label6: "การรับเงิน",
        profitLoss_label7: "ส่งซองจดหมายสีแดง",
        profitLoss_label8: "รับซองจดหมายสีแดง",
        trade_tab1: "บันทึกทั้งหมด",
        trade_tab2: "บันทึกการเติมเงิน",
        trade_tab3: "บันทึกการถอนเงิน",
    },
    table: {
        label_no: " No.",
        label_time: "เวลา",
        label_result: "ผลลัพธ์",
        label_size: "ขนาด",
        label_single_double: "เดี่ยวและคู่",
        label_sun_value: "และค่า",
        no_data: "ไม่มีข้อมูลในขณะนี้",
        big: "ใหญ่",
        small: "เล็ก",
        single: "เดี่ยว",
        double: "คู่",
        label_issue: "หมายเลขประจำเดือน",
        label_bet_amount: "จำนวนเงินเดิมพัน",
        label_reward: "โบนัส",
        no_reward: "ไม่ถูกรางวัล",
        is_reward: "ชนะรางวัล",
        label_level: "เกรด",
        label_integral: "คะแนนการเติบโต",
        label_award: "โปรโมชั่นพิเศษ",
        label_skip_awardl: "รางวัลกระโดด",
        label_register_link: "ลิงค์ลงทะเบียน",
        label_remark: "หมายเหตุ",
        label_rise_time: "สร้างเวลา",
        label_register_number: "จำนวนการลงทะเบียน",
        label_operate: "การดำเนินงาน",
        label_gametit: "สี / เกม",
        label_lower_rebate: "จุดกลับรถในระดับล่าง",
        label_self_rebate: "จุดกลับของตัวเอง",
        label_set_rebate_range: "สามารถกำหนดช่วงจุดกลับได้",
        bonus_state: "สถานะโบนัส",
        label_serial_number: "เรือไหล",
        label_income_amount: "จำนวนรายได้",
        label_expend_amount: "จำนวนเงินที่ใช้จ่าย",
        label_available_balance: "ยอดคงเหลือที่มีอยู่",
        label_abstract: "บทสรุป",
        label_details: "รายละเอียด",
        label_account: "บัญชี",
        label_amount: "จำนวนเงิน",
        label_bet_cont: "เนื้อหาการเดิมพัน",
        label_ottery_number: "หมายเลขออกรางวัล",
        order_number: "หมายเลขเดี่ยว",
        play_methods: "วิธีการเล่น",
        label_periods: "จำนวนงวด",
        label_number: "หมายเลข",
        label_color: "สี",
    },
    my: {
        last_login_time: "เวลาเข้าสู่ระบบล่าสุด",
        logout_account: "ออกจากบัญชี",
        my_account: "บัญชีของฉัน",
        account: "บัญชี",
        personal_tit: "ข้อมูลส่วนตัว",
        security_tit: "ศูนย์รักษาความปลอดภัย",
        bank_tit: "หมายเลขบัญชีธนาคาร",
        record: "บันทึก",
        profit_loss_tit: "กำไรและขาดทุนในวันนี้",
        trade_record_tit: "บันทึกการซื้อขาย",
        bet_record: "บันทึกการเดิมพัน",
        proxy: "ตัวแทน",
        manag_tit: "การจัดการตัวแทน",
        report_tit: "งบตัวแทน",
        lower_bet_tit: "เดิมพันที่ต่ำกว่า",
        lower_trade_tit: "การซื้อขายระดับล่าง",
        other: "อื่นๆ",
        gift_tit: "ของขวัญจากกิจกรรม",
        message_tit: "ศูนย์ข้อความ",
        personal_data: "ข้อมูลส่วนตัว",
        balance: "ยอดคงเหลือ",
        last_sync_time: "เวลาซิงค์ล่าสุด",
        deposit: "เติมเงิน",
        withdraw: "ถอนเงินสด",
        yesterday_deposit_time: "เวลาเฉลี่ยในการเติมเงินเมื่อวานนี้",
        yesterday_withdraw_time: "ถอนเงินเฉลี่ยเมื่อวานนี้",
        help_tit: "ศูนย์ช่วยเหลือ",
        all: "ทั้งหมด",
        lower_report: "รายงานระดับล่าง",
        about_us: "เกี่ยวกับเรา",
        logout: "ขึ้น-ลง",
        invite: "เชิญเพื่อน",
        lang_change: "การสลับภาษา",
    },
    account: {
        add_bank_account: "เพิ่มเลขที่บัญชีธนาคาร",
        bank_tip: "สามารถเพิ่มเลขบัญชีธนาคารได้สูงสุด 5 บัญชี โดยเลขบัญชีธนาคารที่ถอนได้สำเร็จจะถูกล็อกโดยอัตโนมัติและไม่สามารถลบและแก้ไขได้ เลขที่บัญชีธนาคารที่ถอนเงินไม่สำเร็จ สามารถแก้ไขได้ ไม่สามารถลบได้",
        account_msg: "ข้อมูลบัญชี",
        growth_value: "มูลค่าการเติบโต",
        noset_nickname: "ยังไม่มีการตั้งชื่อเล่น",
        nationality: "สัญชาติ",
        next_level: "ห่างจากระดับถัดไป",
        integral: "คะแนน",
        integral_tip: "เพิ่ม 1 แต้ม เมื่อเติมเงิน 1.00 $",
        level_system: "ระบบลำดับชั้น",
        change_avatar: "เปลี่ยน Avatar",
        loginpsd_tip: "รหัสผ่านเข้าสู่ระบบ การรวมกันของตัวอักษรและตัวเลข 6 ~ 16 หลัก",
        modify_psd: "แก้ไขรหัสผ่าน",
        securitypsd_tip: "รหัสผ่านความปลอดภัย (การรวมกันของ 6 หลัก)",
        forget_securitypsd: "ลืมรหัสผ่านที่ปลอดภัย",
        confidentiality_tip: "ข้อมูลบัญชีผู้ใช้สามารถกู้คืนได้เมื่อหมายเลขบัญชีหรือรหัสผ่านสูญหาย",
        bank_account: "หมายเลขบัญชีธนาคาร",
        edit_nickname: "แก้ไขชื่อเล่น",
        confidentiality: "ปัญหาด้านความปลอดภัย",
        accumulated_winning: "รางวัลสะสม",
    },
    agent: {
        illustrate_p1: "เมื่อคุณสามารถดูหน้านี้โดยระบุว่าหมายเลขบัญชีของคุณเป็นทั้งหมายเลขบัญชีผู้เล่นและตัวแทนคุณสามารถวางเดิมพันด้วยตัวเองและพัฒนาผู้เล่นระดับล่างเพื่อรับค่าคอมมิชชั่นการคืนเงิน",
        illustrate_p2: "วิธีรับคะแนนสะสม？",
        illustrate_p3: "ผลตอบแทนที่ได้รับเท่ากับความแตกต่างของจุดผลตอบแทนของตัวเองกับจุดผลตอบแทนที่ต่ำกว่าเช่นจุดผลตอบแทนของตัวเอง 5, จุดผลตอบแทนที่ต่ำกว่า 3 คุณจะได้รับผลตอบแทนของจำนวนเงินเดิมพันที่ต่ำกว่า 2 เช่นเดิมพันที่ต่ำกว่า 100$ คุณจะได้รับ 2$ คลิกเพื่อเปิดบัญชีระดับล่างเพื่อดูจุดคืนของตัวเองหรือตั้งค่าสำหรับระดับล่าง",
        illustrate_p4: "วิธีการเปิดบัญชีสำหรับผู้ด้อยกว่า？",
        illustrate_p5: "คลิกที่การเปิดบัญชีระดับล่าง ตั้งค่าจุดคืนสําหรับผู้ใต้บังคับบัญชาของคุณก่อน เมื่อตั้งค่าสําเร็จจะสร้างรหัสเชิญและส่งรหัสเชิญไปยังการลงทะเบียนระดับล่างของคุณ หลังจากลงทะเบียนแล้วเขาจะเป็นผู้ใต้บังคับบัญชาของคุณ คลิกที่การจัดการผู้ใช้เพื่อดูหมายเลขบัญชีที่เขาลงทะเบียน",
        illustrate_p6: "หากคุณตั้งค่าหมายเลขบัญชีประเภทตัวแทนสำหรับล่างของคุณ ผู้ใต้บังคับบัญชาของคุณจะสามารถพัฒนาผู้ใต้บังคับบัญชาต่อไปได้ หากการตั้งค่าเป็นประเภทผู้เล่น ผู้ใต้บังคับบัญชาของคุณจะสามารถเดิมพันได้เท่านั้น และไม่สามารถพัฒนาผู้ใต้บังคับบัญชาได้อีกต่อไป และไม่สามารถมองเห็นศูนย์ตัวแทนได้ ผู้ใช้ หลอด",
        illustrate_tip1: "1 อัตราผลตอบแทนที่แตกต่างกันยังแตกต่างกันคลิกที่ตารางอัตราผลตอบแทนสามารถตรวจสอบอัตราผลตอบแทน",
        illustrate_tip2: "2 จุดผลตอบแทนที่ต่ำกว่าอัตราต่อรองที่ต่ำกว่าจุดผลตอบแทนที่ตั้งไว้สำหรับระดับล่างไม่ควรต่ำเกินไป",
        illustrate_tip3: "3, การพัฒนาของตัวแทนสามารถดูได้ในงบตัวแทน, เดิมพันที่ต่ำกว่า, การทำธุรกรรมที่ต่ำกว่า",
        illustrate_tip4: "4 ระดับล่างที่แนะนำให้เปิดยังเป็นประเภทของตัวแทนคุณจะได้รับจุดกลับโดยไม่คำนึงถึงการพัฒนาหลายระดับ",
        open_type: "ประเภทการเปิดบัญชี：",
        open_type1: "ทั้งหมด",
        open_type2: "ตัวแทน",
        open_type3: "ผู้เล่น",
        invite_tip1: "1, อัตราผลตอบแทนที่แตกต่างกันจะแตกต่างกันจุดผลตอบแทนที่สูงขึ้นอัตราผลตอบแทนที่สูงขึ้น",
        invite_tip2: "2, ค่าคอมมิชชั่นที่ตัวแทนจะได้รับจะเท่ากับความแตกต่างระหว่างจุดคืนของตัวแทนเองและจุดคืนของผู้ใต้บังคับบัญชาเช่นจุดคืนของตัวแทนเอง 6, จุดคืนของผู้ใต้บังคับบัญชา 4, ตัวแทนจะได้รับ 2% ของจำนวนเงินเดิมพันที่ต่ำกว่านั่นคือการเดิมพันที่ต่ำกว่า 100 หยวนและตัวแทนจะได้รับ 2 หยวน",
        invite_tip3: "3 ที่ต่ำกว่าค่าจุดผลตอบแทนที่ตั้งไว้ต่ำกว่าอัตราต่อรองที่ต่ำกว่าขอแนะนำให้จุดผลตอบแทนที่ตั้งไว้ด้านล่างไม่ต่ำเกินไป",
        rebateDialog_tit: "ตรวจสอบจุดกลับ",
        delete_invite_tip: "แน่ใจว่าคุณต้องการลบรหัสเชิญนี้？",
        rebate_table: "ตารางอัตราผลตอบแทน",
        generate_invitation_code: "สร้างรหัสเชิญ",
        subordinate_tip1: "1, อัตราผลตอบแทนที่แตกต่างกันจะแตกต่างกันจุดผลตอบแทนที่สูงขึ้นอัตราผลตอบแทนที่สูงขึ้น",
        subordinate_tip2: "2, ค่าคอมมิชชั่นที่ตัวแทนจะได้รับจะเท่ากับความแตกต่างระหว่างจุดคืนของตัวแทนเองและจุดคืนของผู้ใต้บังคับบัญชาเช่นจุดคืนของตัวแทนเอง 6, จุดคืนของผู้ใต้บังคับบัญชา 4, ตัวแทนจะได้รับ 2% ของจำนวนเงินเดิมพันที่ต่ำกว่านั่นคือการเดิมพันที่ต่ำกว่า 100 หยวนและตัวแทนจะได้รับ 2 หยวน",
        subordinate_tip3: "3 ที่ต่ำกว่าค่าจุดผลตอบแทนที่ตั้งไว้ต่ำกว่าอัตราต่อรองที่ต่ำกว่าขอแนะนำให้จุดผลตอบแทนที่ตั้งไว้ด้านล่างไม่ต่ำเกินไป",
        manage_tab1: "คำอธิบายตัวแทน",
        manage_tab2: "การเปิดบัญชีระดับล่าง",
        manage_tab3: "รหัสเชิญ",
        manage_tab4: "การจัดการผู้ใช้",
        lower_bet_tip: "บันทึกธุรกรรมจะถูกเก็บไว้นานถึง 21 วัน",
        report_label1: "เดิมพัน",
        report_label2: "ชนะรางวัล",
        report_label3: "กิจกรรม",
        report_label4: "จุดกลับของทีม",
        report_label5: "กำไรของทีม",
        report_label6: "จำนวนเดิมพัน",
        report_label7: "จำนวนการชาร์จครั้งแรก",
        report_label8: "จำนวนผู้ลงทะเบียน",
        report_label9: "จำนวนระดับล่าง",
        report_label10: "ยอดคงเหลือของทีม",
        report_label11: "เติมเงิน",
        report_label12: "ถอนเงินสด",
        report_label13: "จุดคืนตัวแทน",
        report_label14: "โอนเงิน",
        report_label15: "การรับเงิน",
        report_label16: "ส่งซองจดหมายสีแดง",
        report_label17: "รับซองจดหมายสีแดง",
    },
    fund: {
        tip1: "กรุณาโอนเงินเข้าบัญชีธนาคารดังต่อไปนี้",
        tip2: "กรุณากรอกข้อมูลการโอนเงินของคุณอย่างจริงจัง",
        tip3: "(โปรดส่งคำสั่งซื้อหลังจากโอนเงินแล้วไม่สามารถตรวจสอบได้ทันเวลา!)",
        deposit_rate: "อัตราแลกเปลี่ยนเงินฝาก",
        currency_number: "จำนวนสกุลเงิน",
        tip4: "กรุณาโอนเงินโดยใช้แพลตฟอร์มสกุลเงินดิจิทัลที่ตรงกัน",
        tip5: "วงเงินปากกาเดียว",
        tip6: "(หากไม่สามารถกรอกข้อมูลการรับรองได้ กรุณาอัพโหลดภาพหน้าจอใบเสร็จรับเงิน)",
        click_upload: "คลิกเพื่ออัพโหลด",
        upload_tip: "อัปโหลดไฟล์ jpg/png เท่านั้น",
        receiving_address: "ที่อยู่รับเงิน",
        tip7: "โปรดใช้ OVO เพื่อโอนเงินไปยังบัญชีธนาคารด้านล่าง",
        tip8: "1. การชำระเงินผ่านธนาคารทางอินเทอร์เน็ตต้องเปิดธนาคารทางอินเทอร์เน็ตเพื่อให้สามารถชำระเงินได้",
        tip9: "2. เมื่อเติมเงินสำเร็จให้แตะ 'กลับไปที่ร้านค้า เพื่อเข้าบัญชีโดยอัตโนมัติ",
    },
    other: {
        gift_tip1: "1、 ของขวัญจะต้องครบตามเงื่อนไขก่อนจึงจะสามารถรับได้",
        gift_tip2: "2 ของขวัญที่ไม่ได้รับหลังจากหมดอายุหมดอายุโดยอัตโนมัติและไม่สามารถรับได้อีกต่อไป",
        other_tip1: "จดหมายจะถูกเก็บไว้ถึง 7 วันบันทึก;",
    },
    form: {
        input_inner: "โปรดป้อนเนื้อหา",
        label_username: "หมายเลขบัญชีผู้ใช้：",
        place_username: "กรุณากรอกหมายเลขบัญชีผู้ใช้",
        label_loginpsd: "รหัสผ่านเข้าสู่ระบบ：",
        place_loginpsd: "กรุณากรอกรหัสผ่านเข้าสู่ระบบ",
        label_invitecode: "รหัสเชิญ：",
        place_invitecode: "กรุณากรอกรหัสเชิญ 8 หลัก",
        label_oldpsd: "รหัสผ่านเดิม",
        place_oldpsd: "กรุณากรอกรหัสผ่านเดิม",
        label_newpsd: "รหัสผ่านใหม่",
        place_newpsd: "กรุณากรอกรหัสผ่านใหม่",
        label_crmpsd: "ยืนยันรหัสผ่าน",
        place_crmpsd: "กรุณากรอกรหัสผ่านใหม่อีกครั้ง",
        label_remark: "หมายเหตุ",
        place_remark: "กรุณาใส่หมายเหตุ",
        label_lower_account: "หมายเลขบัญชี：",
        place_lower_account: "กรุณากรอกหมายเลขบัญชีด้านล่าง",
        label_type: "ประเภท：",
        place_select: "กรุณาเลือก",
        label_lottery_title: "พันธุ์สี：",
        label_state: "สถานะ：",
        option_state1: "ทั้งหมด",
        option_state2: "ลุ้นรับรางวัล",
        option_state3: "ไม่ถูกรางวัล",
        option_state4: "ชนะแล้ว",
        label_time: "เวลา:",
        times1: "วันนี้",
        times2: "เมื่อวานนี้",
        times3: "เดือนนี้",
        times4: "เดือนที่แล้ว",
        label_realname: "ชื่อจริง",
        place_realname: "กรุณากรอกชื่อจริง",
        label_bank_number: "หมายเลขบัตรธนาคาร",
        place_bank_number: "กรุณากรอกหมายเลขบัตรธนาคาร",
        label_bank_name: "ชื่อธนาคาร",
        place_bank_name: "กรุณาเลือกชื่อธนาคาร",
        label_abstract: "บทสรุป:",
        label_channel: "ช่องทางการเติมเงิน",
        place_channel: "กรุณาเลือกช่องทางการเติมเงิน",
        label_deposit_amount: "จำนวนเงินที่เติมเงิน",
        place_deposit_amount: "กรุณาใส่จำนวนเงินที่เติมเงิน",
        label_holder: "ผู้เปิดบัญชี",
        label_deposit_name: "ชื่อผู้ฝาก",
        place_deposit_name: "กรุณากรอกชื่อผู้ฝาก",
        label_transfer_msg: "ข้อมูลการโอนเงิน",
        place_transfer_msg: "กรุณากรอกข้อมูลการโอนเงิน",
        label_transfer_pic: "ภาพหน้าจอใบเสร็จการทำธุรกรรม",
        label_transfer_pic_tip: "(กรุณาอัพโหลดภาพหน้าจอใบเสร็จรับเงิน)",
        label_bank_account_name: "ชื่อบัญชีธนาคาร",
        label_balance: "ยอดคงเหลือ",
        label_available_withdraw: "จำนวนเงินที่ถอนได้",
        label_available_number: "จำนวนการถอนเงิน",
        label_withdraw_amount: "จำนวนเงินที่ถอนออก",
        placce_withdraw_amount: "กรุณากรอกจำนวนเงินที่ถอน)",
        available_withdraw: "เป็นที่ต้องการ",
        label_transfer_bank: "โอนเงินผ่านธนาคาร",
        label_security_psd: "รหัสผ่านที่ปลอดภัย",
        place_security_psd: "กรุณากรอกรหัสผ่านเพื่อความปลอดภัย",
        place_nickname: "ชื่อเล่น",
        message_nickname: "กรุณากรอกชื่อเล่น",
        place_phone: "กรุณากรอกหมายเลขโทรศัพท์มือถือ",
        place_email: "กรุณากรอกกล่องจดหมาย",
        place_invite_id: "กรุณากรอกรหัสเชิญ",
        place_verify_code: "กรุณากรอกรหัสยืนยัน",
        send: "ส่ง",
        test_username: "ชื่อผู้ใช้ทดสอบ",
        place_amount: "กรุณาใส่จำนวนเงิน",
    },
    login: {
        login_tit: "เข้าสู่ระบบผู้ใช้",
        forget_psd: "ลืมรหัสผ่าน？",
        login: "เข้าสู่ระบบ",
        register_tit: "ลงทะเบียนผู้ใช้",
        register: "ลงทะเบียน",
        have_account: "มีเลขบัญชีอยู่แล้ว เข้าไปล็อกอิน",
        country_code: "รหัสประเทศ",
        phone: "โทรศัพท์มือถือ",
        email: "กล่องจดหมาย",
    },
    city: {
		albania: "แอลเบเนีย",
		algeria: "แอลจีเรีย",
		argentina: "อาร์เจนตินา",
		armenia: "อาร์เมเนีย",
		australia: "ออสเตรเลีย",
		pakistan: "ปากีสถาน",
		austria: "ออสเตรีย",
		bahrain: "บาห์เรน",
		belgium: "เบลเยียม",
		bosnia_and_Herzegovina: "บอสเนียและเฮอร์เซโกวีนา",
		brazil: "บราซิล",
		brunei: "บรูไน",
		bulgaria: "บัลแกเรีย",
		cambodia: "กัมพูชา",
		canada: "แคนาดา",
		cameroon: "แคเมอรูน",
		chile: "ชิลี",
		colombia: "โคลัมเบีย",
		costa_Rica: "คอสตาริกา",
		croatia: "โครเอเชีย",
		cyprus: "ไซปรัส",
		czech_Republic: "สาธารณรัฐเช็ก",
		denmark: "เดนมาร์ก",
		dominican_Republic: "สาธารณรัฐโดมินิกัน",
		egypt: "อียิปต์",
		estonia: "เอสโตเนีย",
		ethiopia: "เอธิโอเปีย",
		finland: "ฟินแลนด์์",
		france: "ฝรั่งเศส",
		georgia: "จอร์เจีย",
		germany: "ประเทศเยอรมัน",
		ghana: "กานา",
		greece: "กรีซ",
		guyana: "กายอานา",
		honduras: "ฮอนดูรัส",
		hong_Kong_China: "ฮ่องกง, จีน",
		hungary: "ฮังการี",
		iceland: "ไอซ์แลนด์",
		ireland: "ไอร์แลนด์",
		italy: "อิตาลี",
		india: "อินเดีย",
		indonesia: "อินโดนีเซีย",
		israel: "อิสราเอล",
		iran: "อิหร่าน",
		iraq: "อิรัก",
		japan: "ญี่ปุ่น",
		kazakstan: "คาซัคสถาน",
		kenya: "เคนยา",
		korea: "เกาหลี",
		kuwait: "คูเวต",
		kyrgyzstan: "คีร์กีซสถาน",
		laos: "ลาว",
		latvia: "ลัตเวีย",
		lithuania: "ประเทศลิธัวเนีย",
		luxembourg: "ลักเซมเบิร์ก",
		macao_China: "มาเก๊า, จีน",
		macedonia: "มาซิโดเนีย",
		malaysia: "ประเทศมาเลเซีย",
		malta: "มอลตา",
		mexico: "เม็กซิโก",
		moldova: "มอลโดวา",
		monaco: "โมนาโก",
		mongolia: "มองโกเลีย",
		montenegro: "มอนเตเนโกร",
		morocco: "โมร็อกโก",
		myanmar: "พม่า",
		netherlands: "เนเธอร์แลนด์",
		new_Zealand: "นิวซีแลนด์",
		nepal: "เนปาล",
		nigeria: "ไนจีเรีย",
		norway: "นอร์เวย์",
		oman: "โอมาน",
		palestine: "ปาเลสไตน์",
		panama: "ปานามา",
		paraguay: "ประเทศปารากวัย",
		peru: "เปรู",
		philippines: "ประเทศฟิลิปปินส์",
		poland: "โปแลนด์",
		portugal: "โปรตุเกส",
		puerto_Rico: "เปอร์โตริโก",
		qatar: "กาตาร์",
		romania: "โรมาเนีย",
		russia: "รัสเซีย",
		republic_of_Trinidad_and_Tobago: "สาธารณรัฐตรินิแดดและโตเบโก",
		rwanda: "ประเทศรวันดา",
		saudi_Arabia: "ซาอุดิอาระเบีย",
		serbia: "เซอร์เบีย",
		singapore: "สิงคโปร์",
		slovakia: "สโลวาเกีย",
		slovenia: "สโลวีเนีย",
		south_Africa: "แอฟริกาใต้",
		spain: "สเปน",
		sri_Lanka: "ศรีลังกา",
		sweden: "สวีเดน",
		switzerland: "สวิตเซอร์แลนด์",
		taiwan_China: "ไต้หวัน จีน",
		tajikistan: "ทาจิกิสถาน",
		tanzania: "แทนซาเนีย",
		thailand: "ประเทศไทย",
		turkey: "ตุรกี",
		turkmenistan: "เติร์กเมนิสถาน",
		ukraine: "ยูเครน",
		united_Arab_Emirates: "สหรัฐอาหรับเอมิเรตส์",
		united_Kingdom: "สหราชอาณาจักร",
		united_States: "สหรัฐอเมริกา",
		uzbekistan: "อุซเบกิสถาน",
		venezuela: "เวเนซุเอลา",
		vietnam: "เวียดนาม",
		afghanistan: "อัฟกานิสถาน",
		angola: "แองโกลา",
		azerbaijan: "อาเซอร์ไบจาน",
		bangladesh: "ประเทศบังคลาเทศ",
		belarus: "เบลารุส",
		belize: "เบลีซ",
		benin: "ประเทศเบนิน",
		bhutan: "ภูฏาน",
		bolivia: "โบลิเวีย",
		botswana: "บอตสวานา",
		british_Virgin_Islands: "หมู่เกาะบริติชเวอร์จิน",
		burkina_Faso: "บูร์กินาฟาโซ",
		burundi: "ประเทศบุรุนดี",
		cape_Verde: "เคปเวิร์ด",
		cayman_Islands: "หมู่เกาะเคย์แมน",
		central_African_Republic: "สาธารณรัฐแอฟริกากลาง",
		chad: "ชาด",
		comoros: "คอโมโรส",
		the_Republic_of_the_Congo: "คองโก (ผ้า)",
		democratic_Republic_of_the_Congo: "คองโก (DRC)",
		djibouti: "จิบูตี",
		ecuador: "เอกวาดอร์",
		el_Salvador: "เอลซัลวาดอร์",
		equatorial_Guinea: "อิเควทอเรียลกินี",
		eritrea: "ประเทศเอริเทรีย",
		fiji: "ประเทศฟิจิ",
		gabon: "กาบอง",
		gambia: "แกมเบีย",
		greenland: "กรีนแลนด์",
		guatemala: "กัวเตมาลา",
		guinea: "กินี",
		haiti: "เฮติ",
		isle_of_Man: "ไอล์ออฟแมน",
		cote_d_Ivoire: "ไอวอรี่โคสต์",
		jamaica: "จาเมกา",
		jordan: "จอร์แดน",
		lebanon: "เลบานอน",
		lesotho: "เลโซโท",
		liberia: "ไลบีเรีย",
		libya: "ลิเบีย",
		madagascar: "มาดากัสการ์",
		malawi: "มาลาวี",
		maldives: "มัลดีฟส์",
		mali: "มาลี",
		mauritania: "เมาริตาเนีย",
		mauritius: "มอริเชียส",
		mozambique: "โมซัมบิก",
		namibia: "นามิเบีย",
		nicaragua: "นิการากัว",
		republic_of_Niger: "ประเทศไนเจอร์",
		north_Korea: "เกาหลีเหนือ",
		reunion: "เรอูนียง",
		san_Marino: "ซานมารีโน",
		senegal: "เซเนกัล",
		sierra_Leone: "เซียร์ราลีโอน",
		somalia: "โซมาเลีย",
		sudan: "ซูดาน",
		suriname: "ซูรินาเม",
		eswatini: "สวาซิแลนด์",
		syria: "ซีเรีย",
		togo: "โตโก",
		tonga: "ตองกา",
		tunisia: "ตูนิเซีย",
		united_States_Virgin_Islands: "หมู่เกาะเวอร์จินของสหรัฐอเมริกา",
		uganda: "ยูกันดา",
		uruguay: "อุรุกวัย",
		vatican: "วาติกัน",
		yemen: "เยเมน",
		yugoslavia: "ยูโกสลาเวีย",
		zambia: "แซมเบีย",
		zimbabwe: "ซิมบับเว",
		china: "ประเทศจีน",
	}
};
