import router from "./router";
import store from "./store";
import { Message } from "element-ui";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getToken } from "@/utils/auth"; // get token from cookie
NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = ["/login", "/register","/web", "/my/service"]; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();
  // await store.dispatch('settings/setSetting').then((res) => {
  //   let isOpen = store.getters.setting.setting.site_open
  //   if(parseInt(isOpen) === 1){
  //     if (to.path !== "/Maintain") {
  //       next({ path: '/Maintain'})
  //     }
  //   }
  // });
  // await store.dispatch('settings/setDomain')

  const hasToken = getToken();
  if (hasToken) {
    // if (true) {
    if (to.path === "/login") {
      // if is logged in, redirect to the home page
      next({ path: "/home" });
      NProgress.done();
    } else {
      try {
        // get user info
        await store.dispatch("user/getInfo");
        // hack method to ensure that addRoutes is complete
        // set the replace: true, so the navigation will not leave a history record
        next();
      } catch (error) {
        // remove token and go to login page to re-login
        await store.dispatch("user/resetToken");
        Message.error(error || "Has Error");
        next(`/`);
        NProgress.done();
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login`);
      NProgress.done();
    }
  }
});

router.afterEach((to, from, next) => {
  // ga.page(to.name, to.name, to.path)
  // finish progress bar
  NProgress.done();
});
