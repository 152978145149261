export default {
    common: {
        contactNow: "Contact Now",
        minPrice: "Min",
        maxPrice: "Max",
        address: {
            title: "Alamat penarikan",
            account_number: "Nomor Akaun",
            add_btn: "Tambah Alamat",
            label_type: "Pilih Jenis",
            place_type: "Silakan pilih tipe",
            label_number: "Nomor Akaun",
            place_number: "Silakan masukkan nomor rekening",
            wallet_address: 'Alamat dompet',
            bank_name: 'Nama Bank',
            bank_address: "Alamat bank",
            name: "Nama",
            payee_address: 'Alamat Penerima',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Nama Branch",
            branch_code: "Kode Branch",
            bank_code: "Kode bank",
            phone: "Nomor ponsel",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Silakan masukkan isi",
        },
        priceType: {
            fast_sum: "Jumlah",
            fast_three_all: "Tiga Pemilihan Umum dengan Nomor Urut yang Sama",
            fast_three_one: "Tiga Nomor yang Sama Pilihan Tunggal",
            fast_three_diff: "Tiga nomor berbeda",
            fast_three_continue: "Pilihan Ganda Tiga Angka (TNT) (komputasi)",
            fast_two_some: "Indeks Ganda Pilihan Ganda",
            fast_two_one: "Pilihan Individu Ganda",
            fast_two_diff: "Nomor pembelahan (elektronik)",
            fast_one_one: "menyala. saringan tunggal membayar satu untuk satu",
            racing_winner: "Juara",
            racing_second: "Tempat kedua",
            racing_third: "Peraih medali perunggu",
            lotto_one: "A",
            lotto_two: "B",
            lotto_three: "C",
            lotto_four: "D",
            lotto_five: "E",
            wingo_sum: "Somme",
            wingo_color: "Color",
            Big: "Tertua",
            Small: "Sedikit",
            Single: "Mono",
            Double: "Nama keluarga Shuang",
            Red: "Bonus",
            Green: "Hijau",
            Purple: "Lithospermum erythrorhizon ",
        },
        img_error: "Gambar gagal dimuat",
        more: "Lebih lanjut",
        cancel: "Pembatalan",
        confirm: "Menentukan",
        tips: "Petunjuk：",
        no_set: "Tidak tetap",
        submit: "Mengirimkan (laporan, dll)",
        copy_success: "Salin Berhasil！",
        copy_error: "Kegagalan Reproduksi！",
        currency: "$",
        filter: "Layar",
        edit: "Ubah",
        gameStart: "Mulai permainan",
        prizenumber: "Nomor pemenang",
        mynumber: "Nomor saya.",
        addresss: "Berikut adalah alamat tautannya",
        gameAgain: "Sekali lagi.",
        gamerule: "Aturan main",
        winning_numbers: "Nomor pemenang",
        winning: "Selamat atas kemenangan Anda!！",
        notwin: "Sayangnya, Anda tidak menang!",
        logout: "Apakah Anda yakin ingin keluar?？",
        success: "Keberhasilan",
        search: "Cari sth.",
        withdraw0: "Aplikasi",
        withdraw1: "Sedang berlangsung",
        withdraw2: "Keberhasilan",
        withdraw3: "Gagal",
        balanceNot: "Saldo rekening tidak mencukupi",
        canceled: "Dibatalkan",
        bill: {
            bill101: "Isi Ulang Pengguna",
            bill102: "Integrator sistem",
            bill103: "Pembekuan sistem",
            bill104: "Pencairan sistem",
            bill201: "Penarikan pengguna",
            bill202: "Pengurangan sistem",
            bill203: "Penarikan Berhasil",
            bill204: "Kegagalan Audit Penarikan Dana",
            bill205: "Biaya penarikan",
            bill301: "Bergabunglah dengan permainan",
            bill302: "Keuntungan Permainan",
        }
    },
    add: {
        cqk: "Penyetoran/penarikan",
        activity: "Promosi",
        online: "Layanan Pelanggan Online",
        lottery: "Aula Lotere",
        game: "Bermain",
        lotter: "Tiket lotere",
        search_lotter: "Cari Warna",
        search: "Berkonsultasi",
        di: "Posisi yang mana?",
        qi: "Periode",
        ju: "Sebelum berakhirnya bulan pertama dari",
        feng: "Pemutus sirkuit",
        result: "Hasil lotere",
        logout: "Batalkan",
        available_balance: "Saldo yang tersedia",
        notice_title: "Pengumuman Platform",
        tip30: "Tidak ada lagi pop-up selama 30 menit",
        close: "Cloture",
        personal: "Pusat pribadi",
        level: "Hirarki",
        common: "Umumnya terlihat",
        news: "Penawaran Terbaru",
        help_center: "Pusat Bantuan",
        app_download:"Unduh Aplikasi",
    },
    addnew: {
        remainder: "Waktu yang tersisa",
        history: "Sejarah loteri",
        trend: "Trend",
        my_bet: "Perintahku.",
        bei: "Multiple",
        agree: "Aku setuju.",
        rule: "《Aturan penjualan》",
        total_amount: "Jumlah total",
        wallet_balance: "Balansi dompet",
        total: "Total",
        random_bet: "Taruhan acak",
    },
    playMethod: {
        p1: "Pilih aturan permainan yang ingin Anda lihat",
        p2: "Jika Anda mempelajari aturannya, Anda akan menggandakan penghasilan Anda.",
        des1: "Singkat",
        des2: "Jika Anda bertransaksi dengan harga 100, jumlah kontrak setelah dikurangi biaya layanan sebesar 2%: 98",
        fived: {
            p1: "Aturan Permainan Lotere 5D",
            p2: "Instruksi menggambar",
            p3: "5 digit yang dipilih secara acak (00000-99999) untuk setiap edisi",
            p4: "Sebagai contoh:",
            p5: "Nomor undian saat ini adalah 12345",
            p6: "A = 1",
            p7: "B = 2",
            p8: "C = 3",
            p9: "D = 4",
            p10: "E = 5",
            p11: "Dan = A + B + C + D + E = 15",
            p12: "Cara bermain",
            p13: "Pemain dapat menentukan taruhan pada enam hasil dan total A, B, C, D dan E",
            p14: "A, B, C, D, E dapat dibeli",
            p15: "Angka (0 1 2 3 4 5 6 7 8 9)",
            p16: "Rendah (0 1 2 3 4)",
            p17: "Tinggi (5 6 7 8 9)",
            p18: "Angka ganjil (1 3 5 7 9)",
            p19: "Genap (0 2 4 6 8)",
            p20: "Jumlah = A + B + C + D + E tersedia untuk dibeli",
            p21: "Rendah (0-22)",
            p22: "Tinggi (23-45)",
            p23: "Angka ganjil (1 3 --- 43 45)",
            p24: "Genap (0 2 --- 42 44)",
        },
        racing: {
            p1: "Gameplay",
            p2: "※Tiga teratas dan：",
            p3: "Jumlah tiga nomor mobil pertama lebih besar dari 16 untuk mobil besar, kurang dari atau sama dengan 16 untuk mobil kecil. Nomor genap disebut nomor ganda, nomor ganjil disebut nomor tunggal. ※Tiga jumlah pertama:",
            p4: "※Peringkat pertama hingga ketiga：",
            p5: "Penunjukan nomor mobil, setiap nomor mobil adalah kombinasi taruhan, hasil lotere 'nomor mobil taruhan' yang sesuai dengan nama taruhan dianggap sebagai pemenang, sisanya dianggap bukan pemenang.",
            p6: "※Kedua belah pihak：",
            p7: "Mengacu pada tunggal dan ganda; besar dan kecil.",
            p8: "※Tunggal dan ganda：",
            p9: "Angka genap disebut dobel, misalnya 2, 4, 6, 8, 10; angka ganjil disebut tunggal, misalnya 1, 3, 5, 7, 9.",
            p10: "※Besar dan kecil",
            p11: "Angka yang lebih besar atau sama dengan 6 dianggap besar, misalnya 6, 7, 8, 9, 10; kurang dari atau sama dengan 5 dianggap kecil, misalnya 1, 2, 3, 4, 5.",
        },
        wingo: {
            p1: "Gameplay",
            p2: "※Sosok tunggal：",
            p3: "Pilih nomor yang akan dipertaruhkan, nomor seri dan jumlah taruhan dianggap sama sebagai pemenang, sisanya dianggap tidak menang.",
            p4: "※Merah muda：",
            p5: "Bertaruh pada warna merah dianggap menang jika nomor lotere adalah 0, 2, 4, 6, 8, dan tidak ada kemenangan untuk skenario lainnya.",
            p6: "※Lebih hijau：",
            p7: "Jika Anda bertaruh pada warna hijau, Anda dianggap sebagai pemenang jika angka yang keluar adalah 1, 3, 5, 7, 9, dan Anda dianggap sebagai bukan pemenang untuk skenario lainnya.",
            p8: "※Ungu：",
            p9: "Jika Anda bertaruh pada warna ungu, Anda akan dianggap sebagai pemenang jika nomor lotere adalah 0 dan 5, dan bukan pemenang dalam kasus lainnya.",
            p10: "※Besar dan kecil：",
            p11: "Angka gambar yang lebih besar atau sama dengan 5 dianggap besar, misalnya 5, 6, 7, 8, 9; kurang dari atau sama dengan 4 dianggap kecil, misalnya 0, 1, 2, 3, 4.",
        },
        lottery: {
            p1: "Gameplay ~ 6 dibuka secara acak.",
            p2: "A：Besar dan kecil, tunggal dan ganda",
            p3: "Ukuran: Jumlah dari tiga nomor lotere berkisar antara 11 sampai 18 dianggap besar; jumlah 3 sampai 10 dianggap kecil.",
            p4: "Tunggal dan Ganda: Jika jumlah total dari ketiga nomor lotere adalah tunggal, maka tunggal menang, dan jika ganda, maka ganda menang.",
            p5: "B：Periksa nomor",
            p6: "Jika nilai jumlah dari nomor lotere adalah 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, anda akan menang; sebagai contoh, jika nomor lotere adalah 1, 2, 3, dan nilai jumlah adalah 6, maka bertaruh pada “6” akan menang.",
            p7: "C：Dadu tunggal",
            p8: "Jika salah satu dari tiga nomor lotere sama dengan nomor yang dipilih, maka akan dianggap sebagai pemenang. Contoh: Jika nomor lotere adalah 1, 1, 3, maka bertaruh pada dadu tunggal 1 atau dadu tunggal 3 akan dianggap menang. (Terlepas dari berapa kali jumlah poin yang ditentukan muncul, pembayaran tidak akan digandakan hanya sekali).",
            p9: "D：Dadu ganda",
            p10: "Hadiah akan dimenangkan jika ada dua nomor hadiah yang sama dan cocok dengan kombinasi dadu ganda yang dipilih. Contoh: Jika nomor hadiah adalah 1, 1, 3, maka bertaruh pada dadu ganda 1, 1, akan menjadi pemenangnya.",
            p11: "E: Round Robin, Round Robin Penuh",
            p12: "Dadu: Hadiah akan dimenangkan jika tiga nomor lotere sama dan cocok dengan kombinasi dadu yang dipilih.",
            p13: "Dadu Lingkaran Penuh: Hadiah dimenangkan jika ketiga karakter nomor lotere sama.",
            p14: "F: Kombinasi dua dadu",
            p15: "Pilih kombinasi 2 dadu, jika hasil lotere sama dengan kombinasi yang dipilih, maka akan dianggap sebagai pemenang. Contoh: Jika nomor lotere adalah 1, 2, 3, maka bertaruh pada kombinasi 2 dadu 12, kombinasi 2 dadu 23, kombinasi 2 dadu 13 akan dianggap menang.",
        }
    },
    webadd: {
        hot: "Popular",
        all: "Seluruh",
        home: "Hall",
        hot_lottery: "Loteri Popular",
        select_channel: "Pilih saluran muat ulang",
        select_bank: "Pilih Bank",
        top_tab1: "Menang loteri",
        top_tab2: "TOP",
        top_tab3: "Aku",
        web_time: "Waktu situs web",
        trade: "Transaksi",
    },
    footer: {
        foot1: "Halaman",
        foot2: "Permainan",
        foot3: "TOP",
        foot4: "Aktivitas",
        foot5: "Aku",
    },
    navBar: {
        back_home: "Kembali ke halaman beranda",
        help_center: "Pusat Bantuan",
        contact_service: "Hubungi Layanan Pelanggan",
        balance: "Saldo",
        my_account: "Akun Saya",
        account: "Akun online",
        record: "Rekor (dalam olahraga, dll)",
        proxy: "Agen",
        other: "Lain",
        deposit: "Mengisi ulang (uang ke kartu)",
        withdraw: "Menarik dana",
        deposit_type1: "Perbankan online",
        deposit_type2: "Transfer bank",
        deposit_type3: "Mata uang digital",
        deposit_type4: "OVO",
        logout: "Batalkan",
        login: "Masuk",
        register: "Pendaftaran",
        nav1: "Tiket lotere",
        nav2: "Bermain",
        nav3: "Fungsi",
        nav4: "Ponsel",
        nav5: "Akun Saya",
    },
    activity: {
        tit1: "Bonus Harian",
        p1: "Jackpot Harian adalah bonus berdasarkan jumlah yang dipertaruhkan pengguna kemarin, tanpa batas atas hadiah!",
        activity_detail: "Detail Acara",
        yestoday_bet: "Taruhan kemarin",
        bonus_ratio: "Rasio Kenaikan Hadiah",
        now_level: "Level saat ini",
        receive_bonus: "Memenuhi syarat untuk mendapatkan kredit tambahan",
        receive: "Mendapatkan",
        not_receive: "Tidak tersedia",
        activity_time: "Waktu aktivitas",
        p2: "1 、 Bonus harian terbuka untuk pengambilan setelah pukul 00:20 setiap hari.",
        p3: "2, persentase bonus didasarkan pada level pengguna dan jumlah taruhan kumulatif kemarin untuk persentase tertentu dari bonus",
        p4: "3. Penarikan dan taruhan tidak sah lainnya tidak akan dihitung",
        p5: "4, Taruhan yang valid harus memenuhi [single telah dibuka dan diselesaikan], seperti kasus bertaruh pada single setelah bertaruh pada beberapa hari sebelum lotere, taruhan tunggal yang valid akan diakumulasikan dalam [tanggal penyelesaian lotere].",
        p6: "5, Contoh: Taruhan 10/1 tetapi 10/15 hanya seri, maka jumlah taruhan efektif dari urutan taruhan akan diakumulasikan dalam 10/15, pengguna dapat 10/16 kali untuk mengumpulkan urutan taruhan dari bonus bonus.",
        p7: "6 、 Bonus aktivitas tidak dapat diambil sebelum pukul 24:00 pada hari aktivitas, sebagai pengabaian otomatis dari kualifikasi aktivitas",
    },
    game: {
        history_tit: "Hadiah Bersejarah",
        play_tit: "Petunjuk untuk bermain",
        official_award: "Hadiah resmi yang asli",
        betting: "Taruhan sedang berlangsung",
        waiting: "Menunggu undian",
        opening: "Menggambar sedang berlangsung.",
        settled: "Menetap",
        auto_change: "Peralihan otomatis",
        height_win: "Kemenangan maksimum",
        total: "Dalam jumlah",
        pour: "Catatan",
        every_pour: "Masing-masing",
        total_pour: "Jumlah total taruhan",
        total_amount: "Total taruhan",
        now_bet: "Taruhan Sekarang",
        my_bet: "Taruhan saya",
        winning: "Memenangkan hadiah",
        top: "TOP",
        winning_tip: "Informasi pemenang diperbarui secara real time",
        top_tip: "Peringkat teratas",
    },
    record: {
        lottery: "Tiket lotere",
        game: "Bermain",
        today_profit_loss: "Laba dan rugi hari ini",
        label_formula: "Rumus titik impas",
        formula: "Rumus: Menang - Taruhan + Aktivitas + Rabat",
        profitLoss_label1: "Menang loteri",
        profitLoss_label2: "Fungsi",
        profitLoss_label3: "Petaruh",
        profitLoss_label4: "Imbalan afiliasi",
        profitLoss_label5: "Transfer",
        profitLoss_label6: "Penerima pembayaran",
        profitLoss_label7: "Kirim Paket Merah",
        profitLoss_label8: "Menerima paket merah",
        trade_tab1: "Semua catatan",
        trade_tab2: "Catatan Isi Ulang",
        trade_tab3: "Catatan Penarikan Dana",
    },
    table: {
        label_no: " No.",
        label_time: "Kali",
        label_result: "Pada akhirnya",
        label_size: "Besarnya",
        label_single_double: "Ganjil dan genap",
        label_sun_value: "Jumlah",
        no_data: "Tidak ada data yang tersedia",
        big: "Tertua",
        small: "Sedikit",
        single: "Mono",
        double: "Shuang",
        label_issue: "Nomor masalah",
        label_bet_amount: "Jumlah taruhan",
        label_reward: "Uang penghargaan",
        no_reward: "Bukan pemenang",
        is_reward: "Memenangkan hadiah",
        label_level: "Hirarki",
        label_integral: "Poin Pertumbuhan",
        label_award: "Imbalan Kemajuan",
        label_skip_awardl: "Bonus Lewati Level",
        label_register_link: "Tautan Pendaftaran",
        label_remark: "Catatan",
        label_rise_time: "Waktu generasi",
        label_register_number: "Jumlah pendaftaran",
        label_operate: "Rig",
        label_gametit: "Warna/game",
        label_lower_rebate: "Imbalan afiliasi",
        label_self_rebate: "Potongan harga sendiri",
        label_set_rebate_range: "Kisaran rabat dapat diatur",
        bonus_state: "Status bonus",
        label_serial_number: "Nomor seri",
        label_income_amount: "Jumlah pendapatan",
        label_expend_amount: "Jumlah pengeluaran",
        label_available_balance: "Saldo yang tersedia",
        label_abstract: "Abstrak",
        label_details: "Keterangan",
        label_account: "Akun online",
        label_amount: "Jumlah uang",
        label_bet_cont: "Isi taruhan",
        label_ottery_number: "Nomor undian",
        order_number: "Nomor perintah",
        play_methods: "Metode permainan",
        label_periods: "Fase",
        label_number: "Angka",
        label_color: "Warna",
    },
    my: {
        last_login_time: "Waktu login terakhir",
        logout_account: "Penarikan dari akun",
        my_account: "Akun Saya",
        account: "Akun online",
        personal_tit: "Profil pribadi",
        security_tit: "Pusat keamanan",
        bank_tit: "Nomor rekening bank",
        record: "Rekor (dalam olahraga, dll)",
        profit_loss_tit: "Laba dan rugi hari ini",
        trade_record_tit: "Catatan transaksi",
        bet_record: "Catatan Taruhan",
        proxy: "Agen",
        manag_tit: "Manajemen Agensi",
        report_tit: "Pernyataan proxy",
        lower_bet_tit: "Taruhan tingkat yang lebih rendah",
        lower_trade_tit: "Transaksi bawahan",
        other: "Sisanya",
        gift_tit: "Hadiah Acara",
        message_tit: "Pusat Berita",
        personal_data: "Profil pribadi",
        balance: "Saldo",
        last_sync_time: "Waktu sinkronisasi terakhir",
        deposit: "Mengisi ulang (uang ke kartu)",
        withdraw: "Menarik dana",
        yesterday_deposit_time: "Mengisi ulang kemarin",
        yesterday_withdraw_time: "mMnarik uang tunai kemarin",
        help_tit: "Pusat Bantuan",
        all: "Seluruh",
        lower_report: "Laporan Subordinate",
        about_us: "Tentang kita",
        logout: "Daftar keluar",
        invite: "Undang Teman",
        lang_change: "Pengalihan bahasa",
    },
    account: {
        add_bank_account: "Tambahkan nomor rekening bank",
        bank_tip: "Maksimal 5 rekening bank dapat ditambahkan. Rekening bank yang telah berhasil ditarik akan terkunci secara otomatis dan tidak dapat dihapus atau diubah. Nomor rekening bank yang belum berhasil ditarik dapat diubah dan tidak dapat dihapus.",
        account_msg: "Informasi Akun",
        growth_value: "Tingkat pertumbuhan",
        noset_nickname: "Nama panggilan tidak ditetapkan",
        nationality: "Kewarganegaraan",
        next_level: "Tingkat berikutnya yang harus dicapai",
        integral: "Total kredit yang diperoleh siswa",
        integral_tip: "Tambah 1 poin untuk setiap isi ulang Rp 1,00.",
        level_system: "Hirarki",
        change_avatar: "Ubah Avatar",
        loginpsd_tip: "Kata sandi masuk 6 hingga 16 digit kombinasi huruf dan angka",
        modify_psd: "Ubah kata sandi Anda",
        securitypsd_tip: "Kata sandi keamanan (kombinasi 6 digit)",
        forget_securitypsd: "Lupa Kode Keamanan",
        confidentiality_tip: "Mengambil informasi akun pengguna jika terjadi masalah keamanan, nomor akun, atau kehilangan kata sandi.",
        bank_account: "Nomor rekening bank",
        edit_nickname: "Sunting gelaran",
        confidentiality: "Pertanyaan keamanan",
        accumulated_winning: "Jackpot Progresif",
    },
    agent: {
        illustrate_p1: "Ketika Anda dapat melihat halaman ini, artinya akun Anda adalah akun pemain dan akun agen, yang memungkinkan Anda untuk memasang taruhan Anda sendiri serta mengembangkan pemain level yang lebih rendah dan mendapatkan komisi rabat.",
        illustrate_p2: "Cara mendapatkan potongan harga？",
        illustrate_p3: "Rabat yang bisa Anda dapatkan sama dengan selisih antara rabat Anda sendiri dan rabat tingkat yang lebih rendah, seperti rabat Anda sendiri sebesar 5 dan rabat tingkat yang lebih rendah sebesar 3, Anda akan bisa mendapatkan rabat dari jumlah taruhan tingkat yang lebih rendah sebesar 2, seperti tingkat yang lebih rendah bertaruh pada Rp. 100, Anda akan mendapatkan Rp. 2. klik di tingkat yang lebih rendah dari akun, Anda dapat melihat rabat Anda sendiri, dan Anda juga dapat mengatur rabat untuk tingkat yang lebih rendah.",
        illustrate_p4: "Cara membuka akun untuk bawahan？",
        illustrate_p5: "Klik pada akun bawahan, pertama-tama atur rabat untuk bawahan Anda, atur kode undangan yang berhasil akan dibuat, kode undangan akan dikirim ke pendaftaran bawahan Anda, pendaftaran, dia adalah bawahan Anda, klik manajemen pengguna, Anda dapat melihat akun terdaftarnya.",
        illustrate_p6: "Jika Anda membuat akun tipe agen untuk bawahan Anda, maka bawahan Anda akan dapat terus mengembangkan bawahan mereka. Jika Anda membuat tipe pemain, maka bawahan Anda hanya dapat memasang taruhan dan tidak akan dapat mengembangkan bawahan mereka dan tidak akan dapat melihat pusat agen. Manajemen Pengguna",
        illustrate_tip1: "1, Rabat peluang yang berbeda berbeda, klik pada tabel peluang rabat, Anda dapat melihat peluang rabat.",
        illustrate_tip2: "2, Semakin rendah rabat, semakin rendah peluangnya, disarankan agar rabat yang ditetapkan untuk level yang lebih rendah tidak terlalu rendah.",
        illustrate_tip3: "3, Anda dapat melihat perkembangan agen dalam pernyataan agen, taruhan tingkat yang lebih rendah, perdagangan tingkat yang lebih rendah.",
        illustrate_tip4: "4, Disarankan untuk membuka level berikutnya juga merupakan tipe agen, terlepas dari perkembangan beberapa level, Anda bisa mendapatkan potongan harga.",
        open_type: "Jenis Pembukaan Rekening：",
        open_type1: "Penuh",
        open_type2: "Agen",
        open_type3: "Pemain",
        invite_tip1: "1, Peluang rabat yang berbeda berbeda, semakin tinggi rabat semakin tinggi peluangnya.",
        invite_tip2: "2, Agen bisa mendapatkan komisi sama dengan perbedaan antara rabat agen sendiri dan rabat bawahan, seperti rabat agen sendiri 6, rabat bawahan 4, agen bisa mendapatkan bawahan 2% dari jumlah taruhan, yaitu, bawahan bertaruh 100 yuan, agen bisa mendapatkan 2 yuan.",
        invite_tip3: "3, Semakin rendah nilai rabat tingkat bawah yang ditetapkan semakin rendah, semakin rendah peluangnya, disarankan agar tingkat yang lebih rendah menetapkan rabat tidak terlalu rendah.",
        rebateDialog_tit: "Lihat Rabat",
        delete_invite_tip: "Apakah Anda yakin ingin menghapus kode undangan ini?？",
        rebate_table: "Tabel Peluang Rabat",
        generate_invitation_code: "Hasilkan Kode Undangan",
        subordinate_tip1: "1, Peluang rabat yang berbeda berbeda, semakin tinggi rabat semakin tinggi peluangnya.",
        subordinate_tip2: "2, Agen bisa mendapatkan komisi sama dengan perbedaan antara rabat agen sendiri dan rabat bawahan, seperti rabat agen sendiri 6, rabat bawahan 4, agen bisa mendapatkan bawahan 2% dari jumlah taruhan, yaitu, bawahan bertaruh 100 yuan, agen bisa mendapatkan 2 yuan.",
        subordinate_tip3: "3, Semakin rendah nilai rabat tingkat bawah yang ditetapkan semakin rendah, semakin rendah peluangnya, disarankan agar tingkat yang lebih rendah menetapkan rabat tidak terlalu rendah.",
        manage_tab1: "Deskripsi Agen",
        manage_tab2: "Pembukaan rekening bawahan",
        manage_tab3: "Kode undangan",
        manage_tab4: "Manajemen pengguna",
        lower_bet_tip: "Catatan transaksi disimpan selama maksimal 21 hari.",
        report_label1: "Petaruh",
        report_label2: "Memenangkan hadiah",
        report_label3: "Fungsi",
        report_label4: "Rabat Tim",
        report_label5: "Keuntungan Tim",
        report_label6: "Jumlah petaruh",
        report_label7: "Jumlah Pengisian Daya Pertama",
        report_label8: "Jumlah pendaftar",
        report_label9: "Jumlah bawahan",
        report_label10: "Keseimbangan Tim",
        report_label11: "Mengisi ulang (uang ke kartu)",
        report_label12: "Menarik dana",
        report_label13: "Rabat Agen",
        report_label14: "Transfer",
        report_label15: "Penerima pembayaran",
        report_label16: "Kirim Paket Merah",
        report_label17: "Menerima paket merah",
    },
    fund: {
        tip1: "Silakan transfer ke rekening bank berikut",
        tip2: "Harap isi informasi transfer Anda dengan hati-hati",
        tip3: "(Pastikan untuk mentransfer uang sebelum mengirimkan pesanan, jika tidak, Anda tidak akan dapat memeriksa pembayaran Anda tepat waktu!)",
        deposit_rate: "Nilai tukar deposito",
        currency_number: "Jumlah mata uang",
        tip4: "Silakan gunakan platform mata uang digital yang sesuai untuk mentransfer uang",
        tip5: "Batas langkah tunggal",
        tip6: "(Jika Anda tidak dapat mengisi informasi autentikasi, silakan unggah tangkapan layar bukti transaksi)",
        click_upload: "Klik untuk mengunggah",
        upload_tip: "Hanya file jpg/png yang dapat diunggah",
        receiving_address: "Alamat penerima pembayaran",
        tip7: "Silakan gunakan OVO untuk mentransfer dana ke rekening bank berikut",
        tip8: "1. Pembayaran bank online memerlukan aktivasi bank online untuk melanjutkan pembayaran.",
        tip9: "2. Setelah memuat ulang berhasil, klik 'Kembali ke Penjual' untuk menerima akun secara otomatis.",
    },
    other: {
        gift_tip1: "1. Hadiah hanya dapat diterima setelah persyaratan untuk menerimanya terpenuhi.",
        gift_tip2: "2. Hadiah yang tidak diklaim setelah tanggal kedaluwarsa secara otomatis tidak berlaku dan tidak dapat diklaim lagi.",
        other_tip1: "Surat-surat disimpan dalam arsip selama maksimal 7 hari;",
    },
    form: {
        input_inner: "Silakan masukkan konten",
        label_username: "Akun pengguna：",
        place_username: "Silakan masukkan akun pengguna Anda",
        label_loginpsd: "Kata sandi masuk：",
        place_loginpsd: "Masukkan kata sandi login Anda",
        label_invitecode: "Kode undangan：",
        place_invitecode: "Silakan isi 8 digit kode undangan",
        label_oldpsd: "Kata sandi asli",
        place_oldpsd: "Masukkan kata sandi asli",
        label_newpsd: "Kata sandi baru",
        place_newpsd: "Masukkan kata sandi baru",
        label_crmpsd: "Konfirmasi Kata Sandi",
        place_crmpsd: "Masukkan kembali kata sandi baru",
        label_remark: "Catatan",
        place_remark: "Silakan masukkan catatan",
        label_lower_account: "Nama pengguna：",
        place_lower_account: "Silakan masukkan nomor rekening bawahan",
        label_type: "Tipologi：",
        place_select: "Silakan pilih",
        label_lottery_title: "Hadiah undian：",
        label_state: "Keadaan：",
        option_state1: "Penuh",
        option_state2: "Undian yang tertunda",
        option_state3: "Bukan pemenang",
        option_state4: "Telah memenangkan hadiah",
        label_time: "Kali：",
        times1: "Pada saat ini",
        times2: "Kemarin",
        times3: "Bulan ini",
        times4: "Ultimo",
        label_realname: "Nama asli dan nama keluarga",
        place_realname: "Silakan masukkan nama asli Anda",
        label_bank_number: "Nomor kartu ATM",
        place_bank_number: "Masukkan nomor kartu bank Anda",
        label_bank_name: "Nama bank",
        place_bank_name: "Pilih nama bank",
        label_abstract: "Abstrak:",
        label_channel: "Saluran Isi Ulang",
        place_channel: "Pilih saluran isi ulang pulsa",
        label_deposit_amount: "jumlah isi ulang",
        place_deposit_amount: "Masukkan nominal isi ulang",
        label_holder: "Pemegang akun",
        label_deposit_name: "Nama Deposan",
        place_deposit_name: "Masukkan nama penyetor",
        label_transfer_msg: "Informasi transfer",
        place_transfer_msg: "Silakan isi informasi transfer",
        label_transfer_pic: "Tangkapan layar tanda terima transaksi",
        label_transfer_pic_tip: "(Silakan unggah tangkapan layar bukti transaksi)",
        label_bank_account_name: "Nama rekening bank",
        label_balance: "Saldo",
        label_available_withdraw: "jumlah yang dapat ditarik",
        label_available_number: "Jumlah penarikan",
        label_withdraw_amount: "Jumlah penarikan",
        placce_withdraw_amount: "Silakan masukkan jumlah penarikan)",
        available_withdraw: "Keinginan",
        label_transfer_bank: "Bank debit",
        label_security_psd: "Kode keamanan",
        place_security_psd: "Masukkan kode keamanan",
        place_nickname: "Nama panggilan",
        message_nickname: "Silakan masukkan nama panggilan",
        place_phone: "Silakan masukkan nomor ponsel Anda",
        place_email: "Silakan masukkan alamat email Anda",
        place_invite_id: "Silakan masukkan kode undangan",
        place_verify_code: "Silakan masukkan kode verifikasi",
        send: "Mengirim",
        test_username: "Uji Nama Pengguna",
        place_amount: "Silakan masukkan jumlahnya",
    },
    login: {
        login_tit: "Login pengguna",
        forget_psd: "Lupa kata sandi?？",
        login: "Masuk",
        register_tit: "Pendaftaran Pengguna",
        register: "Pendaftaran",
        have_account: "Akaun yang ada, log masuk",
        country_code: "Kode negara",
        phone: "Ponsel",
        email: "Kotak masuk",
    },
    city: {
        albania: "Albania",
        algeria: "Aljazair",
        argentina: "Argentina",
        armenia: "Armenia",
        australia: "Australia",
        pakistan: "Pakistan",
        austria: "Austria",
        bahrain: "Bahrain",
        belgium: "Belgia",
        bosnia_and_Herzegovina: "Bosnia dan Herzegovina",
        brazil: "Brazil",
        brunei: "Brunei",
        bulgaria: "Bulgaria",
        cambodia: "Kambodja",
        canada: "Kanada",
        cameroon: "Kamerun",
        chile: "Chile",
        colombia: "Kolombia",
        costa_Rica: "Costa Rica",
        croatia: "KroatiaName",
        cyprus: "Cyprus",
        czech_Republic: "Republik Ceko",
        denmark: "Denmark",
        dominican_Republic: "Republik DominikanName",
        egypt: "Mesir",
        estonia: "Estonia",
        ethiopia: "Ethiopia",
        finland: "Finland",
        france: "Perancis",
        georgia: "Georgia",
        germany: "Jerman",
        ghana: "Ghana",
        greece: "Yunani",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, Tiongkok",
        hungary: "Hungary",
        iceland: "Islandia",
        ireland: "Irlandia",
        italy: "Italia",
        india: "India",
        indonesia: "Indonesia",
        israel: "Israel",
        iran: "Iran",
        iraq: "Irak",
        japan: "Jepang",
        kazakstan: "Republik Kazakh",
        kenya: "Kenya",
        korea: "Korea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kirgistan",
        laos: "Laos",
        latvia: "Latvia",
        lithuania: "Lithuania",
        luxembourg: "Luxemburg",
        macao_China: "Macao China",
        macedonia: "Macedonia",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Meksiko",
        moldova: "Moldava",
        monaco: "Monaco",
        mongolia: "Mongolia",
        montenegro: "Republik Montenegro",
        morocco: "Maroko",
        myanmar: "Myanmar",
        netherlands: "Belanda",
        new_Zealand: "Seland Baru",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Norwegia",
        oman: "Oman",
        palestine: "Palestina",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "Filipina",
        poland: "Poland",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Qatar",
        romania: "Rumania",
        russia: "Rusia",
        republic_of_Trinidad_and_Tobago: "Republik Trinidad dan Tobago",
        rwanda: "Ruanda",
        saudi_Arabia: "Arab Saudi",
        serbia: "Serbia",
        singapore: "Singapura",
        slovakia: "Slovakia",
        slovenia: "Slovenia",
        south_Africa: "Afrika Selatan",
        spain: "Spanyol",
        sri_Lanka: "Sri Lanka",
        sweden: "Swedia",
        switzerland: "瑞Swiss士",
        taiwan_China: "Taiwan China",
        tajikistan: "Republik Tajikistan",
        tanzania: "Tanzania",
        thailand: "Thailand",
        turkey: "T ürkiye",
        turkmenistan: "Republik Turkmenistan",
        ukraine: "Ukraina",
        united_Arab_Emirates: "Emirat Arab Serikat",
        united_Kingdom: "Inggris",
        united_States: "Amerika Serikat",
        uzbekistan: "Uzbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaijan",
        bangladesh: "Bengal",
        belarus: "Belarus",
        belize: "Belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bolivia",
        botswana: "Botswana",
        british_Virgin_Islands: "Kepulauan Perawan Inggris",
        burkina_Faso: "Burkina faso",
        burundi: "Burundi",
        cape_Verde: "Cape Verde",
        cayman_Islands: "Kepulauan Cayman",
        central_African_Republic: "Republik Afrika Tengah",
        chad: "Chad",
        comoros: "Komor",
        the_Republic_of_the_Congo: "Congo (Brazzaville)",
        democratic_Republic_of_the_Congo: "Republik Demokratik Kongo",
        djibouti: "Djibouti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Guinea Ekvatorial",
        eritrea: "Eritrea",
        fiji: "Fiji",
        gabon: "Gabon",
        gambia: "Gambia",
        greenland: "Greenland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: "Pulau Man",
        cote_d_Ivoire: "Cote d'Ivoire",
        jamaica: "Jamaica",
        jordan: "Jordan",
        lebanon: "Lebanon",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Libya",
        madagascar: "Madagaskar",
        malawi: "Malawi",
        maldives: "Maldives",
        mali: "Mali",
        mauritania: "Mauritania",
        mauritius: "Mauritius",
        mozambique: "Mozambique",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Niger",
        north_Korea: "Korea Utara",
        reunion: "Pulau Reunion",
        san_Marino: "San Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "Sudan",
        suriname: "Surinam",
        eswatini: "Eswatini",
        syria: "Syria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisia",
        united_States_Virgin_Islands: "Pulau Perawan AS",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatikan",
        yemen: "Yemen",
        yugoslavia: "Yugoslavia",
        zambia: "Zambia",
        zimbabwe: "Zimbabwe",
        china: "China",
    }
};
