export default {
    common: {
        contactNow: "Contact Now",
        minPrice: "Min",
        maxPrice: "Max",
        address: {
            title: "Dirección de la cartera",
            account_number: "Número de cuenta",
            add_btn: "Añadir dirección",
            label_type: "Seleccionar tipo",
            place_type: "Seleccione el tipo",
            label_number: "Número de cuenta",
            place_number: "Introduzca el número de cuenta",
            wallet_address: 'Dirección de la billetera',
            bank_name: 'Nombre del Banco',
            bank_address: "Dirección bancaria",
            name: "Nombre",
            payee_address: 'Dirección del beneficiario',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Nombre de la sucursal",
            branch_code: "Código de la sucursal",
            bank_code: "Código bancario",
            phone: "Número de teléfono móvil",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Por favor, introduzca el contenido",
        },
        priceType: {
            fast_sum: "Suma",
            fast_three_all: "Tres mismo número Elecciones generales",
            fast_three_one: "Tres El mismo número Elección única",
            fast_three_diff: "Tres números diferentes",
            fast_three_continue: "Números triples de opción múltiple (TNT) (informática)",
            fast_two_some: "Índices dobles Opción múltiple",
            fast_two_one: "Doble elección individual",
            fast_two_diff: "Número bisectriz (electrónica)",
            fast_one_one: "Lit. tamiz único paga uno por uno",
            racing_winner: "Campeones",
            racing_second: "Segundo puesto ",
            racing_third: "Medalla de bronce",
            lotto_one: "A",
            lotto_two: "B",
            lotto_three: "C",
            lotto_four: "D",
            lotto_five: "E",
            wingo_sum: "Somme",
            wingo_color: "Color",
            Big: "Más antiguo",
            Small: "Algunos",
            Single: "Mono",
            Double: "Apellido Shuang",
            Red: "Bonificación",
            Green: "Verde",
            Purple: "Purple",
        },
        img_error: "Error al cargar la imagen",
        more: "Más",
        cancel: "Anulaciones",
        confirm: "Defina",
        tips: "Pista：",
        no_set: "Sin fijar",
        submit: "Presentar ",
        copy_success: "Copiar con éxito！",
        copy_error: "Fallo de reproducción！",
        currency: "$",
        filter: "Cribado",
        edit: "Modificaciones",
        gameStart: "Comenzar el juego",
        prizenumber: "Número ganador",
        mynumber: "Mi número.",
        addresss: "Aquí está la dirección del enlace",
        gameAgain: "Una vez más.",
        gamerule: "Reglas del juego",
        winning_numbers: "Número ganador",
        winning: "Enhorabuena por su victoria.！",
        notwin: "Desgraciadamente, no has ganado.",
        logout: "¿Seguro que quieres cerrar la sesión?",
        success: "Éxitos",
        search: "Buscar algo",
        withdraw0: "Solicitud ",
        withdraw1: "En marcha",
        withdraw2: "Éxitos",
        withdraw3: "Fracasar ",
        balanceNot: "Saldo de cuenta insuficiente",
        canceled: "Cancelado",
        bill: {
            bill101: "Recarga de usuario",
            bill102: "Integrador de sistemas",
            bill103: "Congelación del sistema",
            bill104: "Descongelación del sistema",
            bill201: "Retiradas de usuarios",
            bill202: "Deducciones del sistema",
            bill203: "Retirada con éxito",
            bill204: "Fracaso de la auditoría de retirada",
            bill205: "Tasa de retirada",
            bill301: "Únete al juego",
            bill302: "Beneficios del juego",
        }
    },
    playMethod: {
        p1: "Seleccione las reglas del juego que desea ver",
        p2: "Si estudias las reglas, duplicarás tus ganancias.",
        des1: "Breve",
        des2: "Si negocia por 100, el importe del contrato tras deducir la comisión de servicio del 2%: 98",
        fived: {
            p1: "Reglas del juego de la Lotería 5D",
            p2: "Instrucciones de sorteo",
            p3: "5 dígitos seleccionados al azar (00000-99999) para cada número",
            p4: "Por ejemplo:",
            p5: "El número actual del sorteo es 12345",
            p6: "A = 1",
            p7: "B = 2",
            p8: "C = 3",
            p9: "D = 4",
            p10: "E = 5",
            p11: "Responder cantando A + B + C + D + E = 15",
            p12: "Cómo jugar",
            p13: "Los jugadores pueden especificar apuestas en los seis resultados y totales de A, B, C, D y E",
            p14: "A, B, C, D, E Se pueden comprar",
            p15: "Números (0 1 2 3 4 5 6 7 8 9)",
            p16: "Bajo (0 1 2 3 4)",
            p17: "Alta (5 6 7 8 9)",
            p18: "Números impares (1 3 5 7 9)",
            p19: "Par (0 2 4 6 8)",
            p20: "Suma = A+B+C+D+E está a la venta",
            p21: "Bajo (0-22)",
            p22: "Alta (23-45)",
            p23: "Números impares (1 3---43 45)",
            p24: "Par (0 2---42 44)",
        },
        racing: {
            p1: "Jugabilidad",
            p2: "※Los tres primeros y：",
            p3: "La suma de los tres primeros números del coche es mayor que 16 para el grande, menor o igual que 16 para el pequeño. Los números pares se llaman dobles, los impares simples. ※Las tres primeras sumas:",
            p4: "※Del primer al tercer puesto：",
            p5: "Designación del número de coche, cada número de coche es una combinación de apuesta, el resultado de la lotería 'número de coche de apuesta' correspondiente al nombre de la apuesta se considera ganador, el resto de la situación se considera no ganadora.",
            p6: "※Smbos lados：",
            p7: "Se refiere a individuales y dobles; grandes y pequeños.",
            p8: "※Simple y doble:",
            p9: "Un número par se llama doble, por ejemplo 2, 4, 6, 8, 10; un número impar se llama simple, por ejemplo 1, 3, 5, 7, 9.",
            p10: "※Grande 、Pequeño",
            p11: "Los números mayores o iguales que 6 se consideran grandes, por ejemplo 6, 7, 8, 9, 10; los menores o iguales que 5 se consideran pequeños, por ejemplo 1, 2, 3, 4, 5.",
        },
        wingo: {
            p1: "Jugabilidad",
            p2: "※Cifra única：",
            p3: "Elija un número para apostar, el número del sorteo y el número de la apuesta se consideran ganadores, el resto de los casos se consideran no ganadores.",
            p4: "※Rosa：",
            p5: "Apostar al rojo se considera ganar si los números de la lotería son 0, 2, 4, 6, 8, y no ganar en el resto de los escenarios.",
            p6: "※Más verde：",
            p7: "Si apuesta al verde, se le considera ganador si los números del sorteo son 1, 3, 5, 7, 9, y se le considera no ganador para el resto de los escenarios.",
            p8: "※Violeta：",
            p9: "Si apuesta al morado, se le considerará ganador si los números de la lotería son el 0 y el 5, y no ganador en el resto de los casos.",
            p10: "※Grande 、Pequeño：",
            p11: "Los números mayores o iguales a 5 se consideran grandes, por ejemplo 5, 6, 7, 8, 9; los menores o iguales a 4 se consideran pequeños, por ejemplo 0, 1, 2, 3, 4.",
        },
        lottery: {
            p1: "Juego ~ 6 abiertos al azar.",
            p2: "A：Grandes y pequeños, individuales y dobles",
            p3: "Tamaño: La suma de los tres números de lotería comprendidos entre el 11 y el 18 se considera grande; la suma del 3 al 10 se considera pequeña.",
            p4: "Sencillo y Doble: Si la suma total de los tres números de la lotería es sencillo, entonces gana sencillo, y si es doble, entonces gana doble.",
            p5: "B：Comprobar números",
            p6: "Si el valor de la suma de los números de la lotería es 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, ganará; por ejemplo, si los números de la lotería son 1, 2, 3, y el valor de la suma es 6, entonces apostando al «6» ganará.",
            p7: "C：Dado simple",
            p8: "Si uno de los tres números de la lotería coincide con el número seleccionado, se considera ganador. Ejemplo: Si los números de la lotería son 1, 1, 3, entonces apostar al dado único 1 o al dado único 3 se considerará ganador. (Independientemente de cuántas veces aparezca el número de puntos especificado, el pago no se duplicará sólo una vez).",
            p9: "D：Dados dobles",
            p10: "Se ganará el premio si dos de los números premiados son iguales y coinciden con la combinación de dados dobles elegida. Ejemplo: Si el número premiado es 1, 1, 3, entonces apuesta al dado doble 1, 1, será el ganador.",
            p11: "E: Round Robin, Round Robin completo",
            p12: "Dados: El premio se ganará si los tres números de la lotería son iguales y coinciden con la combinación de dados elegida.",
            p13: "Dados de círculo completo: Se gana un premio si los tres caracteres de los números de la lotería son iguales.",
            p14: "F: Combinaciones de dos dados",
            p15: "Elija cualquier combinación de 2 dados, cuando el resultado de la lotería coincida con la combinación elegida, se considerará ganadora. Ejemplo: Si los números de la lotería son 1, 2, 3, entonces apostar a la combinación de 2 dados 12, combinación de 2 dados 23, combinación de 2 dados 13 se considerará ganador.",
        }
    },
    webadd: {
        hot: "De moda",
        all: "Completo",
        home: "Salón",
        hot_lottery: "Lotería popular",
        select_channel: "Seleccionar canal de recarga",
        select_bank: "Seleccione un banco",
        top_tab1: "Gane un premio",
        top_tab2: "TOP",
        top_tab3: "Mi",
        web_time: "Hora del sitio web",
        trade: "Tratos",
    },
    footer: {
        foot1: "Fig. principio",
        foot2: "Jugar",
        foot3: "TOP",
        foot4: "Función",
        foot5: "Mi",
    },
    navBar: {
        back_home: "Volver a la página de inicio",
        help_center: "Centro de ayuda",
        contact_service: "Contactar con el Servicio de Atención al Cliente",
        balance: "Saldos",
        my_account: "Mi cuenta",
        account: "Cuenta en línea",
        record: "Récord",
        proxy: "Puesto ",
        other: "El resto",
        deposit: "Recarga",
        withdraw: "Retirar fondos",
        deposit_type1: "Banca electrónica",
        deposit_type2: "Transferencia bancaria",
        deposit_type3: "Moneda digital",
        deposit_type4: "OVO",
        logout: "Abortar",
        login: "Iniciar sesión",
        register: "Inscripción",
        nav1: "Billete de lotería",
        nav2: "Jugar",
        nav3: "Función",
        nav4: "Teléfonos móviles",
        nav5: "Mi cuenta",
    },
    activity: {
        tit1: "Bonificación diaria",
        p1: "El bote diario es una bonificación basada en la cantidad que el usuario apostó ayer, ¡sin límite máximo de premio!",
        activity_detail: "Detalles del evento",
        yestoday_bet: "Apuestas de ayer",
        bonus_ratio: "Ratio de aumento del premio",
        now_level: "Nivel actual",
        receive_bonus: "Con derecho a crédito adicional",
        receive: "Consiga",
        not_receive: "No disponible",
        activity_time: "Tiempo de actividad",
        p2: "1、La bonificación diaria está abierta para su recogida a partir de las 00.20 horas de cada día",
        p3: "2、El porcentaje de la bonificación se basa en el nivel del usuario y en el importe de las apuestas acumuladas ayer para obtener un determinado porcentaje de la bonificación.",
        p4: "3、Las retiradas y otras apuestas no válidas no se contabilizarán.",
        p5: "4、Las apuestas válidas deben cumplir [el sencillo ha sido sorteado y liquidado], en el caso de que el sencillo se sortee muchos días después de haber realizado la apuesta, el importe de apuesta efectivo del sencillo se acumulará en la [Fecha de liquidación del sorteo].。",
        p6: "5、Ejemplo: Si se realiza una apuesta el 10/1 pero el sorteo no es hasta el 15/10, el importe válido de la apuesta se acumulará el 15/10, y el usuario podrá cobrar la bonificación correspondiente a esa apuesta el 16/10.",
        p7: "6、La no recogida de la bonificación antes de las 24:00 horas del día de la prueba se considerará una renuncia automática a la clasificación para la prueba.",
    },
    game: {
        history_tit: "Premios históricos",
        play_tit: "Instrucciones de juego",
        official_award: "El premio oficial original",
        betting: "Apuestas en curso",
        waiting: "A la espera de la lotería",
        opening: "Dibujo en curso.",
        settled: "Asentado",
        auto_change: "Conmutación automática",
        height_win: "Ganancia máxima",
        total: "En resumen",
        pour: "Clasificador de sumas de dinero",
        every_pour: "Cada uno de",
        total_pour: "Número total de apuestas",
        total_amount: "Total de apuestas",
        now_bet: "Apueste ahora",
        my_bet: "Mis apuestas",
        winning: "Gane un premio",
        top: "TOP",
        winning_tip: "La información sobre los ganadores se actualiza en tiempo real",
        top_tip: "Clasificación superior",
    },
    record: {
        lottery: "Billete de lotería",
        game: "Jugar",
        today_profit_loss: "Pérdidas y ganancias de hoy",
        label_formula: "Fórmula del umbral de rentabilidad",
        formula: "Fórmula: Ganancia - Apuesta + Actividad + Descuentos",
        profitLoss_label1: "Gane un premio",
        profitLoss_label2: "Función",
        profitLoss_label3: "Apostantes",
        profitLoss_label4: "Recompensa para afiliados",
        profitLoss_label5: "Transferencia ",
        profitLoss_label6: "Beneficiario",
        profitLoss_label7: "Enviar Paquete Rojo",
        profitLoss_label8: "Recibir un paquete rojo",
        trade_tab1: "Completo",
        trade_tab2: "Recarga ",
        trade_tab3: "Retirar fondos",
    },
    table: {
        label_no: " No.",
        label_time: "Veces",
        label_result: "Al final",
        label_size: "Magnitud",
        label_single_double: "Pares e impares",
        label_sun_value: "Suma",
        no_data: "No hay datos disponibles",
        big: "Más antiguo",
        small: "Algunos",
        single: "Mono",
        double: "Apellido Shuang",
        label_issue: "Número de expedición",
        label_bet_amount: "Importe de la apuesta",
        label_reward: "Conceder dinero",
        no_reward: "No es un ganador",
        is_reward: "Gane un premio",
        label_level: "Jerarquía",
        label_integral: "Puntos de crecimiento",
        label_award: "Recompensas por ascenso",
        label_skip_awardl: "Saltar nivel de bonificación",
        label_register_link: "Enlace de inscripción",
        label_remark: "Nota",
        label_rise_time: "Tiempo de generación",
        label_register_number: "Número de inscripciones",
        label_operate: "Plataforma",
        label_gametit: "Colores/juegos",
        label_lower_rebate: "Recompensa para afiliados",
        label_self_rebate: "Reembolso propio",
        label_set_rebate_range: "Gama de rebajas",
        bonus_state: "Bonificación",
        label_serial_number: "Señal de emboscada",
        label_income_amount: "Importe de los ingresos",
        label_expend_amount: "Importe del gasto",
        label_available_balance: "Saldo disponible",
        label_abstract: "Resúmenes",
        label_details: "Datos",
        label_account: "Cuenta en línea",
        label_amount: "Suma de dinero",
        label_bet_cont: "Contenido de las apuestas",
        label_ottery_number: "Número de lotería",
        order_number: "Número impar",
        play_methods: "Forma de jugar",
        label_periods: "Fase",
        label_number: "Números",
        label_color: "Color",
    },
    my: {
        last_login_time: "Última hora de conexión",
        logout_account: "Retirada de la cuenta",
        my_account: "Mi cuenta",
        account: "Cuenta en línea",
        personal_tit: "Perfil personal",
        security_tit: "Centro de seguridad",
        bank_tit: "Número de cuenta bancaria",
        record: "Récord",
        profit_loss_tit: "Pérdidas y ganancias de hoy",
        trade_record_tit: "Registros de transacciones",
        bet_record: "Récords de apuestas",
        proxy: "Puesto",
        manag_tit: "Gestión de la Agencia",
        report_tit: "Declaraciones de representación",
        lower_bet_tit: "Apuestas en el nivel inferior",
        lower_trade_tit: "Operaciones subordinadas",
        other: "El resto",
        gift_tit: "Regalos para eventos",
        message_tit: "Centro de noticias",
        personal_data: "Perfil personal",
        balance: "Saldos",
        last_sync_time: "Última hora de sincronización",
        deposit: "Recarga ",
        withdraw: "Retirar fondos",
        yesterday_deposit_time: "Tiempo medio de recarga ayer",
        yesterday_withdraw_time: "Tiempo medio para retirar efectivo ayer",
        help_tit: "Centro de ayuda",
        all: "Completo",
        lower_report: "Declaraciones subordinadas",
        about_us: "Quiénes somos",
        logout: "Aparecer",
        invite: "Invitar a amigos",
        lang_change: "Cambio de idioma",
    },
    account: {
        add_bank_account: "Añadir número de cuenta bancaria",
        bank_tip: "Pueden añadirse hasta 5 cuentas bancarias. Las cuentas bancarias que se hayan retirado con éxito se bloquearán automáticamente y no podrán borrarse ni modificarse. Los números de cuentas bancarias que no se hayan retirado con éxito podrán modificarse y no podrán borrarse.",
        account_msg: "Información sobre la cuenta",
        growth_value: "Tasa de crecimiento",
        noset_nickname: "Apodo no fijado",
        nationality: "Ciudadanía",
        next_level: "Siguiente nivel",
        integral: "Integral",
        integral_tip: "Por cada 1,00 $ que recargues, obtienes 1 punto.",
        level_system: "Jerarquía",
        change_avatar: "Cambiar avatar",
        loginpsd_tip: "Contraseña de inicio de sesión Combinación de letras y números de 6 a 16 dígitos",
        modify_psd: "Cambie su contraseña",
        securitypsd_tip: "Contraseña de seguridad (combinación de 6 dígitos)",
        forget_securitypsd: "Código de seguridad olvidado",
        confidentiality_tip: "Recupere la información de la cuenta de usuario en caso de problemas de seguridad, pérdida del número de cuenta o de la contraseña.",
        bank_account: "Número de cuenta bancaria",
        edit_nickname: "Editar apodo",
        confidentiality: "Cuestiones de seguridad",
        accumulated_winning: "Bote progresivo",
    },
    agent: {
        illustrate_p1: "Cuando usted puede ver esta página, significa que su cuenta es a la vez una cuenta de jugador y una cuenta de agente, lo que le permite hacer sus propias apuestas, así como desarrollar jugadores de nivel inferior y ganar comisiones de reembolso.",
        illustrate_p2: "Cómo obtener rebajas？",
        illustrate_p3: "La rebaja que puede obtener es igual a la diferencia entre su propia rebaja y la rebaja del nivel inferior, como su propia rebaja de 5 y la rebaja del nivel inferior de 3, podrá obtener la rebaja de la cantidad apostada del nivel inferior de 2, como la apuesta del nivel inferior en los 100 $, obtendrá 2 $. haga clic en el nivel inferior de la cuenta, puede ver su propia rebaja, y también puede establecer la rebaja para el nivel inferior.",
        illustrate_p4: "Cómo abrir una cuenta para un subordinado？",
        illustrate_p5: "Haga clic en la cuenta subordinada, en primer lugar establecer descuentos para sus subordinados, establecer un código de invitación con éxito se generará, el código de invitación será enviado a su registro subordinado, el registro, él es su subordinado, haga clic en la gestión de usuarios, puede ver su cuenta registrada.",
        illustrate_p6: "Si configuras una cuenta de tipo agente para tus subordinados, entonces tus subordinados podrán seguir desarrollando a sus subordinados. Si configuras una cuenta de tipo jugador, entonces tus subordinados sólo podrán hacer apuestas y no podrán desarrollar a sus subordinados y no podrán ver el centro de agentes. Gestión de usuarios",
        illustrate_tip1: "1、Las probabilidades de reembolso son diferentes, haga clic en la tabla de probabilidades de reembolso, puede ver las probabilidades de reembolso。",
        illustrate_tip2: "2、Cuanto menor sea la rebaja, menores serán las probabilidades, ¡se recomienda que la rebaja fijada para el nivel inferior no sea demasiado baja!。",
        illustrate_tip3: "3、La evolución de los agentes puede consultarse en Informes de agentes, Apuestas bajistas, Operaciones bajistas。",
        illustrate_tip4: "4、Se recomienda que la apertura del siguiente nivel es también el tipo de agente, independientemente del número de niveles desarrollados, recibirá descuentos。",
        open_type: "Tipo de apertura de cuenta：",
        open_type1: "Completo",
        open_type2: "Puesto",
        open_type3: "Jugador",
        invite_tip1: "1、Diferentes rebajas tienen diferentes probabilidades, cuanto mayor sea la rebaja, ¡mayores serán las probabilidades!。",
        invite_tip2: "2、El agente puede obtener la comisión es igual a la diferencia entre la rebaja propia del agente y la rebaja subordinada, como la rebaja propia del agente de 6, la rebaja subordinada de 4, el agente puede obtener la subordinada 2% del importe de las apuestas, es decir, la apuesta subordinada de 100 yuanes, el agente puede obtener 2 yuanes.",
        invite_tip3: "3、Cuanto más bajo se fije el valor del descuento para el nivel inferior, más bajas serán las probabilidades para el nivel inferior, se recomienda que el descuento fijado para el nivel inferior no sea demasiado bajo.",
        rebateDialog_tit: "Ver rebajas",
        delete_invite_tip: "¿Está seguro de que desea eliminar este código de invitación?？",
        rebate_table: "Tabla de probabilidades de reembolso",
        generate_invitation_code: "Generar código de invitación",
        subordinate_tip1: "1、Diferentes rebajas tienen diferentes probabilidades, cuanto mayor sea la rebaja, ¡mayores serán las probabilidades!。",
        subordinate_tip2: "2、El agente puede obtener la comisión es igual a la diferencia entre la rebaja propia del agente y la rebaja subordinada, como la rebaja propia del agente de 6, la rebaja subordinada de 4, el agente puede obtener la subordinada 2% del importe de las apuestas, es decir, la apuesta subordinada de 100 yuanes, el agente puede obtener 2 yuanes.。",
        subordinate_tip3: "3、Cuanto más bajo se fije el valor del descuento para el nivel inferior, más bajas serán las probabilidades para el nivel inferior, se recomienda que el descuento fijado para el nivel inferior no sea demasiado bajo.",
        manage_tab1: "Descripción del agente",
        manage_tab2: "Apertura de una cuenta subordinada",
        manage_tab3: "Código de invitación",
        manage_tab4: "Gestión de usuarios",
        lower_bet_tip: "Los registros de transacciones se conservan un máximo de 21 días.",
        report_label1: "Apostantes",
        report_label2: "Gane un premio",
        report_label3: "Función",
        report_label4: "Reembolsos por equipo",
        report_label5: "Beneficios del equipo",
        report_label6: "Número de apostantes",
        report_label7: "Número de primer cargo",
        report_label8: "Número de inscritos",
        report_label9: "Número de subordinados",
        report_label10: "Equilibrio del equipo",
        report_label11: "Recarga",
        report_label12: "Retirar fondos",
        report_label13: "Descuento para agentes",
        report_label14: "Transferencia",
        report_label15: "Beneficiario",
        report_label16: "Enviar Paquete Rojo",
        report_label17: "Recibir un paquete rojo",
    },
    fund: {
        tip1: "Por favor, haga una transferencia a la siguiente cuenta bancaria",
        tip2: "Rellene cuidadosamente los datos de su transferencia",
        tip3: " (Por favor, asegúrese de transferir los fondos antes de enviar su pedido, de lo contrario su pago no será rastreado a tiempo.！)",
        deposit_rate: "Tipo de cambio del depósito",
        currency_number: "Importe de la divisa",
        tip4: "Utilice la plataforma de moneda digital correspondiente para transferir dinero",
        tip5: "Límite de una carrera",
        tip6: "(Si no puede rellenar la información de autenticación, cargue una captura de pantalla del recibo de la transacción.)",
        click_upload: "Haga clic para cargar",
        upload_tip: "Sólo se pueden cargar archivos jpg/png",
        receiving_address: "Dirección del beneficiario",
        tip7: "Utilice OVO para transferir fondos a las siguientes cuentas bancarias",
        tip8: "1、Se requiere banca por Internet para efectuar el pago。",
        tip9: "2、Una vez efectuada la recarga, haga clic en 'Volver al comerciante' para recibir el abono automáticamente.。",
    },
    other: {
        gift_tip1: "1、Los regalos sólo pueden solicitarse una vez cumplidas las condiciones para solicitarlos。",
        gift_tip2: "2、Los regalos no reclamados caducan automáticamente tras la fecha de expiración y no pueden reclamarse。",
        other_tip1: "Las cartas se archivan durante un máximo de 7 días;",
    },
    form: {
        input_inner: "Introduzca el contenido",
        label_username: "Cuenta de usuario：",
        place_username: "Introduzca su cuenta de usuario",
        label_loginpsd: "Contraseña de acceso：",
        place_loginpsd: "Introduzca su contraseña de acceso",
        label_invitecode: "Código de invitación：",
        place_invitecode: "Por favor, introduzca el código de invitación de 8 dígitos",
        label_oldpsd: "Contraseña original",
        place_oldpsd: "Introduzca la contraseña original",
        label_newpsd: "Nueva contraseña",
        place_newpsd: "Introduzca una nueva contraseña",
        label_crmpsd: "Confirmar contraseña",
        place_crmpsd: "Vuelva a introducir la nueva contraseña",
        label_remark: "Nota",
        place_remark: "Introduzca una nota",
        label_lower_account: "Cuenta:",
        place_lower_account: "Introduzca el número de cuenta subordinada",
        label_type: "Tipo:",
        place_select: "Seleccione",
        label_lottery_title: "Premio de lotería：",
        label_state: "Situación：",
        option_state1: "Completo",
        option_state2: "Lotería pendiente",
        option_state3: "No es un ganador",
        option_state4: "Han ganado un premio",
        label_time: "Veces：",
        times1: "En la actualidad",
        times2: "Ayer",
        times3: "El mes en curso",
        times4: "Ultimo",
        label_realname: "Nombre y apellidos reales",
        place_realname: "Introduzca su nombre real",
        label_bank_number: "Número de tarjeta ATM",
        place_bank_number: "Introduzca el número de su tarjeta bancaria",
        label_bank_name: "Nombre del banco",
        place_bank_name: "Seleccione el nombre del banco",
        label_abstract: "Resúmenes:",
        label_channel: "Canal de recarga",
        place_channel: "Seleccione el canal de recarga",
        label_deposit_amount: "Importe de recarga",
        place_deposit_amount: "Introduzca el importe de la recarga",
        label_holder: "Titular de la cuenta",
        label_deposit_name: "Nombre del depositante",
        place_deposit_name: "Introduzca el nombre del depositante",
        label_transfer_msg: "Información sobre transferencias",
        place_transfer_msg: "Rellene los datos de la transferencia",
        label_transfer_pic: "Captura de pantalla del recibo de la transacción",
        label_transfer_pic_tip: "(Cargue una captura de pantalla del recibo de la transacción)",
        label_bank_account_name: "Nombre de la cuenta bancaria",
        label_balance: "Saldos",
        label_available_withdraw: "Importe retirable",
        label_available_number: "Número de retiradas",
        label_withdraw_amount: "Importe de la retirada",
        placce_withdraw_amount: "Introduzca el importe de la retirada)",
        available_withdraw: "Deseabilidad",
        label_transfer_bank: "Banco deudor",
        label_security_psd: "Código de seguridad",
        place_security_psd: "Introduzca el código de seguridad",
        place_nickname: "Término cariñoso",
        message_nickname: "Introduzca un apodo",
        place_phone: "Introduzca su número de teléfono móvil",
        place_email: "Introduzca su dirección de correo electrónico",
        place_invite_id: "Introduzca el código de invitación",
        place_verify_code: "Introduzca el código de verificación",
        send: "Enviando",
        test_username: "Nombre de usuario de prueba",
        place_amount: "Introduzca el importe",
    },
    login: {
        login_tit: "Inicio de sesión de usuario",
        forget_psd: "¿Ha olvidado su contraseña?？",
        login: "Iniciar sesión",
        register_tit: "Registro de usuarios",
        register: "Inscripción",
        have_account: "Si ya tiene una cuenta, inicie sesión",
        country_code: "Código del país",
        phone: "Teléfonos móviles",
        email: "Bandeja de entrada",
    },
    city: {
        albania: "Albania",
        algeria: "Argelia",
	    argentina: "Argentina",
	    armenia:  "Armenia",
	    australia: "Australia",
	    pakistan: "Pakistán",
        austria:  "Austria",
	    bahrain: "Bahrein",
	    belgium: "Bélgica",
	    bosnia_and_Herzegovina: "Bosnia y Herzegovina",
	    brazil: "Brasil",
	    brunei: "Brunei",
	    bulgaria: "Bulgaria",
	    cambodia: "Camboya",
		canada: "Canadá",
		cameroon: "Camerún",
		chile: "Chile",
		colombia: "Colombia",
		costa_Rica: "Costa Rica",
		croatia: "Croacia",
		cyprus: "Chipre",
		czech_Republic: "República Checa",
		denmark: "Dinamarca",
		dominican_Republic: "República Dominicana",
		egypt: "Egipto",
		estonia: "Estonia",
		ethiopia: "Etiopía",
        finland : "Finlandia",
		france: "Francia",
		georgia: "Georgia",
		germany: "Alemania",
		ghana: "Ghana",
		greece: "Grecia",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong kong, China",
		hungary: "Hungría",
		iceland: "Islandia",
		ireland: "Irlanda",
		italy: "Italia",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israel",
		iran: "Irán",
		iraq: "Irak",
		japan: "Japón",
		kazakstan: "Kazajstán",
		kenya: "Kenia",
		korea: "Corea del Sur",
		kuwait: "Kuwait",
		kyrgyzstan:"Kirguistán",
		laos:"Laos",
		latvia:"Letonia",
		lithuania:"Lituania",
		luxembourg:"Luxemburgo",
		macao_China:"Macao, China",
		macedonia:"Macedonia",
		malaysia:"Malasia",
		malta:"Malta",
		mexico:"México",
		moldova:"Moldavia",
		monaco:"Mónaco",
		mongolia:"Mongolia",
		montenegro:"República de Montenegro",
		morocco:"Marruecos",
		myanmar:"Myanmar",
		netherlands:"Países Bajos",
		new_Zealand:"Nueva Zelanda",
		nepal:"Nepal",
		nigeria:"Nigeria",
		norway:"Noruega",
		oman:"Omán",
		palestine:"Palestina",
		panama:"Panamá",
		paraguay:"Paraguay",
		peru:"Perú",
		philippines:"Filipinas",
		poland:"Polonia",
		portugal:"Portugal",
		puerto_Rico:"Puerto Rico",
		qatar:"Qatar",
		romania:"Rumanía",
		russia:"Rusia",
		republic_of_Trinidad_and_Tobago:"República de Trinidad y Tobago",
		rwanda:"Rwanda",
		saudi_Arabia:"Arabia Saudita",
		serbia:"Serbia",
		singapore:"Singapur",
		slovakia:"Eslovaquia",
		slovenia:"Eslovenia",
		south_Africa:"Sudáfrica",
		spain:"España",
		sri_Lanka:"Sri Lanka",
		sweden:"Suecia",
		switzerland:"Suiza",
		taiwan_China:"Taiwán, China",
		tajikistan:"Tayikistán",
		tanzania:"Tanzania",
		thailand:"Tailandia",
		turkey:"Turquía",
		turkmenistan:"Turkmenistán",
		ukraine:"Ucrania",
		united_Arab_Emirates:"Emiratos Árabes Unidos",
		united_Kingdom:"Reino Unido",
		united_States:"Estados Unidos",
		uzbekistan:"Uzbekistán",
		venezuela:"Venezuela",
		vietnam:"Vietnam",
		afghanistan:"Afganistán",
		angola:"Angola",
		azerbaijan:"Azerbaiyán",
		bangladesh:"Bangladesh",
		belarus:"Bielorrusia",
		belize:"Belice",
		benin:"Benin",
		bhutan:"Bhután",
		bolivia:"Bolivia",
		botswana:"Botswana",
		british_Virgin_Islands:"Islas Vírgenes Británicas",
		burkina_Faso:"Burkina Faso",
		burundi:"Burundi",
		cape_Verde:"Cabo Verde",
		cayman_Islands:"Islas Caimán",
		central_African_Republic:"República Centroafricana",
		chad:"Chad",
		comoros:"Comoras",
		the_Republic_of_the_Congo:"Congo (brazzaville)",
		democratic_Republic_of_the_Congo:"República Democrática del Congo (rdc)",
		djibouti:"Djibouti",
		ecuador:"Ecuador",
		el_Salvador:"El Salvador",
		equatorial_Guinea:"Guinea Ecuatorial",
		eritrea:"Eritrea",
		fiji:"Fiji",
		gabon:"Gabón",
		gambia:"Gambia",
		greenland:"Groenlandia",
		guatemala:"Guatemala",
		guinea:"Guinea",
		haiti:"Haití",
		isle_of_Man:"Isla de Man",
		cote_d_Ivoire:"Costa de Marfil",
		jamaica:"Jamaica",
		jordan:"Jordania",
		lebanon:"Líbano",
		lesotho:"Lesotho",
		liberia:"Liberia",
		libya:"Libia",
		madagascar:"Madagascar",
		malawi:"Malawi",
		maldives:"Maldivas",
		mali:"Malí",
		mauritania:"Mauritania",
		mauritius:"Mauricio",
		mozambique:"Mozambique",
		namibia:"Namibia",
		nicaragua:"Nicaragua",
		republic_of_Niger:"Níger",
		north_Korea:"Corea del Norte",
		reunion:"Reunión",
		san_Marino:"San Marino",
		senegal:"Senegal",
		sierra_Leone:"Sierra Leona",
		somalia:"Somalia",
		sudan:"Sudán",
		suriname:"Surinam",
		eswatini:"Swazilandia",
		syria:"Siria",
		togo:"Togo",
		tonga:"Tonga",
		tunisia:"Túnez",
		united_States_Virgin_Islands:"Islas Vírgenes de los Estados Unidos",
		uganda:"Uganda",
		uruguay:"Uruguay",
		vatican:"Vaticano",
		yemen:"Yemen",
		yugoslavia:"Yugoslavia",
		zambia:"Zambia",
		zimbabwe:"Zimbabwe",
		china:"China",
	}
};
