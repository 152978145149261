export default {
    common: {
        contactNow: "Contact Now",
        minPrice: "Min",
        maxPrice: "Max",
        address: {
            title: "Wallet address",
            account_number: "Account number",
            add_btn: "Add address",
            label_type: "Select type",
            place_type: "Please select type",
            label_number: "Account",
            place_number: "Please enter the account number",
            wallet_address: 'Wallet address',
            bank_name: 'BANK NAME',
            bank_address: "Bank address",
            name: "Name",
            payee_address: 'Beneficiary Address',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Branch Name",
            branch_code: "BRANCH CODE",
            bank_code: "Bank code",
            phone: "Cell-phone number",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Please enter content",
        },
        priceType: {
            fast_sum: "сумма",
            fast_three_all: "Три одинаковых номера Общие выборы",
            fast_three_one: "Три одинаковых числа Один выбор",
            fast_three_diff: "Три разных номера",
            fast_three_continue: "Тройные числа с множественным выбором (TNT) (вычисления)",
            fast_two_some: "Двойные индексы Множественный выбор",
            fast_two_one: "Двойной индивидуальный выбор",
            fast_two_diff: "Биссектриса числа (электроника)",
            fast_one_one: "лит. одно сито платит один за один",
            racing_winner: "чемпионы",
            racing_second: "второе место ",
            racing_third: "бронзовый призёр",
            lotto_one: "A",
            lotto_two: "B",
            lotto_three: "C",
            lotto_four: "D",
            lotto_five: "E",
            wingo_sum: "합계",
            wingo_color: "Color",
            Big: "Старейший",
            Small: "несколько",
            Single: "моно",
            Double: "фамилия Шуан",
            Red: "бонус",
            Green: "зеленый",
            Purple: "цветущее растение, корень ",
        },
        img_error: "Изображение не удалось загрузить",
        more: "подробнее",
        cancel: "отмены",
        confirm: "определить",
        tips: "подсказка：",
        no_set: "неисправленный",
        submit: "представить",
        copy_success: "Успех копирования！",
        copy_error: "Нарушение репродуктивной функции！",
        currency: "$",
        filter: "грохочение",
        edit: "модификации",
        gameStart: "Начало игры",
        prizenumber: "выигрышный номер",
        mynumber: "Мой номер.",
        addresss: "Вот адрес ссылки",
        gameAgain: "Еще раз.",
        gamerule: "правила игры",
        winning_numbers: "выигрышный номер",
        winning: "Поздравляем с победой!！",
        notwin: "К сожалению, вы не выиграли!",
        logout: "Вы уверены, что хотите выйти из системы?？",
        success: "успехи",
        search: "искать что-л.",
        withdraw0: "заявление",
        withdraw1: "в процессе",
        withdraw2: "успехи",
        withdraw3: "не удается",
        balanceNot: "Недостаточный остаток на счете",
        canceled: "отменено",
        bill: {
            bill101: "Пополнение счета пользователя",
            bill102: "системный интегратор",
            bill103: "Замерзание системы",
            bill104: "Размораживание системы",
            bill201: "Вывод средств пользователями",
            bill202: "Системные вычеты",
            bill203: "Вывод средств Успешный",
            bill204: "Отказ в аудите отзыва",
            bill205: "Плата за вывод средств",
            bill301: "Присоединяйтесь к игре",
            bill302: "Игровая прибыль",
        }
    },
    playMethod: {
        p1: "Пожалуйста, выберите правила игры, которую вы хотите просмотреть",
        p2: "Если вы изучите правила, то удвоите свой заработок.",
        des1: "краткое содержание",
        des2: "Если вы торгуете на 100, то сумма контракта после вычета комиссии за обслуживание в размере 2%: 98",
        fived: {
            p1: "Правила игры в лотерею 5D",
            p2: "инструкции по рисованию",
            p3: "5 случайно выбранных цифр (00000-99999) для каждого выпуска",
            p4: "например:",
            p5: "Текущий номер тиража - 12345",
            p6: "A = 1",
            p7: "B = 2",
            p8: "C = 3",
            p9: "D = 4",
            p10: "E = 5",
            p11: "и = A + B + C + D + E = 15",
            p12: "Как играть",
            p13: "Игроки могут делать ставки на шесть исходов и тоталы A, B, C, D и E.",
            p14: "A, B, C, D, E можно приобрести",
            p15: "Числа (0 1 2 3 4 5 6 7 8 9)",
            p16: "Низкий (0 1 2 3 4)",
            p17: "Высокий (5 6 7 8 9)",
            p18: "Нечетные числа (1 3 5 7 9)",
            p19: "Даже (0 2 4 6 8)",
            p20: "Сумма = A+B+C+D+E можно купить",
            p21: "Низкий (0-22)",
            p22: "Высокий (23-45)",
            p23: "Нечетные числа (1 3---43 45)",
            p24: "Даже (0 2---42 44)",
        },
        racing: {
            p1: "Геймплей",
            p2: "※Три лучших и：",
            p3: "Сумма первых трех номеров машин больше 16 для больших, меньше или равна 16 для маленьких. Четные числа называются двойными, нечетные - одинарными. ※ Первые три суммы:",
            p4: "※С первого по третье место：",
            p5: "Обозначение номера автомобиля, каждый номер автомобиля представляет собой комбинацию ставок, результат лотереи «номер автомобиля», соответствующий названию ставки, считается выигрышным, остальные ситуации считаются не выигрышными.",
            p6: "※обе стороны：",
            p7: "Относится к одинарным и двойным, большим и маленьким.",
            p8: "※Одноместные и двухместные：",
            p9: "Четное число называется двойкой, например, 2, 4, 6, 8, 10; нечетное - единицей, например, 1, 3, 5, 7, 9.",
            p10: "※Большие и маленькие",
            p11: "Числа больше или равные 6 считаются большими, например 6, 7, 8, 9, 10; меньше или равные 5 считаются маленькими, например 1, 2, 3, 4, 5.",
        },
        wingo: {
            p1: "Геймплей",
            p2: "※одна цифра：",
            p3: "Выберите номер для ставки, ничейный номер и номер ставки считаются выигрышными, остальные случаи считаются не выигрышными.",
            p4: "※розовый：",
            p5: "Ставка на красное считается выигрышной, если в лотерее выпали числа 0, 2, 4, 6, 8, и беспроигрышной при остальных раскладах.",
            p6: "※зеленее：",
            p7: "Если вы поставили на зеленый цвет, вы считаетесь победителем, если выпали числа 1, 3, 5, 7, 9, а при остальных раскладах вы считаетесь невыигравшим.",
            p8: "※фиолетовый (цвет)：",
            p9: "Если вы поставите на фиолетовый цвет, вы будете считаться победителем, если в лотерее выпадут числа 0 и 5, и не победителем в остальных случаях.",
            p10: "※Большие и маленькие：",
            p11: "Числа рисунка больше или равные 5 считаются большими, например, 5, 6, 7, 8, 9; меньше или равные 4 - маленькими, например, 0, 1, 2, 3, 4.",
        },
        lottery: {
            p1: "Игровой процесс ~ 6 случайных открытий.",
            p2: "A：большие и маленькие, одноместные и двухместные",
            p3: "Размер: сумма трех лотерейных номеров от 11 до 18 считается большой; сумма от 3 до 10 - маленькой.",
            p4: "Одиночный и двойной: если сумма трех лотерейных номеров одинарная, то выигрывает одиночный, а если двойная, то двойной.",
            p5: "B：контрольные цифры",
            p6: "Если сумма чисел лотереи равна 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, вы выиграете; например, если числа лотереи - 1, 2, 3, а сумма равна 6, то ставка на «6» принесет выигрыш.",
            p7: "C：один кубик",
            p8: "Если один из трех номеров лотереи совпадает с выбранным номером, он считается выигрышным. Пример: если номера лотереи - 1, 1, 3, то выигрышной считается ставка на одиночный кубик 1 или одиночный кубик 3. (Независимо от того, сколько раз выпадет указанное количество очков, выплата не будет удвоена только один раз).",
            p9: "D：двойные кости",
            p10: "Приз будет выигран, если любые два призовых номера совпадут с выбранной комбинацией двойных костей. Пример: если призовое число 1, 1, 3, то ставка на двойной кубик 1, 1 будет выигрышной.",
            p11: "E：Раунд Робин, Полный раунд Робин",
            p12: "Кубики: приз будет выигран, если три номера лотереи совпадут с выбранной комбинацией кубиков.",
            p13: "Кубики «Полный круг»: приз можно выиграть, если все три символа лотерейных номеров совпадают.",
            p14: "F：комбинация из двух кубиков",
            p15: "Выбирайте любую комбинацию из 2 костей, если результат лотереи совпадет с выбранной комбинацией, она будет считаться выигрышной. Пример: если номера лотереи - 1, 2, 3, то выигрышной будет считаться ставка на комбинацию из 2 кубиков 12, 2 кубиков 23, 2 кубиков 13.",
        }
    },
    webadd: {
        hot: "в моде",
        all: "полный",
        home: "салон",
        hot_lottery: "Популярная лотерея",
        select_channel: "Выберите канал пополнения счета",
        select_bank: "Выберите банк",
        top_tab1: "выиграть приз",
        top_tab2: "TOP",
        top_tab3: "мой",
        web_time: "Время работы сайта",
        trade: "сделки",
    },
    footer: {
        foot1: "рис. начало",
        foot2: "играть",
        foot3: "TOP",
        foot4: "функция",
        foot5: "мой",
    },
    navBar: {
        back_home: "Вернуться на главную страницу",
        help_center: "Центр помощи",
        contact_service: "Обратитесь в службу поддержки клиентов",
        balance: "балансы",
        my_account: "Мой счет",
        account: "онлайн-счёт",
        record: "рекорд",
        proxy: "действовать",
        other: "остальное",
        deposit: "пополнение",
        withdraw: "вывести средства",
        deposit_type1: "онлайн-банкинг",
        deposit_type2: "банковский перевод",
        deposit_type3: "цифровая валюта",
        deposit_type4: "OVO",
        logout: "прервать",
        login: "войти в систему",
        register: "зачисление",
        nav1: "лотерейный билет",
        nav2: "играть",
        nav3: "функция",
        nav4: "мобильные телефоны",
        nav5: "Мой счет",
    },
    activity: {
        tit1: "Ежедневный бонус",
        p1: "Ежедневный джекпот - это бонус, основанный на сумме, которую пользователь поставил вчера, без верхнего предела приза!",
        activity_detail: "Подробности события",
        yestoday_bet: "Вчерашние ставки",
        bonus_ratio: "Коэффициент увеличения премии",
        now_level: "текущий уровень",
        receive_bonus: "право на дополнительный кредит",
        receive: "получить",
        not_receive: "недоступно",
        activity_time: "Время активности",
        p2: "1、Ежедневный бонус можно получить после 00:20 утра каждого дня",
        p3: "2、Процент бонуса зависит от уровня пользователя и накопленной за вчерашний день суммы ставок для получения определенного процента бонуса!",
        p4: "3、Вывод средств и другие недействительные ставки не учитываются",
        p5: "4、Действительные ставки должны удовлетворять требованиям [одинар был разыгран и рассчитан], в случае если одинар будет разыгран через много дней после размещения ставки, эффективная сумма ставки на одинар будет накоплена в [Дата расчета розыгрыша].。",
        p6: "5、Пример: если ставка сделана на 10/1, но розыгрыш состоится только 15 октября, то сумма действительной ставки будет накоплена 10/15, и пользователь сможет получить бонус за эту ставку 10/16.",
        p7: "6、Неполучение бонуса до 24:00 в день соревнований считается автоматическим отказом от участия в квалификации.",
    },
    game: {
        history_tit: "Исторические премии",
        play_tit: "Инструкции по игре",
        official_award: "оригинальный официальный приз",
        betting: "Ставки в процессе",
        waiting: "В ожидании лотереи",
        opening: "Рисунок в процессе работы.",
        settled: "поселился",
        auto_change: "автоматическое переключение",
        height_win: "Максимальный выигрыш",
        total: "в сумме",
        pour: "классификатор денежных сумм",
        every_pour: "каждый из",
        total_pour: "общее количество ставок",
        total_amount: "Всего ставок",
        now_bet: "Сделать ставку сейчас",
        my_bet: "Мои ставки",
        winning: "выиграть приз",
        top: "TOP",
        winning_tip: "Информация о победах обновляется в режиме реального времени",
        top_tip: "Рейтинг лучших",
    },
    record: {
        lottery: "лотерейный билет",
        game: "играть",
        today_profit_loss: "Сегодняшние прибыли и убытки",
        label_formula: "формула безубыточности",
        formula: "Формула: Выигрыш - Ставки + Активность + Возвраты",
        profitLoss_label1: "выиграть приз",
        profitLoss_label2: "функция",
        profitLoss_label3: "бетторы",
        profitLoss_label4: "партнёрское вознаграждение",
        profitLoss_label5: "перевод  ",
        profitLoss_label6: "получатель платежа",
        profitLoss_label7: "Отправить красный пакет",
        profitLoss_label8: "Получите красный пакет",
        trade_tab1: "полный",
        trade_tab2: "пополнение",
        trade_tab3: "вывести средства",
    },
    table: {
        label_no: " No.",
        label_time: "раз",
        label_result: "в конце концов",
        label_size: "величина",
        label_single_double: "четные и нечетные",
        label_sun_value: "сумма",
        no_data: "Нет данных",
        big: "Старейший",
        small: "несколько",
        single: "моно",
        double: "фамилия Шуан",
        label_issue: "номер выпуска",
        label_bet_amount: "размер ставки",
        label_reward: "премиальные деньги",
        no_reward: "не победитель",
        is_reward: "выиграть приз",
        label_level: "иерархия",
        label_integral: "Точки роста",
        label_award: "Награды за продвижение по службе",
        label_skip_awardl: "Бонус за пропуск уровня",
        label_register_link: "Ссылка для регистрации",
        label_remark: "примечание",
        label_rise_time: "время генерации",
        label_register_number: "Количество регистраций",
        label_operate: "буровая установка",
        label_gametit: "Цвета/игры",
        label_lower_rebate: "партнёрское вознаграждение",
        label_self_rebate: "собственный возврат",
        label_set_rebate_range: "Диапазон скидок",
        bonus_state: "бонусный статус",
        label_serial_number: "сигнал о засаде",
        label_income_amount: "Сумма дохода",
        label_expend_amount: "Сумма расходов",
        label_available_balance: "свободный остаток",
        label_abstract: "рефераты",
        label_details: "информация",
        label_account: "онлайн-счёт",
        label_amount: "сумма денег",
        label_bet_cont: "Содержание ставок",
        label_ottery_number: "номер лотереи",
        order_number: "нечетное число",
        play_methods: "способ игры",
        label_periods: "фаза",
        label_number: "номера",
        label_color: "цвет",
    },
    my: {
        last_login_time: "Время последнего входа в систему",
        logout_account: "Снятие средств со счета",
        my_account: "Мой счет",
        account: "онлайн-счёт",
        personal_tit: "личный профиль",
        security_tit: "центр безопасности",
        bank_tit: "Номер банковского счета",
        record: "рекорд",
        profit_loss_tit: "Сегодняшние прибыли и убытки",
        trade_record_tit: "Записи о сделках",
        bet_record: "Рекорды ставок",
        proxy: "действовать",
        manag_tit: "Управление агентством",
        report_tit: "Декларации по доверенности",
        lower_bet_tit: "Ставки на нижнем уровне",
        lower_trade_tit: "Субординированные сделки",
        other: "остальное",
        gift_tit: "Подарки для мероприятий",
        message_tit: "Центр новостей",
        personal_data: "личный профиль",
        balance: "балансы",
        last_sync_time: "Время последней синхронизации",
        deposit: "пополнение",
        withdraw: "вывести средства",
        yesterday_deposit_time: "Среднее время пополнения счета за вчерашний день",
        yesterday_withdraw_time: "Среднее время снятия наличных за вчерашний день",
        help_tit: "Центр помощи",
        all: "полный",
        lower_report: "Субординированные заявления",
        about_us: "О нас",
        logout: "появляться",
        invite: "Пригласите друзей",
        lang_change: "Переключение языка",
    },
    account: {
        add_bank_account: "Добавьте номер банковского счета",
        bank_tip: "Можно добавить до 5 банковских счетов. Банковские счета, с которых были успешно сняты средства, автоматически блокируются и не могут быть удалены или изменены. Номера банковских счетов, с которых не были успешно сняты средства, могут быть изменены, но не могут быть удалены.",
        account_msg: "Информация о счете",
        growth_value: "темпы роста",
        noset_nickname: "Прозвище не установлено",
        nationality: "гражданство",
        next_level: "Следующий уровень",
        integral: "общее",
        integral_tip: "За каждый пополненный счет на 1,00 $ вы получаете 1 балл.",
        level_system: "иерархия",
        change_avatar: "Изменить аватар",
        loginpsd_tip: "Пароль для входа от 6 до 16 цифр, состоящих из букв и цифр",
        modify_psd: "изменить пароль",
        securitypsd_tip: "Пароль безопасности (6-значная комбинация)",
        forget_securitypsd: "Забыли код безопасности",
        confidentiality_tip: "Восстановление информации об учетной записи пользователя в случае проблем с безопасностью, потери номера учетной записи или пароля.",
        bank_account: "Номер банковского счета",
        edit_nickname: "Редактировать прозвище",
        confidentiality: "Вопросы безопасности",
        accumulated_winning: "Прогрессивный джекпот",
    },
    agent: {
        illustrate_p1: "Когда вы видите эту страницу, это означает, что ваш аккаунт является одновременно аккаунтом игрока и аккаунтом агента, что позволяет вам делать собственные ставки, а также развивать игроков более низкого уровня и получать комиссионные за возврат средств.",
        illustrate_p2: "Как заработать скидки？",
        illustrate_p3: "Скидка, которую вы можете получить, равна разнице между вашей собственной ставкой и ставкой нижнего уровня, например, ваша собственная ставка равна 5, а ставка нижнего уровня - 3, вы сможете получить скидку на ставку нижнего уровня в размере 2, например, поставив на нижний уровень 100 $, вы получите 2 $.",
        illustrate_p4: "Как открыть счет для подчиненного？",
        illustrate_p5: "Нажмите на учетную запись подчиненного, сначала настройте скидки для ваших подчиненных, настройте успешный пригласительный код будет сгенерирован, пригласительный код будет отправлен вашему подчиненному регистрации, регистрации, он является вашим подчиненным, нажмите на управление пользователями, вы можете просмотреть его зарегистрированный счет.",
        illustrate_p6: "Если вы создадите для своих подчиненных аккаунт типа агент, то ваши подчиненные смогут продолжать развивать своих подчиненных. Если вы создадите аккаунт типа игрок, то ваши подчиненные смогут только делать ставки, не смогут развивать своих подчиненных и не смогут видеть центр агентов. Управление пользователями",
        illustrate_tip1: "1、Различные коэффициенты возврата отличаются, нажмите на таблицу коэффициентов возврата, вы можете просмотреть коэффициенты возврата。",
        illustrate_tip2: "2、Чем меньше скидка, тем меньше шансы, поэтому рекомендуется, чтобы скидка, установленная для нижнего уровня, не была слишком низкой!。",
        illustrate_tip3: "3、Развитие агентов можно просмотреть в отчетах агентов, ставках на понижение, сделках на понижение。",
        illustrate_tip4: "4、Рекомендуется открывать подчиненных, которые также являются агентами, и вы будете получать вознаграждение независимо от количества развитых уровней.",
        open_type: "Тип открытия счета：",
        open_type1: "полный",
        open_type2: "действовать",
        open_type3: "игрок ",
        invite_tip1: "1、Разные скидки имеют разные коэффициенты, чем выше скидка, тем выше коэффициент!。",
        invite_tip2: "2、Агент может получить комиссию, равную разнице между собственным рибейтом агента и рибейтом подчиненного, например, собственный рибейт агента составляет 6, рибейт подчиненного - 4, агент может получить 2% от суммы ставок подчиненного, т.е. при ставке подчиненного в 100 юаней агент может получить 2 юаня.",
        invite_tip3: "3、Чем меньше значение скидки на нижнем уровне, тем меньше шансов на нижнем уровне, поэтому рекомендуется устанавливать скидку на нижнем уровне не слишком низкой!。",
        rebateDialog_tit: "Посмотреть скидки",
        delete_invite_tip: "Вы уверены, что хотите удалить этот код приглашения?？",
        rebate_table: "Таблица коэффициентов возврата",
        generate_invitation_code: "Создайте код приглашения",
        subordinate_tip1: "1、Разные скидки имеют разные коэффициенты, чем выше скидка, тем выше коэффициент!。",
        subordinate_tip2: "2、Агент может получить комиссию, равную разнице между собственным рибейтом агента и рибейтом подчиненного, например, собственный рибейт агента составляет 6, рибейт подчиненного - 4, агент может получить 2% от суммы ставок подчиненного, т.е. при ставке подчиненного в 100 юаней агент может получить 2 юаня.",
        subordinate_tip3: "3、Чем меньше значение скидки на нижнем уровне, тем меньше шансов на нижнем уровне, поэтому рекомендуется устанавливать скидку на нижнем уровне не слишком низкой!。",
        manage_tab1: "Описание агента",
        manage_tab2: "Открытие субординированного счета",
        manage_tab3: "код приглашения",
        manage_tab4: "управление пользователями",
        lower_bet_tip: "Записи о транзакциях хранятся не более 21 дня.",
        report_label1: "бетторы",
        report_label2: "выиграть приз",
        report_label3: "функция",
        report_label4: "Вознаграждения для команды",
        report_label5: "Прибыль команды",
        report_label6: "Количество бетторов",
        report_label7: "Номер первого заряда",
        report_label8: "Количество зарегистрированных",
        report_label9: "Количество подчиненных",
        report_label10: "Командный баланс",
        report_label11: "пополнение",
        report_label12: "вывести средства",
        report_label13: "Агентское вознаграждение",
        report_label14: "перевод ",
        report_label15: "получатель платежа",
        report_label16: "Отправить красный пакет",
        report_label17: "Получите красный пакет",
    },
    fund: {
        tip1: "Пожалуйста, переведите деньги на следующий банковский счет",
        tip2: "Пожалуйста, внимательно заполните информацию о переводе",
        tip3: " (Пожалуйста, не забудьте перевести деньги до отправки заказа, иначе ваш платеж не будет отслежен вовремя.！)",
        deposit_rate: "Курс обмена депозита",
        currency_number: "Сумма валюты",
        tip4: "Пожалуйста, используйте соответствующую платформу цифровой валюты для перевода денег",
        tip5: "предел однократного нажатия",
        tip6: "(Если вы не можете заполнить информацию для аутентификации, загрузите скриншот квитанции о транзакции.)",
        click_upload: "Нажмите, чтобы загрузить",
        upload_tip: "Можно загружать только файлы jpg/png",
        receiving_address: "адрес получателя платежа",
        tip7: "Пожалуйста, используйте OVO для перевода средств на следующие банковские счета",
        tip8: "1、Для оплаты требуется интернет-банкинг。",
        tip9: "2、После успешного пополнения счета нажмите 'Вернуться к продавцу', чтобы автоматически получить кредит.。",
    },
    other: {
        gift_tip1: "1、На подарки можно претендовать только после того, как будут выполнены все условия для их получения。",
        gift_tip2: "2、Срок действия невостребованных подарков истекает автоматически после окончания срока действия и не может быть восстановлен。",
        other_tip1: "Письма хранятся не более 7 дней;",
    },
    form: {
        input_inner: "Пожалуйста, введите содержимое",
        label_username: "учётная запись пользователя：",
        place_username: "Пожалуйста, введите учетную запись пользователя",
        label_loginpsd: "пароль для входа：",
        place_loginpsd: "Введите пароль для входа в систему",
        label_invitecode: "код приглашения：",
        place_invitecode: "Пожалуйста, введите 8-значный код приглашения",
        label_oldpsd: "оригинальный пароль",
        place_oldpsd: "Пожалуйста, введите оригинальный пароль",
        label_newpsd: "новый пароль",
        place_newpsd: "Пожалуйста, введите новый пароль",
        label_crmpsd: "Подтвердите пароль",
        place_crmpsd: "Пожалуйста, введите новый пароль еще раз",
        label_remark: "примечание",
        place_remark: "Пожалуйста, введите примечание",
        label_lower_account: "имя пользователя：",
        place_lower_account: "Введите номер подчиненного счета",
        label_type: "типология：",
        place_select: "пожалуйста, выберите",
        label_lottery_title: "лотерейный приз：",
        label_state: "положение дел：",
        option_state1: "полный",
        option_state2: "предстоящая лотерея",
        option_state3: "не победитель",
        option_state4: "выиграли приз",
        label_time: "раз：",
        times1: "в настоящее время",
        times2: "вчера",
        times3: "текущий месяц",
        times4: "ultimo",
        label_realname: "настоящее имя и фамилия",
        place_realname: "Пожалуйста, введите ваше настоящее имя",
        label_bank_number: "Номер карты банкомата",
        place_bank_number: "Пожалуйста, введите номер вашей банковской карты",
        label_bank_name: "Название банка",
        place_bank_name: "Пожалуйста, выберите название банка",
        label_abstract: "рефераты:",
        label_channel: "Канал пополнения",
        place_channel: "Пожалуйста, выберите канал пополнения счета",
        label_deposit_amount: "сумма пополнения",
        place_deposit_amount: "Пожалуйста, введите сумму пополнения",
        label_holder: "владелец счёта",
        label_deposit_name: "Имя вкладчика",
        place_deposit_name: "Пожалуйста, введите имя вкладчика",
        label_transfer_msg: "Информация о переводе",
        place_transfer_msg: "Пожалуйста, заполните информацию о переводе",
        label_transfer_pic: "Скриншот квитанции об оплате транзакции",
        label_transfer_pic_tip: "(Пожалуйста, загрузите скриншот квитанции об оплате)",
        label_bank_account_name: "Название банковского счета",
        label_balance: "балансы",
        label_available_withdraw: "снимаемая сумма",
        label_available_number: "Количество снятий",
        label_withdraw_amount: "Сумма снятия",
        placce_withdraw_amount: "Пожалуйста, введите сумму снятия)",
        available_withdraw: "желательность",
        label_transfer_bank: "дебетовый банк",
        label_security_psd: "код безопасности",
        place_security_psd: "Введите код безопасности",
        place_nickname: "ласкательное выражение",
        message_nickname: "Пожалуйста, введите ник",
        place_phone: "Введите номер вашего мобильного телефона",
        place_email: "Пожалуйста, введите адрес электронной почты",
        place_invite_id: "Пожалуйста, введите код приглашения",
        place_verify_code: "Введите проверочный код",
        send: "отправка",
        test_username: "Имя тестового пользователя",
        place_amount: "Пожалуйста, введите сумму",
    },
    login: {
        login_tit: "вход пользователя",
        forget_psd: "Забыли пароль?？",
        login: "войти в систему",
        register_tit: "Регистрация пользователей",
        register: "зачисление",
        have_account: "У вас уже есть учетная запись, войдите в нее",
        country_code: "код страны",
        phone: "мобильные телефоны",
        email: "входящие",
    },
};
