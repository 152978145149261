export default {
    common: {
        contactNow: "Contact Now",
        minPrice: "最低",
        maxPrice: "最高",
        address: {
            title: "取款地址",
            account_number: "帳戶號碼",
            add_btn: "添加地址",
            label_type: "選擇類型",
            place_type: "請選擇類型",
            label_number: "帳戶號碼",
            place_number: "請輸入帳戶號碼",
            wallet_address: '錢包地址',
            bank_name: '銀行名字',
            bank_address: "銀行地址",
            name: "姓名",
            payee_address: '收款人地址',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "分行名字",
            branch_code: "分行程式碼",
            bank_code: "銀行程式碼",
            phone: "手機號",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "請輸入內容",
        },
        priceType: {
            fast_sum: "和值",
            fast_three_all: "三同號通選",
            fast_three_one: "三同號單選",
            fast_three_diff: "三不同號",
            fast_three_continue: "三連號通選",
            fast_two_some: "二同號複選",
            fast_two_one: "二同號單選",
            fast_two_diff: "二不同號",
            fast_one_one: "單篩一賠一",
            racing_winner: "冠軍",
            racing_second: "亞軍",
            racing_third: "季軍",
            lotto_one: "萬",
            lotto_two: "千",
            lotto_three: "百",
            lotto_four: "十",
            lotto_five: "个",
            wingo_sum: "和值",
            wingo_color: "顏色",
            Big: "大",
            Small: "小",
            Single: "單",
            Double: "雙",
            Red: "紅",
            Green: "綠",
            Purple: "紫",
        },
        img_error: "圖片加載失敗",
        more: "更多",
        cancel: "取消",
        confirm: "確定",
        tips: "溫馨提示：",
        no_set: "未設定",
        submit: "提交",
        copy_success: "複製成功！",
        copy_error: "複製失敗！",
        currency: "$",
        filter: "篩選",
        edit: "修改",
        gameStart: "開始遊戲",
        prizenumber: "中獎號碼",
        mynumber: "我的號碼",
        addresss: "這裡是連結位址",
        gameAgain: "再來一次",
        gamerule: "遊戲規則",
        winning_numbers: "中獎號碼",
        winning: "恭喜您中獎了！",
        notwin: "很遺憾，您沒有中獎！",
        logout: "確定要登出嗎？",
        success: "成功",
        search: "蒐索",
        withdraw0: "申請",
        withdraw1: "下發中",
        withdraw2: "成功",
        withdraw3: "失敗",
        balanceNot: "帳戶餘額不足",
        canceled: "已取消",
        bill: {
            bill101: "用戶充值",
            bill102: "系統新增",
            bill103: "系統凍結",
            bill104: "系統解凍",
            bill201: "用戶提現",
            bill202: "系統扣除",
            bill203: "提現成功",
            bill204: "提現稽核失敗",
            bill205: "提現手續費",
            bill301: "加入遊戲",
            bill302: "遊戲盈利",
        }
    },
    playMethod: {
        p1: "請選擇要查看的遊戲規則",
        p2: "規則研究透了會讓收益翻倍哦",
        des1: "簡介",
        des2: "如果您花費100進行交易，扣除服務費2%後，合約金額：98",
        fived: {
            p1: "5D彩票遊戲規則",
            p2: "畫出指令",
            p3: "每一期隨機抽取5比特數位（00000-99999）",
            p4: "例如:",
            p5: "本期抽號為12345",
            p6: "A = 1",
            p7: "B = 2",
            p8: "C = 3",
            p9: "D = 4",
            p10: "E = 5",
            p11: "和= A + B + C + D + E = 15",
            p12: "怎麼玩",
            p13: "玩家可以指定投注A、B、C、D、E的六種結果和總和",
            p14: "A, B, C, D, E可以買到",
            p15: "數位(0 1 2 3 4 5 6 7 8 9)",
            p16: "低(0 1 2 3 4)",
            p17: "高(5 6 7 8 9)",
            p18: "奇數(1 3 5 7 9)",
            p19: "偶數(0 2 4 6 8)",
            p20: "Sum = A+B+C+D+E可以購買",
            p21: "低(0-22)",
            p22: "高(23-45)",
            p23: "奇數(1 3···43 45)",
            p24: "偶數(0 2···42 44)",
        },
        racing: {
            p1: "遊戲玩法",
            p2: "※前三和：",
            p3: "前三名車號相加，和值大於16為大，小於或等於16為小。 和值為偶數叫雙，為奇數叫單。※ 前三和：",
            p4: "※第一名至第三名：",
            p5: "車號指定，每一個車號為一投注組合，開獎結果'投注車號'對應所投名次視為中獎，其餘情形視為不中獎。",
            p6: "※兩面：",
            p7: "指單、雙； 大、小。",
            p8: "※單、雙：",
            p9: "號碼為雙數叫雙，如2、4、6、8、10； 號碼為單數叫單，如1、3、5、7、9。",
            p10: "※大、小",
            p11: "開出之號碼大於或等於6為大，如6、7、8、9、10； 小於或等於5為小，如1、2、3、4、5。",
        },
        wingo: {
            p1: "遊戲玩法",
            p2: "※單個數位：",
            p3: "選擇一個數位投注，開獎數位與投注數位一樣視為中獎，其餘情形視為不中獎。",
            p4: "※紅色：",
            p5: "投注紅色，如果開獎數位為0、2、4、6、8視為中獎，其餘情形視為不中獎。",
            p6: "※綠色：",
            p7: "投注綠色，如果開獎數位為1、3、5、7、9視為中獎，其餘情形視為不中獎。",
            p8: "※紫色：",
            p9: "投注紫色，如果開獎數位為0、5視為中獎，其餘情形視為不中獎。",
            p10: "※大、小：",
            p11: "開獎數位大於或等於5為大，如5、6、7、8、9； 小於等於4為小，如0、1、2、3、4。",
        },
        lottery: {
            p1: "遊戲玩法~6隨機開出。",
            p2: "A：大小單雙",
            p3: "大小：三個開獎號碼總和值11~18為大； 總和值3~10為小。",
            p4: "單雙：三個開獎號碼總和值為單數，則單中獎，為雙數，則雙中獎。",
            p5: "B：點數",
            p6: "開獎號碼總和值為3、4、5、6、7、8、9、10、11、12、13、14、15、16、17、18時，即為中獎； 舉例：如開獎號碼為1、2、3、總和值為6、則投注「6」即為中獎。",
            p7: "C：單骰",
            p8: "三個開獎號碼其中一個與所選號碼相同時、即為中獎。 舉例：如開獎號碼為1、1、3，則投注單骰1或單骰3皆視為中獎。 （不論當局指定點數出現幾次，僅派彩一次不翻倍）",
            p9: "D：雙骰",
            p10: "獎號碼任兩字同號、且與所選擇的雙骰組合相符時，即為中獎。 舉例：如開獎號碼為1、1、3、則投注雙骰1、1，即為中獎。",
            p11: "E：圍骰、全圍骰",
            p12: "圍骰：開獎號碼三字同號、且與所選擇的圍骰組合相符時，即為中獎。",
            p13: "全圍骰：開獎號碼三字同號，即為中獎。",
            p14: "F：二骰組合",
            p15: "任選一二骰組合，當開獎結果任2碼與所選組合相同時，即為中獎。 舉例：如開獎號碼為1、2、3、則投注二骰組合12、二骰組合23、二骰組合13皆視為中獎。",
        }
    },
    add: {
        service: "服务",
        apoio_online: "線上支持",
        online_tip: "客戶服務可幫助解決打嗝問題",
        chat: "CHAT",
        activity_p1: "首次存款活動",
        activity_p2: "第二次存款活動",
        activity_p3: "第三次存款事件",
        activity_table1: "首次存款金額",
        activity_table2: "部分",
        activity_table3: "圖紙要求",
        activity_tip1: "事件條件：",
        activity_tip2: "1.有效的下注要求",
        activity_tip3: "a.當參加活動時，帳戶餘額&lt； 存款金額，有效賭注=（餘額+獎金）x2，您可以提款",
        activity_tip4: "b.在參加活動時，帳戶餘額&gt； 存款金額和有效賭注=（存款金額+獎金）x2，您可以提款",
        activity_tip5: "2.存款成功後，請先參加活動，然後再下注。",
        activity_tip6: "3.第一次存款、第二次存款和第三次存款只能進行一次.",
        activity_tip7: "活動規則：",
        activity_tip8: "1.請在活動前填寫您的個人資訊",
        activity_tip9: "2.具有相同名稱、相同IP地址、相同設備程式碼和相同電話號碼的每個成員只能參加一次活動。 如果會員故意連續注册多個帳戶，公司將撤回或撤銷該機构的權利，並永久凍結該帳戶並收回所有獎金。",
        activity_tip10: "3.如果發現個人或組織實施欺詐以從活動中獲利，則所有活動都是為成員準備的。 公司保留扣留或取消該個人或組織總獎金的全部權利",
        activity_img1: "新成員的前3個重新加載獎金",
        activity_img2: "推薦朋友並賺取獎金",
        activity_img3: "VIP",
        activity_img4: "援助資金高達100%",
        activity_img5: "援助基金",
        go_bet: "直接投注",
        cqk: "存/取款",
        activity: "優惠活動",
        online: "線上客服",
        lottery: "購彩大廳",
        game: "遊戲",
        lotter: "彩票",
        search_lotter: "蒐索彩種",
        search: "査詢",
        di: "第",
        qi: "期",
        ju: "距第",
        feng: "期封盤",
        result: "開獎結果",
        logout: "退出",
        available_balance: "可用餘額",
        notice_title: "平臺公告",
        tip30: "30分內不再彈出",
        close: "關閉",
        personal: "個人中心",
        level: "等級",
        common: "常見",
        news: "最新優惠",
        help_center: "幫助中心",
        app_download: "APP下載",
    },
    addnew: {
        remainder: "剩余时间",
        history: "开奖历史",
        trend: "走势",
        my_bet: "我的注单",
        bei: "倍数",
        agree: "我同意",
        rule: "《预售规则》",
        total_amount: "总金额",
        wallet_balance: "钱包余额",
        total: "总和",
        random_bet: "随机投注",
    },
    webadd: {
        hot: "熱門",
        all: "全部",
        home: "大廳",
        hot_lottery: "熱門彩票",
        select_channel: "選擇充值通路",
        select_bank: "選擇銀行",
        top_tab1: "中獎",
        top_tab2: "TOP",
        top_tab3: "我的",
        web_time: "網站時間",
        trade: "交易",
    },
    footer: {
        foot1: "首頁",
        foot2: "遊戲",
        foot3: "TOP",
        foot4: "活動",
        foot5: "我的",
    },
    navBar: {
        back_home: "返回首頁",
        help_center: "幫助中心",
        contact_service: "聯系客服",
        balance: "餘額",
        my_account: "我的帳戶",
        account: "賬戶",
        record: "記錄",
        proxy: "代理",
        other: "其他",
        deposit: "充值",
        withdraw: "提現",
        deposit_type1: "網銀支付",
        deposit_type2: "銀行轉帳",
        deposit_type3: "數位貨幣",
        deposit_type4: "OVO",
        logout: "退出",
        login: "登入",
        register: "注册",
        nav1: "彩票",
        nav2: "遊戲",
        nav3: "活動",
        nav4: "手機",
        nav5: "我的帳戶",
    },
    activity: {
        tit1: "每日加獎",
        p1: "每日加獎是根據用戶昨日投注金額進行加獎，獎金無上限！",
        activity_detail: "活動詳情",
        yestoday_bet: "昨日投注",
        bonus_ratio: "加獎比例",
        now_level: "當前等級",
        receive_bonus: "可得加獎",
        receive: "領取",
        not_receive: "不可領取",
        activity_time: "活動時間",
        p2: "1、每日加獎在每日淩晨00:20後開放領取",
        p3: "2、加獎比例是根據使用者等級以及昨日累計投注金額進行一定比例的加獎",
        p4: "3、撤單和其他無效投注將不計算在內",
        p5: "4、有效投注需滿足【該注單已開獎結算】，如遇投注後該注單相隔多日才開獎時，該注單的有效投注金額將累計在【開獎結算日】。",
        p6: "5、例：10/1投注但10/15才開獎，則該注單的有效投注金額將累計在10/15，用戶可於10/16時領取到該筆注單的加獎獎勵。",
        p7: "6、活動獎金當天24點前未領取，視為自動放弃活動資格",
    },
    game: {
        history_tit: "歷史開獎",
        play_tit: "玩法說明",
        official_award: "官方原獎",
        betting: "正在投注",
        waiting: "等待開獎",
        opening: "正在開獎",
        settled: "已結算",
        auto_change: "自動切換",
        height_win: "最高可贏",
        total: "總共",
        pour: "注",
        every_pour: "每注",
        total_pour: "總注數",
        total_amount: "總投注",
        now_bet: "立即投注",
        my_bet: "我的投注",
        winning: "中獎",
        top: "TOP",
        winning_tip: "中獎資訊即時更新",
        top_tip: "TOP排行榜",
    },
    record: {
        lottery: "彩票",
        game: "遊戲",
        today_profit_loss: "今日盈虧",
        label_formula: "盈虧公式",
        formula: "公式：中獎-投注+活動+返點",
        profitLoss_label1: "中獎",
        profitLoss_label2: "活動",
        profitLoss_label3: "投注",
        profitLoss_label4: "返點",
        profitLoss_label5: "轉帳",
        profitLoss_label6: "收款",
        profitLoss_label7: "發送紅包",
        profitLoss_label8: "領取紅包",
        trade_tab1: "全部記錄",
        trade_tab2: "充值記錄",
        trade_tab3: "提現記錄",
    },
    table: {
        label_no: " No.",
        label_time: "時間",
        label_result: "結果",
        label_size: "大小",
        label_single_double: "單雙",
        label_sun_value: "和值",
        no_data: "暫無數據",
        big: "大",
        small: "小",
        single: "單",
        double: "雙",
        label_issue: "期號",
        label_bet_amount: "投注金額",
        label_reward: "獎金",
        no_reward: "未中獎",
        is_reward: "中獎",
        label_level: "等級",
        label_integral: "成長積分",
        label_award: "晉級獎勵",
        label_skip_awardl: "跳級獎勵",
        label_register_link: "注册連結",
        label_remark: "備註",
        label_rise_time: "生成時間",
        label_register_number: "注册數",
        label_operate: "操作",
        label_gametit: "彩種/遊戲",
        label_lower_rebate: "下級返點",
        label_self_rebate: "自身返點",
        label_set_rebate_range: "可設定返點範圍",
        bonus_state: "獎金狀態",
        label_serial_number: "流水號",
        label_income_amount: "收入金額",
        label_expend_amount: "支出金額",
        label_available_balance: "可用餘額",
        label_abstract: "摘要",
        label_details: "詳情",
        label_account: "賬戶",
        label_amount: "金額",
        label_bet_cont: "投注内容",
        label_ottery_number: "開獎號碼",
        order_number: "單號",
        play_methods: "玩法",
        label_periods: "期數",
        label_number: "號碼",
        label_color: "顏色",
    },
    my: {
        last_login_time: "上次登錄時間",
        logout_account: "退出帳戶",
        my_account: "我的帳戶",
        account: "賬戶",
        personal_tit: "個人資料",
        security_tit: "安全中心",
        bank_tit: "銀行帳號",
        record: "記錄",
        profit_loss_tit: "今日盈虧",
        trade_record_tit: "交易記錄",
        bet_record: "投注記錄",
        proxy: "代理",
        manag_tit: "代理管理",
        report_tit: "代理報表",
        lower_bet_tit: "下級投注",
        lower_trade_tit: "下級交易",
        other: "其他",
        gift_tit: "活動禮物",
        message_tit: "消息中心",
        personal_data: "個人資料",
        balance: "餘額",
        last_sync_time: "上次同步時間",
        deposit: "充值",
        withdraw: "提現",
        yesterday_deposit_time: "昨日充值平均時間",
        yesterday_withdraw_time: "昨日提現平均時間",
        help_tit: "幫助中心",
        all: "全部",
        lower_report: "下級報表",
        about_us: "關於我們",
        logout: "登出 ",
        invite: "邀請好友",
        lang_change: "語言切換",
    },
    account: {
        add_bank_account: "添加銀行帳號",
        bank_tip: "最多可添加5個銀行帳號，成功提現的銀行帳號會自動鎖定，無法删除和修改。 未成功提現的銀行帳號可修改無法删除。",
        account_msg: "帳戶資訊",
        growth_value: "成長值",
        noset_nickname: "昵稱未設定",
        nationality: "國籍",
        next_level: "距離下一級還要",
        integral: "積分",
        integral_tip: "每充值1.00 $新增1積分",
        level_system: "等級制度",
        change_avatar: "更換頭像",
        loginpsd_tip: "登入密碼6～16比特字母和數位的組合",
        modify_psd: "修改密碼",
        securitypsd_tip: "安全密碼（6比特數位的組合）",
        forget_securitypsd: "忘記安全密碼",
        confidentiality_tip: "密保問題帳號或密碼丟失時可找回用戶帳戶資訊",
        bank_account: "銀行帳號",
        edit_nickname: "編輯昵稱",
        confidentiality: "密保問題",
        accumulated_winning: "累積中獎",
    },
    agent: {
        illustrate_p1: "當您能看到這個頁面，說明您的帳號既是玩家帳號也是代理帳號，既可以自己投注，也可以發展下級玩家，賺取返點傭金。",
        illustrate_p2: "如何賺取返點？",
        illustrate_p3: "可獲得的返點，等於自身返點與下級返點的差值，如自身返點5，下級返點3，你將能獲得下級投注金額2的返點，如下級投注100$，你將會獲得2$。 點擊下級開戶，可查看自身返點，也可為下級設定返點。",
        illustrate_p4: "如何為下級開戶？",
        illustrate_p5: "點擊下級開戶，先為您的下級設定返點，設定成功後會生成一條邀請碼，將邀請碼發送給您的下級注册，注册後他就是您的下級，點擊用戶管理，就能查看他注册的帳號。",
        illustrate_p6: "如果您為下級設定的是代理類型的帳號，那麼您的下級就能繼續發展下級，如果設定的是玩家類型，那麼您的下級只能投注，不能再發展下級，也看不到代理中心。 用戶管",
        illustrate_tip1: "1、返點不同賠率也不同，點擊返點賠率錶，可查看返點賠率。",
        illustrate_tip2: "2、返點越低，賠率就越低，建議為下級設定的返點不要過低。",
        illustrate_tip3: "3、可在代理報表、下級投注、下級交易查看代理的發展情況。",
        illustrate_tip4: "4、建議開設的下級也是代理類型，無論發展了幾級，您都能獲得返點。",
        open_type: "開戶類型：",
        open_type1: "全部",
        open_type2: "代理",
        open_type3: "玩家",
        invite_tip1: "1、不同的返點賠率不同，返點越高賠率越高。",
        invite_tip2: "2、代理可獲得的傭金等於代理自身返點與下級返點的差值，如代理自身返點6，下級返點4，代理可獲得下級投注金額的2%，即下級下注100元，代理可獲得2元。",
        invite_tip3: "3、下級返點值設得越低，下級的賠率就越低，建議給下級設定的返點不要過低。",
        rebateDialog_tit: "查看返點",
        delete_invite_tip: "確定要删除這條邀請碼嗎？",
        rebate_table: "返點賠率錶",
        generate_invitation_code: "生成邀請碼",
        subordinate_tip1: "1、不同的返點賠率不同，返點越高賠率越高。",
        subordinate_tip2: "2、代理可獲得的傭金等於代理自身返點與下級返點的差值，如代理自身返點6，下級返點4，代理可獲得下級投注金額的2%，即下級下注100元，代理可獲得2元。",
        subordinate_tip3: "3、下級返點值設得越低，下級的賠率就越低，建議給下級設定的返點不要過低。",
        manage_tab1: "代理說明",
        manage_tab2: "下級開戶",
        manage_tab3: "邀請碼",
        manage_tab4: "用戶管理",
        lower_bet_tip: "交易記錄最多保留21天。",
        report_label1: "投注",
        report_label2: "中獎",
        report_label3: "活動",
        report_label4: "團隊返點",
        report_label5: "團隊盈利",
        report_label6: "投注人數",
        report_label7: "首充人數",
        report_label8: "注册人數",
        report_label9: "下級人數",
        report_label10: "團隊餘額",
        report_label11: "充值",
        report_label12: "提現",
        report_label13: "代理返點",
        report_label14: "轉帳",
        report_label15: "收款",
        report_label16: "發送紅包",
        report_label17: "領取紅包",
    },
    fund: {
        tip1: "請轉帳到以下銀行帳戶",
        tip2: "請認真填寫你的轉帳資訊",
        tip3: "（請務必轉帳後再提交訂單，否則無法及時查到您的款項！）",
        deposit_rate: "存款匯率",
        currency_number: "貨幣數量",
        tip4: "請使用對應的數位貨幣平臺進行轉帳",
        tip5: "單筆限額",
        tip6: "（若無法填寫認證資訊，請上傳交易收據截圖）",
        click_upload: "點擊上傳",
        upload_tip: "只能上傳jpg/png檔案",
        receiving_address: "收款地址",
        tip7: "請使用OVO轉帳到以下銀行帳戶",
        tip8: "1、網銀支付需開通網銀才能進行支付。",
        tip9: "2、充值成功後，點擊“返回商戶”即可自動到賬。",
    },
    other: {
        gift_tip1: "1、禮物必須達到領取條件後才可領取。",
        gift_tip2: "2、禮物過期後未領取的禮物自動失效，無法再領取。",
        other_tip1: "信件最多保留7天的記錄；",
    },
    form: {
        input_inner: "請輸入內容",
        label_username: "用戶帳號：",
        place_username: "請輸入用戶帳號",
        label_loginpsd: "登入密碼：",
        place_loginpsd: "請輸入登入密碼",
        label_invitecode: "邀請碼：",
        place_invitecode: "請填寫8比特數位邀請碼",
        label_oldpsd: "原密碼",
        place_oldpsd: "請輸入原密碼",
        label_newpsd: "新密碼",
        place_newpsd: "請輸入新密碼",
        label_crmpsd: "確認密碼",
        place_crmpsd: "請再次輸入新密碼",
        label_remark: "備註",
        place_remark: "請輸入備註",
        label_lower_account: "帳號：",
        place_lower_account: "請輸入下級帳號",
        label_type: "類型：",
        place_select: "請選擇",
        label_lottery_title: "彩種：",
        label_state: "狀態：",
        option_state1: "全部",
        option_state2: "待開獎",
        option_state3: "未中獎",
        option_state4: "已中獎",
        label_time: "時間：",
        times1: "今天",
        times2: "昨天",
        times3: "本月",
        times4: "上月",
        label_realname: "真實姓名",
        place_realname: "請輸入真實姓名",
        label_bank_number: "銀行卡號",
        place_bank_number: "請輸入銀行卡號",
        label_bank_name: "銀行名稱",
        place_bank_name: "請選擇銀行名稱",
        label_abstract: "摘要:",
        label_channel: "充值通道",
        place_channel: "請選擇充值通道",
        label_deposit_amount: "充值金額",
        place_deposit_amount: "請輸入充值金額",
        label_holder: "開戶人",
        label_deposit_name: "存款人姓名",
        place_deposit_name: "請輸入存款人姓名",
        label_transfer_msg: "轉帳資訊",
        place_transfer_msg: "請填寫轉帳資訊",
        label_transfer_pic: "交易收據截圖",
        label_transfer_pic_tip: "（請上傳交易收據截圖）",
        label_bank_account_name: "銀行戶名",
        label_balance: "餘額",
        label_available_withdraw: "可提現金額",
        label_available_number: "可提現次數",
        label_withdraw_amount: "提現金額",
        placce_withdraw_amount: "請輸入提現金額",
        available_withdraw: "可取",
        label_transfer_bank: "轉帳銀行",
        label_security_psd: "安全密碼",
        place_security_psd: "請輸入安全密碼",
        place_nickname: "昵稱",
        message_nickname: "請輸入昵稱",
        place_phone: "請輸入手機號",
        place_email: "請輸入郵箱",
        place_invite_id: "請輸入邀請碼",
        place_verify_code: "請輸入驗證碼",
        send: "發送",
        test_username: "Test 用戶名",
        place_amount: "請輸入金額",
    },
    login: {
        login_tit: "用戶登錄",
        forget_psd: "忘記密碼？",
        login: "登入",
        register_tit: "用戶註冊",
        register: "注册",
        have_account: "已有帳號，去登入",
        country_code: "國家代碼",
        phone: "手機",
        email: "郵箱",
    },
};
