export default {
    common: {
        contactNow: "Contact Now",
        minPrice: "Min",
        maxPrice: "Max",
        address: {
            title: "निकालें",
            account_number: "खाता संख्या",
            add_btn: "Add Address",
            label_type: "प्रकार चुनें",
            place_type: "कृपया एक प्रकार चुनें",
            label_number: "खाता संख्या",
            place_number: "कृपया खाता संख्या भरें",
            wallet_address: 'वालेट पता',
            bank_name: 'बैंक नाम',
            bank_address: "Bank address",
            name: "नाम",
            payee_address: 'प्रापक पता',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Branch Name",
            branch_code: "ब्रैंच कोड",
            bank_code: "बैंक कोड",
            phone: "मोबाइल फोन संख्या",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "कृपया सामग्री भरें",
        },
        priceType: {
            fast_sum: "Sum value",
            fast_three_all: "तीन समान संख्या विश्वव्यापी चयन",
            fast_three_one: "Three Same Number Single Choice",
            fast_three_diff: "तीन अलग संख्या",
            fast_three_continue: "तीन पछिला चयन",
            fast_two_some: "दो समान संख्याजाँच",
            fast_two_one: "दो समान संख्या एकल चयन",
            fast_two_diff: "दो अलग संख्या",
            fast_one_one: "दो अलग संख्या",
            racing_winner: "चम्पियोन",
            racing_second: "Runner up",
            racing_third: "तीसरा स्थान",
            lotto_one: "A",
            lotto_two: "B",
            lotto_three: "C",
            lotto_four: "D",
            lotto_five: "E",
            wingo_sum: "Somme",
            wingo_color: "Color",
            Big: "बड़ा",
            Small: "छोटा",
            Single: "एकल",
            Double: "दोहरा",
            Red: "लाल",
            Green: "हरा",
            Purple: "Purple",
        },
        img_error: "छवि लोडिंग असफल",
        more: "अधिक",
        cancel: "रद्द करें",
        confirm: "पुष्टि करें",
        tips: "रिमाइन्डर：",
        no_set: "सेट नहीं",
        submit: "भेजें",
        copy_success: "सफलतापूर्वक नक़ल किया गया！",
        copy_error: "नकल असफल！",
        currency: "$",
        filter: "स्क्रीन",
        edit: "संपादन",
        gameStart: "खेल प्रारंभ करें",
        prizenumber: "जीते हुए संख्या",
        mynumber: "जीते हुए संख्या",
        addresss: "यह लिंक पता है",
        gameAgain: "फिर करें",
        gamerule: "खेल नियम",
        winning_numbers: "जीते हुए संख्या",
        winning: "प्रतिज्ञा जीते हैं！",
        notwin: "दुर्भाग्यशाली, आप प्रतिज्ञा नहीं जीता！",
        logout: "क्या आप निश्चित है कि आप लॉग आउट करना चाहते हैं？",
        success: "सफलता",
        search: "खोजें",
        withdraw0: "अनुप्रयोग",
        withdraw1: "वितरण के नीचे",
        withdraw2: "सफलता",
        withdraw3: "असफल",
        balanceNot: "खाता बैलेन्स अपर्याप्त है",
        canceled: "पहिले स रद्द करेंे",
        bill: {
            bill101: "उपयोक्ता पुनरार्ज करें",
            bill102: "तंत्र विस्तार",
            bill103: "तंत्र विस्तार",
            bill104: "तंत्र निर्माण किया गया है",
            bill201: "प्रयोक्ता निकालें",
            bill202: "तंत्र डिडुक्शन",
            bill203: "Withdrawalसफलता",
            bill204: "हटावाल रीवेशन असफल",
            bill205: "हटवाल फीज़",
            bill301: "खेल में जोड़ें",
            bill302: "खेल लाभ",
        }
    },
    add: {
        cqk: "डिपोज़िट/विच्छेद्रावेल",
        activity: "प्रोमोटिकल क्रियाकलाप",
        online: "ऑनलाइन सेवा",
        lottery: "लोटेरी शॉपिंग हॉल",
        game: "खेल",
        lotter: "लोटेरी",
        search_lotter: "रंग वर्गों के लिए खोजें",
        search: "क्वेरी",
        di: "खण्ड",
        qi: "स्टेज",
        ju: "क्वेरी",
        feng: "अवधि बन्द",
        result: "लोटेरी परिणाम",
        logout: "बाहर करें",
        available_balance: "उपलब्ध",
        notice_title: "प्लेटफॉर्म निर्णय",
        tip30: "30 मिनट के भीतर कोई अधिक पॉपअप नहीं",
        close: "बन्द करें",
        personal: "व्यक्तिगत केंद्र",
        level: "श्रेणी",
        common: "सामान्य",
        news: "नवीन डिस्कॉन्ट",
        help_center: "मदद केंद्र",
        app_download:"एपीपी डाउनलोड",
    },
    addnew: {
        remainder: "बाकी समय",
        history: "इतिहास",
        trend: "स्थिति",
        my_bet: "क्रम फॉर्म",
        bei: "बहुत",
        agree: "मैं सहमत हूँ",
        rule: "पूर्व विक्रेत नियम",
        total_amount: "कुल मात्रा",
        wallet_balance: "वालेट",
        total: "संपूर्ण",
        random_bet: "अनियमित",
    },
    playMethod: {
        p1: "Please select the game rules you want to view",
        p2: "नियमों का अभ्यास संपूर्ण रूप से दोहरा होगा",
        des1: "Brief introduction",
        des2: "यदि आप एक ट्रांसेक्शन में 100 खर्च करते हैं, सेवा फीज़ के 2% निकालने के बाद, कंट्रांट मात्रा 98 है",
        fived: {
            p1: "5D लॉटरी खेल नियम",
            p2: "सूचना बनाएँ",
            p3: "हर समस्या के लिए अनियमित 5 अंक चुनें (00000- 99999)",
            p4: "उदाहरण के लिए:",
            p5: "इस अवधि के लिए ड्राइंग 12345 है",
            p6: "A = 1",
            p7: "B = 2",
            p8: "C = 3",
            p9: "D = 4",
            p10: "E = 5",
            p11: "And= A + B + C + D + E = 15",
            p12: "कैसे खेलें",
            p13: "खेलाड़ियों को छह परिणाम और बीटिंग A, B, C, D और E कुल निर्दिष्ट कर सकते हैं",
            p14: "A, B, C, D, E खरीदा किया जा सकता है",
            p15: "संख्या(0 1 2 3 4 5 6 7 8 9)",
            p16: "कम(0 1 2 3 4)",
            p17: "उच्च(5 6 7 8 9)",
            p18: "अज्ञात संख्या(1 3 5 7 9)",
            p19: "Even(0 2 4 6 8)",
            p20: "Sum = A+B+C+D+Eखरीदा किया जा सकता है",
            p21: "कम(0-22)",
            p22: "उच्च(23-45)",
            p23: "अज्ञात संख्या(1 3···43 45)",
            p24: "Even(0 2···42 44)",
        },
        racing: {
            p1: "खेल खेल",
            p2: "※ऊपर तीन और：",
            p3: "शीर्ष तीन कार की संख्या 16 से बड़ी है, और 16 से कम या बराबर है छोटी है। Even numbers are called pairs, odd numbers are called singles ऊपर तीन और:",
            p4: "※पहिले तीसरे स्थान पर：",
            p5: "प्रत्येक वाहन संख्या डिजाइनशन, प्रत्येक वाहन संख्या एक बेटिंग संयोजन है, और लोट्री परिणाम जो 'बेटिंग वाहन संख्या के बेटिंग स्थिति के साथ सं",
            p6: "※दो पार्श्व：",
            p7: "एक या दोबारा सुनता है; बड़ा और छोटा।",
            p8: "※एकल और दोहरा：",
            p9: "संख्या जिन्हें दोहरा कहता है, जैसे 2, 4, 6, 8, 10 कहता है; अलग संख्या के साथ अनुक्रम, जैसे 1, 3, 5, 7 और 9.",
            p10: "※बड़ा और छोटा",
            p11: "6 से बड़ी संख्या या बराबर है, जैसे 6, 7, 8, 9, 10 के लिए बड़ी समझती है; 5 से कम या बराबर है छोटा समझता है, जैसे 1, 2, 3, 4, 5.",
        },
        wingo: {
            p1: "खेल खेल",
            p2: "※एक अंक：",
            p3: "बेट करने के लिए एक संख्या चुनें, जीते हुए संख्या बेटिंग संख्या के रूप में समान है, और अन्य स्थितियों को जीते नहीं हैं.",
            p4: "※लाल：",
            p5: "लाल में बेटिंग, यदि जीते हुए संख्या 0, 2, 4, 6 या 8 हैं, तो यह जीत के रूप में लिया जाएगा, और अन्य स्थितियों को जीते नहीं हैं।",
            p6: "※हरा：",
            p7: "हरिया में बेटिंग, यदि विजय संख्या 1, 3, 5, 7 या 9 हैं, तो यह जीत के रूप में समझा जाएगा, और अन्य स्थितियाँ जीते नहीं हैं.",
            p8: "※बैंजनी;：",
            p9: "Betting in purple, if the winning numbers are 0 or 5, it will be considered a win, and in other cases, it will be considered a no win.",
            p10: "※बड़ा और छोटा：",
            p11: "जीती संख्या 5 से बड़ी या बराबर है, जैसे 5, 6, 7, 8, 9; 4 से कम या बराबर है छोटा समझा जाता है, जैसे 0, 1, 2, 3, 4.",
        },
        lottery: {
            p1: "खेल खेल ~6 अनियमित रूप से खोल रहा है.",
            p2: "A：एकल तथा दोहरा आकार",
            p3: "आकार: तीन लोटेरी संख्या का कुल मूल्य 11 और 18 के बीच है, जो बड़ा है; 3-10 का कुल मूल्य छोटा है.",
            p4: "एकल और दोहरा: यदि तीन लोट्री संख्या का कुल मूल्य अजीब है, तो यह एक जीन का अर्थ है; यदि यह भी है, तो यह दोहरा जीन का अर्थ है।",
            p5: "B：बिन्दु",
            p6: "जब जीते हुए संख्याओं का कुल मूल्य 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18 है, यह एक जीत मानता है; उदाहरण के लिए, यदि जीते हुए संख्या 1, 2, 3 हैं, और कुल मूल्य 6 है, '6' पर विश्वास में जीते हैं.",
            p7: "C：एकल पासा",
            p8: "जब तीन लोटेरी संख्या में से एक चुना गया संख्या के बराबर है, तो यह जीत की जाती है। उदाहरण के लिए, यदि जीते हुए संख्या 1, 1, या 3 हैं, या एक मरे हुए 1 या एक मरे हुए 3 को जीते हैं. (No matter how many times the designated points appear by the authorities, only one lottery will be distributed without doubling)",
            p9: "D：दोहरा पासा",
            p10: "जबकि जीते हुए संख्या के दो अक्षर एक ही संख्या में हैं और चुने हुए पास के संयोजन को मिलाते हैं, इसे एक जीत मानता है उदाहरण के लिए, यदि विजय संख्या १, १, और ३ हैं, पास १ और १ पर विश्वास किया जाता है.",
            p11: "E：राउन्ड पासे, पूरा राउन्ड पासे",
            p12: "Round dice: When the winning number is three characters with the same number and matches the selected combination of round dice, it is considered a win.",
            p13: "Full circle dice: If the winning number is three characters with the same number, it is considered a win.",
            p14: "F: दो पास संयोजन",
            p15: "एक या दो पासों के कोई संयोजन चुनें, और यदि कोई दो पासे चुने हुए संयोजन में मिलेगा, तो यह एक जीत का समझता है. उदाहरण के लिए, यदि जीते हुए संख्या 1, 2, या 3 हैं, दो पास संयोजन 12, दो पास संयोजन 23, या दो पास संयोजन 13 को जीते हैं.",
        }
    },
    webadd: {
        hot: "पोपलियर",
        all: "पूर्ण",
        home: "घर पृष्ठ",
        hot_lottery: "प्रसिद्ध लोटेरी",
        select_channel: "फिर चैनल चुनें",
        select_bank: "बैंक चुनें",
        top_tab1: "लोटेरी जीता है",
        top_tab2: "TOP",
        top_tab3: "मेरा",
        web_time: "वेबसाइट समय",
        trade: "ट्रांस",
    },
    footer: {
        foot1: "घर पृष्ठ",
        foot2: "खेल",
        foot3: "TOP",
        foot4: "सक्रिया",
        foot5: "मेरा",
    },
    navBar: {
        back_home: "होम पृष्ठ में लौटाएँ",
        help_center: "मदद केंद्र",
        contact_service: "संपर्क ग्राहक सेवा",
        balance: "balance",
        my_account: "मेरा खाता",
        account: "खाता",
        record: "रिकार्ड",
        proxy: "उत्पनकारण्न",
        other: "अन्य",
        deposit: "फिर चार्ज करें",
        withdraw: "विडडवाल",
        deposit_type1: "ईमुद्रा",
        deposit_type2: "बैंक स्थानान्तरण",
        deposit_type3: "डिजिटल मुद्रा",
        deposit_type4: "OVO",
        logout: "बाहर करें",
        login: "लॉगइन",
        register: "रेजिस्टर",
        nav1: "लोटेरी",
        nav2: "खेल",
        nav3: "सक्रिया",
        nav4: "मोबाइल फोन",
        nav5: "मेरा खाता",
    },
    activity: {
        tit1: "रोज़ी बोनास",
        p1: "रोज़ी बोनास कल उपयोक्ता के बेट मात्रा पर आधारित है, और बोनास के लिए कोई सीमा नहीं है!",
        activity_detail: "Event विवरण",
        yestoday_bet: "कल बात कर रहा है",
        bonus_ratio: "बोनास अनुपात",
        now_level: "मौजूदा स्तर",
        receive_bonus: "बोनास प्राप्त कर सकता है",
        receive: "receive",
        not_receive: "क्लिपबोर्ड नहीं",
        activity_time: "क्रियाकलाप समय",
        p2: "1. दिनीय बोनास हर दिन 00: 20 पीठ के बाद संग्रह के लिए उपलब्ध हैं",
        p3: "2. बोनास अनुपात है कि प्रयोक्ता स्तर पर आधारित एक निश्चित प्रतिशत है और कल से जमा किया गया बेटिंग मात्रा है",
        p4: "3. रद्द करना और अन्य अवैध बेट गणना नहीं होगा",
        p5: "4. प्रभावी बेटिंग के लिए 'टिकेट पहले से लोटेरी के लिए स्थिर किया गया है' की आवश्यकता पूरी होना चाहिए. यदि टिकेट बहुत दिनों बेटिंग के बाद लोटेरी के लिए खोल",
        p6: "5. उदाहरण के लिए, यदि 10/1 पर बेट किया जाता है लेकिन प्रतिज्ञा केवल 10/15 पर कोरिया जाता है, बेट के लिए प्रभावी बेट मात्रा 10/15 तक कोरिया जाएगा. प्रयोक्ताओ",
        p7: "6. यदि क्रिया बोनास उसी दिन के पहले 24:00 से पहले प्रार्थना नहीं किया जाएगा, तो इसे क्रिया की स्वचालित रूप से प्राप्त करन",
    },
    game: {
        history_tit: "इतिहासिकलोटेरी",
        play_tit: "खेल खेल सूचना",
        official_award: "आधिकारिक मौलिक प्रतिज्ञा",
        betting: "प्रगति में बढ़ता है",
        waiting: "लॉटरी के लिए प्रतीक्षा कर रहा है",
        opening: "लॉटरी खोल रहा है",
        settled: "सेटिंग",
        auto_change: "स्वचालित स्विचिंग",
        height_win: "सबसे उच्च संभव जीत",
        total: "संपूर्ण",
        pour: "टिप्पणी",
        every_pour: "प्रत्येक नोट",
        total_pour: "टिप्पणी के कुल संख्या",
        total_amount: "कुलबेटिंग",
        now_bet: "तुरंत बेटिंग",
        my_bet: "मेरा बेट",
        winning: "लोटेरी जीता है",
        top: "TOP",
        winning_tip: "जीति जानकारी के वास्तविक समय अद्यतन",
        top_tip: "टॉप रेन्गिंग",
    },
    record: {
        lottery: "लोटेरी",
        game: "खेल",
        today_profit_loss: "आज का लाभ और घाट",
        label_formula: "प्रोफिट तथा हराने वाला फ़ॉर्मेल",
        formula: "फ़ॉर्मुला: जीति - बेटिंग+क्रियाकलाप+पुनःस्थापित करें",
        profitLoss_label1: "लोटेरी जीता है",
        profitLoss_label2: "सक्रिया",
        profitLoss_label3: "बेटिंग",
        profitLoss_label4: "पुनः प्रारंभ करें",
        profitLoss_label5: "स्थानान्तरण",
        profitLoss_label6: "संग्रह",
        profitLoss_label7: "लाल अंवेलोप भेजें",
        profitLoss_label8: "लाल कंवेलप",
        trade_tab1: "सभी रेकॉर्ड",
        trade_tab2: "रेकर्ड फिर चार्ज करें",
        trade_tab3: "वाटवाल रेकॉर्ड",
    },
    table: {
        label_no: " No.",
        label_time: "समय",
        label_result: "परिणाम",
        label_size: "आकार",
        label_single_double: "एक दोहरा",
        label_sun_value: "जोड़ी मूल्य",
        no_data: "वर्तमान में कोई डाटा उपलब्ध नहीं है",
        big: "बड़ा",
        small: "छोटा",
        single: "एकल",
        double: "दोहरा",
        label_issue: "निर्गत संख्या",
        label_bet_amount: "बेटिंग मात्रा",
        label_reward: "बोनास",
        no_reward: "लॉटरी जीता नहीं है",
        is_reward: "लोटेरी जीता है",
        label_level: "श्रेणी",
        label_integral: "वृद्धि बिन्दु",
        label_award: "प्रोमोटिशनबदला",
        label_skip_awardl: "बदला छोड़ें",
        label_register_link: "रेजिस्टरेशनलिंक",
        label_remark: "टिप्पणी",
        label_rise_time: "उत्पन्न समय",
        label_register_number: "रेजिस्ट्रेशन की संख्या",
        label_operate: "प्रक्रिया",
        label_gametit: "रंग/खेल",
        label_lower_rebate: "निम्न स्तर पुनरावृत्ति",
        label_self_rebate: "स्वयं पुनरावृत्ति",
        label_set_rebate_range: "रिबेट सीमा सेट कर सकता है",
        bonus_state: "बोनास स्थिति",
        label_serial_number: "सीरियल संख्या",
        label_income_amount: "आगत मात्रा",
        label_expend_amount: "खर्च मात्रा",
        label_available_balance: "उपलब्ध",
        label_abstract: "अबस्ट्रेक्ट",
        label_details: "विवरण",
        label_account: "खाता",
        label_amount: "पैसा",
        label_bet_cont: "बेटिंगसामग्री",
        label_ottery_number: "लोटेरी संख्या",
        order_number: "क्रम संख्या",
        play_methods: "बजाने विधि",
        label_periods: "अवधि की संख्या",
        label_number: "संख्या",
        label_color: "रंग",
    },
    my: {
        last_login_time: "अंतिम लागइन समय",
        logout_account: "खाता बाहर हो",
        my_account: "मेरा खाता",
        account: "खाता",
        personal_tit: "व्यक्तिगत डाटा",
        security_tit: "सुरक्षा केंद्र",
        bank_tit: "बैंक खाता संख्या",
        record: "रिकार्ड",
        profit_loss_tit: "आज का लाभ और घाट",
        trade_record_tit: "ट्रांसेक्शन रेकर्ड",
        bet_record: "रिकार्ड बेटिंग",
        proxy: "agent",
        manag_tit: "एजेंट प्रबंधन",
        report_tit: "प्रॉक्सी रिपोर्ट",
        lower_bet_tit: "सबकोर्ड बेटिंग",
        lower_trade_tit: "उपनिर्धारित ट्रांसेक्शन्स",
        other: "अन्य",
        gift_tit: "सक्रियदाना",
        message_tit: "संदेश केंद्र",
        personal_data: "व्यक्तिगत डाटा",
        balance: "अग्रभाव",
        last_sync_time: "अंतिम सिंक्रोनाइजेशन समय",
        deposit: "फिर चार्ज करें",
        withdraw: "विडडवाल",
        yesterday_deposit_time: "औसत पुनरार्ज समयकल",
        yesterday_withdraw_time: "मध्यम प्रतिक्रिया समय कल",
        help_tit: "मदद केंद्र",
        all: "पूर्ण",
        lower_report: "सबकोर्ड रिपोर्ट",
        about_us: "हमारे बारेमें",
        logout: "लॉग आउट",
        invite: "मित्रों को आमन्त्रित करें",
        lang_change: "भाषा स्विचिंग",
    },
    account: {
        add_bank_account: "बैंक खाता जोड़ें",
        bank_tip: "5 बैंक खाताओं के अधिकतम जोड़ सकते हैं. सफलतापूर्वक बैंक खाताओं स्वचालित रूप से छोड़ दिया जाएगा और मिटाया  Bank accounts that have not been successfully withdrawn can be modified and cannot be deleted.",
        account_msg: "Account information",
        growth_value: "वृद्धि मान",
        noset_nickname: "उपनाम सेट नहीं है",
        nationality: "nationality",
        next_level: "यह अगले स्तर के साथ भी निकट है",
        integral: "अक्षर",
        integral_tip: "हर 1. 00 $ पुनरार्ज के लिए 1 बिन्दु बढ़ाएँ",
        level_system: "हियरार्चिकल तंत्र",
        change_avatar: "अवातार बदलें",
        loginpsd_tip: "लॉगइन पासवर्ड के लिए 6- 16 अक्षरों और संख्या का संयोजन",
        modify_psd: "परिवर्धित करपासवर्डें",
        securitypsd_tip: "सुरक्षित पासवर्ड (6 अंक का संयोजन)",
        forget_securitypsd: "सुरक्षा पासवर्ड भूल गया",
        confidentiality_tip: "सुरक्षा मुद्दा: जब खाता या पासवर्ड हराए जाता है, उपयोक्ता खाता जानकारी प्राप्त कर सकता है",
        bank_account: "बैंक खाता संख्या",
        edit_nickname: "उपनाम संपादित करें",
        confidentiality: "सुरक्षा प्रश्न",
        accumulated_winning: "सम्पूर्ण जीत",
    },
    agent: {
        illustrate_p1: "जब आप इस पृष्ठ को देख सकते हैं, यह दिखाता है कि आपका खिलाड़ी खाता और प्रॉक्सी खाता हआप अपने अपने शर्त बना सकते हैं या निम्न स्तर खेलाड़ियों को विकास कर सकते हैं कि पुनरावृत्ति कमिशियन्स के लिए.ै",
        illustrate_p2: "रिबेट कैसे प्राप्त करना है？",
        illustrate_p3: "उपलब्ध रिबेट स्वयं रिबेट और अधिकारित रिबेट के बीच भिन्न है. उदाहरण के लिए, यदि स्वयं रिबेट 5 है और अधिकारित रिबेट 3 है, तुम अधिकारित बेट मात्रा के लिए 2 का रिबेट प्राप्त होगा. उदा अपने अपने रिबेट देखने के लिए उपनिर्धारित खाता पर क्लिक करें या उनके उपनिर्धारितों के लिए रिबेट सेट करें.",
        illustrate_p4: "उपनियमों के लिए खाता कैसे खोलें？",
        illustrate_p5: "उपनिर्धारित के लिए खाता खोलने के लिए क्लिक करो, और पहले अपने उपनिर्धारित के लिए पुनरावृत्ति नियत करें. Once successfully set, an invitation code will be generated and sent to your subordinate for registration.  रिज़िस्टेशन के बाद वह तुम्हारा अधिकारी होगा। प्रयोक्ता प्रबंधन पर क्लिक करें कि उसके रेजिस्टरेड खाता देखें.",
        illustrate_p6: "यदि आप अपने सबनियतों के लिए प्रॉक्सी किसी खाता सेट करें तो वे अपने सबनियतों को विकास करने के लिए जारी राख सकते हैं. यदि आप एक खेलाड़ी किसी खाता सेट करें तो आपके स प्रयोक्ता प्रबंधन",
        illustrate_tip1: "1. भिन्न रिबेट के लिए भी विभिन्न संभावना भी विभिन्न है. रिबेट संभावना देखने के लिए रिबेट संभावना को क्लि",
        illustrate_tip2: "2. पुनरावृत्ति को निम्न, संभावना को निम्न है. सिफारिस किया जाता है कि उपनियमों के लिए रिबेट सेट बहुत कम नहीं होना चाहिए।",
        illustrate_tip3: "3. आप प्रॉक्सी रिपोर्ट में एजेंट के विकास स्थिति को देख सकते हैं, सबकोर्ड बैट और सबकोर्ड कार्यों में.",
        illustrate_tip4: "4. सुझावित उपनिर्धारित भी एक एजेन्ट क़िस्म है, और कितने स्तर आपने विकास किया है, तो आप रिबेट प्राप्त कर सकते हैं.",
        open_type: "खाता खोलने का प्रकार：",
        open_type1: "पूर्ण",
        open_type2: "उत्पनकारण्न",
        open_type3: "खेलाड़ी",
        invite_tip1: "1. भिन्न रिबेट की संभावना भिन्न होती है, और जितना उच्च रिबेट होती है, जितना उच्च संभावना है।",
        invite_tip2: "2. कॉमिशिशन जिसे एजेंट प्राप्त कर सकता है एजेंट के अपने रिबेट और नीचे स्तर रिबेट के बीच भिन्न है. उदाहरण के लिए, यदि एजेंट के अपने रिबेट 6 है और नीचे स्तर रिबेट 4 है, एजेंट नीचे स्तर बेटिंग मात्रा के 2%",
        invite_tip3: "3. सबनिर्धारितों के लिए सेट किया गया रिबेट मूल्य को निम्न है, जितना उनकी संभावना निम्न है. सबनिर्धारितों के लिए सेट किया",
        rebateDialog_tit: "दृश्यपुनः प्रारंभ करें",
        delete_invite_tip: "क्या आप निश्चित है कि आप इस निमंत्रण कोड को मिटाना चाहते हैं？",
        rebate_table: "कमेंसेंसेशन पुनः प्रारंभ करेंरेटमेटर",
        generate_invitation_code: "निमन्त्रण कोड उत्पन्न करें",
        subordinate_tip1: "1. भिन्न रिबेट की संभावना भिन्न होती है, और जितना उच्च रिबेट होती है, जितना उच्च संभावना है।",
        subordinate_tip2: "2. कॉमिशिशन जिसे एजेंट प्राप्त कर सकता है एजेंट के अपने रिबेट और नीचे स्तर रिबेट के बीच भिन्न है. उदाहरण के लिए, यदि एजेंट के अपने रिबेट 6 है और नीचे स्तर रिबेट 4 है, एजेंट नीचे स्तर बेटिंग मात्रा के 2%",
        subordinate_tip3: "3. सबनिर्धारितों के लिए सेट किया गया रिबेट मूल्य को निम्न है, जितना उनकी संभावना निम्न है. सबनिर्धारितों के लिए सेट किया",
        manage_tab1: "प्रॉक्सी वर्णन",
        manage_tab2: "खाता खोलें",
        manage_tab3: "निमन्त्रण कोड",
        manage_tab4: "प्रयोक्ता प्रबंधन",
        lower_bet_tip: "ट्रांसेक्शन रेकॉर्ड के अधिकतम रेक्सन21 दिन",
        report_label1: "बेटिंग",
        report_label2: "लोटेरी जीता है",
        report_label3: "सक्रिया",
        report_label4: "टीम पुनः प्रारंभ करें",
        report_label5: "टीम प्रयोगिता",
        report_label6: "बेट की संख्या",
        report_label7: "पहले चार्जर की संख्या",
        report_label8: "रेजिस्ट्रेशन की संख्या",
        report_label9: "उपनिर्देशिका की संख्या",
        report_label10: "टीम बैलेन्स",
        report_label11: "फिर चार्ज करें",
        report_label12: "बाहर करेंवर्तमान",
        report_label13: "एजेंटेशन पुनरावृत्ति",
        report_label14: "स्थानान्तरण",
        report_label15: "संग्रह",
        report_label16: "लाल अंवेलोप भेजें",
        report_label17: "लाल कंवेलप",
    },
    fund: {
        tip1: "कृपया निम्न बैंक खाता में स्थानान्तरण करें",
        tip2: "कृपया अपने स्थानान्तरण जानकारी सावधान से भरें",
        tip3: "(कृपया निश्चित करो कि क्रम देने से पहले फ़ंड स्थानांतरित करने के लिए,,अन्यथा, हम तुम्हारा पैसा को समय से नहीं पाएँगे！)",
        deposit_rate: "डिपोज़िट बदला दर",
        currency_number: "मुद्रा मात्रा",
        tip4: "कृपया संगत डिजिटल मुद्रा प्लेटफ़ॉर्म के लिए प्रयोग करें",
        tip5: "एकल ट्रांसेक्शन सीमा",
        tip6: "(यदि प्रमाणीकरण जानकारी भरने में असमर्थ，कृपया ट्रांसेक्शन रिसीप्ट का स्क्रीनसॉट अपलोड करें)",
        click_upload: "अपलोड करने के लिए क्लिक करें",
        upload_tip: "सिर्फ jpg/png फ़ाइल अपलोड कर सकते हैं",
        receiving_address: "पता प्राप्त कर रहा है",
        tip7: "कृपया ओवो को निम्न बैंक खाता में स्थानान्तरण करने के लिए इस्तेमाल करें",
        tip8: "1. ऑनलाइन बैंकिंग पैसा के लिए ऑनलाइन बैंकिंग को पैसा के साथ चलाने के लिए सक्रिय करने की आवश्यकता है.",
        tip9: "2. सफलता पुनर्चार्ज के बाद, खाता स्वचालित रूप से प्राप्त करने के लिए 'वापस करेंगे' को क्लिक करें.",
    },
    other: {
        gift_tip1: "1. प्रदानों को संग्रह के लिए स्थितियों का पूरा करना चाहिए इससे पहले कि वे जमा कर सकते हैं.",
        gift_tip2: "2. उपहार समाप्त होने के बाद, अनिवार्य किया गया उपहार स्वचालित रूप से अवैध हो जाएगा और फिर से प्रतिवेदन नह",
        other_tip1: "अक्षरों का रेकॉर्ड अधिकतम 7 दिनों के लिए रखें;",
    },
    form: {
        input_inner: "कृपया सामग्री भरें",
        label_username: "प्रयोक्ता खाता：",
        place_username: "कृपया उपयोक्ता खाता भरें",
        label_loginpsd: "लॉगइन पासवर्ड：",
        place_loginpsd: "कृपया अपना लॉगइन पासवर्ड भरें",
        label_invitecode: "निमन्त्रण कोड：",
        place_invitecode: "कृपया 8- अंक आमन्त्रण कोड भरें",
        label_oldpsd: "मौलिक पासवर्ड",
        place_oldpsd: "कृपया मूल पासवर्ड भरें",
        label_newpsd: "नया पासवर्ड",
        place_newpsd: "कृपया एक नया पासवर्ड भरें",
        label_crmpsd: "पासवर्ड पुष्टि करें",
        place_crmpsd: "कृपया नया पासवर्ड फिर भरें",
        label_remark: "टिप्पणी",
        place_remark: "कृपया एक टिप्पणी भरें",
        label_lower_account: "Account：",
        place_lower_account: "कृपया प्रविष्ट करेंस्तर खाता",
        label_type: "प्रकार：",
        place_select: "कृपया चुनें",
        label_lottery_title: "रंग सीड:：",
        label_state: "स्थिति：",
        option_state1: "पूर्ण",
        option_state2: "प्रतिज्ञा दिया जाना है",
        option_state3: "लॉटरी जीता नहीं है",
        option_state4: "लोटेरी जीता है",
        label_time: "समय：",
        times1: "आज",
        times2: "कल",
        times3: "इस महिना",
        times4: "पिछला महीना",
        label_realname: "वास्तविक नाम",
        place_realname: "कृपया अपना वास्तविक नाम भरें",
        label_bank_number: "बैंक कारसंख्या्ड",
        place_bank_number: "कृपया अपना बैंक कार्ड क्रमांक भरें",
        label_bank_name: "बैंक नाम",
        place_bank_name: "कृपया एक बैंक नाम चुनें",
        label_abstract: "अबस्ट्रेक्ट:",
        label_channel: "फिर चार्ज करें",
        place_channel: "कृपया फिर चैनल चुनें",
        label_deposit_amount: "फिर चार्ज करेंपैसा",
        place_deposit_amount: "कृपया पुनरार्ज मात्रा भरें",
        label_holder: "खाता होल्डर",
        label_deposit_name: "डेपोसिटर का नाम",
        place_deposit_name: "कृपया डेपोसिटर का नाम भरें",
        label_transfer_msg: "स्थानांतरित जानकारी",
        place_transfer_msg: "कृपया स्थानान्तरण जानकारी भरें",
        label_transfer_pic: "Screenshot of transaction receipt",
        label_transfer_pic_tip: "(कृपया ट्रांसेक्शन रिसीप्ट के स्क्रीनशॉट अपलोड करें)",
        label_bank_account_name: "बैंक खाता नाम",
        label_balance: "balance",
        label_available_withdraw: "छोड़ने योग्य मात्रा",
        label_available_number: "प्राप्त करने की संख्या उपलब्ध",
        label_withdraw_amount: "अंतर्गत मात्रा",
        placce_withdraw_amount: "कृपया रिट्रोकल मात्रा भरें)",
        available_withdraw: "आवश्यक",
        label_transfer_bank: "स्थानान्तरण बैंक",
        label_security_psd: "सुरक्षित पासवर्ड",
        place_security_psd: "कृपया सुरक्षित पासवर्ड भरें",
        place_nickname: "उपनाम",
        message_nickname: "कृपया उपनाम भरें",
        place_phone: "कृपया अपना फोन संख्या भरें",
        place_email: "कृपया अपना इमेल पता भरें",
        place_invite_id: "कृपया आमन्त्रणा कोड भरें",
        place_verify_code: "Please enter the verification code",
        send: "भेजें",
        test_username: "प्रयोक्ता नाम जाँचें",
        place_amount: "कृपया मात्रा भरें",
    },
    login: {
        login_tit: "प्रयोक्ता लागइन",
        forget_psd: "पासवर्ड भूल गया？",
        login: "लॉगइन",
        register_tit: "प्रयोक्ता रिजिस्ट्रेशन",
        register: "रेजिस्टर",
        have_account: "मौजूदा खाता, लगइन",
        country_code: "देश कोड",
        phone: "मोबाइल फोन",
        email: "मेलबाक्स",
    },
};
